import React, {Component} from "react";
import moment from 'moment';
import {withRouter, Link} from "react-router-dom";
import queryString from 'query-string';
import {NavLink, NavItem, Nav} from "reactstrap";
import classnames from "classnames";
import Api from "../../Utils/API";
import {Api as TicketNo, CustomerUi, LoopCustomerUi} from '../../Utils/consts';
import LoadingIndicator from "../../components/LoadingIndicator";
import ContactFormBody from "../../components/ContactFormBody";
import Avatar from "../../components/Avatar";
import DataTable from "../../components/DataTable";
import backIcon from "../../assets/images/backIcon.png";
import taskIcon from "../../assets/images/taskIcon.png";
import pencilIcon from "../../assets/images/pencilIcon.png";
import logoLightSvg from "../../assets/images/jiffi_logo.svg";
import loopSvg from "../../assets/images/loop-web-design-logo.svg";
import starGrey from "../../assets/images/starGrey.png";
import starYellow from "../../assets/images/starYellow.png";
import AddTask from '../../components/AddTask';
import AddRequest from '../../components/AddRequest';
import AddPhoneLog from '../../components/AddPhoneLog';
import AddTag from '../../components/AddTag';
import AddNote from '../../components/AddNote';
import imageIcon from "../../assets/images/inviteImage.png";
import logPhoneBlue from "../../assets/images/logPhoneBlue.png";
import blueTagIcon from "../../assets/images/blueTagIcon.png";
import blueNoteIcon from "../../assets/images/blueNoteIcon.png";
import trashIcon from "../../assets/images/trash.svg";
import iconLogin from "../../assets/images/iconLogin.svg";
import {getFirstWord} from '../../Utils/helper';
import Tags from "../../components/Tags";
import Swal from "sweetalert2";
import ConfirmDelete from "../../components/ConfirmDelete";
import RequestForm from "../../components/RequestForm";
import Skeleton from "react-loading-skeleton";
import UpdatePhoneLog from "../../components/UpdatePhoneLog";
import UpdateEmail from "../../components/UpdateEmail";
import ReactTooltip from "react-tooltip";

class ContactDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            addTask: false,
            addRequest: false,
            addCall: false,
            addTag: false,
            addNote: false,
            newRequest: false,
            loading: true,
            selectedProject: 0,
            coders: [],
            requestor: [],
            pricing_methods: [],
            statuses: [],
            requestDetails: [],
            request_types: [],
            company: [],
            contactDetails: [],
            projects: [],
            allTask: [],
            comments: [],
            allUser: [],
            allCoders: [],
            contactTags: 0,
            propertyDetailsName: '',
            propertyDetails: [],
            isSubmit: false,
            tags: [],
            customActiveTab: '1',
            requested_tasks: [],
            contactLogs: [],
            log_details: '',
            isPdetails: false,
            toastData: { title:'', description: '' },
            notes: [],
            updateEmail: false,
            updateCall: false,
            updateNote: false,
            note_details: [],
            disableLoginButton: null,
            userTypeOptions: [],
            userGroup: null,
            userGroupOptions: [],
        }
        this.toggleNav = this.toggleNav.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
        this.confirmDeleteContact = this.confirmDeleteContact.bind(this);
        this.reloadData = this.reloadData.bind(this);
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        let id = params.id;
        this.setState({id : id});
        this.getUserTypeOptions();
        this.getUserGroupOptions();
        this.contactInformation();
        this.allTask();
        this.getList();
        this.allCoders();
        this.allUser();
        this.getCompany();
        this.getContactsProjects();
        this.contactLogs();
        this.getUserTags(id);
        this.userNotes(id);
        window.addEventListener('load', this.handleLoad);
    }

    reloadData(id = null) {
        if(this.state.id == '')
            this.setState({ id: id })

        Api.taskDetails(this.state.id).then(result => {
            let newDetails = result.data;
            this.setState({ requestDetails: newDetails.content, newRequest: false, toastData: { title:'Success!', description: 'You successfully updated the request!' } });
            this.showToast();
            this.contactInformation();
        }).catch(error => {
            console.log(error);
        });
    }

    showToast() {
        var x = document.getElementById("snackbar-contact");
        x.className = "show row notification-div";
        setTimeout(() => { this.closeToast(); }, 5000);
    }

    removeTags = (tag, index) => {
        let params = {
            tag_id: tag.id,
            target_id: this.state.contactDetails.id,
            type: 'user'
        }

        Api.assignTag(params).then(result => {
            this.setState({tags: this.state.tags.filter(tag_item => this.state.tags.indexOf(tag_item) !== index)});
        }).catch(error => {
            console.log(error);
        });
    };

    contactLogs(){
        const params = queryString.parse(this.props.location.search);
        Api.contactLogsCustomer(params.id).then(result => { 
            this.setState({ contactLogs: result.data });
        }).catch(error => {
            console.log(error);
        });
    }

    getUserTags(id = null) {
        let uid = this.state.id;

        if(id !== null)
            uid = id;

        Api.getUserTags(uid).then(result => {
            this.setState({ tags: result.data });
        }).catch(error => {
            console.log(error);
        });
    }

    userNotes(id = null) {
        let uid = this.state.id;

        if(id !== null)
            uid = id;

        Api.getUserNotes(uid).then(result => {
            this.setState({ notes: result.data });
        }).catch(error => {
            console.log(error);
        });
    }

    getUserTypeOptions() {
        // retrieve user type options
        Api.getUserTypeOptions().then(result => { 
            this.setState({userTypeOptions: result.data});
        }).catch(error => {
            console.log('getUserTypeOptions error', error)
        });
    }

    getUserGroupOptions() {
        // retrieve user group options
        Api.getUserGroupOptions().then(result => {
            this.setState({userGroupOptions: result.data});
            console.log('::getUserGroupOptions', result.data);
        }).catch(error => {
            console.log('contacts-list > getUserGroupOptions error', error)
        });
    }

    getList() {
        Api.getRequestProjectTasks(this.state.selectedProject).then(result => {
            setTimeout(() => {
                this.setState({ columns: result.data.board, coders: result.data.coders, requestor: result.data.requestors, pricing_methods: result.data.pricing_methods, statuses: result.data.statuses, request_types: result.data.request_types, loading: false });
            }, 1000)
        }).catch(error => {
            console.log(error);
        });
    }

    confirmDeleteContact(id){
        let params = { id: id }
        let confirm_details = { page: 'contact' }

        ConfirmDelete(confirm_details).then(res => {
            if (res.isConfirmed) {
                this.deleteContact(params)
            }
        })
    }

    deleteContact(params) {
        Api.updateUserStatus(params).then(result => {
            //add swal here . . . 
            Swal.fire( 'Deleted!', 'Contact has been deleted.', 'success' )
            this.props.history.goBack();
        }).catch(error => {
            Swal.fire('Changes are not saved', '', 'info')
            console.log(error);
        });
    }
    
    handleLoad() {
        document.onmouseup = function (e) {
            if (!e.target.closest(".primary-assignee")) {
                if (document.body.classList.contains('primary-assignee-show')) {
                    document.body.classList.remove('primary-assignee-show');
                    document.querySelector('.primary-assignee-content').classList.add('toogle-hide');

                }
            }

            if (!e.target.closest(".primary-qa-content")) {
                if (document.body.classList.contains('primary-qa-show')) {
                    document.body.classList.remove('primary-qa-show');
                    document.querySelector('.primary-qa-content').classList.add('toogle-hide');

                }
            }
        };


    }

    updateDetails(e) {
        this.setState({ details: e });
    }

    propertyDetails(e) {
        document.body.classList.remove('minimize-form');
        if (e.credentials) {
            let credentials = e.credentials;
            for (let index = 0; index < credentials.length; index++) {
                   if(this.IsJsonString(credentials[index].details)){
                       credentials[index].details = JSON.parse(credentials[index].details);
                  }
            }
            this.setState({ propertyDetailsName: e.name , propertyDetails: credentials, isPdetails: true });
        }
    }

    contactInformation() {
        let params = queryString.parse(this.props.location.search);
        Api.userProfile(params.id).then(result => {
            this.setState({
                requested_tasks: result.data.requested_tasks,
                contactDetails: result.data,
                loading: false,
                disableLoginButton: result.data.is_registered == 0 ? true : false,
                userGroup: result.data?.user_group?.title?.toLowerCase(),
            });
            this.getUserTags();
        }).catch(error => {
            console.log(error);
        });
    }

    getContactsProjects() {
        let params = queryString.parse(this.props.location.search);
        Api.getContactsProjects(params.id).then(result => { 
            let projects = [];
            result.data.map((project) => {
                projects.push({label: project.text, value: project.id})
            })
            this.setState({ projects:  projects});
        }).catch(error => {
            console.log(error);
        });
    }

    getCompany() {
        Api.getCompany().then(result => {
            this.setState({ company: result.data })
        }).catch(error => {
            console.log(error);
        });
    }

    allTask() {
        Api.allTask().then(result => {
            this.setState({ allTask: result.data })
        }).catch(error => {
            console.log('ContactDetails > allTask error', error);
        });
    }

    allCoders() {
        Api.allCoders().then(result => {
            this.setState({ allCoders: result.data })
        }).catch(error => {
            console.log(error);
        });
    }

    allUser() {
        Api.allUser().then(result => {
            this.setState({ allUser: result.data })
        }).catch(error => {
            console.log(error);
        });
    }

    minimize(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');
    }

    addContact() {
        document.body.classList.remove('minimize-form');
    }

    openTask(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ addTask: true, addRequest: false, addCall: false, addTag: false, addNote: false, newRequest: false });
    }

    openRequest(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ addTask: false, addRequest: true, addCall: false, addTag: false, addNote: false, newRequest: false });
    }

    openCall(event){
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ addTask: false, addRequest: false, addCall: true, addTag: false, addNote: false, updateNote: false, updateCall: false, newRequest: false, log_details: '' });
    }

    updateCall(event, log){
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ log_details: log, updateEmail: false, addTask: false, addRequest: false, addCall: false, addTag: false, addNote: false, updateNote: false, updateCall: true });
    }

    updateEmail(event, log){
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ log_details: log, updateEmail: true, addTask: false, addRequest: false, addCall: false, addTag: false, addNote: false, updateNote: false, updateCall: false, newRequest: false })
    }

    openTag(event){
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ addTask: false, addRequest: false, addCall: false, addTag: true, addNote: false, newRequest: false });
    }

    openNote(event){
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ addTask: false, addRequest: false, addCall: false, addTag: false, addNote: true, newRequest: false });
    }

    toggleNav(e) {
        this.setState({ customActiveTab: e })
    }

    closeTask(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ addTask: false });
    }

    closeRequest(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ addRequest: false, newRequest: false, updateRequest: false });
    }

    closeCall(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ addCall: false, updateCall: false });
    }

    closeTag(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ addTag: false });
    }

    closeNote(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ addNote: false, updateNote: false });
    }

    sendInvite(type) {
        if(this.state.contactDetails.company_id == null) {
            Swal.fire( 'Cannot send an invite!', 'Contact is not linked to a company.', 'error' )
        } else {
            this.setState({ isSubmit: true });
            let params = queryString.parse(this.props.location.search);
            let name =  this.state.contactDetails ?  `${this.state.contactDetails.firstname} ${this.state.contactDetails.surname}` :'' 
            Api.sendInvite({id: params.id, type}).then(result => {
                console.log(result.data);
                
                this.setState({ isSubmit: false ,toastData: { title:'Invitation Sent!', description: `Your invitaiton has successfully been sent to ${name}.` } });
                this.showToast();
            }).catch(error => {
                this.setState({ isSubmit: false });
            });
        }
    }


    showToast() {
        var x = document.getElementById("snackbar-contact");
        x.className = "show row notification-div";
        setTimeout(() => { this.closeToast(); }, 5000);
    }

    closeToast() {
        var x = document.getElementById("snackbar-contact");
        x.className = x.className.replace("show", "");
    }

    newRequest(e){ 
        let requested_tasks = this.state.requested_tasks.concat(e.task);
        this.setState({ addNote: false, updateNote: false, requested_tasks: requested_tasks, addRequest: false, isSubmit: false ,toastData: { title:'Success!', description: 'You successfully created a new request!' } });
        this.showToast();
    }

    updateList(e){ 
        let requested_tasks = this.state.requested_tasks.concat(e);
        this.setState({ addNote: false, updateNote: false, requested_tasks: requested_tasks, addTask: false ,toastData: { title:'Success!', description: 'You successfully created a new task!' } });
        this.showToast();
    }

    newPhoneCall(e){
        this.contactLogs();
        this.setState({ addCall: false, addNote: false, updateNote: false, toastData: { title:'Success!', description: `You successfully logged a phone call!` } });
        this.showToast();
    }

    assignTag() {
        this.getUserTags();
    }

    newNote(note) {
        const notes = this.state.notes;
        notes.splice(0, 0, note);
        this.setState({ notes: notes, addNote: false, updateNote: false, isSubmit: false, toastData: { title: 'Success!', description: 'You successfully created a new note!' } });
        this.showToast();
    }

    updateProfile(request){
        this.setState({contactDetails: request})
    }

    updateRequest(event, request){
        event.stopPropagation();
        let id = request.id;
        
        this.setState({ comments: { loading: true, value: [] } });
        if (id !== this.state.id) {
            Api.getComment(request.id).then(result => {
                this.setState({ comments: { loading: false, value: result.data.replies ? result.data.replies : [] } });
            }).catch(error => {
                console.log(error);
            });
        }

        if (id) {
            Api.taskDetails(id).then(result => {
                this.setState({ id: id });
                document.body.classList.add('actual-time-and-estimate-show');
                this.setState({ newRequest: true, requestDetails: result.data.content });
            }).catch(error => {
                console.log(error);
            });
        }
    }

    contactInformationPreloader() {
        return (
            <React.Fragment>
                <ul className="contact-preloader-container">
                    <li><Skeleton height={25} width={'60%'} /></li>
                    <li><Skeleton height={25} width={'30%'} /></li>
                    <li><Skeleton height={25} width={'35%'} /></li>
                    <li><Skeleton height={25} width={'40%'} /></li>
                    <li><Skeleton height={25} width={'50%'} /></li>
                    <li><Skeleton height={25} width={'45%'} /></li>
                    <li><Skeleton height={25} width={'35%'} /></li>
                    <li><Skeleton height={25} width={'30%'} /></li>
                    <li><Skeleton height={25} width={'25%'} /></li>
                    <li><Skeleton height={25} width={'22%'} /></li>
                </ul>
            </React.Fragment>
        )
    }

    contactNamePreloader(){
        return (
            <React.Fragment>
                <div className="row-2 ml-2">
                    <div>
                        <Skeleton style={{marginRight: 10}} height={20} width={65}/>
                        <Skeleton height={20} width={65}/>
                    </div>
                    <div>
                        <Skeleton style={{marginRight: 10}} height={15} width={120}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    updateContactLog(e, contact, index) {
        let log_details = {
            contact: contact,
            index: index
        }

        if(contact.type == 'phone call')
            return this.updateCall(e, log_details)

        return this.updateEmail(e, log_details);
    }

    updateCall(event, log) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ log_details: log, addTask: false, addRequest: false, addCall: false, addTag: false, addNote: false, updateNote: false, updateCall: true, updateEmail: false });
    }

    updateEmail(event, log) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ log_details: log, updateEmail: true, addTask: false, addRequest: false, addCall: false, addTag: false, addNote: false, updateNote: false, updateCall: false, newRequest: false })
    }

    updateContactNote(e, note, index) {
        const noteDetails = {
            id: note.id,
            note: note.note,
            subject: note.subject,
            index: index,
            attachments: note.attachments
        }

        this.viewNote(e, noteDetails)
    }

    viewNote(event, noteDetails){
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ note_details: noteDetails, addTask: false, addRequest: false, addCall: false, addTag: false, addNote: false, updateNote: true, updateCall: false, newRequest: false });
    }

    updateNoteList(note) {
        let notes = this.state.notes;
        notes.splice(notes.findIndex(item => item.id == note.id), 1);
        const updatedList = [note].concat(notes);

        this.setState({ notes: updatedList, updateNote: false, isSubmit: false, toastData: { title: 'Success!', description: 'You successfully created a new note!' } });
        this.showToast();
    }

    closeNewEmail(event){
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        this.setState({ updateEmail: false })
    }

    updatedPhoneCall(e){
        this.contactLogs();
        this.setState({ updateCall: false, toastData: { title:'Success!', description: `You successfully updated a phone call!` } });
        this.showToast();
    }

    loginAsContactInCustomerPortal(e) {
        const contact = this.state.id;

        // call customer portal api to generate contact's encrypted token
        Api.generateContactToken(contact).then(({data}) => {
            // redirect to customer portal
            if(data.user_group.toLowerCase() === 'jiffiwebhelp') {
                window.open(CustomerUi + 'login?token=' + data.access_token, '_blank');
            } else {
                window.open(LoopCustomerUi + 'login?token=' + data.access_token, '_blank');
            }
        }).catch(error => {
            // Error 😨
            if (error.response) {
                /*
                * The request was made and the server responded with a
                * status code that falls out of the range of 2xx
                */
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                /*
                * The request was made but no response was received, `error.request`
                * is an instance of XMLHttpRequest in the browser and an instance
                * of http.ClientRequest in Node.js
                */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }

            console.log(error);
        });
    }

    render() {  
        const columns = this.state.columns;
        const data = { columns };
        let params = queryString.parse(this.props.location.search);
        const { contactDetails, requested_tasks, contactLogs, notes, tags, disableLoginButton, loading } = this.state;
        const dataTable = {
            columns: [
                {
                    label: "ID No",
                    field: "idNo",
                },
                {
                    label: "Request Name",
                    field: "requestName",
                },
                {
                    label: "Project",
                    field: "project",
                },
                {
                    label: "Status",
                    field: "status",
                },
                {
                    label: "Assignee",
                    field: "assignee",
                },
                {
                    label: "Due",
                    field: "due",
                }
            ],
            rows: []
        };

        let list = this.state.requested_tasks; 
        for (let index = 0; index < list.length; index++) {
            dataTable.rows.push(
                {
                    idNo: list[index].id,
                    requestName: list[index].text,
                    project: list[index].project ? list[index].project.name : '',
                    status: <div className="request-status">
                                {list[index].status &&
                                    <>
                                        <i className={`fas fa-circle status-icon status-${list[index].status.id}`}></i>
                                        {list[index].status.title}
                                    </>
                                }
                            </div>,
                    assignee: list[index].primary_assignee ? `${list[index].primary_assignee.firstname} ${list[index].primary_assignee.surname}` : '',
                    requestName: list[index].text,
                    due: list[index].due_date ? moment(list[index].due_date).format(' Do MMMM ') : '',
                    clickEvent: (e) => this.updateRequest(e, list[index])
                }
            );
        }
        
        const dataTableLogs = {
            columns: [
                {
                    label: "Time",
                    field: "time",
                },
                {
                    label: "Subject",
                    field: "subject",
                },
                {
                    label: "Team",
                    field: "team",
                },
                {
                    label: "Type",
                    field: "type",
                }
            ],
            rows: []
        };

        for (let index = 0; index < contactLogs.length; index++) {
            dataTableLogs.rows.push(
                {
                    time: <span style={{cursor: "pointer"}} onClick={(e) => this.updateContactLog(e, contactLogs[index], index)}>{contactLogs[index].date}</span>,
                    subject: <span style={{cursor: "pointer"}} onClick={(e) => this.updateContactLog(e, contactLogs[index], index)}>{contactLogs[index].target?.subject}</span>,
                    team: contactLogs[index].type === "phone call" ? contactLogs[index].target?.contact?.firstname + " " + contactLogs[index].target?.contact?.lastname : contactLogs[index].target?.sender?.firstname + " " + contactLogs[index].target?.sender?.lastname,
                    type: <span style={{cursor: "pointer"}} onClick={(e) => this.updateContactLog(e, contactLogs[index], index)}>{contactLogs[index].type}</span>,
                }
            );
        }

        const dataTableNotes = {
            columns: [
                {
                    label: "Time",
                    field: "time",
                },
                {
                    label: "Subject",
                    field: "subject",
                },
                {
                    label: "Team",
                    field: "team",
                },
                {
                    label: "Type",
                    field: "type",
                }
            ],
            rows: []
        };

        for (let index = 0; index < notes.length; index++) {
            dataTableNotes.rows.push(
                {
                    time: notes[index].date,
                    subject: <span style={{cursor: "pointer"}} onClick={(e) => { this.updateContactNote(e, notes[index], index) }}>{notes[index].subject}</span>,
                    team: notes[index].firstname + ' ' + notes[index].lastname,
                    type: 'user',
                }
            );
        }

       let selectedTags = tags => console.log(tags);
        return (
            <React.Fragment>
                <div id="snackbar-contact" onClick={this.closeToast.bind(this)} className="display-none"  >
                    <div className="col-5 notification-image flex-center" >
                        <img src={imageIcon} />
                    </div>
                    <div className="col-7 notification-details my-auto" >
                        <h5>{this.state.toastData.title}</h5>
                        <p>{this.state.toastData.description}</p>
                    </div>
                </div>
               
                { this.state.loading && <LoadingIndicator loading={this.state.loading}/> }
                <div className="page-content page-content-grey">
                    <div className="container-fluid container-body">
                        <div className="row row-contacts">
                            <div className="col-xs-12 col-md-5  col-lg-5 div-1">
                                <div>
                                    {!params.fromCompanyProfile &&
                                        <Link to="contacts-list" className="btn btn-icon template back-to-list" > <img src={backIcon} />  &nbsp; Back to Contact List</Link>
                                    }
                                </div>
                                <span className="profile-type"> CONTACT </span>
                                <div className="basic-contact flex-row mt-4">
                                    <div className="row-1">
                                        <Avatar
                                            name={ contactDetails.firstname ?  getFirstWord(contactDetails.firstname) : 'no name' }
                                            round={true}
                                            size="43"
                                            color="#f44336"
                                            image={""}
                                        />
                                    </div>
                                        {!this.state.loading ? 
                                            <div className="row-2 ml-2">
                                                <div>{contactDetails.firstname} {contactDetails.surname}</div>
                                                <div className="company">{contactDetails.company ? contactDetails.company.name || contactDetails.company.label : ''}</div>
                                            </div>
                                            : this.contactNamePreloader() 
                                        }
                                </div>
                                <div className="action-btn">
                                    <button disabled={loading} onClick={this.openTask.bind(this)} className="btn btn-default mr-3 profile-btn" > <img src={taskIcon} />  &nbsp; Add Task</button>
                                    <button disabled={loading} onClick={this.openRequest.bind(this)} className="btn btn-default mr-3 profile-btn" > <img src={pencilIcon} />  &nbsp; Add Request</button>
                                    <button disabled={loading} onClick={this.openCall.bind(this)} className="btn btn-default mr-3 profile-btn" > <img src={logPhoneBlue} />  &nbsp; Log Phone Call</button>
                                    <button disabled={loading} onClick={this.openTag.bind(this)} className="btn btn-default mr-3 profile-btn"><img src={blueTagIcon} />&nbsp; Add Tag</button>
                                    <button disabled={loading} onClick={this.openNote.bind(this)} className="btn btn-default mr-3 profile-btn"><img src={blueNoteIcon} />&nbsp; Add Note</button>
                                    <button disabled={loading} onClick={() => this.confirmDeleteContact(this.state.contactDetails.id)} className="btn btn-default mr-3 profile-btn delete-contact-btn"><img className="delete-contact-icon" src={trashIcon} />&nbsp; Delete</button>
                                    <span data-tip data-for={`message-tooltip`}>
                                        <button disabled={loading || disableLoginButton} onClick={(e) => this.loginAsContactInCustomerPortal(e)} className="btn btn-default mr-3 profile-btn delete-contact-btn"><img className="delete-contact-icon" src={iconLogin} />&nbsp; Login as {contactDetails.firstname ?  getFirstWord(contactDetails.firstname) : 'contact'}</button>
                                    </span>
                                    {disableLoginButton &&
                                        <ReactTooltip 
                                            id={`message-tooltip`} 
                                            effect="solid" 
                                            className="message-tooltip"
                                            offset={{'top': 0, 'right': 20}} 
                                            arrowColor="transparent"
                                            backgroundColor="#00000073"
                                        > 
                                            User Account is not complete!
                                        </ReactTooltip>
                                    }
                                    
                                </div>
                                {/* Jiffi invite button */}
                                <div className="company-details-div flex-row bb-0">
                                    <div className="flex-item">
                                        <img src={logoLightSvg} alt="" height="38" width="85" />
                                        <span className="name" >Jiffi Account</span>
                                    </div>
                                    <div className="flex-item flex-1 flex-right resend">
                                    {/* <button disabled={this.state.loading} onClick={this.sendInvite.bind(this)} className="btn btn-outline-primary" >  */}
                                    <button disabled={this.state.loading} onClick={() => this.sendInvite('jiffi')} className="btn btn-outline-primary jiffi-orange">
                                            { this.state.isSubmit ?
                                                        <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
                                                    :   <i className="fa fa-paper-plane"></i>
                                            }
                                            Send Invite
                                        </button>
                                    </div>
                                </div>

                                {/* Loop invite button */}
                                <div className="company-details-div flex-row">
                                    <div className="flex-item">
                                        <img src={loopSvg} alt="" height="38" width="85" />
                                        <span className="name" >Loop Account</span>
                                    </div>
                                    <div className="flex-item flex-1 flex-right resend">
                                    {/* <button disabled={this.state.loading} onClick={this.sendInvite.bind(this)} className="btn btn-outline-primary" >  */}
                                    <button disabled={this.state.loading} onClick={() => this.sendInvite('loop')} className="btn btn-outline-primary">
                                            { this.state.isSubmit ?
                                                        <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
                                                    :   <i className="fa fa-paper-plane"></i>
                                            }
                                            Send Invite
                                        </button>
                                    </div>
                                </div>

                                <div className="contact-info">
                                    <p className="title">CONTACT INFORMATION</p>
                                    {!this.state.loading ? 
                                        <ContactFormBody
                                            company={this.state.company}
                                            contactDetails={this.state.contactDetails}
                                            id={this.state.id}
                                            updateProfile={this.updateProfile.bind(this)}
                                            userTypeOptions={this.state.userTypeOptions}
                                            userGroupOptions={this.state.userGroupOptions}
                                            userGroup={this.state.userGroupOptions.filter(option => option.label.toLowerCase() === this.state.userGroup)[0]}
                                            updateSendInvite={(obj) => {
                                                this.setState({
                                                    ...this.state,
                                                    userGroup: obj.label.toLowerCase()
                                                });
                                            }}
                                        />
                                        : this.contactInformationPreloader()
                                    }
                                </div>
                                <div className="other-info">
                                    <p className="title">OTHER INFO</p>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            Total Requests
                                            <p className="result">2</p>
                                        </div>
                                        <div className="col-lg-4">
                                            Last Review
                                            <div className="rating">
                                                <img src={starYellow} />
                                                <img src={starYellow} />
                                                <img src={starYellow} />
                                                <img src={starYellow} />
                                                <img src={starGrey} />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            Last Contact
                                            <p className="result">28th July 20</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-7 col-lg-7 div-2" style={{ background: '#F8F8FB' }}>
                                <Nav tabs className="nav-tabs-custom">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.customActiveTab === "1"
                                            })}
                                            onClick={() => {
                                                this.toggleNav("1");
                                            }}
                                        >
                                            <span className="d-sm-block">Requests & Tasks</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="navlink-second" >
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.customActiveTab === "2"
                                            })}
                                            onClick={() => {
                                                this.toggleNav("2");
                                            }}
                                        >
                                            <span className="d-sm-block">Contact Log</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="navlink-second" >
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.customActiveTab === "3"
                                            })}
                                            onClick={() => {
                                                this.toggleNav("3");
                                            }}
                                        >
                                            <span className="d-sm-block">Notes & Tags</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                { this.state.customActiveTab === '1' &&
                                    <div className="request-task clickable-datatable">
                                        <p className="title">Requests & Tasks</p>
                                        <DataTable data={dataTable} entriesOptions={[8, 30, 100, 500]} entries={8}/>
                                    </div>
                                }
                                { this.state.customActiveTab === '2' &&
                                    <div className="request-task">
                                        <p className="title">Contact Logs</p>
                                        <DataTable data={dataTableLogs} entriesOptions={[8, 30, 100, 500]} entries={8}/>
                                    </div>
                                }
                                { this.state.customActiveTab === '3' &&
                                    <div>
                                        <div className="request-task">
                                            <div className="header">Notes</div>
                                            <DataTable data={dataTableNotes} entriesOptions={[8, 30, 100, 500]} entries={8}/>
                                        </div>
                                        <div className="request-task">
                                            <div className="header tag-title">Tags <span className="tags-count">{this.state.tags.length} Tags</span></div>
                                            <Tags tags={this.state.tags} removeTags={this.removeTags} selectedTags={selectedTags}/>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.addTask &&
                    <AddTask
                        minimize={this.minimize.bind(this)}
                        open={this.openTask.bind(this)}
                        close={this.closeTask.bind(this)}
                        task={this.state.allTask}
                        coders={this.state.allCoders}
                        projects={this.state.projects}
                        updateList={this.updateList.bind(this)}
                        contactDetails={this.state.contactDetails}
                        name={ contactDetails.firstname ?  `${getFirstWord(contactDetails.firstname)} ${getFirstWord(contactDetails.surname)}` :'' }
                    />
                }

                { this.state.addRequest &&
                    <AddRequest
                        isContact={true}
                        minimize={this.minimize.bind(this)}
                        open={this.openRequest.bind(this)}
                        close={this.closeRequest.bind(this)}
                        task={this.state.allTask}
                        coders={this.state.allCoders}
                        allUser={this.state.allUser}
                        contacts={this.state.contactDetails}
                        name={ contactDetails.firstname ?  `${getFirstWord(contactDetails.firstname)} ${getFirstWord(contactDetails.surname)}` :'' }
                        newRequest={this.newRequest.bind(this)}
                        company_id={contactDetails.company_id}
                    />
                }

                { this.state.newRequest &&
                    <RequestForm
                        coders={this.state.coders}
                        requestor={this.state.requestor}
                        details={this.state.requestDetails}
                        pricing_methods={this.state.pricing_methods}
                        request_types={this.state.request_types}
                        statuses={this.state.statuses}
                        close={this.closeRequest.bind(this)}
                        reloadData={this.reloadData.bind(this)}
                        name={this.state.name}
                        comments={this.state.comments}
                        propertyDetails={this.propertyDetails.bind(this)}
                        updateDetails={this.updateDetails.bind(this)}
                    />
                }

                { this.state.addCall &&
                    <AddPhoneLog
                        minimize={this.minimize.bind(this)}
                        open={this.openCall.bind(this)}
                        close={this.closeCall.bind(this)}
                        id={this.state.id}
                        action={this.newPhoneCall.bind(this)}
                    />
                }

                { this.state.addTag &&
                    <AddTag
                        minimize={this.minimize.bind(this)}
                        open={this.openTag.bind(this)}
                        close={this.closeTag.bind(this)}
                        selectContact={true}
                        targetType="user"
                        targetId={this.state.contactDetails?.id}
                        targetName={this.state.contactDetails.firstname + ' ' + this.state.contactDetails.surname}
                        assign={this.assignTag.bind(this)}
                        tags={this.state.tags}
                        form={'addtag-form'}
                    />
                }

                { this.state.updateCall &&
                    <UpdatePhoneLog
                        id={this.state.id}
                        minimize={this.minimize.bind(this)}
                        open={this.updateCall.bind(this)}
                        close={this.closeCall.bind(this)}
                        action={this.updatedPhoneCall.bind(this)}
                        contacts={this.state.companyDetails?.contacts}
                        selectContact={false}
                        contactDetailsUpdate={this.state.log_details}
                    />
                }
                
                { this.state.updateEmail &&
                    <UpdateEmail
                        minimize={this.minimize.bind(this)}
                        users={this.state.allUser}
                        contacts={this.state.companyDetails?.contacts}
                        close={this.closeNewEmail.bind(this)}
                        updateEmail={this.updateEmail.bind(this)}
                        emailDetails={this.state.log_details}
                    />
                }

                { this.state.addNote &&
                    <AddNote
                        minimize={this.minimize.bind(this)}
                        open={this.openNote.bind(this)}
                        close={this.closeNote.bind(this)}
                        selectContact={true}
                        targetType="user"
                        targetId={this.state.id}
                        newNote={this.newNote.bind(this)}
                        isNew={true}
                    />
                }

                { this.state.updateNote &&
                    <AddNote
                        minimize={this.minimize.bind(this)}
                        open={this.openNote.bind(this)}
                        close={this.closeNote.bind(this)}
                        selectContact={true}
                        targetType="user"
                        targetId={this.state.id}
                        updateNote={this.updateNoteList.bind(this)}
                        isNew={false}
                        noteDetails={this.state.note_details}
                    />
                }
            </React.Fragment>
        );
    }
}

export default withRouter(ContactDetails);
