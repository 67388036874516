import React from "react";

const TaskActivity = (props) => {
    return (
        <React.Fragment>
            <div className="activity-container">
                <div className="activity-desc">{props.description}</div>
                <div className="activity-date">{props.date}</div>
            </div>
        </React.Fragment>
    );
};

export default TaskActivity;