import React, { useEffect, useState } from 'react';

/* SVG */
import internal_note from "../../assets/images/internal_note.svg";
import pencilIconLight from "../../assets/images/pencilIconLight.png";
import pencilIconGrey from "../../assets/images/pencilIconGrey.png";
import internalNote from "../../assets/images/internalNote.png";
import publicReply from "../../assets/images/publicReply.png";
import archiveIcon from "../../assets/images/archive.svg";
import no_email_selected_icon from "../../assets/images/no_email-selected.svg";
import trash from "../../assets/images/trash.svg";
import reply from "../../assets/images/reply.svg";
import iconAttachment from "../../assets/images/attachmentIcon.png";
import AttachmentFile from "../../components/AttachmentFile";

/* PACKAGES */
import { getFirstWord, getCookie } from '../../Utils/helper';
import moment from 'moment';
import htmlParser from 'react-html-parser';
import Avatar from 'react-avatar';
import Api from "../../Utils/API";
import ReactTooltip from "react-tooltip";
import Swal from 'sweetalert2';

const logged_in_user = getCookie("userDetails");
let user_details = "";
if (logged_in_user) user_details = JSON.parse(logged_in_user);

const EmailThread = (props) => {
    const [authUser, setAuthUser] = useState('');

    useEffect(() => {
        Api.getAuthUser().then(result => {
            const user = result.data;
            console.log(user)
            setAuthUser(user)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <React.Fragment>
            { props.selectedEmail.length <= 0 ?  
                <>
                    <div className="no-email">
                        <img src={no_email_selected_icon} alt="" height="200" width="210" />
                        <div className="no-text">No Selected Email</div>
                    </div>
                </>
                :
                <>
                    <div className="email-details">
                        <div className="top-action">
                            <div className="top-action-title">
                                {props.selectedEmail.subject}
                            </div>
                            <div className="top-action-btn">
                                {/* <button className="btn btn-circle-email"><img src={starIcon} alt="" height="20" /></button> */}
                                <button className="btn btn-circle-email"><img onClick={() => props.confirmArchiveEmails(false)} src={archiveIcon} alt="" height="20" /></button>
                                {props.selectedEmail.sender.email == authUser.email &&<button className="btn btn-circle-email"><img onClick={() => props.confirmDeleteEmail()} src={trash} alt="" height="20" /></button>}
                                {(user_details.role === 'super admin' || user_details.role === 'admin' || user_details.role === 'customer service agent') && <button className="btn btn-circle-email" onClick={() => props.scroll(props.myRefPublic, 1)}><img src={reply} alt="" height="20" /></button>}
                                <button className="btn btn-circle-email" onClick={() => props.scroll(props.myRefPublic, 2)} ><img src={internal_note} alt="" height="20" /></button>
                                { !props.selectedEmail.task_id && !props.NewticketRequest &&
                                    <>
                                        <button className="btn btn-circle-email" onClick={props.isAddRequest ? null : props.addRequest} disabled={props.isAddRequest}>
                                            <span data-tip data-for={`message-tooltip`}>
                                                {props.isAddRequest ? <i className="bx bx-loader bx-spin font-size-14 align-middle mr-0"></i> : <img src={pencilIconGrey} alt="" height="20" />}
                                            </span>
                                        </button>
                                        {/* <i data-tip data-for={`message-tooltip`} className="fa fa-trash"></i> */}
                                        {props.isAddRequest &&
                                            <ReactTooltip 
                                                id={`message-tooltip`}
                                                effect="solid"
                                                className="message-tooltip"
                                                offset={{'top':0,'right':0}}
                                                arrowColor="transparent"
                                                backgroundColor="#00000073"
                                            >A request is being created.</ReactTooltip>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    {/* REPLIES */}
                    { props.selectedEmail.replies.map((item, i) => 
                        <div className="email-details" key={i}>
                            <div className="email-card">
                                <div className="email-head">
                                    {item.sender !== null &&
                                        <div className="avatar-name">
                                            <div className="mr-2">
                                                <Avatar
                                                    name={getFirstWord(item.from)}
                                                    round={true}
                                                    size="47"
                                                    color="#f44336"
                                                    image={""}
                                                />
                                            </div>
                                            <div className="avatar-content">
                                                <h2>{item.sender.firstname} {item.sender.lastname}</h2>
                                            </div>
                                        </div>
                                    }

                                    <div className="date-time email-type">
                                        {moment(item.created_at).format(' dddd, DD  MMM ' + ',' + 'h:mm A')}
                                        <div 
                                            className="email-type-div" 
                                            style={{color: item.email_tag ? item.email_tag.color : '', backgroundColor: item.email_tag ? item.email_tag.bg_color : ''}}
                                        >
                                            <span>{item.email_tag && item.email_tag.name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="email-content">
                                    { htmlParser(item.body) }
                                </div>
                                <table className="table-attachment table table-centered table-nowrap table">
                                    <tbody>
                                        <tr>
                                            <td width="150" ><img src={iconAttachment} /> <span className="ml-2" >{item?.attachments?.length || 0} attachments</span> </td> 
                                            <td ><div className="border-center"></div></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="container" >
                                    <div className="row attachment-list-row">
                                        {(item?.attachments?.length > 0) && item?.attachments?.map((attachment, i) => {
                                            return (
                                                <div className="col-md-6" style={{'marginTop': '2px', 'marginBottom': '10px'}} key={i} >
                                                    <AttachmentFile data={attachment} email={true}/>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div> 
                            </div>
                        </div>
                    )}

                    {/* MAIN EMAIL */}
                    <div className="email-details">
                        <div className="email-card">
                            <div className="email-head">
                                <div className="avatar-name">
                                    <div className="mr-2">
                                        <Avatar
                                            name={getFirstWord(props.selectedEmail.from)}
                                            round={true}
                                            size="47"
                                            color="#f44336"
                                            image={""}
                                        />
                                    </div>
                                    <div className="avatar-content">
                                        <h2>{props.selectedEmail.sender ? props.selectedEmail.sender.firstname : ''} {props.selectedEmail.sender ? props.selectedEmail.sender.lastname : ''}</h2>
                                        <div className="selected-to">
                                            To: {props.selectedEmail.to}
                                        </div>
                                    </div>
                                </div>
                                <div className="date-time">
                                    {moment(props.selectedEmail.created_at).format(' dddd, DD  MMM ' + ',' + 'h:mm A')}
                                </div>
                            </div>
                            <div className="email-content">
                                {htmlParser(props.selectedEmail.body)}
                            </div> 
                            <table className="table-attachment table table-centered table-nowrap table">
                                <tbody>
                                    <tr>
                                        <td width="150" ><img src={iconAttachment} /> <span className="ml-2" >{props.selectedEmail.attachments.length} attachments</span> </td> 
                                        <td ><div className="border-center"></div></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="container" >
                                <div className="row attachment-list-row">
                                    {props.selectedEmail.attachments.map((item, i) => {
                                        return (
                                            <div className="col-md-6" style={{'marginTop': '2px', 'marginBottom': '10px'}} key={i} >
                                                <AttachmentFile data={item} email={true}/>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div> 
                        </div>
                    </div>

                    {!props.isInternal && !props.isPublicReply && !props.isProposal &&
                        <div className="row email-actions-bottom" >
                            {(user_details.role === 'super admin' || user_details.role === 'admin' || user_details.role === 'customer service agent') &&
                                <button className="col-lg-4 btn btn-default" onClick={() => props.scroll(props.myRefPublic, 1)} >
                                    <img src={publicReply} style={{ marginRight: 10 }} />
                                    Public Reply
                                </button>
                            }
                            
                            <button className="col-lg-4 btn btn-default" onClick={() => props.scroll(props.myRefPublic, 2)} >
                                <img src={internalNote} style={{ marginRight: 10 }} />
                                Internal Note
                            </button>
                            {!props.selectedEmail.task_id && !props.NewticketRequest &&
                                <>
                                    <button className="col-lg-4 btn btn-default" 
                                        onClick={props.isAddRequest ? null : props.addRequest} 
                                        disabled={props.isAddRequest}
                                    >
                                        <span data-tip data-for='message-tooltip-bottom'>
                                            {
                                                props.isAddRequest ?
                                                    (<i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i>)
                                                    : ''
                                            }
                                            <i style={{marginRight:10}} className="fa fa-plus"></i>
                                            Add Request
                                        </span>
                                    </button>
                                    {props.isAddRequest &&
                                        <ReactTooltip 
                                            id={`message-tooltip-bottom`}
                                            effect="solid"
                                            className="message-tooltip"
                                            offset={{'top':0,'right':0}}
                                            arrowColor="transparent"
                                            backgroundColor="#00000073"
                                        >A request is being created.</ReactTooltip>
                                    }
                                </>
                            }
                            {!props.requestDetails.task_number &&
                                <ReactTooltip 
                                    id={`send-proposal-btn-message-tooltip`}
                                    effect="solid"
                                    className="message-tooltip"
                                    offset={{'top':0,'right':0}}
                                    arrowColor="transparent"
                                    backgroundColor="#00000073"
                                >A request is not yet created for this email.</ReactTooltip>
                            }
                        </div>
                    }
                </>
            }
        </React.Fragment>
    )
}

export default EmailThread;