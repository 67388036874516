// @flow
import { bindActionCreators } from "redux";
import {
    ADVANCE_SEARCH_TASKS,
    SEARCH_TASKS,
    FILTER_TASKS,
    FILTER_MY_TASKS
} from "./actionTypes";

const INIT_STATE = {
    taskAdvanceSearch: [],
    taskSearchString: "",
    taskFilter: {
        org: null,
        con: null,
        page: null
    },
    myTaskFilter: {
        type: null
    }
};

const Task = (state = INIT_STATE, action) => {
	switch (action.type) {

        case ADVANCE_SEARCH_TASKS: 
            return {
                ...state, 
                taskAdvanceSearch: action.payload
            }
            
		case SEARCH_TASKS:
			return {
				...state,
				taskSearchString: action.payload
			};

        case FILTER_TASKS:
            let filter = action.payload.id;
            let type = action.payload.type;
            let page = action.payload.page;
            let tf = {};
            tf.page = page

            if (type == 'organisation') {
                if (state.taskFilter.org == filter) {
                    tf.org = null;
                } else {
                    tf.org = filter;
                }

                tf.con = state.taskFilter.con;

                return {
                    ...state,
                    taskFilter: tf
                }
            } else if (type == 'contractor') {
                if (state.taskFilter.con == filter) {
                    tf.con = null;
                } else {
                    tf.con = filter;
                }

                tf.org = state.taskFilter.org;

                return {
                    ...state,
                    taskFilter: tf
                }
            } else {
                return {
                    ...state,
                    taskFilter: {
                        "org": null,
                        "con": null,
                        "page": null
                    }
                }
            }

        case FILTER_MY_TASKS:
            let taskType = action.payload.type;
            let mtf = {};
            if (state.myTaskFilter.type == taskType) {
                mtf.type = null;
            } else {
                mtf.type = taskType;
            }
            return {
                ...state,
                myTaskFilter: mtf
            }

		default:
			return state;
	}
};

export default Task;
