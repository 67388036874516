import React from 'react';
import Swal from "sweetalert2";

export default function UnderDevelopmentAlert(props) {  
    return (
        Swal.fire({
            title: 'Ooops!',
            text: `This is still under development.`,
            showCancelButton: false,
            confirmButtonColor: '#EB5757',
            cancelButtonColor: '#ffffff',
            confirmButtonText: 'Okay',
            showCloseButton: true,
            reverseButtons: true
          }).then((result) => {
            return result;
        })
    );
}

