import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// Public
import Company from "./public/companies/reducer";
import Contact from "./public/contacts/reducer";
import Inbox from "./public/inbox/reducer";
import Property from "./public/properties/reducer";
import Task from "./public/tasks/reducer";
import User from "./public/users/reducer";
import Profitability from "./public/profitability/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Company,
  Contact,
  Inbox,
  Property,
  Task,
  User,
  Profitability
});

export default rootReducer;
