import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, {
  textarea_module_2,
  textarea_module_3,
  formats,
} from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

export const TextAreaEditor = (props) => {
  const [state, setState] = React.useState({ value: null });
  const handleChange = (value) => {
    setState({ value });
    props.action(value);
  };

  useEffect(() => {
    if (props.value) {
      setState({ ...state, value: props.value });
    }

    if (props.clear) {
      setState("");
    }
  }, [props]);

  return (
    <div className={`text-editor ${props.form ? props.form : ""}`}>
      <EditorToolbar
        form={props.form}
        fileUploadAction={props.fileUploadAction}
        index={props.index}
      />
      <ReactQuill
        theme="snow"
        className={
          props.className
            ? `${props.className} react-quill-textarea`
            : "react-quill-textarea"
        }
        readOnly={props.emailDetails}
        value={state.value}
        onChange={handleChange}
        placeholder={
          props.placeholder
            ? props.placeholder
            : "Tap here to start writing your comment..."
        }
        modules={props.index == 2 ? textarea_module_2 : textarea_module_3}
        formats={formats}
        disabled={props.disabled}
      />
    </div>
  );
};

export default TextAreaEditor;
