import React from 'react';
import { CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const RenderCardTitle = (props) => {
    return (
             <React.Fragment>
                <UncontrolledDropdown className="float-right">
                    <DropdownToggle href="#" className="arrow-none" tag="i">
                        <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => props.editColumn(props.title, props.id)}>Edit</DropdownItem>
                        {props.userRole === 'super admin' &&
                            <DropdownItem onClick={() => props.deleteColumn(props.title, props.id)} className="dropdown-delete">
                                <i className="fa fa-trash"></i>
                                Delete Section
                            </DropdownItem>
                        }
                        <DropdownItem onClick={() => props.retrieveNextItems(props.id, props.currentPage, props.lastPage, props.hash)}>Load More Items</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <CardTitle  className="mb-4">{props.title}</CardTitle>
            </React.Fragment>
          );
    }
        
export default RenderCardTitle;