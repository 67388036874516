import React, { useEffect } from 'react';
import Swal from 'sweetalert2'
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import blueTagIcon from "../assets/images/blueTagIcon.png";
import { UniversalValidator } from '../Utils/helper';
import Api from "../Utils/API";
import Select, {components} from "react-select";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #ECECF5',
        color: state.isSelected ? '#fff' : '#8890AB',
        padding: 6,
        fontSize: 12,
    }),
    placeholder: (base, state) => ({
        ...base,
        color: '#AFB6CB',
    }),
    control: (base, state) => ({
        ...base,
        border: '0 !important',
        // This line disables the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    })
    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';

    //   return { ...provided, opacity, transition };
    // }
}

export const AddTag = (props) => { 
    const [state, setState] = React.useState({
        isSubmit: false,
        addNew: false,
        isOpen: false,
        tag_id: null,
        target_id: null,
        removeTagLoader: false,
        tagOptions: [],
        contactOptions: [],
        newTag: false,
        tagTitle: '',
        tagTitleError: '',
        createTagErrorMessage: '',
        isMinimized: false
    });

    useEffect(() => {
        getTagOptions();
    }, [props]);

    function getTagOptions() {
        Api.getTagOptionsWithAssignedTags([props.targetType, props.targetId]).then(result => {
            let tagOptions = result.data;
            let tagUnassigned = [];
            tagOptions.map((tag, index) => {
                if(!tag.assigned){
                    tagUnassigned.push(tag);
                }
            });
            
            setState({ ...state, tagOptions: tagUnassigned });    
        }).catch(error => {
            console.log(error);
        });
    }

    function handleCreateTag(event) {
        event.stopPropagation();
        const tagTitleError = UniversalValidator({ value: state.tagTitle, isValidate: true, isUrl: false, errorMsg: 'Title is required.' });
        
        if (tagTitleError) {
            Swal.fire({
                title: 'Error!',
                text: 'Title is required.',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setState({ ...state, tagTitleError: 'Title is required.' });
        }else{
            setState({ ...state, isSubmit: true });
            let params = {
                title: state.tagTitle
            }

            Api.saveTag(params).then(result => {
                if(result.status === "validation failed") {
                    setState({ ...state, isSubmit: false, tagTitleError: result.message.title[0] });
                } else {
                    let temp = state.tagOptions;
                    temp.push(result.data);

                    setState({ ...state, isSubmit: false, tagTitle: '', tagOptions: temp, addNew: false, newTag: !state.newTag });
                }

            }).catch(error => {
                // setState({ ...state, isSubmit: false, tagTitleError: error.data.message.title[0] });
                console.log(error);
            });
        }
    }

    const tagUpdate = (e) => {
        // Find index of specific object using findIndex method.    
        let objIndex = state.tagOptions.findIndex((obj => obj.value == e.value));

        let params = {
            tag_id: e.value,
            target_id: props.targetId,
            type: props.targetType
        }

        Api.assignTag(params).then(result => {
            props.assign();
            let temp = state.tagOptions;
            temp[objIndex].assigned = !temp[objIndex].assigned;
            state.tagOptions.splice(objIndex, 1);
            setState({...state, tagOptions: temp, isSubmit: false, newTag: !state.newTag, isOpen: !state.isOpen})
        }).catch(error => {
            console.log(error);
        });
    }

    function handleRemoveTag(tag_id) {
        setState({...state, removeTagLoader: true, selected_tag: tag_id})
        let params = {
            tag_id: tag_id,
            target_id: props.targetId,
            type: props.targetType
        }

        Api.assignTag(params).then(result => {
            props.assign();
            setState({...state, isSubmit: false, isOpen: !state.isOpen})
        }).catch(error => {
            console.log(error);
        });
    }

    function updateTagTitle(title) {
        setState({ ...state, tagTitle: title });
    }

    function updateBooleanAddTag() {
        setState({ ...state, newTag: true, isOpen: true });
    }

    function updateBooleanNewTag(){
        setState({ ...state, newTag: false, addNew: true });
    }

    function hideNewTagField() {
        setState({ ...state, tagTitle: '', addNew: false, newTag: !state.newTag});
    }

    const Menu = (props) => {
        return (
            <React.Fragment>
                <components.Menu {...props}>
                    <div>
                        <button onClick={updateBooleanNewTag} className="btn add-tag-btn" disabled={state.isSubmit}  >
                            <i className="fa fa-plus" ></i> Add New Tag
                        </button>
                        {props.selectProps.fetchingData ? (
                            <span className="fetching">Fetching data...</span>
                        ) : (
                            <div className="tags-select-list">{props.children}</div>
                        )}
                    </div>
                </components.Menu>
            </React.Fragment>
        )
    };

    const onInputChange = (inputValue, { action }) => {
        console.log(inputValue, action);
        switch (action) {
            case 'menu-close':
                setState({ ...state, addNew: false, newTag: false});
            return;
        default:
            return;
        }
    }

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setState({
            ...state, isMinimized: true
        })
    }

    function openForm(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');

        setState({
            ...state, isMinimized: false
        })
    }

    return (
        <React.Fragment>
            <div className="contact-form phone-logs-form tag-form">
                <div className="form-header" onClick={(e) => openForm(e)} >
                    <div className="row-1"><img src={blueTagIcon} className="mr-2 image-to-white"/>Add Tag to {props.targetName}</div>
                    <div className="row-2">
                        {state.isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body add-tag-form">
                    <div className="row ml-2">
                        {/* Adding new tag (clicks on "Add Tag" on lists' header)*/}
                        {state.addNew ? 
                            <div className={`contact-group ${props.form}`}>
                                <div className="row-1">
                                    Title
                                </div>
                                <div className="row-2">
                                    <div className="flex-row">
                                        <div className="col-9 padding-0 datepicker">
                                            <input className="form-control" placeholder="Enter Tag Title" value={state.tagTitle} onChange={(e) => updateTagTitle(e.target.value)} />
                                            {
                                                state.tagTitleError ?
                                                    <label className="eror-fields">{state.tagTitleError}</label>
                                                    : ''
                                            }
                                        </div>
                                        <div className="col-1">
                                            <div className="new-tag-circle-clear" onClick={hideNewTagField}>
                                                <i className="fa fa-times" ></i> 
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="new-tag-circle-ok" onClick={handleCreateTag}>
                                                <i className="fa fa-check" ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : null}

                        {state.newTag ?
                            <div className="col-lg-12">
                                {/* Selecting new tag */}
                                <Select
                                    className="form-select ml-1"
                                    styles={customStyles}
                                    isLoading={false}
                                    isClearable={true}
                                    menuIsOpen={state.isOpen}
                                    onChange={tagUpdate}
                                    onInputChange={onInputChange}
                                    options={state.tagOptions}
                                    components={{
                                        Menu,
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            </div>
                            : !state.addNew ?
                                <div className="col-md-6">
                                    <button onClick={updateBooleanAddTag} className="btn new-tag-btn" disabled={state.isSubmit}  >
                                        <i className="fa fa-plus" ></i> New Tag
                                    </button>
                                </div>
                            : null
                        }
                        <div className="col-lg-12">
                            {props.tags.length > 0 ?
                                props.tags.map((tag, index) =>
                                    <div key={tag.id} className="tag-option">
                                        <div className="row">
                                            <div className="col-11">
                                                {tag.title}
                                                {state.removeTagLoader && state.selected_tag == tag.id ? 
                                                  <i className="bx bx-loader bx-spin font-size-14 align-middle" style={{float: 'right'}}></i>
                                                : <i onClick={() => handleRemoveTag(tag.id)} className="fa fa-times" style={{float: 'right'}}></i> }
                                            </div>
                                            {/* <div className="col-2 tag-assigned">
                                                {tag.assigned ? <i className="fa fa-check" ></i> : ''}
                                            </div> */}
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddTag;
