// @flow
import {
    USER_ROLE
} from "./actionTypes";

const INIT_STATE = {
	user_role: ""
};

const User = (state = INIT_STATE, action) => {
	switch (action.type) {
		case USER_ROLE:
			return {
				...state,
				user_role: action.payload
			};

		default:
			return state;
	}
};

export default User;
