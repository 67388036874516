
import React, { useState, useEffect } from 'react';
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import NewEmailFormBody from './NewEmailFormBody';

export const NewEmail = (props) => {
    const [isMinimized, setIsMinimized] = React.useState(false);

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setIsMinimized(true);
    }

    function openForm(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');

        setIsMinimized(false);
    }

    return (
        <React.Fragment>
            <div className="contact-form">
                <div className="form-header" onClick={(e) => openForm(e)} >
                    <div className="row-1">New Email</div>
                    <div className="row-2">
                        {isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                    <NewEmailFormBody contacts={props.contacts} addEmail={props.addEmail.bind(this)} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default NewEmail;
