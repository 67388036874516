import React from 'react';
import Swal from 'sweetalert2'
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import whiteNoteIcon from "../assets/images/whiteNoteIcon.svg";
import { UniversalValidator } from '../Utils/helper';
import Api from "../Utils/API";
import RichTextArea from "./RichTextArea";
import attachment from "../assets/images/attachment.svg";
import addAttachmentIcon from "../assets/images/add-attachment.svg";
import AttachmentFile from '../components/AttachmentFile';

export const AddNote = (props) => { 
    const [state, setState] = React.useState({
        id: null,
        isSubmit: false,
        subject: '',
        note: '',
        subjectError: '',
        noteError: '',
        index: '',
        isMinimized: false,
        attachments: []
    });

    React.useEffect(() => {
        const noteDetails = props.noteDetails;
        if(noteDetails?.id) {
            Api.retrieveNoteAttachments(noteDetails.id).then(result => {
                console.log("retrieveNoteAttachments success", result.data);
                setState({...state, id: noteDetails?.id, subject: noteDetails?.subject, note: noteDetails?.note, index: noteDetails?.index, attachments: result.data ?? []})
            }).catch(error => {
                setState({ ...state, isSubmit: false });
                console.log("retrieveNoteAttachments error", error);
            });
        }
    }, [JSON.stringify(props.noteDetails)]);

    function handleSubmit(event) {
        const subjectError = UniversalValidator({ value: state.subject, isValidate: true, isUrl: false, errorMsg: 'Subject is required.' });
        const noteError = UniversalValidator({ value: state.note, isValidate: true, isUrl: false, errorMsg: 'Note is required.' });

        if (subjectError && noteError) {
            Swal.fire({
                title: 'Error!',
                text: 'Subject and Note are required.',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setState({ ...state, subjectError: 'Subject is required.', noteError: 'Note is required.', isSubmit: false });
        } else if (subjectError) {
            Swal.fire({
                title: 'Error!',
                text: 'Subject is required.',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setState({ ...state, subjectError: 'Subject is required.', isSubmit: false });
        } else if (noteError) {
            Swal.fire({
                title: 'Error!',
                text: 'Note is required.',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setState({ ...state, noteError: 'Note is required.', isSubmit: false });
        } else {
            setState({ ...state, isSubmit: true });

            if(props.isNew) {
                let data = new FormData();
                const attachments = state.attachments ?? [];
                data.append("id", state.id);
                data.append("targetId", props.targetId);
                data.append("subject", state.subject);
                data.append("note", state.note);
                data.append("type", props.targetType);
                for (let index = 0; index < attachments.length; index++) {
                    data.append("attachments[]", attachments[index]);
                }

                Api.addNote(data).then(result => {
                    props.newNote(result.data);
                    setState({ ...state, isSubmit: false, subject: '', note: ''});
                }).catch(error => {
                    setState({ ...state, isSubmit: false });
                    console.log(error);
                });
            } else {
                let data = new FormData();
                const attachments = state.attachments ?? [];
                data.append("id", state.id);
                data.append("subject", state.subject);
                data.append("note", state.note);
                data.append("type", props.targetType);
                for (let index = 0; index < attachments.length; index++) {
                    data.append("attachments[]", attachments[index]);
                } 

                Api.updateNote(data).then(result => {
                    props.updateNote(result.data);
                    setState({ ...state, isSubmit: false});
                }).catch(error => {
                    setState({ ...state, isSubmit: false });
                    console.log(error);
                });
            }
        }
    }

    function updateSubject(subject) {
        setState({ ...state, subject, subjectError: '' });
    }

    function updateNote(note) {
        setState({ ...state, note, noteError: '' });
    }

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setState({
            ...state, isMinimized: true
        })
    }

    function openForm(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');

        setState({
            ...state, isMinimized: false
        })
    }

    function handleImageChange(e) {
        e.preventDefault();
        setState({ ...state, isAttachment: true });

        let files = e.target.files;
        let attachments = state.attachments;
        for (let index = 0; index < files.length; index++) {
            attachments.push(files[index]);
        }

        setState({...state, attachments});
    }

    function removeAttachment(attachment) {
        if(state.id) {
            removeNoteAttachments(state.id, attachment);
            const attachments = state.attachments;
            // remove attachment in UI
            if(attachment.id) {
                attachments.splice(attachments.findIndex(att => att.id == attachment.id), 1)
            } else {
                attachments.splice(attachments.findIndex(att => att.lastModified == attachment.lastModified), 1)
            }
            setState({...state, attachments});
        }
    }

    function removeAllAttachments() {
        if(state.id) {
            removeNoteAttachments(state.id);

            // remove attachments in UI
            setState({...state, attachments: []});
        }
    }

    function removeNoteAttachments(noteId, attachment = null) {
        let params = null;
        if(attachment.id) {
            params = {
                noteId,
                attachmentId: attachment.id
            }
        }

        if(params) {
            // remove attachment/s from backend
            Api.removeNoteAttachments(params).then(result => {
                // props.updateNote(result.data);
                console.log("removeNoteAttachments success", result.data);
            }).catch(error => {
                Swal.fire({
                    title: 'Error!',
                    text: 'Removing attachment/s failed!.',
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
                console.log("removeNoteAttachments error", error);
            });
        }
    }

    const attachmentSection = () => {
        let attachments = [];
        let taskAttachments = props.details.attachments ?? null;
        if(taskAttachments) {
          taskAttachments.map((att) => {
            attachments.push(att);
          });
        }

        return (
          <React.Fragment>
            <table className="table-attachment table table-centered table-nowrap">
              <tbody>
                <tr>
                  <td width="100"><span className="ml-2 attachment-header">ATTACHMENTS</span></td>
                  <td style={{paddingRight: '26px'}}><div className="border-center"></div></td>
                </tr>
              </tbody>
          </table>
          <div className="attachment-container">
            <div onClick={() => { document.getElementById('icon-button-file').click(); }} className="add-attachment-card"><img src={addAttachmentIcon} /></div>
            {attachments.map((item, i) => {
                return (
                // <div className="col-md-6 attachment-item" key={i} >
                <div className="attachment-item" key={i} >
                    <AttachmentFile data={item} />
                </div>
                );
            })}
          </div>
          </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <input
            accept="image/*"
            style={{ display: "none" }}
            id="icon-button-file"
            type="file"
            onChange={(e) => handleImageChange(e)}
            multiple
        />
            <div className="contact-form notes-form" >
                <div className="form-header" onClick={(e) => openForm(e)} >
                    <div className="row-1"><img src={whiteNoteIcon} className="mr-2"/>{props.isNew ? 'Add Notes' : 'Update Notes'}</div>
                    <div className="row-2">
                        {state.isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body" style={{margin: 0}}>
                    <div className="contact-group" style={{padding: "2px 15px 2px 15px"}}>
                        <div className="row-1">
                            Subject
                        </div>
                        <div className="row-2">
                            <input className="form-control note-subject" placeholder="Enter Subject" value={state.subject} onChange={(e) => updateSubject(e.target.value)} />
                            {
                                state.subjectError ?
                                    <label className="eror-fields">{state.subjectError}</label>
                                    : ''
                            }
                        </div>
                    </div>

                    <RichTextArea
                        disabled={props.details ? props.details.approved_at : false}
                        placeholder="Start writing notes..."
                        addClass="request-form"
                        // action={updateProposalButton}
                        action={(e)=>updateNote(e)}
                        value={state.note ?? ""}
                        index={3}
                        className="note-textarea"
                    />
                    <div className="note-attachments-container" >
                        <div className="note-attachments">
                            <div className="row note-attachments-title" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '0px'}}>
                                <h2>
                                    <img
                                    src={attachment}
                                    style={{ height: 15, marginRight: 8 }}
                                    />{" "}
                                    {state.attachments.length ? (state.attachments.length > 1 ? `${state.attachments.length} attachments` : "1 attachment") : "0 attachments"}
                                </h2>
                                <span className="remove-all-text" onClick={removeAllAttachments} >
                                    {" "}
                                    Remove All
                                </span>
                                <hr style={{width: "410px"}} />
                            </div>

                            <div className="note-attachment-container">
                                <div class="uploads-wrap">
                                    <>
                                        <div>
                                            <div onClick={() => { document.getElementById("icon-button-file").click(); }} className="add-attachment-card" style={{height: "60px", width: "60px !important", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                                                <img src={addAttachmentIcon} width="14px" height="14px" />
                                                <div className="note-add-attachment-card">Add</div>
                                            </div>
                                        </div>
                                        {state.attachments.map((item, i) => {
                                            return (
                                                <div className="attachment-item" key={i} style={{width: "225px", height: "60px", marginTop: "10px", marginBottom: "10px"}} >
                                                    <AttachmentFile data={item} itemHeight="60px" removeAttachment={removeAttachment} type="note" />
                                                </div>
                                            );
                                        })}
                                    </>
                                </div>
                            </div>
                        </div>
                        
                        {state.attachments ? 
                            attachmentSection : null
                        }

                        <div className="note-footer-action">
                            <div className="row">
                                <div className="col-12 text-right">
                                    <button type="buttom" onClick={handleSubmit} className="btn btn-primary btn-large" disabled={state.isSubmit} >
                                        {
                                            state.isSubmit ?
                                                (<i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i>)
                                                : ''
                                        }
                                        {props.isNew ? 'Add' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddNote;
