import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";
import {
  NavLink,
  NavItem,
  Nav,
  // Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
import classnames from "classnames";
import Api from "../../Utils/API";
import LoadingIndicator from "../../components/LoadingIndicator";
import CompanyFormBody from "../../components/CompanyFormBody";
import Input from "../../components/InputWithDoubleClick/Input";
import Select from "../../components/SelectWithDoubleClick/Select";
// import Avatar from "../../components/Avatar";
import backIcon from "../../assets/images/backIcon.png";
import taskIcon from "../../assets/images/taskIcon.png";
import pencilIcon from "../../assets/images/pencilIcon.png";
// import logoCompany from "../../assets/images/logo-dark.png";
import starGrey from "../../assets/images/starGrey.png";
import starYellow from "../../assets/images/starYellow.png";
import AddTask from "../../components/AddTask";
import AddRequest from "../../components/AddRequest";
import AddWebsite from "../../components/AddWebsite";
import AddServer from "../../components/AddServer";
import AddEnvironment from "../../components/AddEnvironment";
import AddPhoneLog from "../../components/AddPhoneLog";
import AddTag from "../../components/AddTag";
import AddNote from "../../components/AddNote";
import imageIcon from "../../assets/images/inviteImage.png";
import errorImageIcon from "../../assets/images/error-img.png";
import bxBriefcase from "../../assets/images/bx-briefcase.png";
import DataTable from "../../components/DataTable";
import Switch from "../../components/Switch";
import moment from "moment";
import logPhoneBlue from "../../assets/images/logPhoneBlue.png";
import blueTagIcon from "../../assets/images/blueTagIcon.png";
import blueNoteIcon from "../../assets/images/blueNoteIcon.png";
// import NewEmail from "../../components/NewEmail";
import UpdateEmail from "../../components/UpdateEmail";
import UpdateWebsite from "../../components/UpdateWebsite";
import UpdateEnvironment from "../../components/UpdateEnvironment";
import UpdateServer from "../../components/UpdateServer";
import UpdatePhoneLog from "../../components/UpdatePhoneLog";
import RequestForm from "../../components/RequestForm";
import { getCookie, getFirstWord } from "../../Utils/helper";
import Skeleton from "react-loading-skeleton";
import Tags from "../../components/Tags";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SubmitManualPayment from "../../components/PopupForms/SubmitManualPayment";
import { completedWork } from "../../store/actions";
import { connect } from "react-redux";

const ReactSwal = withReactContent(Swal);
let userDetails = getCookie("userDetails");
let loginDetails = "";
if (userDetails) {
  loginDetails = JSON.parse(userDetails);
}

class CompanyProfile extends Component {
  constructor(props) {
    super(props);

    let params = queryString.parse(this.props.location.search);
    this.state = {
      // id: params.id,
      company_id: params.id,
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      note_details: [],
      newRequest: false,
      updateCall: false,
      updateEmail: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      loading: true,
      // fPA: true,
      selectedProject: 0,
      coders: [],
      requestor: [],
      pricing_methods: [],
      statuses: [],
      propertyDetails: [],
      isPdetails: false,
      propertyDetailsName: "",
      requestDetails: [],
      request_types: [],
      contactLogs: [],
      name: loginDetails
        ? ` ${getFirstWord(loginDetails.firstname)} ${getFirstWord(
            loginDetails.surname
          )} `
        : "",
      company: [],
      companyDetails: [],
      allTask: [],
      allUser: [],
      allCoders: [],
      comments: [],
      allContacts: [],
      invironments: [],
      subscriptions: [],
      payments: [],
      contact_details: "",
      id: "",
      isSubmit: false,
      customActiveTab: "1",
      openProp: false,
      tab: "tasks", // tasks / logs / payments / properties / referrer
      dataTable: {},
      tasksDataTable: {
        columns: [
          {
            label: "ID No",
            field: "id",
          },
          {
            label: "Request Name",
            field: "name",
          },
          {
            label: "Project",
            field: "project",
          },
          {
            label: "Status",
            field: "status",
          },
          {
            label: "Assignee",
            field: "assignee",
          },
          {
            label: "Due",
            field: "due",
          },
        ],
        rows: [],
      },
      logs: [],
      propertiesDataTable: {
        columns: [
          {
            label: "Company",
            field: "company",
          },
          {
            label: "Payment",
            field: "payment",
          },
          {
            label: "Commission",
            field: "commission",
          },
          {
            label: "Date",
            field: "date",
          },
          {
            label: "Action",
            field: "action",
          },
        ],
        rows: [],
      },
      referrer: [],
      contactOptions: [],
      requested_tasks: [],
      websitesList: [],
      toastData: { title: "", description: "" },
      host: [],
      contactLogs: [],
      companyTags: [],
      tags: [],
      notes: [],
      relatedContacts: "",
      tier_1_rate: 15,
      tier_2_rate: 5,
      referrer_enabled: false,
      commissions: [],
      first_referrer: null,
      second_referrer: null,
      showFirstReferrerOptions: false,
      showSecondReferrerOptions: false,
      referralSearchTypingTimeout: 0,
      referralSearchString: "",
      referral_link: "",
      hourly_rate: 0,
      hourlyRateTypingTimeout: 0,
      hourlyRateToast: "",
    };
    this.toggleNav = this.toggleNav.bind(this);
    this.addProperty = this.addProperty.bind(this);
    this.saveProperty = this.saveProperty.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.toggleFirstReferrerOptions =
      this.toggleFirstReferrerOptions.bind(this);
    this.toggleSecondReferrerOptions =
      this.toggleSecondReferrerOptions.bind(this);
    this.updateFirstReferrer = this.updateFirstReferrer.bind(this);
    this.updateSecondReferrer = this.updateSecondReferrer.bind(this);
    this.handleHourlyRateChange = this.handleHourlyRateChange.bind(this);
    this.updateHourlyRate = this.updateHourlyRate.bind(this);
  }

  componentDidMount() {
    this.companyInformation();
    this.companyProperties();
    this.companyRelatedContacts();
    this.allCoders();
    this.allContacts();
    this.allServerTypeOptions();
    this.getInvironment();
    this.getHosted();
    this.contactLogs();
    this.invoices();
    this.companyTags();
    this.companyNotes();
    this.allUser();
    this.allAffiliates();
    this.getCommissions("");
  }

  contactLogs() {
    Api.contactLogsCompany(this.state.company_id)
      .then((result) => {
        this.setState({ contactLogs: result.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  invoices() {
    Api.invoices(this.state.company_id)
      .then((result) => {
        console.log(result.data);
        this.setState({
          subscriptions: result.data.subscriptions,
          payments: result.data.payments,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  companyTags() {
    Api.getCompanyTags(this.state.company_id)
      .then((result) => {
        this.setState({ tags: result.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  companyNotes() {
    Api.getCompanyNotes(this.state.company_id)
      .then((result) => {
        this.setState({ notes: result.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  companyRelatedContacts() {
    Api.getCompanyRelatedContacts(this.state.company_id)
      .then((result) => {
        this.setState({ relatedContacts: result.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getHosted() {
    let host = [];
    Api.getHosted(this.state.company_id)
      .then((result) => {
        result.data.map((item) => {
          host.push({ label: item.title, value: item.id });
        });
        this.setState({ host: host });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getInvironment() {
    let invironments = [];
    Api.getInvironment(this.state.company_id)
      .then((result) => {
        result.data.map((item) => {
          invironments.push({ label: item.name, value: item.id });
        });
        this.setState({ invironments: invironments });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  companyProperties() {
    Api.getCompanyProperties(this.state.company_id)
      .then((result) => {
        this.setState({ websitesList: result.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  allUser() {
    Api.allUser()
      .then((result) => {
        this.setState({ allUser: result.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  allCoders() {
    Api.allCoders()
      .then((result) => {
        // console.log('coders' , result.data);
        this.setState({ allCoders: result.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  allContacts() {
    Api.getContacts()
      .then((result) => {
        // console.log('coders' , result.data);
        const temp = result.data;
        const allContacts = [];
        temp.forEach((contact) => {
          allContacts.push({
            value: contact.id,
            label: `${
              contact.firstname +
              (contact.lastname ? " " + contact.lastname : "")
            } <${contact.email}>`,
          });
        });
        this.setState({ allContacts });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  allServerTypeOptions() {
    Api.getOptions("server type")
      .then((result) => {
        let options = [];

        result.data.map((opt) => {
          options.push({ label: opt.title, value: opt.id });
        });

        this.setState({ serverTypeOptions: options });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  companyInformation() {
    Api.companyProfile(this.state.company_id)
      .then((result) => {
        let projects = [];
        let contacts = [];
        console.log(result.data);
        result.data.projects.map((project) => {
          projects.push({ label: project.name, value: project.id });
        });

        result.data.contacts.map((contact) => {
          contacts.push({
            label: contact.firstname + " " + contact.surname,
            value: contact.id,
          });
        });

        const affiliate = result.data.affiliate_company,
          first_referrer =
            affiliate && affiliate.first_referrer
              ? {
                  value: affiliate.first_referrer.id,
                  label: affiliate.first_referrer.name,
                }
              : result.data.referrer
              ? {
                  value: result.data.referrer.id,
                  label: result.data.referrer.name,
                }
              : null,
          second_referrer =
            affiliate && affiliate.second_referrer
              ? {
                  value: affiliate.second_referrer.id,
                  label: affiliate.second_referrer.name,
                }
              : null;

        // setTimeout(() => {
        this.setState({
          requested_tasks: result.data.tasks,
          loading: false,
          companyDetails: result.data,
          projects: projects,
          contactOptions: contacts,
          tier_1_rate:
            affiliate && affiliate.tier_1_rate ? affiliate.tier_1_rate : 15,
          tier_2_rate:
            affiliate && affiliate.tier_2_rate ? affiliate.tier_2_rate : 5,
          referrer_enabled: (affiliate && affiliate.is_active) ?? false,
          first_referrer,
          second_referrer,
          referral_link:
            "https://sample-referral-link.jiffi.io/neuma_jasndauh3SDqeslkdsl123lskdade219039",
          hourly_rate: parseFloat((result.data.hourly_rate ?? 0) / 100).toFixed(
            2
          ),
        });
        // }, 1000)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  allAffiliates() {
    Api.getReferrerOptions(this.state.company_id)
      .then((result) => {
        let options = [];
        const data = result.data;
        data.forEach(function (d) {
          options.push(d);
        });

        // check if company has first and second referrer and remove them from the options
        if (this.state.first_referrer)
          options.splice(options.indexOf(this.state.first_referrer), 1);
        if (this.state.second_referrer)
          options.splice(options.indexOf(this.state.second_referrer), 1);
        this.setState({ referrerOptions: options });
      })
      .catch((error) => {
        console.log("ALL AFFILIATES", error);
      });
  }

  getCommissions(query) {
    let params = {
      company_id: this.state.company_id,
      query,
    };
    this.setState({ loading: true });
    Api.getCommissions(params)
      .then((result) => {
        console.log("getCommissions > api result", result.data);
        setTimeout(() => {
          this.setState({ commissions: result.data, loading: false });
        }, 1000);
      })
      .catch((error) => {
        console.log("getCommissions", error);
      });
  }

  copyReferralLink() {
    if (!this.state.referrer_enabled) return null;
    navigator.clipboard.writeText(this.state.referral_link);
    this.setState({
      toastData: {
        title: "Success!",
        description: "Copied referral link to clipboard!",
      },
    });
    this.showTost();
  }

  minimize(event) {
    event.stopPropagation();
    document.body.classList.add("minimize-form");
  }

  addContact() {
    document.body.classList.remove("minimize-form");
  }

  openTask(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({ addRequest: false });
    this.setState({
      addTask: true,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });
  }

  openRequest(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      addTask: false,
      addRequest: true,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });
  }

  openWebsite(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      addTask: false,
      addRequest: false,
      addWebsite: true,
      addServer: false,
      addEnvironment: false,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });
  }

  updateWebsite(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: true,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });
  }

  openServer(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: true,
      addEnvironment: false,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });
  }

  updateServer(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: true,
      updateCall: false,
      newRequest: false,
    });
  }

  openEnvironment(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: true,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });
  }

  updateEnvironment(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: true,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: true,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });
  }

  openCall(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      addCall: true,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
      contact_details: "",
    });
  }

  updateCall(event, log) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      contact_details: log,
      updateEmail: false,
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: true,
    });
  }

  updateEmail(event, log) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      contact_details: log,
      updateEmail: true,
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });
  }

  openTag(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      addCall: false,
      addTag: true,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });
  }

  openManualPayment(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });

    /* MODAL POPUP - FORM TO SUBMIT FOR QA */
    ReactSwal.fire({
      showCloseButton: false,
      showConfirmButton: false,
      customClass: {
        content: "popupForm-payment-content",
        popup: "popupForm-payment-modal",
        closeButton: "popupForm-close",
      },
      allowEscapeKey: false,
      closeOnClickOutside: false,
      allowOutsideClick: false,
      html: (
        <SubmitManualPayment
          company={this.state.company_id}
          contacts={this.state.allContacts}
          swalbtn={ReactSwal}
          allowPreviousDates={true}
          showTimeSelect={true}
          showTimeInput={true}
          manualPaymentAdded={this.manualPaymentAdded.bind(this)}
          api={Api}
          moment={moment}
          updateHourlyRate={(hourly_rate) => {
            const cDetails = this.state.companyDetails;
            cDetails.hourly_rate = hourly_rate;

            this.setState({
              companyDetails: cDetails,
              hourly_rate: parseFloat(hourly_rate).toFixed(2),
            });
          }}
          // updatePaymentsSection={updatePaymentsSection}
        />
      ),
    }).then((res) => {
      if (res.isConfirmed) {
        console.log("res.isConfirmed", res.isConfirmed);
      } else {
        console.log("res.isConfirmed", res.isConfirmed);
      }
    });

    return;
    /* (END) MODAL POPUP - FORM TO SUBMIT FOR QA */
  }
  /*
    const statusUpdate = (e) => {
        const column = props.columns.find(column => column.id === state.details.status.id);
        const card = column.cards.find(card => card.id === state.details.id);
        setState({ ...state, status: e, preload_status: true });
        let params = {
            task_id: state.details.id,
            status: e.value,
        }

        if(!state.details.report && state.details.status.title == 'In Progress' && e.label == 'Quality Assurance'){
            ReactSwal.fire({
                showCloseButton: true,
                showConfirmButton: false,
                customClass: {
                    content: 'popupForm-QA-content',
                    popup: 'popupForm-modal',
                    closeButton: 'popupForm-close'
                },
                html: <SubmitQA 
                        props={card} 
                        user_details={user_details} 
                        confirmButton={ReactSwal} 
                        report_details={(e) => report = e}
                      />
              }).then((res) => {
                if(res.isConfirmed){
                    state.report_details = report;
                    updateStatusAPI(params, e);
                }
            })
            return;
        }

        return updateStatusAPI(params, e);
    }
    */

  openNote(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      addCall: false,
      addTag: false,
      addNote: true,
      updateNote: false,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });
  }

  viewNote(event, noteDetails) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({
      note_details: noteDetails,
      addTask: false,
      addRequest: false,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      addCall: false,
      addTag: false,
      addNote: false,
      updateNote: true,
      updateWebsite: false,
      updateEnvironment: false,
      updateServer: false,
      updateCall: false,
      newRequest: false,
    });
  }

  IsJsonString(str) {
    try {
      var json = JSON.parse(str);
      return typeof json === "object";
    } catch (e) {
      return false;
    }
  }

  propertyDetails(e) {
    document.body.classList.remove("minimize-form");
    if (e.credentials) {
      let credentials = e.credentials;
      for (let index = 0; index < credentials.length; index++) {
        if (this.IsJsonString(credentials[index].details)) {
          credentials[index].details = JSON.parse(credentials[index].details);
        }
      }
      this.setState({
        propertyDetailsName: e.name,
        propertyDetails: credentials,
        isPdetails: true,
      });
    }
  }

  updateDetails(e) {
    this.setState({ details: e });
  }

  toggleNav(e) {
    let active = this.state.customActiveTab;
    this.setState({ customActiveTab: e });
  }

  closeNewEmail(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({ updateEmail: false });
  }

  closeTask(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({ addTask: false });
  }

  closeRequest(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({ addRequest: false, newRequest: false });
  }

  closeWebsite(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({ addWebsite: false, updateWebsite: false });
  }

  closeServer(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({ addServer: false, updateServer: false });
  }

  closeEnvironment(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({ addEnvironment: false, updateEnvironment: false });
  }

  closeCall(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({ addCall: false, updateCall: false });
  }

  closeTag(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({ addTag: false });
  }

  closeNote(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    this.setState({ addNote: false, updateNote: false, note_details: [] });
  }

  sendInvite() {
    this.showTost();
  }

  showToast() {
    var x = document.getElementById("snackbar-company");
    x.className = "show row notification-div";
    if (x)
      return setTimeout(() => {
        this.closeToast();
      }, 5000);
  }

  removeTags = (tag, index) => {
    let params = {
      tag_id: tag.id,
      target_id: this.state.companyDetails.id,
      type: "company",
    };

    Api.assignTag(params)
      .then((result) => {
        this.setState({
          tags: this.state.tags.filter(
            (tag_item) => this.state.tags.indexOf(tag_item) !== index
          ),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  reloadData(id = null) {
    if (this.state.id == "") {
      this.setState({ id: id });
    }

    Api.taskDetails(this.state.id)
      .then((result) => {
        let newDetails = result.data;
        this.setState({
          requestDetails: newDetails.content,
          newRequest: false,
          toastData: {
            title: "Success!",
            description: "You successfully updated the request!",
          },
        });
        this.showToast();
        this.companyInformation();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  manualPaymentAdded() {
    this.setState({
      toastData: {
        title: "Success!",
        description: "You successfully logged a manual payment record!",
      },
    });
    this.showToast();
  }

  closeToast() {
    var x = document.getElementById("snackbar-company");
    x.className = x.className.replace("show", "");
  }

  populateTasks(tasksDataTable, list) {
    if (list) {
      for (let index = 0; index < list.length; index++) {
        let status = "";
        let statusText = "NO ACCOUNT";
        if (list[index].is_csa === 1) {
          status = "status-completed";
          statusText = "REGISTERED";
        }

        // moment(list[index].due_date ).format("YYYY-MM-DD") : ''
        // let dueDate = moment(state.dueDate).format("YYYY-MM-DD")

        let day = "";
        let month = "";

        if (list[index].due_date) {
          day = moment(list[index].due_date).format("DD");
          month = moment(list[index].due_date).format("MMMM");

          if (day == "11") {
            day = "11th";
          } else if (day.substr(-1) == "1") {
            day = day.concat("st");
          } else if (day.substr(-1) == "2") {
            day = day.concat("nd");
          } else if (day.substr(-1) == "3") {
            day = day.concat("rd");
          } else {
            day = day.concat("th");
          }
        }

        tasksDataTable.rows.push({
          id: list[index].id,
          name: list[index].text,
          project: list[index].project ? list[index].project.name : "",
          status: list[index].status ? list[index].status.title : "",
          assignee: list[index].primary_assignee
            ? list[index].primary_assignee.firstname +
              " " +
              list[index].primary_assignee.surname
            : "",
          due: day ? `${day} ${month}` : "",
        });
      }

      return tasksDataTable;
    }

    return null;
  }

  populateLogs() {
    //
  }

  populatePayments() {
    //
  }

  populateProperties(propertiesDataTable, list) {
    if (list) {
      for (let index = 0; index < list.length; index++) {
        let status = "";
        let statusText = "NO ACCOUNT";
        if (list[index].is_csa === 1) {
          status = "status-completed";
          statusText = "REGISTERED";
        }
        propertiesDataTable.rows.push({
          server: list[index].name,
          primary_contact: list[index]["primary_contact"]
            ? list[index]["primary_contact"].firstname +
              " " +
              list[index]["primary_contact"].surname
            : "",
          type: list[index].type ? list[index].type : "",
          last_maintenance: "12/8/2019, 9:40 AM",
        });
      }

      return propertiesDataTable;
    }

    return null;
  }

  updateList(task) {
    let requested_tasks = this.state.requested_tasks.concat(task);
    this.setState({
      requested_tasks: requested_tasks,
      addTask: false,
      isSubmit: false,
      toastData: {
        title: "Success!",
        description: "You successfully created a new task!",
      },
    });
    this.showTost();
  }

  newRequest(task) {
    let requested_tasks = this.state.requested_tasks.concat(task.task);
    this.setState({
      requested_tasks: requested_tasks,
      addRequest: false,
      isSubmit: false,
      newRequest: false,
      toastData: {
        title: "Success!",
        description: "You successfully created a new request!",
      },
    });
    this.showTost();
  }

  addProperty(property) {
    let websitesList = this.state.websitesList.concat(property);
    this.setState({
      websitesList: websitesList,
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      isSubmit: false,
      updateProperty: false,
      toastData: {
        title: "Success!",
        description: "You successfully created a new properties!",
      },
    });
    this.showTost();
  }

  saveProperty(property) {
    // Find index of specific object using findIndex method.
    const section = this.state.websitesList.findIndex(
      (item) => item.id == property.id
    );
    this.state.websitesList[section] = property;

    this.setState({
      addWebsite: false,
      addServer: false,
      addEnvironment: false,
      isSubmit: false,
      updateEnvironment: false,
      updateWebsite: false,
      updateServer: false,
      toastData: {
        title: "Success!",
        description: "You successfully updated properties!",
      },
    });
    this.companyProperties();
    this.showTost();
  }

  newPhoneCall(e) {
    this.contactLogs();
    this.setState({
      addCall: false,
      toastData: {
        title: "Success!",
        description: `You successfully logged a phone call!`,
      },
    });
    this.showTost();
  }

  updatedPhoneCall(e) {
    this.contactLogs();
    this.setState({
      updateCall: false,
      toastData: {
        title: "Success!",
        description: `You successfully updated a phone call!`,
      },
    });
    this.showTost();
  }

  assignTag() {
    this.companyTags();
  }

  updateProperty(event, property) {
    event.stopPropagation();
    this.setState({ propertyDetails: property });
    if (property.type == "server") {
      return this.updateServer(event);
    } else if (property.type == "website") {
      return this.updateWebsite(event);
    } else if (property.type == "environment") {
      return this.updateEnvironment(event);
    }
  }

  updateRequest(event, request) {
    event.stopPropagation();
    let id = request.id;

    this.setState({ comments: { loading: true, value: [] } });
    if (id !== this.state.id) {
      Api.getComment(request.id)
        .then((result) => {
          this.setState({
            comments: {
              loading: false,
              value: result.data.replies ? result.data.replies : [],
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (id) {
      Api.taskDetails(id)
        .then((result) => {
          this.setState({ id: id });
          document.body.classList.add("actual-time-and-estimate-show");
          this.setState({
            newRequest: true,
            requestDetails: result.data.content,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  updateContactLog(e, contact, index) {
    let contact_details = {
      contact: contact,
      index: index,
    };

    if (contact.type == "phone call") {
      this.updateCall(e, contact_details);
    } else {
      this.updateEmail(e, contact_details);
    }
  }

  updateCompanyNote(e, note, index) {
    const noteDetails = {
      id: note.id,
      note: note.note,
      subject: note.subject,
      index: index,
    };

    this.viewNote(e, noteDetails);
  }

  newNote(note) {
    const notes = [note].concat(this.state.notes);
    this.setState({
      notes: notes,
      addNote: false,
      isSubmit: false,
      toastData: {
        title: "Success!",
        description: "You successfully created a new note!",
      },
    });
    this.showTost();
  }

  updateNoteList(note) {
    let notes = this.state.notes;
    notes.splice(
      notes.findIndex((item) => item.id == note.id),
      1
    );

    const updatedList = [note].concat(notes);

    this.setState({
      notes: updatedList,
      updateNote: false,
      isSubmit: false,
      toastData: {
        title: "Success!",
        description: "You successfully created a new note!",
      },
    });
    this.showTost();
  }

  showTost() {
    var x = document.getElementById("snackbar-company");
    x.className = "show row notification-div";
    setTimeout(() => {
      this.closeToast();
    }, 5000);
  }

  closeToast() {
    var x = document.getElementById("snackbar-company");
    x.className = x.className.replace("show", "");
  }

  relatedContactDetails(e, details) {
    Api.userProfile(details.id)
      .then((result) => {
        console.log(result.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  companyInformationPreloader() {
    return (
      <React.Fragment>
        <ul className="contact-preloader-container">
          <li>
            <Skeleton height={25} width={"60%"} />
          </li>
          <li>
            <Skeleton height={25} width={"30%"} />
          </li>
          <li>
            <Skeleton height={25} width={"35%"} />
          </li>
          <li>
            <Skeleton height={25} width={"40%"} />
          </li>
          <li>
            <Skeleton height={25} width={"50%"} />
          </li>
          <li>
            <Skeleton height={25} width={"45%"} />
          </li>
          <li>
            <Skeleton height={25} width={"35%"} />
          </li>
          <li>
            <Skeleton height={25} width={"30%"} />
          </li>
          <li>
            <Skeleton height={25} width={"25%"} />
          </li>
          <li>
            <Skeleton height={25} width={"22%"} />
          </li>
        </ul>
      </React.Fragment>
    );
  }

  companyNamePreloader() {
    return (
      <React.Fragment>
        <div className="row-2 ml-2">
          <div>
            <Skeleton style={{ marginRight: 10 }} height={20} width={65} />
            <Skeleton height={20} width={65} />
          </div>
          <div>
            <Skeleton style={{ marginRight: 10 }} height={15} width={120} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  getReferrerOptions() {
    Api.getReferrerOptions()
      .then((result) => {})
      .catch((error) => {
        console.log("getReferrerOptions ERROR", error);
        const err = error.response
          ? error.response.data
          : "An error occured. Please try again later. If error persists, please contact the administrator.";
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  }

  toggleReferralStatus() {
    this.setState({ referrer_enabled: !this.state.referrer_enabled });
    Api.toggleReferralStatus(this.state.company_id)
      .then((result) => {
        //
      })
      .catch((error) => {
        this.setState({ referrer_enabled: !this.state.referrer_enabled }); // revert previous setState
        console.log("getReferrerOptions ERROR", error);
        const err = error.response
          ? error.response.data
          : "An error occured. Please try again later. If error persists, please contact the administrator.";
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  }

  updateFirstReferrer(e) {
    let referrers = this.state.referrerOptions,
      selectedData = e,
      indexData = referrers.indexOf(selectedData),
      newObject = { index: indexData, ...selectedData },
      { first_referrer } = this.state,
      params = {
        company_id: this.state.company_id,
        first_referrer: selectedData.value,
      };

    Api.updateReferrer(params)
      .then((result) => {
        // check if company already has a first_referrer
        if (first_referrer) {
          referrers.splice(first_referrer.index, 0, {
            value: first_referrer.value,
            label: first_referrer.label,
          });
        }

        // remove selected option from the array of referrers
        const index = referrers.indexOf(selectedData);
        if (index !== -1) {
          referrers.splice(index, 1);
        }

        this.setState({
          first_referrer: newObject,
          showFirstReferrerOptions: false,
          referrerOptions: referrers,
        });
      })
      .catch((error) => {
        console.log(error);
        const err = error.response
          ? error.response.data
          : "Something went wrong.";

        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  }

  updateSecondReferrer(e) {
    let referrers = this.state.referrerOptions,
      selectedData = e,
      indexData = referrers.indexOf(selectedData),
      newObject = { index: indexData, ...selectedData },
      { second_referrer } = this.state,
      params = {
        company_id: this.state.company_id,
        second_referrer: selectedData.value,
      };

    Api.updateReferrer(params)
      .then((result) => {
        // check if company already has a second_referrer
        if (second_referrer) {
          referrers.splice(second_referrer.index, 0, {
            value: second_referrer.value,
            label: second_referrer.label,
          });
        }

        // remove selected option from the array of referrers
        const index = referrers.indexOf(selectedData);
        if (index !== -1) {
          referrers.splice(index, 1);
        }

        this.setState({
          second_referrer: newObject,
          showSecondReferrerOptions: false,
          referrerOptions: referrers,
        });
      })
      .catch((error) => {
        console.log(error);
        const err = error.response
          ? error.response.data
          : "Something went wrong.";

        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  }

  onChangeTierOneRate(value) {
    this.setState({ tier_1_rate: value });
  }

  onChangeTierTwoRate(value) {
    this.setState({ tier_2_rate: value });
  }

  updateTierOneRate(value) {
    Api.updateTierRate(this.state.company_id, value, 1)
      .then((result) => {
        this.setState({ tier_1_rate: value });
      })
      .catch((error) => {
        console.log("updateTierOneRate ERROR", error);
        const err = error.response
          ? error.response.data
          : "An error occured. Please try again later. If error persists, please contact the administrator.";
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  }

  updateTierTwoRate(value) {
    Api.updateTierRate(this.state.company_id, value, 2)
      .then((result) => {
        this.setState({ tier_2_rate: value });
      })
      .catch((error) => {
        console.log("updateTierTwoRate ERROR", error);
        const err = error.response
          ? error.response.data
          : "An error occured. Please try again later. If error persists, please contact the administrator.";
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  }

  toggleFirstReferrerOptions() {
    const bool = this.state.showFirstReferrerOptions;
    this.setState({
      showFirstReferrerOptions: !bool,
      showSecondReferrerOptions: false,
    });
  }

  toggleSecondReferrerOptions() {
    const bool = this.state.showSecondReferrerOptions;
    this.setState({
      showFirstReferrerOptions: false,
      showSecondReferrerOptions: !bool,
    });
  }

  handleReferralSearchChange = (e) => {
    if (this.state.referralSearchTypingTimeout) {
      clearTimeout(this.state.referralSearchTypingTimeout);
    }

    const value = e.target.value;
    const $this = this;

    this.setState({
      referralSearchString: value,
      referralSearchTypingTimeout: setTimeout(function () {
        $this.getCommissions(value);
      }, 2000),
    });
  };

  updateHourlyRate(hourly_rate) {
    let id = this.state.companyDetails.id;

    if (id) {
      const params = {
        id,
        hourly_rate,
      };

      Api.updateHourlyRate(params)
        .then((result) => {
          console.log(
            `Successfully updated ${this.state.companyDetails.name} hourly rate to ${this.state.hourly_rate}.`
          );
          this.showHourlyRateToast(true, "Hourly rate has been updated.");
          const companyDetails = this.state.companyDetails;
          companyDetails.hourly_rate = hourly_rate;
          this.setState({
            companyDetails: companyDetails,
          });

          this.getCompletedWork();
        })
        .catch((error) => {
          this.showHourlyRateToast(
            false,
            "An error occured in updating the hourly rate."
          );
          this.setState({
            hourly_rate: parseFloat(
              this.state.companyDetails.hourly_rate / 100
            ).toFixed(2),
          });
          console.log(
            `Failed to update ${this.state.companyDetails.name} hourly rate to ${this.state.hourly_rate}.`
          );
          console.log("updateHourlyRate > error", error);
        });
    }
  }

  getCompletedWork() {
    Api.getCompletedWork()
      .then(({ data }) => {
        this.props.setCompletedWork({
          hours: data.hours,
          amount: data.amount,
        });
      })
      .catch((error) => {
        console.log("compnaies-profile > getCompletedWork > error", error);
      });
  }

  handleHourlyRateChange(e) {
    e.preventDefault();
    if (this.state.hourlyRateTypingTimeout) {
      clearTimeout(this.state.hourlyRateTypingTimeout);
    }

    const value = e.target.value;
    const $this = this;

    this.setState({
      hourly_rate: value,
      hourlyRateTypingTimeout: setTimeout(function () {
        $this.updateHourlyRate(value);
      }, 500),
    });
  }

  showHourlyRateToast(success, message) {
    this.setState({
      hourlyRateToast: {
        success: success,
        message: message,
      },
    });
    setTimeout(() => {
      this.closeHourlyRateToast();
    }, 5000);
  }

  closeHourlyRateToast() {
    this.setState({
      hourlyRateToast: "",
    });
  }

  render() {
    let {
      companyDetails,
      tab,
      tasksDataTable,
      company_id,
      contactLogs,
      payments,
      subscriptions,
      relatedContacts,
      notes,
      tags,
      tier_1_rate,
      tier_2_rate,
      first_referrer,
      second_referrer,
      referrerOptions,
    } = this.state;
    let obj = null;
    if (first_referrer && referrerOptions) {
      obj = referrerOptions.find((obj) => obj.value == first_referrer.value);
      if (obj) {
        referrerOptions.splice(first_referrer.index, 1);
      }
      obj = null;
    }
    if (second_referrer && referrerOptions) {
      obj = referrerOptions.find((obj) => obj.value == second_referrer.value);
      if (obj) {
        referrerOptions.splice(second_referrer.index, 1);
      }
      obj = null;
    }

    const dataTable = {
      columns: [
        {
          label: "ID No",
          field: "id",
        },
        {
          label: "Request Name",
          field: "name",
        },
        {
          label: "Project",
          field: "project",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Assignee",
          field: "assignee",
        },
        {
          label: "Due",
          field: "due",
        },
      ],
      rows: [],
    };

    let list = this.state.requested_tasks;
    for (let index = 0; index < list.length; index++) {
      let status = "";
      let statusText = "NO ACCOUNT";
      if (list[index].is_csa === 1) {
        status = "status-completed";
        statusText = "REGISTERED";
      }

      let day = "";
      let month = "";

      if (list[index].due_date) {
        day = moment(list[index].due_date).format("DD");
        month = moment(list[index].due_date).format("MMMM");

        if (day == "11") {
          day = "11th";
        } else if (day.substr(-1) == "1") {
          day = day.concat("st");
        } else if (day.substr(-1) == "2") {
          day = day.concat("nd");
        } else if (day.substr(-1) == "3") {
          day = day.concat("rd");
        } else {
          day = day.concat("th");
        }
      }
      dataTable.rows.push({
        id: (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => this.updateRequest(e, list[index])}>
            {list[index].id}
          </span>
        ),
        name: (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => this.updateRequest(e, list[index])}>
            {list[index].text}
          </span>
        ),
        project: (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => this.updateRequest(e, list[index])}>
            {list[index].project ? list[index].project.name : ""}
          </span>
        ),
        status: list[index].status ? list[index].status.title : "",
        assignee: list[index].primary_assignee
          ? list[index].primary_assignee.firstname +
            " " +
            list[index].primary_assignee.surname
          : "",
        due: day ? `${day} ${month}` : "",
      });
    }

    let properties = this.state.websitesList;
    const dataTableWebsites = {
      columns: [
        {
          label: "Server",
          field: "server",
        },
        {
          label: "Primary Contact",
          field: "primary_contact",
        },
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Last Maintenance",
          field: "last_maintenance",
        },
      ],
      rows: [],
    };

    for (let index = 0; index < properties.length; index++) {
      let status = "";
      let statusText = "NO ACCOUNT";
      if (properties[index].is_csa === 1) {
        status = "status-completed";
        statusText = "REGISTERED";
      }
      dataTableWebsites.rows.push({
        server: (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => this.updateProperty(e, properties[index])}>
            {properties[index].name}
          </span>
        ),
        primary_contact: properties[index].primary_contact
          ? properties[index].primary_contact.firstname +
            " " +
            properties[index].primary_contact.surname
          : "",
        type: properties[index].type ? properties[index].type : "",
        credentials: properties[index].credentials,
        last_maintenance: "12/8/2019, 9:40 AM",
      });
    }

    const dataTableRelatedContacts = {
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Main Business Contact",
          field: "main_business_contact",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Office Phone",
          field: "office_phone",
        },
        {
          label: "Title",
          field: "title",
        },
      ],
      rows: [],
    };

    if (relatedContacts != "") {
      for (let index = 0; index < relatedContacts.contacts.length; index++) {
        let main_contact =
          relatedContacts.main_contact == relatedContacts.contacts[index].id
            ? "YES"
            : "NO";

        dataTableRelatedContacts.rows.push({
          name: (
            <Link
              to={`/contacts-profile?id=${
                relatedContacts.contacts[index].id
              }&fromCompanyProfile=${true}`}>
              {relatedContacts.contacts[index].firstname}{" "}
              {relatedContacts.contacts[index].lastname}
            </Link>
          ),
          main_business_contact: (
            <span className={`main-contact ${main_contact}`}>
              <Link
                to={`/contacts-profile?id=${
                  relatedContacts.contacts[index].id
                }&fromCompanyProfile=${true}`}>
                {main_contact}
              </Link>
            </span>
          ),
          email: (
            <Link
              to={`/contacts-profile?id=${
                relatedContacts.contacts[index].id
              }&fromCompanyProfile=${true}`}>
              {relatedContacts.contacts[index].email}
            </Link>
          ),
          office_phone: "12/8/2019, 9:40 AM",
          title: relatedContacts.contacts[index].title,
        });
      }
    }

    const dataTableLogs = {
      columns: [
        {
          label: "Time",
          field: "time",
        },
        {
          label: "Subject",
          field: "subject",
        },
        {
          label: "Team",
          field: "team",
        },
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Contact",
          field: "contact",
        },
      ],
      rows: [],
    };

    const dataTableSubscription = {
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Next Charge",
          field: "next_charge",
        },
        {
          label: "Last Modified",
          field: "last_modified",
        },
        {
          label: "Next Charge Date",
          field: "next_charge_date",
        },
        {
          label: "Transactions",
          field: "transactions",
        },
      ],
      rows: [],
    };

    for (let index = 0; index < subscriptions.length; index++) {
      dataTableSubscription.rows.push({
        name: (
          <span className="subscription-name">{subscriptions[index].name}</span>
        ),
        status: (
          <span className={`subscription ${subscriptions[index].status}`}>
            {subscriptions[index].status}
          </span>
        ),
        next_charge: (
          <span>
            $
            {subscriptions[index].nextCharge != null
              ? parseFloat(subscriptions[index].nextCharge).toFixed(2)
              : "0.00"}
          </span>
        ),
        last_modified: subscriptions[index].lastModified,
        next_charge_date: subscriptions[index].nextChargeDate,
        transactions: subscriptions[index].transactions,
      });
    }

    const dataTablePayments = {
      columns: [
        {
          label: "INV #",
          field: "invoice_number",
        },
        {
          label: "Request Name",
          field: "request_name",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Due",
          field: "due",
        },
      ],
      rows: [],
    };
    console.log("companies-profile > render > payments", payments);
    for (let index = 0; index < payments.length; index++) {
      dataTablePayments.rows.push({
        invoice_number: (
          <span className="payment-invoice">{payments[index].invoiceNo}</span>
        ),
        request_name: payments[index].name,
        status: (
          <span className={`payment ${payments[index].status}`}>
            <i className="fa fa-circle"></i>{" "}
            {payments[index].status ? "Paid" : "Payment Failed"}
          </span>
        ),
        due: moment(payments[index].due).format("Do MMMM"),
      });
    }

    for (let index = 0; index < contactLogs.length; index++) {
      dataTableLogs.rows.push({
        time: (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) =>
              this.updateContactLog(e, contactLogs[index], index)
            }>
            {contactLogs[index].date}
          </span>
        ),
        subject: (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) =>
              this.updateContactLog(e, contactLogs[index], index)
            }>
            {contactLogs[index].target.subject}
          </span>
        ),
        team:
          contactLogs[index].type === "phone call"
            ? contactLogs[index].phone_call.contact.firstname +
              " " +
              contactLogs[index].phone_call.contact.lastname
            : contactLogs[index].email.sender.firstname +
              " " +
              contactLogs[index].email.sender.lastname,
        type: (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) =>
              this.updateContactLog(e, contactLogs[index], index)
            }>
            {contactLogs[index].type}
          </span>
        ),
        contact: contactLogs[index].target.contact
          ? `${contactLogs[index].target.contact.firstname} ${contactLogs[index].target.contact.lastname}`
          : null,
      });
    }

    const dataTableNotes = {
      columns: [
        {
          label: "Time",
          field: "time",
        },
        {
          label: "Subject",
          field: "subject",
        },
        {
          label: "Team",
          field: "team",
        },
        {
          label: "Type",
          field: "type",
        },
      ],
      rows: [],
    };

    for (let index = 0; index < notes.length; index++) {
      dataTableNotes.rows.push({
        time: notes[index].date,
        subject: (
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              this.updateCompanyNote(e, notes[index], index);
            }}>
            {notes[index].subject}
          </span>
        ),
        team: notes[index].firstname + " " + notes[index].lastname,
        type: notes[index].type,
      });
    }

    /** COMMISSIONS DATATABLE */
    let commissions = this.state.commissions;
    const dataTableCommissions = {
      columns: [
        {
          label: "Company",
          field: "company",
        },
        {
          label: "Payment",
          field: "payment",
        },
        {
          label: "Commission",
          field: "commission",
        },
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
    };

    for (let index = 0; index < commissions.length; index++) {
      dataTableCommissions.rows.push({
        company: commissions[index].cname,
        payment: `$ ${(commissions[index].payment / 100).toFixed(2)}`,
        commission: `$ ${(commissions[index].commission / 100).toFixed(2)}`,
        date: commissions[index].date,
        action: null,
      });
    }
    /** /REFERRALS DATATABLE */

    let selectedTags = (tags) => console.log(tags);
    return (
      <React.Fragment>
        {/* ALERTS - START */}
        {/* <div id="snackbar-company" onClick={this.closeToast.bind(this)} className="display-none"  >
                    <div className="col-5 notification-image flex-center" >
                        <img src={imageIcon} />
                    </div>
                    <div className="col-7 notification-details my-auto" >
                        <h5>Invitation Sent!</h5>
                        <p>Your invitaiton has successfully been sent to John Amber.</p>
                    </div>
                </div>
                <div id="snackbar-add-request" onClick={this.closeToast.bind(this)} className="display-none"  >
                    <div className="col-5 notification-image flex-center" >
                        <img src={imageIcon} />
                    </div>
                    <div className="col-7 notification-details my-auto" >
                        <h5>Success!</h5>
                        <p>You successfully created a new task!</p>
                    </div>
                </div> */}

        {/* DISPLAY TOAST ALERT FOR UPDATING HOURLY RATE */}
        {this.state.hourlyRateToast !== "" ? (
          <div
            id="snackbar-hourly-rate"
            onClick={this.closeHourlyRateToast.bind(this)}
            className={`show row notification-div ${
              this.state.hourlyRateToast?.success ? "" : "error"
            }`}>
            <div className="col-5 notification-image flex-center">
              <img
                src={
                  this.state.hourlyRateToast?.success
                    ? imageIcon
                    : errorImageIcon
                }
              />
            </div>
            <div className="col-7 notification-details my-auto">
              <h5>
                {this.state.hourlyRateToast?.success ? "Success!" : "Error!"}
              </h5>
              <p>{this.state.hourlyRateToast?.message}</p>
            </div>
          </div>
        ) : null}

        <div
          id="snackbar-company"
          onClick={this.closeToast.bind(this)}
          className="display-none">
          <div className="col-5 notification-image flex-center">
            <img src={imageIcon} />
          </div>
          <div className="col-7 notification-details my-auto">
            <h5>{this.state.toastData.title}</h5>
            <p>{this.state.toastData.description}</p>
          </div>
        </div>
        {/* ALERTS - START */}

        {this.state.loading ? (
          <LoadingIndicator loading={this.state.loading} />
        ) : (
          ""
        )}

        <div className="page-content page-content-grey">
          <div className="container-fluid container-body">
            <div className="row row-contacts">
              <div className="col-xs-12 col-md-5 col-lg-5 div-1">
                <div>
                  <Link
                    to="companies-list"
                    className="btn btn-icon template back-to-list">
                    {" "}
                    <img src={backIcon} /> &nbsp; Back to Company List
                  </Link>
                </div>
                <span className="profile-type"> COMPANY </span>
                <div className="basic-contact flex-row mt-4">
                  {!this.state.loading ? (
                    <div className="row-2 ml-2">
                      <div>{companyDetails.name}</div>
                      <div className="company">
                        {companyDetails.pa_address
                          ? `${companyDetails.pa_address} ${companyDetails.pa_city}, ${companyDetails.pa_country}`
                          : "(Update your address)"}
                      </div>
                    </div>
                  ) : (
                    this.companyNamePreloader()
                  )}
                </div>
                <div className="flex-row">
                  <div className="col-lg-3">
                    <p>Credit Balance</p>
                    <h5>
                      {companyDetails.credits != null
                        ? parseFloat(companyDetails.credits).toFixed(2)
                        : "0.00"}
                    </h5>
                  </div>
                  <div className="col-lg-3">
                    <p>Life Time Value</p>
                    <h5>
                      $
                      {companyDetails.life_time_value
                        ? companyDetails.life_time_value
                        : "0.00"}
                    </h5>
                  </div>
                  <div className="col-lg-3">
                    <p>Status</p>
                    <div className="flex-row">
                      <div
                        className={
                          companyDetails.is_active
                            ? "status-circle-active"
                            : "status-circle-inactive"
                        }></div>
                      <h5 className="profile-status">
                        {companyDetails.is_active == true
                          ? "Active"
                          : "Inactive"}
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <p>Hourly Rate</p>
                    <div className="hourly-rate">
                      <span>$</span>
                      <input
                        type="number"
                        className="form-control"
                        value={this.state.hourly_rate}
                        onChange={(e) => this.handleHourlyRateChange(e)}
                        min={0}
                      />
                    </div>
                  </div>
                </div>
                <div className="action-btn">
                  <button
                    disabled={this.state.loading}
                    onClick={this.openTask.bind(this)}
                    className="btn btn-default mr-3 profile-btn">
                    {" "}
                    <img src={taskIcon} /> &nbsp; Add Task
                  </button>

                  {/* hide add request button if companiy_id == 1 */}
                  {this.state.company_id != 1 ? (
                    <button
                      disabled={this.state.loading}
                      onClick={this.openRequest.bind(this)}
                      className="btn btn-default mr-3 profile-btn">
                      {" "}
                      <img src={pencilIcon} /> &nbsp; Add Request
                    </button>
                  ) : null}

                  <ButtonDropdown
                    disabled={this.state.loading}
                    isOpen={this.state.openProp}
                    toggle={() => {
                      this.setState({ openProp: !this.state.openProp });
                    }}>
                    <DropdownToggle
                      caret
                      color="primary btn-primary-group mr-3 profile-btn">
                      <img src={bxBriefcase} /> Add Properties{" "}
                      <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={this.openWebsite.bind(this)}>
                        Add Website
                      </DropdownItem>
                      <DropdownItem onClick={this.openServer.bind(this)}>
                        Add Server
                      </DropdownItem>
                      <DropdownItem onClick={this.openEnvironment.bind(this)}>
                        Add Environment
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>

                  <button
                    disabled={this.state.loading}
                    onClick={this.openCall.bind(this)}
                    className="btn btn-default mr-3 profile-btn">
                    <img src={logPhoneBlue} />
                    &nbsp; Log Phone Call
                  </button>

                  <button
                    disabled={this.state.loading}
                    onClick={this.openTag.bind(this)}
                    className="btn btn-default mr-3 profile-btn">
                    <img src={blueTagIcon} />
                    &nbsp; Add Tag
                  </button>

                  <button
                    disabled={this.state.loading}
                    onClick={this.openManualPayment.bind(this)}
                    className="btn btn-default mr-3 profile-btn">
                    <img src={blueTagIcon} />
                    &nbsp; Log Payment
                  </button>

                  <button
                    disabled={this.state.loading}
                    onClick={this.openNote.bind(this)}
                    className="btn btn-default mr-3 profile-btn">
                    <img src={blueNoteIcon} />
                    &nbsp; Add Note
                  </button>
                </div>

                <div className="contact-info">
                  <p className="title">COMPANY INFORMATION</p>

                  {!this.state.loading ? (
                    <CompanyFormBody
                      companyDetails={this.state.companyDetails}
                      new={false}
                    />
                  ) : (
                    this.companyInformationPreloader()
                  )}
                </div>
                <div className="other-info">
                  <p className="title">OTHER INFO</p>
                  <div className="row">
                    <div className="col-lg-4">
                      Total Requests
                      <p className="result">2</p>
                    </div>
                    <div className="col-lg-4">
                      Last Review
                      <div className="rating">
                        <img src={starYellow} />
                        <img src={starYellow} />
                        <img src={starYellow} />
                        <img src={starYellow} />
                        <img src={starGrey} />
                      </div>
                    </div>
                    {/* INCOMPLETE */}
                    <div className="col-lg-4">
                      Credit Purchase
                      <p className="result">4</p>
                    </div>
                    <div className="col-lg-4">
                      Last Request
                      <p className="result">28th July 20</p>
                    </div>
                    <div className="col-lg-4">
                      Last Contact
                      <p className="result">28th July 20</p>
                    </div>
                    {/* /INCOMPLETE */}
                  </div>
                  <div
                    className="row"
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "center",
                      padding: "0px 10px",
                    }}>
                    <div className="referrer-info" style={{ width: "50%" }}>
                      <div className="title mt-2">1st REFERRER</div>
                      {this.state.showFirstReferrerOptions ? (
                        <Select
                          toggleOptions={this.toggleFirstReferrerOptions}
                          list={referrerOptions ? referrerOptions : []}
                          action={this.updateFirstReferrer}
                        />
                      ) : (
                        <div
                          onDoubleClick={this.toggleFirstReferrerOptions}
                          className="result">
                          {first_referrer ? first_referrer.label : "---"}
                        </div>
                      )}
                    </div>
                    <div className="referrer-info" style={{ width: "50%" }}>
                      <div className="title mt-2">2nd REFERRER</div>
                      {this.state.showSecondReferrerOptions ? (
                        <Select
                          toggleOptions={this.toggleSecondReferrerOptions}
                          list={referrerOptions ? referrerOptions : []}
                          action={this.updateSecondReferrer}
                        />
                      ) : (
                        <div
                          onDoubleClick={this.toggleSecondReferrerOptions}
                          className="result">
                          {second_referrer ? second_referrer.label : "---"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xs-12 col-md-7 col-lg-7 div-2"
                style={{ background: "#F8F8FB" }}>
                <Nav tabs className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customActiveTab === "1",
                      })}
                      onClick={() => {
                        this.toggleNav("1");
                      }}>
                      <span className="d-sm-block">Requests & Tasks</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="navlink-second">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customActiveTab === "2",
                      })}
                      onClick={() => {
                        this.toggleNav("2");
                      }}>
                      <span className="d-sm-block">Contact Log</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="navlink-second">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customActiveTab === "3",
                      })}
                      onClick={() => {
                        this.toggleNav("3");
                      }}>
                      <span className="d-sm-block">
                        Subscriptions & Payments
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="navlink-second">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customActiveTab === "4",
                      })}
                      onClick={() => {
                        this.toggleNav("4");
                      }}>
                      <span className="d-sm-block">Properties</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="navlink-second">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customActiveTab === "5",
                      })}
                      onClick={() => {
                        this.toggleNav("5");
                      }}>
                      <span className="d-sm-block">Notes & Tags</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="navlink-second">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customActiveTab === "6",
                      })}
                      onClick={() => {
                        this.toggleNav("6");
                      }}>
                      <span className="d-sm-block">Related Contacts</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="navlink-second">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customActiveTab === "7",
                      })}
                      onClick={() => {
                        this.toggleNav("7");
                      }}>
                      <span className="d-sm-block">Referrer</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                {this.state.customActiveTab === "1" && (
                  <div className="request-task">
                    <div className="header">REQUEST & TASKS</div>
                    <DataTable
                      data={dataTable}
                      entriesOptions={[8, 30, 100, 500]}
                      entries={8}
                    />
                  </div>
                )}

                {this.state.customActiveTab === "2" && (
                  <div className="request-task">
                    <div className="header">CONTACT LOG</div>
                    <DataTable
                      data={dataTableLogs}
                      entriesOptions={[8, 30, 100, 500]}
                      entries={8}
                    />
                  </div>
                )}

                {this.state.customActiveTab === "3" && (
                  <div>
                    <div className="request-task">
                      <div className="header">SUBSCRIPTION</div>
                      <DataTable
                        data={dataTableSubscription}
                        entriesOptions={[8, 30, 100, 500]}
                        entries={8}
                      />
                    </div>

                    <div className="request-task">
                      <div className="header">PAYMENTS</div>
                      <DataTable
                        data={dataTablePayments}
                        entriesOptions={[8, 30, 100, 500]}
                        entries={8}
                      />
                    </div>
                  </div>
                )}

                {this.state.customActiveTab === "4" && (
                  <div className="request-task">
                    <div className="header">PROPERTIES</div>
                    <DataTable
                      data={dataTableWebsites}
                      entriesOptions={[8, 30, 100, 500]}
                      entries={8}
                    />
                  </div>
                )}

                {this.state.customActiveTab === "5" && (
                  <div>
                    <div className="request-task">
                      <div className="header">Notes</div>
                      <DataTable
                        data={dataTableNotes}
                        entriesOptions={[8, 30, 100, 500]}
                        entries={8}
                      />
                    </div>
                    <div className="request-task">
                      <div className="header tag-title">
                        Tags{" "}
                        <span className="tags-count">
                          {this.state.tags.length} Tags
                        </span>
                      </div>
                      <Tags
                        tags={this.state.tags}
                        removeTags={this.removeTags}
                        selectedTags={selectedTags}
                      />
                    </div>
                  </div>
                )}

                {this.state.customActiveTab === "6" && (
                  <div className="request-task">
                    <div className="header">CONTACTS</div>
                    <DataTable
                      data={dataTableRelatedContacts}
                      entriesOptions={[8, 30, 100, 500]}
                      entries={8}
                    />
                  </div>
                )}

                {this.state.customActiveTab === "7" && (
                  <div>
                    <div className="row referrer-total-container">
                      <div className="request-task total-payments">
                        <p>Total Payments</p>
                        <b>$ 50.00</b>
                        <i className="far fa-credit-card"></i>
                      </div>
                      <div className="request-task total-earned">
                        <p>Total Earned</p>
                        <b>$ 50.00</b>
                        <i className="fas fa-money-bill-wave-alt"></i>
                      </div>
                      <div className="request-task life-time-value">
                        <p>Life Time Value</p>
                        <b>$ {"0.00" || companyDetails.life_time_value}</b>
                        <i className="far fa-gem"></i>
                      </div>
                    </div>

                    <div className="request-task">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="header">REFERRER</div>
                        </div>
                        <div className="col-md-6 text-right switch-input">
                          <span>Enable</span>{" "}
                          <Switch
                            state={this.state.referrer_enabled ? true : false}
                            toggleStatus={this.toggleReferralStatus.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="row referrer-tier-container">
                        <div className="tier-1">
                          <p>1ST TIER RATE</p>
                          <b>
                            <Input
                              type="number"
                              value={tier_1_rate}
                              saveData={this.updateTierOneRate.bind(this)}
                              enabled={true}
                              append={"%"}
                              onChange={this.onChangeTierOneRate.bind(this)}
                            />
                          </b>
                        </div>
                        <div
                          className="tier-2"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}>
                          <p>2ND TIER RATE</p>
                          <b>
                            <Input
                              type="number"
                              value={tier_2_rate}
                              saveData={this.updateTierTwoRate.bind(this)}
                              enabled={true}
                              append={"%"}
                              onChange={this.onChangeTierTwoRate.bind(this)}
                            />
                          </b>
                        </div>
                      </div>
                      <div className="share-description">
                        Share this link to your folks. When we get work from
                        them using your link, you’ll get {tier_1_rate}%.
                      </div>
                      <div className="row referrer-container">
                        <div className="col-md-6 referrer-link">
                          <div className="break-word">
                            <input
                              className="form-control"
                              value={
                                this.state.referrer_enabled
                                  ? this.state.referral_link
                                  : ""
                              }
                              style={
                                this.state.referrer_enabled
                                  ? { backgroundColor: "#FFF", opacity: 1 }
                                  : {
                                      backgroundColor: "#697989",
                                      backgroundSize: "2px 4px",
                                      width: "95%",
                                      margin: "0 1px",
                                    }
                              }
                              disabled
                            />
                            <button
                              className="btn btn-primary ml-2"
                              onClick={() => this.copyReferralLink()}
                              disabled={!this.state.referrer_enabled}>
                              <i className="fa fa-link"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="request-task">
                      <div
                        className="header"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "20px",
                        }}>
                        <div style={{ alignItems: "flex-start" }}>
                          REFERRALS
                        </div>
                        <div
                          style={{ alignItems: "flex-end", display: "flex" }}>
                          <input
                            style={{
                              width: "224px",
                              height: "35px",
                              background: "#FFF",
                              border: "1px solid #CED4DA",
                              boxSizing: "border-box",
                              borderRadius: "4px",
                              padding: "0 12px 0 15px",
                            }}
                            type="text"
                            placeholder="Search"
                            onChange={(e) => this.handleReferralSearchChange(e)}
                          />
                        </div>
                      </div>
                      <DataTable
                        data={dataTableCommissions}
                        entriesOptions={[8, 30, 100, 500]}
                        entries={8}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.addTask ? (
          <AddTask
            minimize={this.minimize.bind(this)}
            open={this.openTask.bind(this)}
            close={this.closeTask.bind(this)}
            // task={this.state.allTask}
            projects={this.state.projects}
            coders={this.state.allCoders}
            company_id={this.state.company_id}
            updateList={this.updateList.bind(this)}
          />
        ) : (
          ""
        )}

        {this.state.addRequest ? (
          <AddRequest
            minimize={this.minimize.bind(this)}
            open={this.openRequest.bind(this)}
            close={this.closeRequest.bind(this)}
            task={this.state.allTask}
            coders={this.state.allCoders}
            allUser={this.state.allUser}
            company_id={this.state.company_id}
            contacts={this.state.companyDetails.contacts}
            newRequest={this.newRequest.bind(this)}
          />
        ) : (
          ""
        )}

        {this.state.newRequest ? (
          <RequestForm
            coders={this.state.coders}
            requestor={this.state.requestor}
            details={this.state.requestDetails}
            pricing_methods={this.state.pricing_methods}
            request_types={this.state.request_types}
            statuses={this.state.statuses}
            close={this.closeRequest.bind(this)}
            reloadData={this.reloadData.bind(this)}
            name={this.state.name}
            comments={this.state.comments}
            propertyDetails={this.propertyDetails.bind(this)}
            updateDetails={this.updateDetails.bind(this)}
          />
        ) : (
          ""
        )}

        {this.state.addWebsite ? (
          <AddWebsite
            minimize={this.minimize.bind(this)}
            open={this.openWebsite.bind(this)}
            close={this.closeWebsite.bind(this)}
            task={this.state.allTask}
            coders={this.state.allCoders}
            allUser={this.state.allUser}
            companyId={company_id}
            contacts={this.state.contactOptions}
            addProperty={this.addProperty}
          />
        ) : (
          ""
        )}

        {this.state.updateWebsite ? (
          <UpdateWebsite
            minimize={this.minimize.bind(this)}
            open={this.updateWebsite.bind(this)}
            close={this.closeWebsite.bind(this)}
            task={this.state.allTask}
            coders={this.state.allCoders}
            allUser={this.state.allUser}
            companyId={company_id}
            contacts={this.state.contactOptions}
            updateProperty={this.saveProperty}
            propertyDetails={this.state.propertyDetails}
          />
        ) : (
          ""
        )}

        {this.state.addServer ? (
          <AddServer
            minimize={this.minimize.bind(this)}
            open={this.openServer.bind(this)}
            close={this.closeServer.bind(this)}
            task={this.state.allTask}
            coders={this.state.allCoders}
            allUser={this.state.allUser}
            contacts={this.state.contactOptions}
            primaryContact={this.state.primaryContact}
            environment={this.state.environment}
            host={this.state.host}
            addProperty={this.addProperty}
            serverTypeOptions={this.state.serverTypeOptions}
            invironments={this.state.invironments}
            companyId={company_id}
          />
        ) : (
          ""
        )}

        {this.state.updateServer ? (
          <UpdateServer
            minimize={this.minimize.bind(this)}
            open={this.updateServer.bind(this)}
            close={this.closeServer.bind(this)}
            task={this.state.allTask}
            coders={this.state.allCoders}
            allUser={this.state.allUser}
            contacts={this.state.contactOptions}
            primaryContact={this.state.primaryContact}
            environment={this.state.environment}
            host={this.state.host}
            updateProperty={this.saveProperty}
            serverTypeOptions={this.state.serverTypeOptions}
            invironments={this.state.invironments}
            companyId={company_id}
            propertyDetails={this.state.propertyDetails}
          />
        ) : (
          ""
        )}

        {this.state.addEnvironment ? (
          <AddEnvironment
            minimize={this.minimize.bind(this)}
            open={this.openEnvironment.bind(this)}
            close={this.closeEnvironment.bind(this)}
            task={this.state.allTask}
            coders={this.state.allCoders}
            allUser={this.state.allUser}
            contacts={this.state.contactOptions}
            primaryContact={this.state.primaryContact}
            environment={this.state.environment}
            host={this.state.host}
            type={this.state.type}
            addProperty={this.addProperty}
            companyId={company_id}
          />
        ) : (
          ""
        )}

        {this.state.updateEnvironment ? (
          <UpdateEnvironment
            minimize={this.minimize.bind(this)}
            open={this.updateEnvironment.bind(this)}
            close={this.closeEnvironment.bind(this)}
            task={this.state.allTask}
            coders={this.state.allCoders}
            allUser={this.state.allUser}
            contacts={this.state.contactOptions}
            primaryContact={this.state.primaryContact}
            environment={this.state.environment}
            host={this.state.host}
            type={this.state.type}
            updateProperty={this.saveProperty}
            companyId={company_id}
            propertyDetails={this.state.propertyDetails}
          />
        ) : (
          ""
        )}

        {this.state.addCall ? (
          <AddPhoneLog
            minimize={this.minimize.bind(this)}
            open={this.openCall.bind(this)}
            close={this.closeCall.bind(this)}
            action={this.newPhoneCall.bind(this)}
            contacts={this.state.companyDetails.contacts}
            selectContact={true}
          />
        ) : (
          ""
        )}

        {this.state.updateCall ? (
          <UpdatePhoneLog
            minimize={this.minimize.bind(this)}
            open={this.updateCall.bind(this)}
            close={this.closeCall.bind(this)}
            action={this.updatedPhoneCall.bind(this)}
            contacts={this.state.companyDetails.contacts}
            selectContact={true}
            contactDetailsUpdate={this.state.contact_details}
          />
        ) : (
          ""
        )}

        {this.state.updateEmail ? (
          <UpdateEmail
            minimize={this.minimize.bind(this)}
            users={this.state.allUser}
            contacts={this.state.companyDetails.contacts}
            close={this.closeNewEmail.bind(this)}
            updateEmail={this.updateEmail.bind(this)}
            emailDetails={this.state.contact_details}
          />
        ) : (
          ""
        )}

        {this.state.addTag ? (
          <AddTag
            minimize={this.minimize.bind(this)}
            open={this.openTag.bind(this)}
            close={this.closeTag.bind(this)}
            selectContact={true}
            targetType="company"
            targetId={this.state.companyDetails.id}
            targetName={this.state.companyDetails.name}
            assign={this.assignTag.bind(this)}
            tags={this.state.tags}
            form={"addtag-form"}
          />
        ) : (
          ""
        )}

        {this.state.addNote ? (
          <AddNote
            minimize={this.minimize.bind(this)}
            open={this.openNote.bind(this)}
            close={this.closeNote.bind(this)}
            selectContact={true}
            targetType="company"
            targetId={this.state.companyDetails.id}
            newNote={this.newNote.bind(this)}
            isNew={true}
          />
        ) : (
          ""
        )}

        {this.state.updateNote ? (
          <AddNote
            minimize={this.minimize.bind(this)}
            open={this.openNote.bind(this)}
            close={this.closeNote.bind(this)}
            selectContact={true}
            targetType="company"
            targetId={this.state.companyDetails.id}
            updateNote={this.updateNoteList.bind(this)}
            isNew={false}
            noteDetails={this.state.note_details}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    () => {},
    (dispatch) => {
      return {
        setCompletedWork: (e) => dispatch(completedWork(e)),
      };
    }
  )(CompanyProfile)
);
