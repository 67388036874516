// @flow
import {
    SEARCH_PROPERTIES,
	CLEAR_PROPERTY_SEARCH_STRING
} from "./actionTypes";

const INIT_STATE = {
	propertySearchString: ""
};

const Property = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SEARCH_PROPERTIES:
			return {
				...state,
				propertySearchString: action.payload
			};

		case CLEAR_PROPERTY_SEARCH_STRING:
			return {
				...state,
				propertySearchString: ""
			};

		default:
			return state;
	}
};

export default Property;
