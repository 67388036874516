import React, { useEffect, useState } from "react";
import Api from "../../Utils/API";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
    getCookie,
    UniversalValidator
} from '../../Utils/helper';
import Swal from "sweetalert2";
import { Card, CardBody } from "reactstrap";

let access_token = getCookie("access_token");

const ChangePassword = (props) => {
    const [oldPw, setOldPw] = useState('');
    const [newPw, setNewPw] = useState('');
    const [confirmNewPw, setConfirmNewPw] = useState('');
    const [oldPwError, setOldPwError] = useState('');
    const [newPwError, setNewPwError] = useState('');
    const [confirmNewPwError, setConfirmNewPwError] = useState('');
    const [newPwMismatchError, setNewPwMismatchError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(! access_token)
            window.location.href = '/login'
    }, [])

    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        const oldPwError = UniversalValidator({ value: oldPw, isValidate: true, isUrl: false, errorMsg: 'Current Password is required.' });
        const newPwError = UniversalValidator({ value: newPw, isValidate: true, isUrl: false, errorMsg: 'New Password is required.' });
        const confirmNewPwError = UniversalValidator({ value: confirmNewPw, isValidate: true, isUrl: false, errorMsg: 'Confirm New Password is required.' });
        const newPwMismatchError = '';

        if (newPw != confirmNewPw) {
            Swal.fire({
                title: 'Error!',
                text: 'New Password and Confirm New Password do not match.',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setNewPwMismatchError('New Password and Confirm New Password do not match.');
            setLoading(false);
        } else if (oldPwError && newPwError && confirmNewPwError) {
            Swal.fire({
                title: 'Error!',
                text: 'Current Password, New Password and Confirm New Password are required.',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setOldPwError(oldPwError);
            setNewPwError(newPwError);
            setConfirmNewPwError(confirmNewPwError);
            setLoading(false);
        } else if (oldPwError) {
            Swal.fire({
                title: 'Error!',
                text: oldPwError,
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setOldPwError(oldPwError);
            setLoading(false);
        }  else if (newPwError) {
            Swal.fire({
                title: 'Error!',
                text: newPwError,
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setNewPwError(newPwError);
            setLoading(false);
        } else if (confirmNewPwError) {
            Swal.fire({
                title: 'Error!',
                text: confirmNewPwError,
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setConfirmNewPwError(confirmNewPwError);
            setLoading(false);
        } else {
            let params = {
                oldPw: oldPw,
                newPw: newPw
            }

            Api.changePassword(params).then(result => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Your password has been changed.',
                    icon: 'success',
                    confirmButtonText: 'Close'
                });

                setOldPw('');
                setNewPw('');
                setConfirmNewPw('');
                if(newPwMismatchError === '') setNewPwMismatchError('');
                if(confirmNewPwError === '') setConfirmNewPwError('');
                setLoading(false);
            }).catch(error => {
                console.log(error);

                if(error.response && error.response.data) {
                    Swal.fire({
                        title: 'Error!',
                        text: error.response.data,
                        icon: 'error',
                        confirmButtonText: 'Close'
                    });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Something went wrong.',
                        icon: 'error',
                        confirmButtonText: 'Close'
                    });
                }

                setLoading(false)
            });
        }
    }

    return (
        <React.Fragment>
            {loading ? <LoadingIndicator loading={loading} /> : ''}
            <div className="page-content">
                <div className="container-fluid container-body nopadding">
                    {/* <div className="row row-email"> */}
                        <div className="col-md-12" >
                        {/* <div className="justify-content-center" style={{display: 'flex', flex: 1}}> */}
                            <Card className="overflow-hidden" style={{background: '#FFF', boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.06)', borderRadius: 10, marginBottom: 0}}>
                                <CardBody>
                                    <div className="p-2" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
                                        <h2 style={{fontSize: 36, fontFamily: 'sofia-pro !important', marginBottom: 30}}>Change Password</h2>
                                        <form onSubmit={handleSubmit} className="change-password-form">
                                            <div className="contact-group">
                                                <div className="row-2">
                                                    Current Password
                                                </div>
                                                <div className="row-2">
                                                    <input type="password" className="form-control note-subject" placeholder="Enter Current Password" value={oldPw} onChange={(e) => setOldPw(e.target.value)} />
                                                    {
                                                        oldPwError ?
                                                            <label className="eror-fields">{oldPwError}</label>
                                                            : ''
                                                    }
                                                </div>
                                            </div>

                                            <div className="contact-group">
                                                <div className="row-2">
                                                    New Password
                                                </div>
                                                <div className="row-2">
                                                    <input type="password" className="form-control note-subject" placeholder="Re-enter New Password" value={newPw} onChange={(e) => setNewPw(e.target.value)} />
                                                    {
                                                        oldPwError ?
                                                            <label className="eror-fields">{newPwError}</label>
                                                            : ''
                                                    }
                                                </div>
                                            </div>

                                            <div className="contact-group">
                                                <div className="row-2">
                                                    Confirm New Password
                                                </div>
                                                <div className="row-2">
                                                    <input type="password" className="form-control note-subject" placeholder="Re-enter New Password" value={confirmNewPw} onChange={(e) => setConfirmNewPw(e.target.value)} />
                                                    {
                                                        oldPwError ?
                                                            <label className="eror-fields">{confirmNewPwError}</label>
                                                            :
                                                            newPwMismatchError ?
                                                                <label className="eror-fields">{newPwMismatchError}</label>
                                                                : ''
                                                    }
                                                </div>
                                            </div>

                                            <div className="change-password footer-action">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button type="buttom" onClick={handleSubmit} className="btn btn-primary btn-large" disabled={loading} >
                                                            {
                                                                loading ?
                                                                    (<i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i>)
                                                                    : ''
                                                            }
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </React.Fragment >
    );
}

export default ChangePassword;