
import React, { useState, useEffect } from 'react';
import closeIcon from "../../assets/images/closeIconWhite.png";
import minimizeIcon from "../../assets/images/minimizeIcon.png";
import maximizeIcon from "../../assets/images/maximizeIcon.svg";
import imageIcon from "../../assets/images/inviteImage.png";
import { customStyles } from '../../Utils/helper';
import API from "../../Utils/API";
import Select from "react-select";

export const AddWebsiteLog = ({
    id,
    close = () => {},
    updateList = () => {},
}) => {
    const [state, setState] = useState({value: null});
    const [isMinimized, setIsMinimized] = useState(false);
    const [log, setLog] = useState();
    const [type, setType] = useState();
    const typeOptions = [{
        value: "activity",
        label: "Activity",
    }]

    useEffect(() => {
        return () => {
            setState({value: null});
            setIsMinimized(false);
            setLog();
            setType();
        }
    }, []);

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setIsMinimized(true);
    }

    function openForm(event) {
        event.stopPropagation();
        setIsMinimized(false);
        document.body.classList.remove('minimize-form');
    }

    function updateType(e) {
        e.stopPropagation();
        setType(e);
    }

    function updateLog(e) {
        e.stopPropagation();
        setLog(e.target.value);
    }

    function showToast() {
        var x = document.getElementById("snackbar-company-form"); 
        x.className = "show row notification-div";
        setTimeout( ()=> { closeToast(); }, 5000);
    }

    function closeToast() {
        var x = document.getElementById("snackbar-company-form"); 
        if(x)
            x.className = x.className.replace("show", "");
    }

    function handleSubmit(event) {
        event.preventDefault();

        if(!log) {
            alert("Log is required");
        }

        if(!type) {
            alert("Type is required");
        }

        let params = {
            id,
            log: {
                log,
                type: type.value
            }
        }

        API.createWebsiteLog(params).then(result => {
            updateList();
        }).catch(error => {
            console.log(error)
            alert(error.message);
            setState({ ...state, isSubmit: false });
        }).finally(() => {
            close();
        });
    }

    return (
        <React.Fragment>
            <div className="contact-form" >
                <div className="form-header" onClick={(e) => openForm(e)} >
                    <div className="row-1"> Add Contact</div>
                    <div className="row-2">
                        {isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                    {/* !!! */}
                    <div id="snackbar-add-contact" onClick={closeToast} className="display-none">
                        <div className="col-5 notification-image flex-center" >
                            <img src={imageIcon} />
                        </div>
                        <div className="col-7 notification-details my-auto" >
                            <h5>Success!</h5>
                            { state.isUpdate?
                                    <p>Contact successfully updated!</p>
                                :   <p>You successfully created a new Contact!</p>
                            }
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="contact-group">
                            <div className="row-1">Type</div>
                            <div className="row-2">
                                <Select
                                    className="form-select ml-1"
                                    styles={customStyles}
                                    value={type}
                                    onChange={(e) => { setType(e) }}
                                    // onChange={updateType}
                                    options={typeOptions}
                                    placeholder="Select Type"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="contact-group">
                            <div className="row-1">Log</div>
                            <div className="row-2">
                                <input
                                    className="form-control"
                                    placeholder="Enter log here..."
                                    value={log}
                                    onChange={(e) => updateLog(e)}
                                    // onChange={updateLog}
                                    required />
                                { state.lnameError && <label className="eror-fields">{state.lnameError}</label> }
                            </div>
                        </div>
                        <div className="form-action">
                            <button className="btn btn-primary btn-large" disabled={state.isSubmit}>
                                { state.isSubmit && <i className="bx bx-loader bx-spin font-size-14 align-middle"></i> }
                                Save
                            </button>
                        </div>
                    </form>
                    {/* !!! */}
                </div>

            </div>
        </React.Fragment>
    );
};

export default AddWebsiteLog;
