import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import Api from "../../Utils/API";
import imageIcon from "../../assets/images/inviteImage.png";
import LoadingIndicator from "../../components/LoadingIndicator";
import DataTable from "../../components/DataTable";
// import { Api as ApiImage } from '../../Utils/consts';
import moment from 'moment';
import { connect } from "react-redux";
import AddEnvironment from "../../components/AddEnvironment";
import AddServer from "../../components/AddServer";
import AddWebsite from "../../components/AddWebsite";
import UpdateWebsite from "../../components/UpdateWebsite";
import UpdateServer from "../../components/UpdateServer";
import UpdateEnvironment from "../../components/UpdateEnvironment";

function WebsitesList(props) {
    // const history = useHistory();
    const [stateCompanies, setCompaniesState] = useState([])
    const [state, setState] = useState({
        propertyType: 'websites',
        loading: true,
        addWebsite: false,
        addServer: false,
        addEnvironment: false,
        updateWebsite: false,
        updateServer: false,
        updateEnvironment: false,
        properties: [],
        isSubmit: false,
        propertyDetails: '',
        openProp: false,
        allTask: [],
        allUser: [],
        details: '',
        allCoders: [],
        contactOptions: [],
        toastData: { title: '', description: '' },
    })
        
    //first render
    useEffect(() => {
        propertiesList();
        companiesList();
        allCoders();
    }, [])

    // Render update after changing property type
    useEffect(() => {
        propertiesList();
    }, [state.propertyType])

    // Re-render if searching
    useEffect(() => {
        search(props.propertySearchString);
    }, [props.propertySearchString]);

    const search = (search) => {
        setState({ ...state, loading: true });
        Api.getProperties(state.propertyType, search).then(result => {
            setState({...state, properties: result.data, loading: false, addWebsite: false, addServer: false, addEnvironment: false, updateProperty: false, updateEnvironment: false, updateWebsite: false, updateServer: false});
        }).catch(error => {
            console.log(error);
        });
    };

    const companiesList = () => {
        Api.getCompanies().then(result => {
            setCompaniesState({companies: result.data })
        }).catch(error => {
            console.log(error);
        });
    }

    const propertiesList = (property_added = false) => {
        setState({...state, loading: true }) /* loads every property type changes */
        
        Api.getProperties(state.propertyType).then(result => {
            setState({...state, properties: result.data, loading: false, addWebsite: false, addServer: false, addEnvironment: false, updateProperty: false, updateEnvironment: false, updateWebsite: false, updateServer: false, toastData: { title: property_added ? 'Success!' : '', description:  property_added ? 'You successfully created a new properties!' : '' }})
        }).catch(error => {
            console.log(error);
        });
    }

    const allCoders = () => {
        Api.allCoders().then(result => { // console.log('coders' , result.data);
            setState({...state, allCoders: result.data })
        }).catch(error => {
            console.log(error);
        });
    }

    const showToast = () => {
        var x = document.getElementById("snackbar-properties");
        x.className = "show row notification-div";
        setTimeout(() => { closeToast() }, 2500);
    }

    const closeToast = () => {
        var x = document.getElementById("snackbar-properties");
        x.className = x.className.replace("show", "");
    }

    const addProperty = (property) => {
        // let properties = state.properties.concat(property);
        // console.log(properties)
        propertiesList(true);
        showToast();
    }

    const minimize = (event) => {
        event.stopPropagation();
        document.body.classList.add('minimize-form');
    }

    const openWebsite = (event) => {
        event.stopPropagation();
        event.preventDefault();
        document.body.classList.remove('minimize-form');
        setTimeout(() => { setState({...state, addWebsite: true, addServer: false, addEnvironment: false, openProp: false, updateEnvironment: false, updateWebsite: false, updateServer: false, details: ''}) }, 100);
    }

    const openServer = (event) => {
        event.stopPropagation();
        event.preventDefault();
        document.body.classList.remove('minimize-form');
        setTimeout(() => { setState({...state,addWebsite: false, addServer: true, addEnvironment: false, openProp: false, updateEnvironment: false, updateWebsite: false, updateServer: false, details: ''}) }, 100);
    }

    const openEnvironment = (event) => {
        event.stopPropagation();
        event.preventDefault();
        document.body.classList.remove('minimize-form');
        setTimeout(() => { setState({...state, addWebsite: false, addServer: false, addEnvironment: true, openProp: false, updateEnvironment: false, updateWebsite: false, updateServer: false, details: ''}) }, 100);
    }

    const closeWebsite = (event) => {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        setState({...state, addWebsite: false, updateWebsite: false });
    }

    const saveProperty = (property) => {
        // Find index of specific object using findIndex method. 
        const section = state.properties.findIndex(item => item.id == property.id)
        state.properties[section] = property;

        setState({...state, addWebsite: false, addServer: false, addEnvironment: false, isSubmit: false, updateEnvironment: false, updateWebsite: false, updateServer: false, toastData: { title: 'Success!', description: 'You successfully updated properties!' } });
        propertiesList(true);
        showToast();
    }


    const closeServer = (event) => {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        setState({...state, addServer: false, updateServer: false});
    }

    const closeEnvironment = (event) => {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        setState({...state, addEnvironment: false, updateEnvironment: false});
    }

    const updateWebsite = (event, property) => {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        setState({...state, addWebsite: false, addServer: false, addEnvironment: false, updateWebsite: true, updateEnvironment: false, updateServer: false, details: property || state.details  });
    }

    const updateServer = (event, property) => {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        setState({...state, addWebsite: false, addServer: false, addEnvironment: false,updateWebsite: false, updateEnvironment: false, updateServer: true, details: property || state.details });
    }

    const updateEnvironment = (event, property) => {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        setState({...state, addWebsite: false, addServer: false, addEnvironment: false, updateWebsite: false, updateEnvironment: true, updateServer: false, details: property || state.details  });
    }

    const handleClick = (e, property) => {
        e.stopPropagation();
        window.location.href = `/website-details?id=${property.id}`;
    }

    const toggleTab = (propertyType) => {
        setState({...state, propertyType });
    }

    const dataTable = {
        columns: [
            {
                label: "Website",
                field: "website",
            },
            {
                label: "Name",
                field: "name",
            },
            {
                label: "Primary Contact",
                field: "primary_contact",
            },
            {
                label: "Type",
                field: "type",
            },
            {
                label: "Last Maintenance",
                field: "last_maintenance",
            },
        ],
        rows: []
    };
    let properties = state.properties;  
    console.log(properties)
    for (let index = 0; index < properties.length; index++) {
        dataTable.rows.push(
            {
                website: properties[index].name,
                name: properties[index].company ? properties[index].company.name : '',
                primary_contact: properties[index].primary_contact ? `${properties[index].primary_contact.firstname} ${properties[index].primary_contact.surname}` : '',
                type: properties[index].type,
                last_maintenance: properties[index].last_maintenance === null ? 'None' : moment(properties[index].last_maintenance).format('DD/MM/YYYY hh:mm A'),
                // clickEvent: (e) => handleClick(e, properties[index])
                clickEvent: (e) => handleClick(e, properties[index])
            }
        );
    }

    return (
        <React.Fragment>
            
            {/* SNACKBAR NOTIFICATION */}
            <div id="snackbar-properties" onClick={closeToast} className="display-none">
                <div className="col-5 notification-image flex-center" >
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto" >
                    <h5>{state.toastData.title}</h5>
                    <p>{state.toastData.description}</p>
                </div>
            </div>
            
            {/* LOADER */}
            {state.loading ? <LoadingIndicator loading={state.loading} /> : ''}
            
            <div className="page-content">
                <div className="header-title">
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn btn-primary" onClick={(e) => openWebsite(e)}>
                                <i className="fa fa-plus"></i>
                                Add Website
                            </button>
                        </div>
                    </div>
                </div>

                {/* DATATABLE */}
                <div className="container-fluid container-body white-bg clickable-datatable">
                    <DataTable data={dataTable} entriesOptions={[8, 30, 100, 500]} entries={15} />
                </div>

            </div>

            {/* FORMS FOR WEBSITE, SERVER AND ENVIRONMENT */}
            { state.addWebsite ?
                <AddWebsite
                    minimize={minimize}
                    open={openWebsite}
                    close={closeWebsite}
                    task={state.allTask}
                    coders={state.allCoders}
                    allUser={state.allUser}
                    contacts={state.contactOptions}
                    addProperty={addProperty}
                    companies={stateCompanies.companies}
                    details={state.details}
                />
            : '' }

            {
                state.updateWebsite ? 
                <UpdateWebsite
                    minimize={minimize}
                    open={updateWebsite}
                    close={closeWebsite}
                    task={state.allTask}
                    coders={state.allCoders}
                    allUser={state.allUser}
                    companies={stateCompanies.companies}
                    contacts={state.contactOptions}
                    updateProperty={saveProperty}
                    propertyDetails={state.details}
                />
                : ''
            }

            { state.addServer ?
                <AddServer
                    minimize={minimize}
                    open={openServer}
                    close={closeServer}
                    task={state.allTask}
                    coders={state.allCoders}
                    allUser={state.allUser}
                    contacts={state.contactOptions}
                    primaryContact={state.primaryContact}
                    environment={state.environment}
                    host={state.host}
                    addProperty={addProperty}
                    serverTypeOptions={state.serverTypeOptions}
                    host={state.host}
                    invironments={state.invironments}
                    companies={stateCompanies.companies}
                    details={state.details}
                />
            : '' }

            {
                state.updateServer ?
                    <UpdateServer
                        minimize={minimize}
                        open={updateServer}
                        close={closeServer}
                        task={state.allTask}
                        coders={state.allCoders}
                        allUser={state.allUser}
                        contacts={state.contactOptions}
                        primaryContact={state.primaryContact}
                        environment={state.environment}
                        host={state.host}
                        updateProperty={saveProperty}
                        serverTypeOptions={state.serverTypeOptions}
                        host={state.host}
                        invironments={state.invironments}
                        companies={stateCompanies.companies}
                        propertyDetails={state.details}
                    />
                    : ''
            }

            { state.addEnvironment ?
                <AddEnvironment
                    minimize={minimize}
                    open={openEnvironment}
                    close={closeEnvironment}
                    task={state.allTask}
                    coders={state.allCoders}
                    allUser={state.allUser}
                    contacts={state.contactOptions}
                    primaryContact={state.primaryContact}
                    environment={state.environment}
                    host={state.host}
                    type={state.type}
                    addProperty={addProperty}
                    companies={stateCompanies.companies}
                    details={state.details}
                />
            : '' }
            {
                state.updateEnvironment ?
                    <UpdateEnvironment
                        minimize={minimize}
                        open={updateEnvironment}
                        close={closeEnvironment}
                        task={state.allTask}
                        coders={state.allCoders}
                        allUser={state.allUser}
                        contacts={state.contactOptions}
                        primaryContact={state.primaryContact}
                        environment={state.environment}
                        host={state.host}
                        type={state.type}
                        updateProperty={saveProperty}
                        companies={stateCompanies.companies}
                        propertyDetails={state.details}
                    />
                : ''
            }
        </React.Fragment>
    );
}

const mapStatetoProps = ({Property}) => {
    return {
        propertySearchString: Property.propertySearchString,
        clearPropertySearchString: Property.clearPropertySearchString
    };
};

export default connect(mapStatetoProps)(WebsitesList);
