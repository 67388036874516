
import React, { useState, useEffect } from 'react';
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";


export const PropertyDetails = (props) => {
    const [state, setState] = React.useState([]);
    // const handleChange = value => {
    //     setState({ value });
    //     props.handleChange(value);
    // };

    useEffect(() => {
        if (props.data) {
            setState(props.data);
        }
    }, [props]);



    return (
        <React.Fragment>
            <div className="contact-form property-details-form" >
                <div className="form-header" onClick={props.open} >
                   <div className="row-1"> {props.name}</div>
                    <div className="row-2">
                        <button className="btn btn-icon" onClick={props.minimize} > <img src={minimizeIcon} /> </button>
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                    {state.map((item, i) => {
                        return (
                            <>
                                <h4 className="website-font mt-3 mb-3" key={'h4'+i}>{item.name}</h4>
                                <table className="table-property" key={'table'+i}>
                                    <tbody key={'tbody'+i}>
                                        <tr key={'url'+i} >
                                            <td>Url:</td>
                                            <td><b>{item.details.url}</b></td>
                                        </tr>
                                        <tr key={'username'+i} >
                                            <td>Username:</td>
                                            <td><b>{item.details.username}</b></td>
                                        </tr>
                                        <tr key={'password'+i} >
                                            <td>Password:</td>
                                            <td><b>{item.details.password}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        );
                    })}
                    <div style={{ minHeight: 22 }} ></div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PropertyDetails;
