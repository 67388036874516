import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Api from "../../Utils/API";
// import AddContact from '../../components/AddContact';
import AddCompany from '../../components/AddCompany';
import LoadingIndicator from "../../components/LoadingIndicator";
import DataTable from "../../components/DataTable";
import Avatar from "../../components/Avatar";
import { getFirstWord } from '../../Utils/helper';
import { Api as ApiImage } from '../../Utils/consts';
import moment from 'moment';
import { connect } from "react-redux";

class CompanyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newRequest: false,
            loading: true,
            addCompany: false,
            list: [],
            company: [],
            id: '',
            isSubmit: false,
        }

        this.search = this.search.bind(this)
    }

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate(prev_props) {
        if(prev_props.companySearchString !== this.props.companySearchString) {
            // search
            this.search();
        }
    }

    search() {
        this.setState({ loading: true });
        Api.searchCompanies(this.props.companySearchString).then(result => {  
            this.setState({ list: result.data, loading: false});
        }).catch(error => {
            console.log(error);
        });
    }

    getList() {
        Api.getCompanies().then(result => {
            console.log(result.data);
            setTimeout(() => {
                this.setState({ list: result.data, loading: false });
            }, 1000);
        }).catch(error => {
            console.log(error);
        });
    }

    minimize(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');
    }

    addCompany() {
        document.body.classList.remove('minimize-form');
        this.setState({ addCompany: true });
    }

    openCompany(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
    }

    updateList(company) {
        let list = this.state.list

        list.push(company)

        this.setState({
            list: list
        });
    }

    render() {

        const dataTable = {
            columns: [
                {
                    label: "Organisation",
                    field: "name",
                },
                {
                    label: "Phone",
                    field: "phone",
                },
                {
                    label: "No. of Employees",
                    field: "numEmployees",
                },
                {
                    label: "Date Added",
                    field: "dateAdded",
                    width: 150
                },
                {
                    label: "Action",
                    field: "action",
                    width: 150
                }
            ],
            rows: []
        };

        let list = this.state.list;  // console.log('list', list);
        for (let index = 0; index < list.length; index++) {
            let status = '';
            let statusText = 'NO ACCOUNT';
            if (list[index].is_csa === 1) {
                status = 'status-completed';
                statusText = 'REGISTERED';
            }
            dataTable.rows.push(
                {
                    name: <div  className="container-profile">
                            <div className="row-1">
                                <Avatar
                                    name={` ${getFirstWord(list[index].name)} `}
                                    round={true}
                                    size="32"
                                    color="#f44336"
                                    image={list[index].avatar ? ApiImage + list[index].avatar : ''}
                                    className="btn-cirle-avatar"
                                />
                            </div>
                            <div className="row-2 item-center flex-row">
                                {list[index].name}
                            </div>
                        </div>,
                    email: list[index].email,
                    phone: list[index].phone1,
                    organisation: list[index].organisation,
                    numEmployees: list[index].number_of_employees,
                    dateAdded: list[index].created_at === null ? '' : moment(list[index].created_at).format('DD/MM/YYYY hh:mm A'),
                    action: <Link to={`/companies-profile?id=${list[index].id}`} className="btn-sm view-profile-btn"><i className="fa fa-search" ></i></Link>
                }
            );
        }

        return (
            <React.Fragment>
                {this.state.loading ? <LoadingIndicator loading={this.state.loading} /> : ''}
                <div className="page-content">
                    <div className="header-title" >
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <button onClick={this.addCompany.bind(this)} className="btn btn-primary" disabled={this.state.isSubmit}  >
                                    <i className="fa fa-plus" ></i>
                                    Add Company
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid container-body white-bg">
                        <DataTable
                            data={dataTable}
                            entriesOptions={[8, 30, 100, 500]} 
                            entries={8}
                        />
                    </div>
                </div>
                {
                    this.state.addCompany ?
                        <AddCompany
                            minimize={this.minimize.bind(this)}
                            openCompany={this.openCompany.bind(this)}
                            close={() => { this.setState({ addCompany: false }) }}
                            updateList={ this.updateList.bind(this) }
                        />
                        : ''
                }

            </React.Fragment>
        );
    }
}

const mapStatetoProps = ({Company}) => {
    return { companySearchString: Company.companySearchString };
};

export default connect(mapStatetoProps, (dispatch) => {
    return {
        // setsearch:(e)=> dispatch(searchCompanies(e))
    }
})((CompanyList));
