

import React, { useState, useEffect } from 'react';
import closeIcon from "../../../assets/images/closeIconWhite.png";
import minimizeIcon from "../../../assets/images/minimizeIcon.png";
import maximizeIcon from "../../../assets/images/maximizeIcon.svg";
import LogFormBody from './LogFormBody';

export const LogForm = (props) => { 
    const [isMinimized, setIsMinimized] = useState(false);

    const updateFormDisplay = (event) => {
        event.stopPropagation();
        
        isMinimized ? document.body.classList.remove('minimize-form') : document.body.classList.add('minimize-form');
        setIsMinimized(!isMinimized);
    }

    return (
        <React.Fragment>
            <div className="contact-form">
                <div className="form-header" onClick={(e) => updateFormDisplay(e)}>
                    <div className="row-1">{props.action_title}</div>
                    <div className="row-2">
                        <button className="btn btn-icon" onClick={(e) => updateFormDisplay(e)}> <img src={isMinimized ? maximizeIcon : minimizeIcon} /> </button>
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                    <LogFormBody 
                        submitButton={props.submitButton} 
                        type_options={props.type_options} 
                        property_options={props.property_options} 
                        log_details={props.log_details}
                        error={props.error}
                        isSubmit={props.isSubmit}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default LogForm;
