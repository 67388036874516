import React, { useState, useEffect } from "react";
import Api from "../../Utils/API";
import imageIcon from "../../assets/images/inviteImage.png";
import LoadingIndicator from "../../components/LoadingIndicator";
import DataTable from "../../components/DataTable";
import Avatar from "../../components/Avatar";
import { getFirstWord } from '../../Utils/helper';
import { Api as ApiImage } from '../../Utils/consts';
import moment from 'moment';
import { connect } from "react-redux";
import LogForm from "../../components/Forms/Logs/LogForm";

function LogsList(props) {
    const [stateTypeOptions, setStateTypeOptions] = useState([]);
    const [statePropertyOptions, setStatePropertyOptions] = useState([]);
    const [state, setState] = useState({
        loading: true,              // For page progress loader purposes
        openLogForm: false,         // Modal for adding log
        addLog: false,              // Modal for updating Member
        editLog: false,           // Modal for updating Member
        logs_list: [],              // Populate logs to datatable
        isSubmit: false,            
        log_details: '',            // for updating a log
        toastData: { title: '', description: '' },
        error: '',
    })
        
    // Render
    useEffect(() => {
        typeList();             // fill type_options
        propertyList();         // fill property_options
        logList();              // fill list
    }, [])

    const logList = () => {
        Api.getPropertyNotes().then(result => {
            setState({...state, logs_list: result.data, loading: false});
        }).catch(error => {
            console.log(error);
        });
    }

    // API -----------------------------------------------
    const propertyList = () => {
        Api.getAllProperties().then(result => {
            setStatePropertyOptions({...statePropertyOptions, property_options: result.data, loading: false})
        }).catch(error => {
            console.log(error);
        });
    }

    const typeList = () => {
        Api.getAllPropertyNoteTypes().then(result => {
            setStateTypeOptions({...stateTypeOptions, type_options: result.data})
        }).catch(error => {
            console.log(error);
        });
    }

    // Toast ---------------------------------------------
    const showToast = () => {
        var x = document.getElementById("snackbar-properties");
        x.className = "show row notification-div";
        setTimeout(() => { closeToast() }, 2500);
    }

    const closeToast = () => {
        var x = document.getElementById("snackbar-properties");
        x.className = x.className.replace("show", "");
    }

    // Add/Update Log ---------------------------------------
    const addLog = (log) => {
        setState({...state, isSubmit: true});

        Api.addPropertyNote(log).then(response => {
            let new_log = response.data.property_note;
            let message = response.data.message;
            
            // Check for validation errors
            if (response.data.status == 'validation failed')
                return setState({...state, error: message, isSubmit: false})

            state.logs_list.unshift(new_log);
            updateLogList(message);
        }).catch(error => {
            console.log(error);
        });
    }

    const updateLog = (log) => {
        setState({...state, isSubmit: true});

        Api.updatePropertyNote(log).then(response => {
            let logs = state.logs_list;
            let updated_log = response.data.property_note;
            let message = response.data.message;

            // Check for validation errors
            if (response.data.status == 'validation failed')
                return setState({...state, error: message, isSubmit: false})

            // Update the selected domain
            const logIndex = logs.findIndex(obj => obj.id === updated_log.id);
            logs[logIndex] = updated_log;

            updateLogList(message);
        }).catch(error => {
            console.log(error);
        });
    }

    // Update Domains List after adding/updating it on the DB --
    const updateLogList = (message) => {
        setState({
            ...state,
            logs_list: state.logs_list,
            openLogForm: false,
            addLog: false,
            editLog: false,
            isSubmit: false,
            log_details: '',
            error: '',
            toastData: { title:'Success!', description: message} 
        });

        showToast();
    }

    const minimize = (event) => {
        event.stopPropagation();
        document.body.classList.add('minimize-form');
    }

    // Modals --------------------------------------------
    // ----- add modal -----
    const openLog = (event, add=false, edit=false, details='') => {
        event.stopPropagation();
        event.preventDefault();
        document.body.classList.remove('minimize-form');
        setState({...state, openLogForm: true, addLog: add, editLog: edit, log_details: details, error: ''})
    }

    // ----- close modal -----
    const closeLog = (event) => {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        setState({...state, openLogForm: false, log_details: '' });
    }
    
    // Datatable -------------------------------------------
    const { logs_list } = state;
    const logsTable = {
        columns: [
            {
                label: "Property Name",
                field: "name"
            },
            {
                label: "Title",
                field: "title"
            },
            {
                label: "Notes",
                field: "notes"
            },
            {
                label: "Type",
                field: "type"
            },
            {
                label: "Added By",
                field: "added_by"
            },
            {
                label: "Date & Time",
                field: "date_time"
            },
        ],
        rows: []
    };

    logs_list.forEach(item => {
        logsTable.rows.push({
            name: item.property.name,
            title: <span className="text-truncate" style={{display: 'inline-block', width: 250}}>{item.title}</span>,
            notes: <div className="log-notes">{item.notes}</div>,
            type: item.type.title,
            added_by: <div  className="container-profile">
                        <div className="row-1">
                            <Avatar
                                name={` ${getFirstWord(item.added_by.firstname)} `}
                                round={true}
                                size="22"
                                color="#f44336"
                                // image={ApiImage + list[index].avatar}
                                className="btn-cirle-avatar"
                            />
                        </div>
                        <div className="row-2 item-center flex-row log_added_by">
                            {item.added_by.firstname} {item.added_by.surname}
                        </div>
                    </div>,
            date_time: moment(item.created_at).format('YY-MM-DD hh:mm A'),
            clickEvent: (e) => openLog(e, false, true, item)
        })
    });

    return (
        <React.Fragment>
            
            {/* SNACKBAR NOTIFICATION */}
            <div id="snackbar-properties" onClick={closeToast} className="display-none">
                <div className="col-5 notification-image flex-center" >
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto" >
                    <h5>{state.toastData.title}</h5>
                    <p>{state.toastData.description}</p>
                </div>
            </div>
            
            {/* LOADER */}
            {state.loading ? <LoadingIndicator loading={state.loading} /> : ''}
            
            <div className="page-content">
                <div className="header-title-2">
                    <div className="row">

                        {/* HEADER TITLE */}
                        <div className="col-md-6">
                        </div>

                        {/* Add Log Button */}
                        <div className="col-md-6 mb-2 text-right" style={{marginTop: -5}}>
                            <button className="btn btn-primary" onClick={(e) => openLog(e, true)} disabled={state.isSubmit || state.loading}>
                                + Add Log
                            </button>
                        </div>
                    </div>
                </div>

                {/* DATATABLE */}
                <div className="container-fluid container-body white-bg clickable-datatable">
                    <DataTable data={logsTable} entriesOptions={[8, 30, 100, 500]} entries={8} scrollY={true}/>
                </div>

            </div>

            {/* FORMS FOR LOGS */}
            { state.openLogForm ?
                <LogForm
                    minimize={minimize}
                    open={openLog}
                    close={closeLog}
                    isSubmit={state.isSubmit}
                    submitButton={state.log_details ? updateLog : addLog}
                    action_title={state.addLog ? 'Add Log Notes' : 'Update Log Notes'}
                    log_details={state.log_details ? state.log_details : ''}
                    type_options={stateTypeOptions.type_options}
                    property_options={statePropertyOptions.property_options}
                    error={state.error}
                />
            : '' }

        </React.Fragment>
    );
}

const mapStatetoProps = ({Company}) => {
    return { companySearchString: Company.companySearchString };
};

export default connect(mapStatetoProps, (dispatch) => {
    return {
        // setsearch:(e)=> dispatch(searchCompanies(e))
    }
})((LogsList));
