

import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from "react-quill";
import EditorToolbar, { textarea_module_2, textarea_module_3, formats, modules } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

export const Editor = (props) => {
  const [hover, setHover] = useState(false);
  const [state, setState] = React.useState({ 
    value: props.value
  });

  const handleChange = value => {
    setState({ ...state, value: value || '' });
    props.handleChange(value);
  };

  const handleImageChange = event => {
    let files = event.target.files;
    props.handleImage(files);
  };

  const removeAttachment = (i) => {
    props.removeAttachment(i)
  }
  
  useEffect(() => {
    if( props.value) setState({...state, value: props.value});
    if (props.clear) setState('');
  }, [props]);

  return (
    <div className={`text-editor ${props.form ? props.form : ''}`}>
      <EditorToolbar form={props.form} index={props.index ? props.index : 1}/>
      <ReactQuill
        theme="snow"
        readOnly={props.emailDetails}
        value={state.value || ''}
        onChange={handleChange}
        placeholder={ props.placeholder ?  props.placeholder :'Tap here to start writing your comment...'}
        modules={props.index == 2 ? textarea_module_2 : props.index == 3 ? textarea_module_3 : modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;
