import React, {useState, useEffect} from 'react';
import Api from "../../Utils/API";
// import {Api as ApiImage} from "../../Utils/consts";
// import ScroolUser from "../ScroolUser";
import userIcon from "../../assets/images/userIcon.png";
import Designers from './Designers';
import Developers from './Developers';
import ProjectManagers from './ProjectManagers';
import QA from './QA';
import {Row, Col} from "reactstrap";

const TeamAssignmentRow = (props) => {
    const [state, setState] = useState({
        users: [],
        projectManagers: props.projectManagers ?? [],
        developers: props.develolpers ?? [],
        qas: props.qas ?? [],
        designers: props.designers ?? [],
        pricing: props.pricing,
        isViewOnly: props.viewOnly
    });

    useEffect(() => {
        Api.allCoders().then(result => {
            let coders = result.data;    // get coders
            let coder_options = [];      // used on <Select>
            coders.forEach(item => {
                coder_options.push({
                    label: `${item.firstname} ${item.surname}`,
                    value: item.id
                })
            })

            setState((prevState) => ({
                ...prevState,
                users: coders
            }))
        }).catch(error => {
            console.log(error)
        });
    }, []);

    // useEffect(() => {
    //     setState((prevState) => ({
    //         ...prevState,
    //         admin: props.admin,
    //         loadAdminAssignee: false
    //     }));
    // }, [props.admin]);

    // useEffect(() => {
    //     setState((prevState) => ({
    //         ...prevState,
    //         primary: props.primary ? state.users.filter(opt => opt.id == props.primary)[0]: null,
    //         loadPrimaryAssignee: false
    //     }));
    // }, [props.primary]);

    // useEffect(() => {
    //     setState((prevState) => ({
    //         ...prevState,
    //         qa: props.qa,
    //         loadQaAssignee: false
    //     }));
    // }, [props.qa]);

    // useEffect(() => {
    //     setState((prevState) => ({
    //         ...prevState,
    //         designer: props.designer,
    //         loadDesignerAssignee: false
    //     }));
    // }, [props.designer]);

    // useEffect(() => {
    //     const {admin, primary, qa, designer, pricing} = props;
    //     setState((prevState) => ({
    //         ...prevState,
    //         loadPricing: false,
    //         pricing
    //     }))
    // }, [props.pricing]);

    const updateDesigners = (id, prev = null) => {
        props.updateDesigners(id, prev);
        // const {designers} = state;
        // let prevIndex = prev ? designers.indexOf(prev) : -1;

        // if(designers.includes(id)) {
        //     // remove id from the array
        //     designers.splice(designers.indexOf(id), 1);
        // } else {
        //     // check if prev is not null and if it is in the designers array
        //     if(prev && designers.includes(prev)) {
        //         // retrieve prev's index
        //         prevIndex = designers.indexOf(prev);
        //         // remove prev from the array
        //         designers.splice(prevIndex, 1, id);
        //     } else {
        //         designers.push(id);
        //     }
        // }

        // setState((prevState) => ({
        //     ...prevState,
        //     designers
        // }));
    }

    const updateDevelopers = (id, prev = null) => {
        props.updateDevelopers(id, prev);
        // const {developers} = state;
        // let prevIndex = prev ? developers.indexOf(prev) : -1;

        // if(developers.includes(id)) {
        //     // remove id from the array
        //     developers.splice(developers.indexOf(id), 1);
        // } else {
        //     // check if prev is not null and if it is in the developers array
        //     if(prev && developers.includes(prev)) {
        //         // retrieve prev's index
        //         prevIndex = developers.indexOf(prev);
        //         // remove prev from the array
        //         developers.splice(prevIndex, 1, id);
        //     } else {
        //         developers.push(id);
        //     }
        // }

        // setState((prevState) => ({
        //     ...prevState,
        //     developers
        // }));
    }

    const updateProjectManagers = (id, prev = null) => {
        props.updateProjectManagers(id, prev);
        // const {projectManagers} = state;
        // let prevIndex = prev ? projectManagers.indexOf(prev) : -1;

        // if(projectManagers.includes(id)) {
        //     // remove id from the array
        //     projectManagers.splice(projectManagers.indexOf(id), 1);
        // } else {
        //     // check if prev is not null and if it is in the projectManagers array
        //     if(prev && projectManagers.includes(prev)) {
        //         // retrieve prev's index
        //         prevIndex = projectManagers.indexOf(prev);
        //         // remove prev from the array
        //         projectManagers.splice(prevIndex, 1, id);
        //     } else {
        //         projectManagers.push(id);
        //     }
        // }

        // setState((prevState) => ({
        //     ...prevState,
        //     projectManagers
        // }));
    }

    const updateQas = (id, prev = null) => {
        props.updateQas(id, prev);
        // const {qas} = state;
        // let prevIndex = prev ? qas.indexOf(prev) : -1;

        // if(qas.includes(id)) {
        //     // remove id from the array
        //     qas.splice(qas.indexOf(id), 1);
        // } else {
        //     // check if prev is not null and if it is in the qas array
        //     if(prev && qas.includes(prev)) {
        //         // retrieve prev's index
        //         prevIndex = qas.indexOf(prev);
        //         // remove prev from the array
        //         qas.splice(prevIndex, 1, id);
        //     } else {
        //         qas.push(id);
        //     }
        // }

        // setState((prevState) => ({
        //     ...prevState,
        //     qas
        // }));
    }

    const removeDesigner = (assignee) => {
        props.removeDesigner(assignee);
        // const {designers} = state;
        // if(designers.includes(assignee.id)) {
        //     // remove id from the array
        //     designers.splice(designers.indexOf(assignee.id), 1);
        // }

        // setState((prevState) => ({
        //     ...prevState,
        //     designers
        // }));
    }

    const removeDeveloper = (assignee) => {
        props.removeDeveloper(assignee);
        // const {developers} = state;
        // if(developers.includes(assignee.id)) {
        //     // remove id from the array
        //     developers.splice(developers.indexOf(assignee.id), 1);
        // }

        // setState((prevState) => ({
        //     ...prevState,
        //     developers
        // }));
    }

    const removeProjectManager = (assignee) => {
        props.removeProjectManager(assignee);
        // const {projectManagers} = state;
        // if(projectManagers.includes(assignee.id)) {
        //     // remove id from the array
        //     projectManagers.splice(projectManagers.indexOf(assignee.id), 1);
        // }

        // setState((prevState) => ({
        //     ...prevState,
        //     projectManagers
        // }));
    }

    const removeQa = (assignee) => {
        props.removeQa(assignee);
        // const {qas} = state;
        // if(qas.includes(assignee.id)) {
        //     // remove id from the array
        //     qas.splice(qas.indexOf(assignee.id), 1);
        // }

        // setState((prevState) => ({
        //     ...prevState,
        //     qas
        // }));
    }

    return (
        <React.Fragment>
            {
                props.view === "edit"
                    ? <>
                        <Row className="mb-2 w-full">
                            <Col className="col-lg-6 pr-0" style={{minWidth: "50%"}}>
                                <div style={{marginBottom: "6px", marginTop: "2px", paddingRight: 0}}>Project Manager:</div>
                                <div style={{display: "flex", flexDirection: "row", minWidth: "102px"}}>
                                    <ProjectManagers updateProjectManagers={updateProjectManagers} removeProjectManager={removeProjectManager} projectManagers={props.projectManagers} userIcon={userIcon} options={state.users} viewOnly={state.isViewOnly} />
                                </div>
                            </Col>

                            <Col className="col-lg-6 pr-0">
                                <div style={{marginBottom: "6px", marginTop: "2px"}}>Developer:</div>
                                <div style={{display: "flex", flexDirection: "row", minWidth: "102px"}}>
                                    <Developers updateDevelopers={updateDevelopers} removeDeveloper={removeDeveloper} developers={props.developers} userIcon={userIcon} options={state.users} viewOnly={state.isViewOnly} />
                                </div>
                            </Col>
                        </Row>

                        <Row className="w-full">
                            <Col className="col-lg-6 pr-0">
                                <div style={{marginBottom: "6px", marginTop: "2px"}}>Designer:</div>
                                <div style={{display: "flex", flexDirection: "row", minWidth: "102px"}}>
                                    <Designers updateDesigners={updateDesigners} removeDesigner={removeDesigner} designers={props.designers} userIcon={userIcon} options={state.users} viewOnly={state.isViewOnly} hasSelected={state.designers.length > 0} />
                                </div>
                            </Col>

                            <Col className="col-lg-6 pr-0">
                                <div style={{marginBottom: "6px", marginTop: "2px"}}>QA:</div>
                                <div style={{display: "flex", flexDirection: "row", minWidth: "102px"}}>
                                    <QA updateQas={updateQas} removeQa={removeQa} qas={props.qas} userIcon={userIcon} options={state.users} viewOnly={state.isViewOnly} />
                                </div>
                            </Col>
                        </Row>
                    </>
                    : <Row>
                        <Col className="col-lg-3">
                            <div style={{marginBottom: "6px", marginTop: "2px"}}>Project Manager:</div>
                            <div style={{display: "flex", flexDirection: "row", minWidth: "102px"}}>
                                <ProjectManagers updateProjectManagers={updateProjectManagers} removeProjectManager={removeProjectManager} projectManagers={props.projectManagers} userIcon={userIcon} options={state.users} viewOnly={state.isViewOnly} />
                            </div>
                        </Col>
        
                        <Col className="col-lg-3">
                            <div style={{marginBottom: "25px", marginTop: "2px"}}>Developer:</div>
                            <div style={{display: "flex", flexDirection: "row", minWidth: "102px"}}>
                                <Developers updateDevelopers={updateDevelopers} removeDeveloper={removeDeveloper} developers={props.developers} userIcon={userIcon} options={state.users} viewOnly={state.isViewOnly} />
                            </div>
                        </Col>
        
                        <Col className="col-lg-3">
                            <div style={{marginBottom: "25px", marginTop: "2px"}}>Designer:</div>
                            <div style={{display: "flex", flexDirection: "row", minWidth: "102px"}}>
                                <Designers updateDesigners={updateDesigners} removeDesigner={removeDesigner} designers={props.designers} userIcon={userIcon} options={state.users} viewOnly={state.isViewOnly} />
                            </div>
                        </Col>
        
                        <Col className="col-lg-3">
                            <div style={{marginBottom: "25px", marginTop: "2px"}}>QA:</div>
                            <div style={{display: "flex", flexDirection: "row", minWidth: "102px"}}>
                                <QA updateQas={updateQas} removeQa={removeQa} qas={props.qas} userIcon={userIcon} options={state.users} viewOnly={state.isViewOnly} />
                            </div>
                        </Col>
                    </Row>
            }
        </React.Fragment>
    );
}

export default TeamAssignmentRow;