import React from 'react';
import Swal from "sweetalert2";

export default function ErrorAlert(props) {  
    return (
        Swal.fire({
            title: 'Ooops!',
            text: `${props.message}`,
            icon: 'error',
            confirmButtonColor: '#3680ed',
            confirmButtonText: 'Okay.',
            showCloseButton: true,
            customClass: {
                confirmButton: 'swal-custom-confirm',
            },
          }).then((result) => {
            return result;
        })
    );
}

