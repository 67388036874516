import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import Api from "../Utils/API";
import { Api as ApiImage, TicketNo } from "../Utils/consts";
import { getFirstWord } from "../Utils/helper";

import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import calendar from "../assets/images/calendar.png";
import Select, { components } from "react-select";
import DatepickerDiv from "./Datepicker";
import Input from "./Input";
import Avatar from "./Avatar";
import RichText from "./RichText";
import ScroolUser from "./ScroolUser";
import { customStyles, UniversalValidator } from "../Utils/helper";

import userIcon from "../assets/images/userIcon.png";
import calendarIcon from "../assets/images/calendar.png";
import clockIcon from "../assets/images/clock.png";
import NumberPickerData from "./NumberPickerData";
import PropertyInformation from "./PropertyInformation";
import TextAreaInput from "./TextAreaInput";

function ButtonUser(props) {
  return (
    <button className="btn btn-cirle-dash ml-1" onClick={props.action}>
      <img src={userIcon} />
    </button>
  );
}

function ButtonUserOnly(props) {
  return (
    <div className="primary-assignee-div">
      <div className="close-avatar" onClick={props.actionDelete}>
        {/* <img src={deleteIcon} /> */}
        <button className="btn-cirle">
          {" "}
          <i className=" fa fa-times" style={{ fontSize: 10 }}></i>{" "}
        </button>
      </div>
      <div onClick={props.action}>
        <Avatar
          name={` ${getFirstWord(props.details.firstname)} ${getFirstWord(
            props.details.surname
          )} `}
          round={true}
          size="32"
          color="#f44336"
          image={ApiImage + props.details.avatar}
          className="btn-cirle-avatar"
        />
      </div>
    </div>
  );
}

export const AddRequest = React.memo((props) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // let currentDate = new Date();
  // var date = new Date();
  // date.setDate(date.getDate() + 1);
  let defaultDate = new Date();
  let tomorrow = new Date(defaultDate);
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (tomorrow.getDay() == 6 || tomorrow.getDay() == 0) {
    defaultDate.setDate(defaultDate.getDate());
  } else {
    defaultDate.setDate(defaultDate.getDate() + 1);
  }

  console.log("defaultDate =>", defaultDate);

  const [state, setState] = React.useState({
    company_id: props.company_id,
    subject: "",
    userOptions: [],
    comment_attachments: [],
    clearRich: false,
    isSubmit: false,
    psOpen: false,
    description: "",
    coders: props.coders,
    dueDate: defaultDate,
    dueTime: defaultDate,
    value: null,
    openCalendar: false,
    qa: "",
    details: "",
    coder_options: "",
    project_options: "",
    selectedProject: "",
    recipient: [],
    cc: [],
    subjectError: "",
    assigneeError: "",
    detailsError: "",
    primaryAdetails: "",
    contacts: [],
    email_id: props.selectedEmail,
    requestor: props.requestor,
    loadPrimaryAssignee: false,
    timeOpen: false,
    openPropertInfo: false,
    estimateTimeHr: "00",
    estimateTimeMin: "00",
    propertyID: [],
    propertyID_options: [],
    pricing_method: [],
    pricing_methods_options: props.pricingMethodOptions,
    isMinimized: false,
  });

  useEffect(() => {
    console.log(props);
    let users = props.coders;
    let userOptions = state.userOptions;
    let contacts = props.contacts;
    let contactOptions = [];

    for (let index = 0; index < users.length; index++) {
      userOptions.push({
        value: users[index].id,
        label: `${users[index].firstname} <${users[index].email}> `,
      });
    }

    if (props.isContact) {
      contactOptions.push({
        value: contacts.id,
        label: `${contacts.firstname} <${contacts.email}> `,
      });
    } else {
      for (let index = 0; index < contacts.length; index++) {
        contactOptions.push({
          value: contacts[index].id,
          label: `${contacts[index].firstname} <${contacts[index].email}> `,
        });
      }
    }

    companyProperties();

    setState({
      ...state,
      userOptions: userOptions,
      contactOptions: contactOptions,
      recipient: props.isContact ? contactOptions[0] : [],
    });
  }, [props]);

  const editEstimate = (e) => {
    e.preventDefault();
    document.body.classList.add("actual-time-show");
    document.querySelector(".timer-picker-div").classList.remove("toogle-hide");
    setState({
      ...state,
      hr: state.estimateTimeHr,
      min: state.estimateTimeMin,
      timeOpen: true,
      actualTimeAction: 2,
    });
  };

  function updateTime(e) {
    setState({
      ...state,
      timeOpen: false,
      estimateTimeHr: e.hr,
      estimateTimeMin: e.min,
    });
  }

  const updateProperty = (e) => {
    setState({ ...state, propertyID: e });
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {children}
      <i
        className="fa fa-info-circle property-info"
        onClick={(e) => {
          seePropertyInfo(e, props);
        }}
      ></i>
    </components.SingleValue>
  );

  const seePropertyInfo = (e, property) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(property.data.value);
    Api.getPropertyInfo(property.data.value)
      .then((result) => {
        console.log(result.data);
        document.body.classList.remove("minimize-form");
        setState({
          ...state,
          openPropertInfo: true,
          propertyInfo: result.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const minimizePropertyInfo = (event) => {
    event.stopPropagation();
    document.body.classList.add("minimize-form");
  };

  const openPropertInfo = (event) => {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    setState({ ...state, openPropertInfo: true });
  };

  const closePropertyInfo = (event) => {
    event.stopPropagation();
    setState({ ...state, openPropertInfo: false });
  };

  const pricing_methodUpdate = (e) => {
    setState({ ...state, pricing_method: e });
  };

  function companyProperties() {
    Api.getCompanyPropertyOptions(props.company_id)
      .then((result) => {
        setState({
          ...state,
          propertyID_options: result.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //If clicked outside the div of primary assignee's dropdown container
  function useOutsideAlerter(ref) {
    useEffect(() => {
      let users = props.coders;
      let userOptions = [];
      let contacts = props.contacts;
      let contactOptions = [];

      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          for (let index = 0; index < users.length; index++) {
            userOptions.push({
              value: users[index].id,
              label: `${users[index].firstname} <${users[index].email}> `,
            });
          }

          if (props.isContact) {
            contactOptions.push({
              value: contacts.id,
              label: `${contacts.firstname} <${contacts.email}> `,
            });
          } else {
            for (let index = 0; index < contacts.length; index++) {
              contactOptions.push({
                value: contacts[index].id,
                label: `${contacts[index].firstname} <${contacts[index].email}> `,
              });
            }
          }

          setState({
            ...state,
            userOptions: userOptions,
            coders: props.coders,
            psOpen: false,
            qaOpen: false,
            // details: props.details,
            contactOptions: contactOptions,
            recipient: props.isContact ? contactOptions[0] : [],
          });
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function handleSubmit(event) {
    event.preventDefault();

    // const projectError = UniversalValidator({ value: state.project_id, isValidate: true, isUrl: false, errorMsg: 'Project is required.' });
    const subjectError = UniversalValidator({
      value: state.subject,
      isValidate: true,
      isUrl: false,
      errorMsg: "Subject is required.",
    });
    //const assigneeError = UniversalValidator({ value: state.primaryAdetails, isValidate: true, isUrl: false, errorMsg: 'Assignee is required.' });
    // const qaError = UniversalValidator({ value: state.assignee, isValidate: true, isUrl: false, errorMsg: 'QA is required.' });
    const detailsError = UniversalValidator({
      value: state.details,
      isValidate: true,
      isUrl: false,
      errorMsg: "Scope is required.",
    });

    if (subjectError || detailsError) {
      Swal.fire({
        title: "Error!",
        text: "Please fill in all fields!",
        icon: "error",
        confirmButtonText: "Close",
      });
      setState({
        ...state,
        subjectError: subjectError,
        detailsError: detailsError,
        openCalendar: false,
      });
    } else {
      setState({ ...state, isSubmit: true });
      let cc_input = [];
      let cc = state.cc;
      if (cc.length > 0) {
        for (let index = 0; index < cc.length; index++) {
          cc_input.push(cc[index].value);
        }
      }

      let dueDate = moment(state.dueDate).format("YYYY-MM-DD");
      let dueTime = moment(state.dueTime).format("HH:mm:ss");
      let params = {
        title: state.subject,
        description: state.description,
        clarified_description: state.details,
        assignee: state.primaryAdetails.id,
        // qa_id: state.qa,
        due_date: dueDate + " " + dueTime,
        type: "request",
        company_id: state.company_id,
        contact_id: state.recipient.value,
        cc_ids: cc_input,
        email_id: state.email_id ? state.email_id.id : 0,
        requestor: state.requestor,
        estimateTime: state.estimateTimeHr + ":" + state.estimateTimeMin,
        property_id: state.propertyID.value,
        pricing_id: state.pricing_method.value,
      };

      Api.addTaskCompany(params)
        .then((result) => {
          console.log("api return", result.data);
          props.newRequest(result.data);
          setState({ ...state, isSubmit: false });
        })
        .catch((error) => {
          setState({ ...state, isSubmit: false });
          console.log(error);
        });
    }
  }

  const updateDueDate = (e) => {
    let dueDate = moment(e).format("YYYY-MM-DD");
    console.log("dueDate =>", dueDate);
    setState({ ...state, dueDate: e });
  };

  const updateDueTime = (e) => {
    let dueDate = moment(e).format("YYYY-MM-DD");
    console.log("dueTime =>", e);
    setState({ ...state, dueTime: e });
  };

  const toggleNav = (e) => {
    setState({ ...state, customActiveTab: e });
  };

  const handleChangeRich = (e) => {
    setState({ ...state, details: e, clearRich: false, detailsError: "" });
  };

  const primaryAssignee = (e) => {
    let coders = state.coders;
    let selectedData = e;
    var indexData = coders.indexOf(selectedData);
    let newObject = { index: indexData, ...selectedData };
    let selectedCoders = state.primaryAdetails;

    if (state.primaryAdetails) {
      // REMOVE SELECTED ASSIGNEE FROM LIST
      coders.splice(indexData, 1);

      // get the last index of coders
      let lastIndex = coders.length - 1;
      // get index of record to add
      let indexToAdd = selectedCoders.index;
      // subtract 1 to selectedCoders.index if indexData < indexToAdd
      // if(indexData < indexToAdd)
      //     indexToAdd--;

      // ADD PREVIOUSLY SELECTED ASSIGNEE BACK TO THE LIST
      // check if selectedCoders.index >= lastIndex
      if (indexToAdd >= lastIndex)
        if (selectedCoders.id > coders[lastIndex].id)
          // check if selectedCoders.id > coders[lastIndex].id
          coders.push(selectedCoders);
        //
        else if (coders[lastIndex - 1].id > selectedCoders.id)
          coders.splice(lastIndex - 1, 0, selectedCoders);
        else coders.splice(lastIndex, 0, selectedCoders);
      else {
        // check if selectedCoders.id > coders[lastIndex].id
        if (selectedCoders.id > coders[indexToAdd].id) {
          coders.splice(indexToAdd + 1, 0, selectedCoders);
        } else {
          if (indexToAdd <= 0) {
            coders.splice(indexToAdd, 0, selectedCoders);
          } else {
            if (coders[indexToAdd - 1].id > selectedCoders.id) {
              console.log(1);
              coders.splice(indexToAdd - 1, 0, selectedCoders);
            } else {
              console.log(2);
              coders.splice(indexToAdd, 0, selectedCoders);
            }
          }
        }
      }

      setState({ ...state, psOpen: false, primaryAdetails: newObject });
    } else {
      if (indexData > -1) {
        coders.splice(indexData, 1);
      }

      setState({ ...state, psOpen: false, primaryAdetails: newObject });
    }
  };

  const qaAssignee = (e) => {
    let coders = state.coders;
    let selectedData = e;
    var indexData = coders.indexOf(selectedData);
    let newObject = { index: indexData, ...selectedData };
    let selectedCoders = state.QAdetails;

    if (state.QAdetails) {
      // REMOVE SELECTED ASSIGNEE FROM LIST
      // get the last index of coders
      let lastIndex = coders.length - 1;
      // get index of record to add
      let indexToAdd = selectedCoders.index;

      if (indexToAdd >= lastIndex)
        if (selectedCoders.id > coders[lastIndex].id)
          coders.push(selectedCoders);
        //
        else if (coders[lastIndex - 1].id > selectedCoders.id)
          coders.splice(lastIndex - 1, 0, selectedCoders);
        else coders.splice(lastIndex, 0, selectedCoders);
      else {
        if (selectedCoders.id > coders[indexToAdd].id) {
          coders.splice(indexToAdd + 1, 0, selectedCoders);
        } else {
          if (indexToAdd <= 0) {
            coders.splice(indexToAdd, 0, selectedCoders);
          } else {
            if (coders[indexToAdd - 1].id > selectedCoders.id) {
              coders.splice(indexToAdd - 1, 0, selectedCoders);
            } else {
              coders.splice(indexToAdd, 0, selectedCoders);
            }
          }
        }
      }

      setState({ ...state, qaOpen: false, QAdetails: newObject });
    } else {
      if (indexData > -1) {
        coders.splice(indexData, 1);
      }

      setState({ ...state, qaOpen: false, QAdetails: newObject });
    }
  };

  const selectPS = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.body.classList.add("primary-assignee-show");
    document
      .querySelector(".primary-assignee-content")
      .classList.remove("toogle-hide");
    setState({ ...state, psOpen: true, qaOpen: false });
  };

  const selectQA = (e) => {
    document.body.classList.add("primary-qa-show");
    document
      .querySelector(".primary-qa-content")
      .classList.remove("toogle-hide");
    setState({ ...state, qaOpen: true, psOpen: false });
  };

  const deletePrimaryAssignee = (e) => {
    console.log("deletePrimaryAssignee");
    setState({ ...state, primaryAdetails: [] });
  };

  const deleteQAAssignee = (e) => {
    setState({ ...state, QAdetails: "" });
  };

  let iconName = "";
  if (state.recipient) {
    iconName = state.recipient.label;
  }

  function onChangecc(e) {
    if (e) {
      setState({ ...state, cc: e });
    } else {
      setState({ ...state, cc: [] });
    }
  }

  function minimizeForm(event) {
    event.stopPropagation();
    document.body.classList.add("minimize-form");

    setState({
      ...state,
      isMinimized: true,
    });
  }

  function openForm(event) {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");

    setState({
      ...state,
      isMinimized: false,
    });
  }

  return (
    <React.Fragment>
      <div className="contact-form">
        <div className="form-header" onClick={(e) => openForm(e)}>
          <div className="row-1"> Add Request to {props.name}</div>
          <div className="row-2">
            {state.isMinimized ? (
              <button
                className="btn btn-icon"
                style={{ boxShadow: "0 !important" }}
                onClick={(e) => openForm(e)}
              >
                {" "}
                <img src={maximizeIcon} />{" "}
              </button>
            ) : (
              <button className="btn btn-icon" onClick={(e) => minimizeForm(e)}>
                {" "}
                <img src={minimizeIcon} />{" "}
              </button>
            )}
            <button className="btn btn-icon" onClick={props.close}>
              {" "}
              <img src={closeIcon} />{" "}
            </button>
          </div>
        </div>
        <div className="form-body add-request-form">
          <Input
            placeholder="Request Title"
            addClass="request-input"
            action={(e) => {
              setState({ ...state, subject: e.target.value, subjectError: "" });
            }}
            value={state.subject}
          />
          {state.subjectError ? (
            <label className="eror-fields">{state.subjectError}</label>
          ) : (
            ""
          )}
          <div className="mt-4"></div>

          <div className="flex-row">
            <div className="flex-item">
              <Avatar
                name={state.requestor ? getFirstWord(state.requestor) : ""}
                round={true}
                size="47"
                color="#f44336"
                className="btn-cirle-avatar"
              />
            </div>
            <div className="flex-item flex-1">
              <div className="flex-row item-baseline">
                <div className="recipient">To:</div>
                <div className="flex-1">
                  {props.isEmail ? (
                    <>
                      <p className="ml-2"> {props.requestor ?? ""}</p>
                    </>
                  ) : (
                    <>
                      <Select
                        className="form-select"
                        // classNamePrefix="select2-selection"
                        styles={customStyles}
                        value={state.recipient}
                        onChange={(e) => {
                          setState({ ...state, recipient: e });
                        }}
                        options={state.contactOptions}
                        placeholder="Select user"
                        noOptionsMessage={() => null}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </>
                  )}

                  {state.recipientError ? (
                    <label className="eror-fields">
                      {state.recipientError}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex-row item-baseline">
                <div className="recipient">CC:</div>
                <div className="flex-1">
                  <Select
                    isMulti
                    className="form-select"
                    // classNamePrefix="select2-selection"
                    styles={customStyles}
                    //value={state.projectDetails}
                    // onChange={(e) => { setState({ ...state, projectDetails: e, project_id: e.value, projectError: '' }) }}
                    onChange={onChangecc}
                    options={state.userOptions}
                    placeholder="Select user"
                    noOptionsMessage={() => null}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4"></div>
          <div className="flex-row">
            <div className="col-4">
              <h6 className="title-1">Primary Assignee</h6>
              {state.primaryAdetails ? (
                <ButtonUserOnly
                  action={selectPS}
                  actionDelete={deletePrimaryAssignee}
                  details={state.primaryAdetails}
                  load={state.loadPrimaryAssignee}
                />
              ) : (
                <ButtonUser action={selectPS} />
              )}
              <div className="primary-assignee-content">
                {/* <ScroolUser
                                    list={state.coders}
                                    action={primaryAssignee}
                                /> */}
                {state.psOpen ? (
                  <div ref={wrapperRef}>
                    <ScroolUser list={state.coders} action={primaryAssignee} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <h6 className="title-1">Due Date</h6>
              <div className="flex-row" style={{ alignItems: "end" }}>
                <div className="col-2 datepicker-container">
                  <img src={calendarIcon} className="datepicker-icon" />
                </div>
                <div className="col-8 padding-0">
                  <DatepickerDiv
                    updateDueDate={updateDueDate}
                    date={state.dueDate}
                  />
                </div>
              </div>
            </div>

            {/* <div className="col-4">
                            <h6 className="title-1">Due Date</h6>
                            <div className="flex-row datepicker-group">
                                <img src={calendarIcon} className="datepicker-icon" />
                                <DatepickerDiv updateDueDate={updateDueDate} date={state.dueDate} showTimeSelect={true} timeOnly={false} />
                            </div>
                        </div> */}

            <div className="col-4">
              <h6 className="title-1">Time</h6>
              <div className="flex-row" style={{ alignItems: "end" }}>
                <div className="col-2 datepicker-container">
                  <img src={clockIcon} className="datepicker-icon" />
                </div>
                <div className="col-8 padding-0 datepicker-timeonly">
                  <DatepickerDiv
                    updateDueDate={updateDueTime}
                    date={state.dueTime}
                    showTimeSelect={true}
                    timeOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4"></div>
          <div className="flex-row">
            {/* PRICING METHOD */}
            <div className="col-4 mt-3">
              <h6 className="title-1">Pricing Method</h6>
              <Select
                className="form-select ml-1"
                // classNamePrefix="select2-selection"
                styles={customStyles}
                value={state.pricing_method}
                onChange={pricing_methodUpdate}
                options={state.pricing_methods_options}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            {/* PROPERTY */}
            <div className="col-4 mt-3">
              <h6 className="title-1">Property ID</h6>
              <Select
                className="form-select ml-1"
                // classNamePrefix="select2-selection"
                styles={customStyles}
                value={state.propertyID}
                openMenuOnClick={false}
                onChange={updateProperty}
                options={state.propertyID_options}
                components={{
                  SingleValue,
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            {/* ESTIMATED TIME */}
            <div className="col-4 actual-time actual-time-and-estimate">
              <h6 className="title-1">Estimated Time</h6>
              <img src={clockIcon} />
              <span className="time-2 ml-2" onClick={editEstimate}>
                {state.estimateTimeHr}:{state.estimateTimeMin}
              </span>
              <div className="timer-picker-div">
                {state.timeOpen ? (
                  <NumberPickerData
                    hr={state.estimateTimeHr}
                    min={state.estimateTimeMin}
                    type={2}
                    action={updateTime}
                    loading={state.isSaveTime}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="mt-4"></div>
          <div className="contact-right-text">
            <h6 className="title-1 ml-1 pt-3">Description</h6>
            <div className="row mt-2 mb-2 p-2" style={{ background: "white" }}>
              <RichText
                placeholder="Description"
                handleChange={(e) => {
                  setState({ ...state, description: e, clearRich: false });
                }}
                value={state.description}
                clear={state.clearRich}
                index={2}
              />
            </div>
          </div>
          <div className="contact-right-text">
            <h6 className="title-1 ml-1 pt-3">Scope</h6>
            <div className="row mt-2 mb-2 p-2" style={{ background: "white" }}>
              <RichText
                placeholder="Scope"
                handleChange={(e) =>
                  setState({
                    ...state,
                    details: e,
                    clearRich: false,
                    detailsError: "",
                  })
                }
                value={state.details}
                clear={state.clearRich}
                index={3}
              />
            </div>
            {state.detailsError && (
              <label className="eror-fields">{state.detailsError}</label>
            )}
          </div>
          <div className="footer-action">
            <div className="row">
              <div className="col-12 text-right">
                <button
                  type="buttom"
                  onClick={handleSubmit}
                  className="btn btn-primary btn-large"
                  disabled={state.isSubmit}
                >
                  {state.isSubmit ? (
                    <i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i>
                  ) : (
                    ""
                  )}
                  Assign
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {state.openPropertInfo ? (
        <div class="requests-form">
          <PropertyInformation
            propertyDetails={state.propertyInfo}
            minimize={minimizePropertyInfo}
            openPropertInfo={openPropertInfo}
            close={closePropertyInfo}
            reset={seePropertyInfo}
          />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
});

export default AddRequest;
