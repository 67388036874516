import {
	WEEKLY_WAGES_COST,
    WEEKLY_OTHER_OVERHEAD,
    COST_OF_AFTER_SALES_CARE,
    COMPLETED_WORK
} from "./actionTypes";

export const weeklyWagesCost = (weeklyWagesCost) => ({
	type: WEEKLY_WAGES_COST,
	payload: weeklyWagesCost
});

export const weeklyOtherOverhead = (weeklyOtherOverhead) => ({
	type: WEEKLY_OTHER_OVERHEAD,
	payload: weeklyOtherOverhead
});

export const costOfAfterSalesCare = (costOfAfterSalesCare) => ({
	type: COST_OF_AFTER_SALES_CARE,
	payload: costOfAfterSalesCare
});

export const completedWork = (completedWork) => ({
	type: COMPLETED_WORK,
	payload: completedWork
});


