import {
    SEARCH_PROPERTIES,
	CLEAR_PROPERTY_SEARCH_STRING
} from "./actionTypes";

export const searchProperties = search => ({
	type: SEARCH_PROPERTIES,
	payload: search
});

export const clearPropertySearchString = search => ({
	type: CLEAR_PROPERTY_SEARCH_STRING,
	payload: search
});
