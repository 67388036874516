import React, {useEffect, useState} from 'react';
import ScroolUser from "./ScroolUser";

export default function ButtonUser(props) {
    const [state, setState] = useState({
        selected: null
    });

    const handleOptionSelect = (id, identifier) => {
        setState((prevState) => ({...prevState, selected: id, psOpen: false}));
        props.action(id, identifier);
        toggleOptions();
    }

    const toggleOptions = () => {
        props.setActiveOptionIndex(0)
    }

    return (
        <React.Fragment>
            <button
                className="btn btn-cirle-dash ml-0"
                onClick={toggleOptions}
                disabled={props.load}
            >
                {props.load && (
                    <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
                )}
                <img src={props.userIcon} />
            </button>
            <div className="primary-assignee-content">
                {props.active && (
                    <ScroolUser
                        list={props.options}
                        action={(e) => handleOptionSelect(e, null)}
                        page="add-website-designer"
                        closeDropdown={toggleOptions}
                    />
                )}
            </div>
        </React.Fragment>
    );
}
