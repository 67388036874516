import React, { useEffect, useState } from 'react';
import classes from './input.css';
import { numberValidator, floatValidator } from "../../Utils/helper";

const Input = (props) => {
    const [state, setState] = useState({
        value: props.value,
        showInput: false,
        enabled: props.enabled,
        typingTimeout: 0,
        valueError: false
    });

    useEffect(() => {
        setState({...state, value: props.value, enabled: props.enabled ?? true})
    }, [props]);

    const inputChange = (e) => {
        resizeInput(e)

        let input = e.target;
        input.style.color="#F15A23";

        if (state.typingTimeout) {
            clearTimeout(state.typingTimeout);
        }

        const value = input.value;
        props.onChange(value);
        let valueError = false;

        if(props.type === 'float') {
            valueError = floatValidator(value);
        } else if(props.type === 'number') {
            valueError = numberValidator(value);
        }

        if(valueError) {
            setState({
                ...state,
                value,
                valueError
            });
        } else {
            setState({
                ...state,
                value,
                valueError: false,
                typingTimeout: setTimeout(function () {
                    // update value
                    props.saveData(value);
                    // change font color to blue
                    input.style.color="#2F80ED";
                }, 2000)
            });
        }
    }

    const handleDoubleClick = () => {
        setState({
            ...state,
            showInput: true
        });
    }

    const resizeInput = (e) => {
        e.target.style.width=(e.target.value.length + 2) + 'ch';
    }

    const handleFocusOut = (e) => {
        // hide input if valueError is false
        if(!state.valueError) {
            if(props.value != state.value) {
                if (state.typingTimeout) {
                    clearTimeout(state.typingTimeout);
                }
                // update value
                props.saveData(state.value);
            }
            // close input field
            setState({
                ...state,
                showInput: false
            });
        }
    }

    return (
        <div className = {classes.TextField}>
        {
        state.showInput ?
            <>
                <div>
                    <input value={state.value} onChange={(e) => inputChange(e)} onBlur={props.blur} onFocus={e => resizeInput(e)} onBlur={e => handleFocusOut(e)} autoFocus />
                    {props.append && <span>{props.append}</span>}
                </div>
                {state.valueError && <span style={{color: '#EB5757', fontWeight: 400}}>{state.valueError}</span>}
            </>
            :
            state.enabled ?
                <div onDoubleClick = {handleDoubleClick} className = {classes.Change}>{state.value}{props.append && props.append}</div>
                :
                <div>{state.value}{props.append && props.append}</div>
        }
        </div>
    )
}


export default Input;