import React, {useEffect, useState} from 'react';
import ButtonUser from '../ButtonUser';
import ButtonUserOnly from '../ButtonUserOnly';

export  default function QA(props) {
    const [options, setOptions] = useState(props.options);
    const [state, setState] = useState({
        loadOptions: false,
        showOptions: false,
        activeQaIndex: -1
    });

    useEffect(() => {
        const options = props.options.filter(usr => !props.qas.includes(usr.id));
        setOptions(options);
    }, [props.options, JSON.stringify(props.qas)]);

    const selectPS = (selected, prev) => {
        props.updateQas(selected.id, prev);
    };

    const setActiveQaIndex = (index) => {
        setState((prevState) => ({
            ...prevState,
            activeQaIndex: index === prevState.activeQaIndex ? -1 : index
        }));
    }

    return (
        <React.Fragment>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                {props.qas.length ?
                    props.qas.map((id, i) =>
                        <div style={{marginRight: "10px"}}>
                            <ButtonUserOnly
                                key={`qa-${i}`}
                                action={props.viewOnly ? null : selectPS}
                                actionDelete={props.removeQa}
                                details={state.primaryAdetails}
                                load={state.loadPrimaryAssignee}
                                assignee={props.options.filter(opt => opt.id == id)[0]}
                                scrollUser={props.scroolUser}
                                options={options}
                                active={state.activeQaIndex === (i + 1) ? true : false}
                                index={i + 1}
                                setActiveOptionIndex={setActiveQaIndex}
                                viewOnly={props.viewOnly}
                            />
                        </div>
                    ) : null
                }

                {props.viewOnly ?
                    null:
                    <div style={{margin: 0}}>
                        <ButtonUser index={0} key={`qa-0`} action={selectPS} load={state.loadOptions} userIcon={props.userIcon} options={options} active={state.activeQaIndex === 0 ? true : false} setActiveOptionIndex={setActiveQaIndex} />
                    </div>
                }
            </div>
        </React.Fragment>
    );
}