import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Upload from "../../assets/images/Upload.png";
import File from "../../assets/images/file.png";
import Api from "../../Utils/API";
import HtmlParser from "react-html-parser";
import RichTextArea from "./../RichTextArea";
const maxSize = 26214400; //25mb

export const SubmitQA = (props) => {
  const [state, setState] = useState({
    request_details: props.props,
    report_note: "",
    files: [],
    preload_btn: false,
  });

  useEffect(() => {
    console.log(state.request_details);
  }, []);

  const submit = () => {
    setState((prevState) => ({ ...prevState, preload_btn: true }));
    const data = new FormData();
    const { report_note, request_details, files } = state;
    let { user_details, confirmButton, revertBoardChanges, report_details } =
      props;

    data.append("report_note", report_note);
    data.append("request_id", request_details.id);
    data.append("created_by", user_details.id);

    for (let file of files) {
      data.append("files[]", file);
    }
    Api.storeReport(data)
      .then((result) => {
        report_details(result.data);
        setState((prevState) => ({ ...prevState, preload_btn: false }));
        confirmButton.clickConfirm();
      })
      .catch((error) => {
        console.log(error);
        setState((prevState) => ({ ...prevState, preload_btn: false }));
        // REVERT STATUS CHANGE
        revertBoardChanges();
      });
  };

  const handleInputChange = (value) => {
    setState((prevState) => ({ ...prevState, report_note: value }));
  };

  const handleAcceptedFiles = (files) => {
    for (let file of files) {
      state.files.push(file);
    }
    setState((prevState) => ({ ...prevState, files: state.files }));
  };

  const removeFile = (index) => {
    state.files.splice(index, 1);
    setState({ ...state, files: state.files });
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const { request_details, files, preload_btn, report_note } = state;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-8" id="submitQA">
          <h2 className="header">Submit Report for QA</h2>
          <div className="upload-files-container">
            <h2 className="subtitle upload-files">
              Upload image or files that show your completed work
            </h2>
            <Dropzone
              accept="image/png, image/jpeg, image/jpg, application/pdf"
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}
              minSize={0}
              maxSize={maxSize}>
              {({
                getRootProps,
                getInputProps,
                isDragReject,
                rejectedFiles,
              }) => {
                const isFileTooLarge =
                  rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                return (
                  <div
                    className="dropzone"
                    style={{ position: "relative", cursor: "pointer" }}>
                    <div
                      {...getRootProps()}
                      className="dz-message needsclick mt-2">
                      <input {...getInputProps()} />
                      <img src={Upload} />
                      <p>
                        Drag & Drop to upload or <span>browse</span> from files
                      </p>
                    </div>
                    {isFileTooLarge && (
                      <div className="text-danger text-center font-weight-bold large-file-message">
                        File is too large!
                        <p
                          className="text-danger font-weight-normal"
                          style={{ fontSize: 12 }}>
                          File size should not exceed 25MB
                        </p>
                      </div>
                    )}
                  </div>
                );
              }}
            </Dropzone>
          </div>

          <div className="uploads-container">
            <h2 className="subtitle uploads">Uploads ({files.length})</h2>
            <div className="files-uploaded-container">
              {files.map((file, i) => {
                return (
                  <div className="attached_file_report" key={i}>
                    <img src={File} />
                    <div className="file_details">
                      <h2
                        className="text-truncate"
                        style={{ width: "85%" }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={file.name}>
                        {file.name}
                      </h2>
                      <h2>{formatBytes(file.size)}</h2>
                    </div>
                    <i
                      className="fas fa-times-circle"
                      onClick={() => removeFile(i)}></i>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="report-note-container">
            <h2 className="subtitle report-note">Task Report</h2>
            <ul>
              <li>Describe what you did</li>
              <li>Any Issues Faced and Resolution</li>
              <li>Any Recommendations</li>
            </ul>
            <RichTextArea
              placeholder="Write something..."
              addClass="request-form"
              name="report-note"
              action={(e) => {
                handleInputChange(e);
              }}
              index={3}
            />
            {/* <textarea
              id="report-note"
              name="report-note"
              rows="6"
              placeholder="Write something..."
              onChange={(e) => handleInputChange(e.target.value)}></textarea> */}
          </div>
          <button
            className="submit-btn"
            onClick={submit}
            disabled={preload_btn || (!report_note && files.length < 1)}>
            {preload_btn ? (
              <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
            ) : (
              "Submit"
            )}
          </button>
        </div>
        <div className="col-4" id="taskDetails">
          <h2 className="header">Task Details</h2>
          <div>
            <h2 className="subtitle task-title">Task Title</h2>
            <p>{request_details.content.title}</p>
          </div>
          <div>
            <h2 className="subtitle description">Description</h2>
            <p className="tdescription">
              {HtmlParser(request_details.content.description) || ""}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubmitQA;
