import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Gantt from "../Gantt/Gantt";
import Toolbar from '../GanttToolbar/Toolbar';
import Api from "../../Utils/API";

const defaultTimeline = { data: [], links: [] }

const RequestTimeline = (props) => {
    const [currentZoom, setCurrentZoom] = useState('Days');
    const [tasks, setTasks] = useState({ data: [], links: [] });
    const [coders, setCoders] = useState([]);
    const [nextPageUrl, setNextPageUrl] = useState(null);
 
    const handleZoomChange = (zoom) => {
        setCurrentZoom(zoom);
    }

    const getList = () => {
        Api.getTasksForTimeline(0).then(result => {
            const data = [];
            let temp = result.data.tasks.data;

            temp.map(task => {
                let estimatedTime = parseFloat(task.estimated_time ?? 0).toFixed(2);
                let actualTimeUsed = parseFloat(task.actual_time_used ?? 0).toFixed(2);
                let startDate = task.start_date ? moment(task.start_date).format("DD-MM-YYYY") : (task.created_at ? moment(task.created_at).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY"))
                let endDate = task.due_date ? moment(task.due_date).format("DD-MM-YYYY") : moment(task.created_at).add(1, 'day').format("DD-MM-YYYY");

                if(startDate > endDate) {
                    moment(task.created_at).format("DD-MM-YYYY")
                }

                data.push({
                    id: task.id,
                    text: task.text,
                    start_date: startDate,
                    end_date: endDate,
                    duration: moment(endDate, 'DD-MM-YYYY').diff(moment(startDate, 'DD-MM-YYYY'), 'days'),
                    progress: estimatedTime == 0 ? 0 : (actualTimeUsed / estimatedTime),
                    owner_id: task.primary_team_id
                });
            });

            setTasks({...tasks, data});
            setNextPageUrl(result.data.tasks.next_page_url);
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        getList();
        const coders = [];

        props.coders.forEach(coder => {
            coders.push({
                id: coder.id,
                text: `${coder.firstname} ${coder.surname}`
            });
        });

        setCoders(coders);
    }, [])

    const handlePageChange = () => {
        if(nextPageUrl) {
            Api.pageChange(nextPageUrl).then(result => {
                const data = tasks.data;
                let temp = result.data.tasks.data;
console.log('tasks', tasks)
console.log('data', data)
console.log('result', temp)
                temp.map(task => {
                    let estimatedTime = parseFloat(task.estimated_time ?? 0).toFixed(2);
                    let actualTimeUsed = parseFloat(task.actual_time_used ?? 0).toFixed(2);
                    let startDate = task.start_date ? moment(task.start_date).format("DD-MM-YYYY") : (task.created_at ? moment(task.created_at).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY"))
                    let endDate = task.due_date ? moment(task.due_date).format("DD-MM-YYYY") : moment(task.created_at).add(1, 'day').format("DD-MM-YYYY");

                    if(startDate > endDate) {
                        moment(task.created_at).format("DD-MM-YYYY")
                    }

                    data.push({
                        id: task.id,
                        text: task.text,
                        start_date: startDate,
                        end_date: endDate,
                        duration: moment(endDate, 'DD-MM-YYYY').diff(moment(startDate, 'DD-MM-YYYY'), 'days'),
                        progress: estimatedTime == 0 ? 0 : (actualTimeUsed / estimatedTime),
                        owner_id: task.primary_team_id
                    });
                });
console.log('next_page_url', result.data.tasks.next_page_url)
                setTasks({...tasks, data});
                setNextPageUrl(result.data.tasks.next_page_url);
            }).catch(error => {
                console.log('email-inbox > handlePageChange error', error);
            });
        }
    }

console.log('request timeline', tasks)
    return (
        <div>
            <div className="zoom-bar">
                <Toolbar
                    zoom={currentZoom}
                    onZoomChange={handleZoomChange}
                />

                {nextPageUrl && <button className="btn-load-more" onClick={handlePageChange}>Load More</button>}
            </div>
            <div className="gantt-container">
                <Gantt
                    tasks={tasks}
                    zoom={currentZoom}
                    coders={coders}
                />
            </div>
        </div>
    );
}

export default RequestTimeline;