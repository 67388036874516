import React from 'react';
export default function Input(props) {  
    
    let value = '';
    if(props.value){
        value = props.value;
    }
    return (
        <React.Fragment>
            <input
                className={`form-input ${props.addClass}`}
                placeholder={props.placeholder}
                onChange={props.action}
                value={value}
            />
        </React.Fragment>
    );
}

