import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';

import { logoutUser } from '../../store/actions';
import {
    getCookie
} from '../../Utils/helper';
import Pages404 from "../Utility/pages-404";

const baseUrl = window.location.origin.toString();
// const baseUrl = 'https://dev.jiffi.io';
// const baseUrl = 'http://localhost:7000';

const NoAuth = (props) => {
    let access_token = getCookie("access_token");

    let history = useHistory();

    const url = window.location.href;
    let is404 = (url !== baseUrl && url !== baseUrl + '/');

    useEffect(() => {
        if(access_token == '') {
            clearHistory();
            // window.location.href = '/'
        } else {
            if(! is404)
                window.location.href = '/email-inbox';
        }
    }, []);

    function clearHistory() {
        history.entries = [];
        history.index = -1;
        history.push(`/login`);
    }

    return (
        is404 ?
            <Pages404 />
            :
            <></>
    );
}

export default NoAuth;
