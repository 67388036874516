import React, { Component } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import './Gantt.css';

// DHTMLX Gantt Wrapper for react
export default class Gantt extends Component {
    componentDidMount() {
        const { tasks, zoom } = this.props;
        gantt.init(this.ganttContainer);

		this.setZoom(zoom);

        // gantt.message({
		// 	text:[
		// 		"Displaying a resource usage diagram.",
		// 		"The diagram is in sync with the main Gantt.",
		// 		"Columns and resources are fully customizable, the resources can be changed using a public api."
		// 	].join("<br><br>"),
		// 	expire: -1
		// });

        gantt.config.date_format = "%Y-%m-%d %H:%i:%s";

		gantt.templates.grid_row_class = function(start, end, task){
			var css = [];
			if(gantt.hasChild(task.id)){
				css.push("folder_row");
			}

			if(task.$virtual){
				css.push("group_row")
			}

			// if(this.shouldHighlightTask(task)){
			// 	css.push("highlighted_resource");
			// }

			return css.join(" ");
		};

		// gantt.templates.task_row_class = function(start, end, task){
		// 	if(this.shouldHighlightTask(task)){
		// 		return "highlighted_resource";
		// 	}
		// 	return "";
		// };

		gantt.templates.timeline_cell_class = function (task, date) {
			if (!gantt.isWorkTime({date: date, task: task}))
				return "week_end";
			return "";
		};

		gantt.templates.resource_cell_class = function(start_date, end_date, resource, tasks){
			var css = [];
			css.push("resource_marker");
			if (tasks.length <= 1) {
				css.push("workday_ok");
			} else {
				css.push("workday_over");
			}
			return css.join(" ");
		};

		gantt.templates.resource_cell_value = function(start_date, end_date, resource, tasks){
			var html = "<div>"
			if(resourceMode == "hours"){
				html += tasks.length * 8;
			}else{
				html += tasks.length;
			}
			html += "</div>";
			return html;
		};

		var resourceTemplates = {
			grid_row_class: function(start, end, resource){
				var css = [];
				if(gantt.$resourcesStore.hasChild(resource.id)){
					css.push("folder_row");
					css.push("group_row");
				}
				// if(this.shouldHighlightResource(resource)){
				// 	css.push("highlighted_resource");
				// }
				return css.join(" ");
			},
			task_row_class: function(start, end, resource){
				var css = [];
				// if(this.shouldHighlightResource(resource)){
				// 	css.push("highlighted_resource");
				// }
				if(gantt.$resourcesStore.hasChild(resource.id)){
					css.push("group_row");
				}

				return css.join(" ");

			}
		};

		gantt.locale.labels.section_owner = "Owner";
		gantt.config.lightbox.sections = [
			{name: "description", height: 38, map_to: "text", type: "textarea", focus: true},
			{name: "owner", height: 22, map_to: "owner_id", type: "select", options: gantt.serverList("people")},
			{name: "time", type: "duration", map_to: "auto"}
		];

		var resourceConfig = {
			scale_height: 30,
			scales: [
				{unit: "day", step: 1, date: "%d %M"}
			],
			columns: [
				{
					name: "name", label: "Name", tree:true, width:200, template: function (resource) {
						return resource.text;
					}, resize: true
				},
				{
					name: "progress", label: "Complete", align:"center",template: function (resource) {
						var tasks = this.getResourceTasks(resource.id);

						var totalToDo = 0,
							totalDone = 0;
						tasks.forEach(function(task){
							totalToDo += task.duration;
							totalDone += task.duration * (task.progress || 0);
						});

						var completion = 0;
						if(totalToDo){
							completion = Math.floor((totalDone / totalToDo)*100);
						}

						return Math.floor(completion) + "%";
					}, resize: true
				},
				{
					name: "workload", label: "Workload", align:"center", template: function (resource) {
						var tasks = this.getResourceTasks(resource.id);
						var totalDuration = 0;
						tasks.forEach(function(task){
							totalDuration += task.duration;
						});

						return (totalDuration || 0) * 8 + "h";
					}, resize: true
				},

				{
					name: "capacity", label: "Capacity", align:"center",template: function (resource) {
						var store = gantt.getDatastore(gantt.config.resource_store);
						var n = store.hasChild(resource.id) ? store.getChildren(resource.id).length : 1

						var state = gantt.getState();

						return gantt.calculateDuration(state.min_date, state.max_date) * n * 8 + "h";
					}
				}

			]
		};

		gantt.config.scales = [
			{unit: "month", step: 1, format: "%F, %Y"},
			{unit: "day", step: 1, format: "%d %M"}
		];

		gantt.config.auto_scheduling = true;
		gantt.config.auto_scheduling_strict = true;
		gantt.config.work_time = true;
		gantt.config.columns = [
			{name: "text", tree: true, width: 200, resize: true},
			{name: "start_date", align: "center", width: 80, resize: true},
			{name: "assignee", align: "center", width: 80, label: "Assignee", template: function (task) {
				if(task.type == gantt.config.types.project){
					return "";
				}

				var store = gantt.getDatastore(gantt.config.resource_store);
				var owner = store.getItem(task[gantt.config.resource_property]);
				if (owner) {
					return owner.text;
				} else {
					return "Unassigned";
				}
			}, resize: true},
			{name: "duration", width: 60, align: "center", resize: true},
			{name: "add", width: 44}
		];

		gantt.config.resource_store = "resource";
		gantt.config.resource_property = "owner_id";
		gantt.config.order_branch = true;
		gantt.config.open_tree_initially = true;
		gantt.config.scale_height = 50;
		gantt.config.layout = {
			css: "gantt_container",
			rows: [
				{
					gravity: 2,
					cols: [
						{view: "grid", group:"grids", scrollY: "scrollVer"},
						{resizer: true, width: 1},
						{view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},
						{view: "scrollbar", id: "scrollVer", group:"vertical"}
					]
				},
				{ resizer: true, width: 1, next: "resources"},
				{
					height: 35,
					cols: [
						{ html:"", group:"grids"},
						{ resizer: true, width: 1},
						{ html:"<label class='active' >Hours per day <input checked type='radio' name='resource-mode' value='hours'></label>" +
						"<label>Tasks per day <input type='radio' name='resource-mode' value='tasks'></label>", css:"resource-controls"}
					]
				},

				{
					gravity:1,
					id: "resources",
					config: resourceConfig,
					templates: resourceTemplates,
					cols: [
						{ view: "resourceGrid", group:"grids", scrollY: "resourceVScroll" },
						{ resizer: true, width: 1},
						{ view: "resourceTimeline", scrollX: "scrollHor", scrollY: "resourceVScroll"},
						{ view: "scrollbar", id: "resourceVScroll", group:"vertical"}
					]
				},
				{view: "scrollbar", id: "scrollHor"}
			]
		};

		var resourceMode = "hours";
		gantt.attachEvent("onGanttReady", function(){
			var radios = [].slice.call(gantt.$container.querySelectorAll("input[type='radio']"));
			radios.forEach(function(r){
				gantt.event(r, "change", function(e){
					var radios = [].slice.call(gantt.$container.querySelectorAll("input[type='radio']"));
					radios.forEach(function(r){
						r.parentNode.className = r.parentNode.className.replace("active", "");
					});

					if(this.checked){
						resourceMode = this.value;
						this.parentNode.className += " active";
						gantt.getDatastore(gantt.config.resource_store).refresh();
					}
				});
			});
		});

		gantt.$resourcesStore = gantt.createDatastore({
			name: gantt.config.resource_store,
			type: "treeDatastore",
			initItem: function (item) {
				item.parent = item.parent || gantt.config.root_id;
				item[gantt.config.resource_property] = item.parent;
				item.open = true;
				return item;
			}
		});

		gantt.$resourcesStore.attachEvent("onAfterSelect", function(id){
			gantt.refreshData();
		});

		gantt.$resourcesStore.attachEvent("onParse", function(){
			var people = [];
			gantt.$resourcesStore.eachItem(function(res){
				if(!gantt.$resourcesStore.hasChild(res.id)){
					var copy = gantt.copy(res);
					copy.key = res.id;
					copy.label = res.text;
					people.push(copy);
				}
			});
			gantt.updateCollection("people", people);
		});

		gantt.$resourcesStore.parse(this.props.coders);

		gantt.parse(tasks);
    }

    shouldHighlightTask(task){
        var store = gantt.$resourcesStore;
        var taskResource = task[gantt.config.resource_property],
            selectedResource = store.getSelectedId();
        if(taskResource == selectedResource || store.isChildOf(taskResource, selectedResource)){
            return true;
        }
    }

    shouldHighlightResource(resource){
        var selectedTaskId = gantt.getState().selected_task;
        if(gantt.isTaskExists(selectedTaskId)){
            var selectedTask = gantt.getTask(selectedTaskId),
                selectedResource = selectedTask[gantt.config.resource_property];

            if(resource.id == selectedResource){
                return true;
            }else if(gantt.$resourcesStore.isChildOf(selectedResource, resource.id)){
                return true;
            }
        }
        return false;
    }

    getResourceTasks(resourceId){
        var store = gantt.getDatastore(gantt.config.resource_store),
            field = gantt.config.resource_property,
            tasks;

        if(store.hasChild(resourceId)){
            tasks = gantt.getTaskBy(field, store.getChildren(resourceId));
        }else{
            tasks = gantt.getTaskBy(field, resourceId);
        }
        return tasks;
    }

    toggleGroups(input) {
        gantt.$groupMode = !gantt.$groupMode;
        if (gantt.$groupMode) {
            input.value = "show gantt view";

            var groups = gantt.$resourcesStore.getItems().map(function(item){
                var group = gantt.copy(item);
                group.group_id = group.id;
                group.id = gantt.uid();
                return group;
            });

            gantt.groupBy({
                groups: groups,
                relation_property: gantt.config.resource_property,
                group_id: "group_id",
                group_text: "text"
            });
        } else {
            input.value = "show resource view";
            gantt.groupBy(false);
        }
    }

    setZoom(value) {
        switch (value) {
            case 'Hours':
                gantt.config.scale_unit = 'day';
                gantt.config.date_scale = '%d %M';

                gantt.config.scale_height = 60;
                gantt.config.min_column_width = 30;
                gantt.config.subscales = [
                    { unit:'hour', step:1, date:'%H' }
                ];
            break;
            case 'Days':
                gantt.config.min_column_width = 70;
                gantt.config.scale_unit = 'week';
                gantt.config.date_scale = '#%W';
                gantt.config.subscales = [
                    { unit: 'day', step: 1, date: '%d %M' }
                ];
                gantt.config.scale_height = 60;
            break;
            case 'Months':
                gantt.config.min_column_width = 70;
                gantt.config.scale_unit = 'month';
                gantt.config.date_scale = '%F';
                gantt.config.scale_height = 60;
                gantt.config.subscales = [
                    { unit:'week', step:1, date:'#%W' }
                ];
            break;
            default:
            break;
        }
    }

    shouldComponentUpdate(nextProps) {
        // check if zoom type was changed
        if(this.props.zoom !== nextProps.zoom) {
            return true;
        }

		// check if tasks prop was updated
        if(JSON.stringify(this.props.tasks.data) !== JSON.stringify(nextProps.tasks.data)) {
            return true;
        }

		// check if coders prop was updated
        if(this.props.coders !== nextProps.coders) {
            return true;
        }

		// check if tasks prop was updated
        if(this.props.tasks !== nextProps.tasks) {
            return true;
        }

        return false;
    }

    componentDidUpdate() {
		this.setZoom(this.props.zoom);
        gantt.parse(this.props.tasks);
		gantt.$resourcesStore.parse(this.props.coders);
        gantt.render();
    }

    initGanttDataProcessor() {
        const onDataUpdated = this.props.onDataUpdated;
        this.dataProcessor = gantt.createDataProcessor((entityType, action, item, id) => {
            return new Promise((resolve, reject) => {
                if (onDataUpdated) {
                    onDataUpdated(entityType, action, item, id);
                }
                return resolve();
            });
        });
    }

    componentWillUnmount() {
        if (this.dataProcessor) {
            this.dataProcessor.destructor();
            this.dataProcessor = null;
        }
    }

    render() {
        return (
			<div ref={(input) => { this.ganttContainer = input }} style={{ width: '100%', height: '100%' }}></div>
        );
	}
}