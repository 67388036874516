import React, { useState, useEffect, useRef } from 'react';
import Select from "react-select";
import imageIcon from "../../../assets/images/inviteImage.png";
import Avatar from 'react-avatar';
// import { numberValidator } from '../../../Utils/helper';
import Switch from "../../../components/Switch";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #ECECF5',
        color: state.isSelected ? '#fff' : '#8890AB',
        padding: 6,
        fontSize: 13,
    }),
    placeholder: (base, state) => ({
        ...base,
        color: '#AFB6CB',
        fontSize: 13,
    }),
    control: (base, state) => ({
        ...base,
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
    singleValue:(base, state) => ({
        ...base,
        fontSize: 13
    }),
}

export const MemberFormBody = React.memo(props => {
    const [picture, setPicture] = useState([]);
    const [state, setState] = useState({
        id: '',
        firstname: '',
        surname: '',
        email: '',
        mobile: '',
        team: '',
        phone: '',
        user_level: {
            id: 1,
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ',
            title: 'User'
        },
        photo: '',
        team_options: props.team_options || [],
        user_level_options: props.userLevels || [],
        hubstaff_id: '',
        rate: 0.00,
        mode: true
    });

    useEffect(() => {
        // fill details if action is edit
        if(props.member_details){
            let selected_team = '';
            if(props.member_details.team){
                selected_team = {
                    id: props.member_details.team.team_details.id,
                    value: props.member_details.team.team_details.id,
                    label: props.member_details.team.team_details.team_name
                }
            }
            
            setState({
                ...state,
                id: props.member_details.id,
                user_id: props.member_details.user_id,
                firstname: props.member_details.firstname,
                surname:  props.member_details.surname,
                email:  props.member_details.email,
                mobile:  props.member_details.mobile,
                phone:  props.member_details.phone,
                team: selected_team,
                user_level: props.member_details.user_level,
                photo: props.member_details.photo,
                hubstaff_id: props.member_details.hubstaff_id ?? '',
                rate: props.member_details.rate ?? 0.00,
                mode: props.member_details.is_hourly ?? true,
            })
        }
    }, []);

    const handleSubmit = () => {
        const data = new FormData();
        if(state.id){
            data.append('id', state.id);
            data.append('user_id', state.user_id);
        }
        data.append('firstname', state.firstname || '');
        data.append('surname', state.surname || '');
        data.append('email', state.email);
        data.append('mobile', state.mobile || '');
        data.append('phone', state.phone || '');
        data.append('hubstaff_id', state.user_level.title == 'User' ? '' : state.hubstaff_id || '');
        data.append('team', JSON.stringify(state.team));
        data.append('user_level', state.user_level.id);
        data.append('photo', state.photo);
        data.append('rate', state.rate);
        data.append('is_hourly', state.mode == true ? 1 : 0);
        checkValidation(data);
    }

    const checkValidation = (data) => {
        let firstnameError = '';
        let surnameError = '';
        let emailError = '';
        let userLevelError = '';

        firstnameError = !state.firstname ? 'Firstname is required.' : null;
        surnameError = !state.surname ? 'Surname is required.' : null;
        emailError = !state.email ? 'Email is required.' : null;
        userLevelError = !state.user_level ? 'User Level is required.' : null;

        if (firstnameError || surnameError || emailError || userLevelError){
            return setState({ 
                ...state, 
                firstnameError, 
                surnameError, 
                emailError, 
                userLevelError,
            });
        }

        props.submitButton(data);
    }

    const changeUserLevel = (user_level) => {
        setState({ ...state, user_level: user_level })
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const handleChange = event => {
        let selected_photo = event.target.files[0];
        selected_photo.preview = URL.createObjectURL(event.target.files[0]);
        selected_photo.formattedSize = formatBytes(event.target.files[0].size);
        
        setState({...state, photo: selected_photo})
        console.log(selected_photo)
    } 

    function closeToast() {
        var x = document.getElementById("snackbar-company-form"); 
        x.className = x.className.replace("show", "");
    }

    const memberPhoto = () => {
        return(
        <div className="col-12">
            <p className="mb-2 member-photo-title">Member Photos</p>
            <div className="row">
                <div className="col-3" style={{textAlign: 'center'}}>
                    {state.photo ? (
                        <Avatar round size="80" src={state.photo.hasOwnProperty('preview') ? state.photo.preview : `${state.photo.path}`} className="btn-cirle-avatar" />
                    ):(
                        <Avatar round size="80" facebookId="100008343750912" className="btn-cirle-avatar" />
                    )}
                    
                </div>
                <div className="col-9 upload-container">
                    <label htmlFor="files" className="btn btn-primary btn-medium mb-3">Upload Photo</label>
                    <input id="files" type="file" className="btn btn-default" accept=".jpg, .jpeg, .png" style={{display: 'none'}} onClick={(e) => e.target.value = ''} onChange={handleChange}/>
                    {state.photo ? (
                        <button className="btn btn-default mb-3 ml-2" onClick={() => setState({...state, photo: ''})}>Remove</button>
                    ): null}
                    <p className="upload-image-desc">Images should be atleast 300 x 300 px in png or jpeg file</p>
                </div>
            </div>
        </div>
        )
    }

    const memberDetails = () => {
        return (
        <React.Fragment>
            <div className="col-6 mb-3">
                <h6>Firstname</h6>
                <div className="row">
                    <input className="form-control" placeholder="Enter Firstname" value={state.firstname || ''} onChange={(e)=>{setState({...state, firstname: e.target.value, firstnameError: ''}) }}  />
                    { state.firstnameError ? <label className="eror-fields">{state.firstnameError}</label> : '' } 
                </div>
            </div>
            <div className="col-6 mb-3">
                <h6>Surname</h6>
                <div className="row">
                    <input className="form-control" placeholder="Enter Surname" value={state.surname || ''} onChange={(e)=>{setState({...state, surname: e.target.value, surnameError: ''}) }}  />
                    { state.surnameError ? <label className="eror-fields">{state.surnameError}</label> : '' } 
                </div>
            </div>
            <div className="col-6 mb-3">
                <h6>Email</h6>
                <div className="row">
                    <input className="form-control" placeholder="eg. sample@email.com" value={state.email || ''} onChange={(e)=>{setState({...state, email: e.target.value , emailError: ''}) }}  />
                    { state.emailError ? <label className="eror-fields">{state.emailError}</label> : '' } 
                </div>
            </div>
            <div className="col-6 mb-3">
                <h6>Mobile</h6>
                <div className="row">
                    <input className="form-control" placeholder="eg. 02 9876 5432" value={state.mobile || ''} onChange={(e)=>{setState({...state, mobile: e.target.value, mobileError: ''}) }}  />
                    { state.mobileError ? <label className="eror-fields">{state.mobileError}</label> : '' } 
                </div>
            </div>
            <div className="col-6 mb-1">
                <h6>Phone</h6>
                <div className="row">
                    <input className="form-control" placeholder="eg. 02 9876 5432" value={state.phone || ''} onChange={(e)=>{setState({...state, phone: e.target.value, phoneError: ''}) }}  />
                    { state.phoneError ? <label className="eror-fields">{state.phoneError}</label> : '' } 
                </div>
            </div>

            {/* HUBSTAFF ID */}
            {state.user_level.title != 'User' && 
                <div className="col-6 mb-1">
                    <h6>Hubstaff ID</h6>
                    <div className="row">
                        <input className="form-control" placeholder="12345" value={state.hubstaff_id || ''} onChange={(e)=>{setState({...state, hubstaff_id: e.target.value}) }}  />
                    </div>
                </div>
            }

            <div className="border-spacer" ></div>
            <div className="col-6 mb-1">
                <h6>Rate (AUD $)</h6>
                <div className="row">
                    <input className="form-control" placeholder="Rate in AUD" value={state.rate || '0.00'} onChange={(e)=>{setState({...state, rate: e.target.value, rateError: ''}) }}  />
                    { state.rateError ? <label className="eror-fields">{state.rateError}</label> : '' } 
                </div>
            </div>

            <div className="col-6 mb-1">
                <h6>Mode</h6>
                <div className="row" style={{marginLeft: 0}}>
                    <div className="switch-input">
                        <Switch state={state.mode} onText="Hourly" offText="Fixed" width={75} toggleStatus={() => {setState({...state, mode: !state.mode})}}/>
                    </div>
                </div>
            </div>

            <div className="border-spacer" ></div>
            <div className="col-6 mt-1">
                <h6>Team</h6>
                <Select
                    className="form-select"
                    styles={customStyles}
                    placeholder='Select Team'
                    value={state.team}
                    onChange={(e) => setState({...state, team: e})}
                    options={state.team_options}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                />
            </div>
        </React.Fragment>
        )
    }

    const userLevel = () => {
        return(
        <div className="col-12 mb-3">
            <p>User Level</p>
            <div className="row">
                {state.user_level_options.map((user_level, index) => {
                    return(
                        <div className="col-6 mt-2" key={index}>
                            <div className="row p-3 m-1 user-level-card" onClick={() => changeUserLevel(user_level)}>
                                <div className="col-1 p-0 mr-2">
                                    <input 
                                        name="user-level" 
                                        type="radio" 
                                        className="form-control card-checkbox" style={{ with: 15, height: 15 }} 
                                        checked={state.user_level.title == user_level.title} 
                                        onChange={() => changeUserLevel(user_level)} 
                                    />
                                </div>
                                <div className="col-10 p-0">
                                    <h4 className="card-title">{user_level.title}</h4>
                                    <p className="card-description">{user_level.description}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
        )
    }

    return (
        <React.Fragment>
            <div id="snackbar-company-form" onClick={closeToast.bind(this)} className="display-none"  >
                <div className="col-5 notification-image flex-center" >
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto" >
                    <h5>Success!</h5>
                    <p>Added New Member!.</p>
                </div>
            </div>
            <div className="members form-container">
                <div className="row mb-4 mt-2">
                    {memberPhoto()}
                </div>
                <div className="row">
                    {memberDetails()}
                </div>
                <div className="row mt-4">
                    {userLevel()}
                </div>
                <div className="form-action">
                    <button className="btn btn-primary btn-large" onClick={handleSubmit} disabled={props.preload}>
                        {props.preload && <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>}
                        {props.member_details ? 'Update' : 'Add'}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
});


export default MemberFormBody;
