import React, {  useEffect, useState } from 'react';
import { connect } from "react-redux";
import MetisMenu from "metismenujs";
import { NavLink, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import inbox_icon from "../../assets/images/Inbox.svg";
import design_icon from "../../assets/images/Designs.svg";
import request_icon from "../../assets/images/Requests.svg";
import my_task_icon from "../../assets/images/MyTasks.svg";
import contact_icon from "../../assets/images/Contacts.svg";
import company_icon from "../../assets/images/Companies.svg";
import property_icon from "../../assets/images/Properties.svg";
import team_icon from "../../assets/images/Team.svg";
import log_icon from "../../assets/images/Logs.svg";
import domain_icon from "../../assets/images/Domain.svg";
import settings_icon from "../../assets/images/settings.svg";
import caretUp from "../../assets/images/caretUp.svg";
import caretDown from "../../assets/images/caretDown.svg";
import Api from "../../Utils/API";
import queryString from 'query-string';
import { filterTasks, filterMyTasks } from "../../store/actions";
import Avatar from "../../components/Avatar";
import { getFirstWord } from '../../Utils/helper';
import ReactTooltip from 'react-tooltip';

const SidebarContent = ({Task, setFilter, setMyTaskFilter, ...props}) => {
    const [state, setState] = useState({
        organisations: [],
        contractors: [],
        currentFilter: props.location.pathname === '/tasks-kanban' ? 'request' : (props.location.pathname === '/design-kanban' ? 'design' : null)
    });
    const [authUser, setAuthUser] = useState('');
    // const [showMyTasksFilters, setShowMyTasksFilters] = useState(false);
    const [showRequestFilters, setShowRequestFilters] = useState(false);
    const [showDesignFilters, setShowDesignFilters] = useState(false);
    const [organisationFilter, setOrganisationFilter] = useState(props.taskFilter.org);
    const [contractorFilter, setContractorFilter] = useState(props.taskFilter.con);

    const params = queryString.parse(props.location.search);

    useEffect(() => {
        Api.getAuthUser().then(result => {
            const user = result.data;
            setAuthUser(user)
        }).catch(err => {
            console.log(err)
        })
        var pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }

        initMenu();

        // if(pathName === '/my-requests-kanban') {
        //     setShowMyTasksFilters(true);
        //     // setSideBarTaskFilter(params.organisation ?? params.org, 'organisation', 'request');
        //     // setSideBarTaskFilter(params.contractor ?? params.con, 'contractor', 'request');
        // } else
        if(pathName === '/tasks-kanban') {
            setShowRequestFilters(true);
            getRequestFilterOptions('request');
            setSideBarTaskFilter(params.organisation ?? params.org, 'organisation', 'request');
            setSideBarTaskFilter(params.contractor ?? params.con, 'contractor', 'request');
        } else if(pathName === '/design-kanban') {
            setShowDesignFilters(true);
            getRequestFilterOptions('design');
            setSideBarTaskFilter(params.organisation ?? params.org, 'organisation', 'design');
            setSideBarTaskFilter(params.contractor ?? params.con, 'contractor', 'design');
        } else {
            setSideBarTaskFilter(null, 'organisation', 'project');
            setSideBarTaskFilter(null, 'contractor', 'project');
        }

    }, [props.location.pathname]);
    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    function getRequestFilterOptions(currentFilter) {
        Api.sidebarRequestFilterOptions(currentFilter).then(result => {
            let companies = result.data.companies;
            let contractors = result.data.coders;
            sortContractors(contractors);
            sortCompanies(companies);
            
            setState({ ...state, organisations: companies, contractors: contractors, currentFilter });
        }).catch(error => {
            console.log(error);
        });
    }

    const sortContractors = (contractors) => {
        contractors.sort(function(a, b){
            if(a.firstname < b.firstname) return -1;
            if(a.firstname > b.firstname) return 1;
            return 0;
        })
    }

    const sortCompanies = (companies) => {
        companies.sort(function(a, b){
            if(a.name < b.name) return -1;
            if(a.name > b.name) return 1;
            return 0;
        })
    }

    // function displayMyTasksFilters() {
    //     setShowMyTasksFilters(! showMyTasksFilters);
    // }

    function displayRequestFilters() {
        setShowRequestFilters(! showRequestFilters);
    }

    function setSideBarTaskFilter(id, type, page) {
        if (type === 'contractor') {
            if (contractorFilter == id) {
                setContractorFilter(null);
            } else {
                setContractorFilter(id);
            }
            // setOrganisationFilter(null);
        } else if(type === 'organisation') {
            if (organisationFilter == id) {
                setOrganisationFilter(null);
            } else {
                setOrganisationFilter(id);
            }
            // setContractorFilter(null);
        }

        setFilter({'id': id, 'type': type, 'page': page});
    }

    function setSideBarMyTasksFilter(type) {
        setMyTaskFilter({'type': type});
    }

    return (
        <React.Fragment>
            <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
                <li>
                    <NavLink to="email-inbox" className="waves-effect" activeClassName="selected-menu" >
                        <img src={inbox_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                        <span>{props.t('Inbox')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink to="my-requests-kanban" className="waves-effect with-dropdown" activeClassName="selected-menu" >
                        {/* <div className="dropdown-container" onClick={displayMyTasksFilters} > */}
                        <div className="dropdown-container">
                            <div>
                                <img src={my_task_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                                <span>My Requests</span>
                            </div>
                        </div>
                        {/* <img src={showMyTasksFilters ? caretUp : caretDown} alt="" height="10" width="10" className="right-icon" onClick={() => setShowMyTasksFilters(!showMyTasksFilters)}/> */}
                    </NavLink>

                    {/* {showMyTasksFilters &&
                        <div className="sidebar-submenu">
                            <div className="sidebar-submenu-group">
                                <div key={'my-tasks-filter-requests'} className={props.myTaskFilter.type === 'request' ? "active-filter" : ''}>
                                    <a className="sidebar-submenu-organisation" onClick={() => setSideBarMyTasksFilter('request')}>
                                        <div className="sidebar-submenu-organisation-rectangle"></div>
                                        {'Requests'}
                                    </a>
                                </div>
                                <div key={'my-tasks-filter-designs'} className={props.myTaskFilter.type === 'design' ? "active-filter" : ''}>
                                    <a className="sidebar-submenu-organisation" onClick={() => setSideBarMyTasksFilter('design')}>
                                        <div className="sidebar-submenu-organisation-rectangle"></div>
                                        {'Designs'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    } */}
                </li>

                {authUser.role !== 'coder' &&
                <>
                    <li>
                        <NavLink to="tasks-kanban" className="waves-effect with-dropdown" activeClassName="selected-menu" >
                            <div className="dropdown-container" onClick={displayRequestFilters} >
                                <div>
                                    <img src={request_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                                    <span>Requests</span>
                                </div>
                            </div>
                            <img src={showRequestFilters ? caretUp : caretDown} alt="" height="10" width="10" className="right-icon" onClick={() => setShowRequestFilters(!showRequestFilters)}/>
                        </NavLink>

                        {showRequestFilters ?
                            <div className="sidebar-submenu">
                                <div className="sidebar-submenu-group">
                                    <label className="sidebar-submenu-header">Organisations</label>
                                    {state.organisations.map((el) =>
                                        <div key={'organisation-filter-' + el.id} className={el.id == Number(params.org) ? "active-filter" : ''}>
                                            <a className="sidebar-submenu-organisation text-truncate" onClick={() => setSideBarTaskFilter(el.id, 'organisation', state.currentFilter)}>
                                                <div className="sidebar-submenu-organisation-rectangle"></div>
                                                <span style={{
                                                    width: 160,
                                                    whiteSpace: 'normal'
                                                }} data-tip data-for={`message-tooltip-${el.id}`} className="text-truncate">{el.name}</span>
                                                <ReactTooltip 
                                                    id={`message-tooltip-${el.id}`} 
                                                    effect="solid" 
                                                    className="message-tooltip"
                                                    place="right"
                                                    backgroundColor="#00000073"
                                                    style={{width: '200px'}}
                                                > {el.name}</ReactTooltip>
                                            </a>
                                        </div>
                                    )}
                                </div>

                                {/* Hide Contractors if not an admin user */}
                                {authUser.role != 'coder' ? 
                                    <div className="sidebar-submenu-group">
                                        <label className="sidebar-submenu-header">Contractors</label>
                                        {state.contractors.map((el) =>
                                            <div key={'contractor-filter-' + el.id} className={el.id == Number(params.con) ? "active-filter" : ''}>
                                                <a className="sidebar-submenu-contractor" onClick={() => setSideBarTaskFilter(el.id, 'contractor', state.currentFilter)}>
                                                    <Avatar
                                                        name={`${getFirstWord(el.firstname)}`}
                                                        round={true}
                                                        size="32"
                                                        color="#f44336"
                                                        className="btn-cirle-avatar"
                                                    />
                                                    <span>{(el.firstname.indexOf(" ") !== -1 ? el.firstname.substr(0, el.firstname.indexOf(" ")) : el.firstname) + "'s Tasks"}</span>
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                : null}
                            </div>
                            : null
                        }
                    </li>

                    <li>
                    <NavLink to="design-kanban" className="waves-effect with-dropdown" activeClassName="selected-menu" >
                        <div className="dropdown-container" onClick={displayRequestFilters} >
                            <div>
                                <img src={design_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                                <span>Designs</span>
                            </div>
                        </div>
                        <img src={showRequestFilters ? caretUp : caretDown} alt="" height="10" width="10" className="right-icon" onClick={() => setShowRequestFilters(!showRequestFilters)}/>
                    </NavLink>

                    {showDesignFilters ?
                        <div className="sidebar-submenu">
                            <div className="sidebar-submenu-group">
                                <label className="sidebar-submenu-header">Organisations</label>
                                {state.organisations.map((el) =>
                                    <div key={'organisation-filter-' + el.id} className={el.id == organisationFilter ? "active-filter" : ''}>
                                        <a className="sidebar-submenu-organisation" onClick={() => setSideBarTaskFilter(el.id, 'organisation', state.currentFilter)}>
                                            <div className="sidebar-submenu-organisation-rectangle"></div>
                                            {el.name}
                                        </a>
                                    </div>
                                )}
                            </div>

                            {/* Hide Contractors if not an admin user */}
                            {authUser.role != 'coder' ? 
                                <div className="sidebar-submenu-group">
                                    <label className="sidebar-submenu-header">Contractors</label>
                                    {state.contractors.map((el) =>
                                        <div key={'contractor-filter-' + el.id} className={el.id == contractorFilter ? "active-filter" : ''}>
                                            <a className="sidebar-submenu-contractor" onClick={() => setSideBarTaskFilter(el.id, 'contractor', state.currentFilter)}>
                                                <Avatar
                                                    name={`${getFirstWord(el.firstname)}`}
                                                    round={true}
                                                    size="32"
                                                    color="#f44336"
                                                    className="btn-cirle-avatar"
                                                />
                                                <span>{(el.firstname.indexOf(" ") !== -1 ? el.firstname.substr(0, el.firstname.indexOf(" ")) : el.firstname) + "'s Tasks"}</span>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            : null}
                        </div>
                        : null
                    }
                    </li>
                </>
                }

                <li>
                    <NavLink exact to="sales-kanban" className="waves-effect" activeClassName="selected-menu" >
                        <img src={my_task_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                        <span>{props.t('Sales')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink exact to="contacts-list" className="waves-effect" activeClassName="selected-menu" >
                        <img src={contact_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                        <span>{props.t('Contacts')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink exact to="companies-list" className="waves-effect" activeClassName="selected-menu" >
                        <img src={company_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                        <span>{props.t('Companies')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink exact to="websites-list" className="waves-effect" activeClassName="selected-menu svg-selected-custom" >
                        <img src={property_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                        <span>{props.t('Websites')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink exact to="teams-list" className="waves-effect" activeClassName="selected-menu svg-selected-custom-2" >
                        <img src={team_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                        <span>{props.t('Team')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink exact to="logs-list" className="waves-effect" activeClassName="selected-menu svg-selected-custom-3" >
                        <img src={log_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                        <span>{props.t('Logs')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink exact to="domains-list" className="waves-effect" activeClassName="selected-menu svg-selected-custom-4" >
                        <img src={domain_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                        <span>{props.t('Domain')}</span>
                    </NavLink>
                </li>

                {
                    authUser.role === 'admin'
                        ? <li>
                            <NavLink exact to="settings" className="waves-effect" activeClassName="selected-menu svg-selected-custom-5" >
                                <img src={settings_icon} alt="" height="18.68" width="20" style={{marginRight: 20}} />
                                <span>{props.t('Settings')}</span>
                            </NavLink>
                        </li>
                        : null
                }
            </ul>
        </div>
        </React.Fragment>
    );
}

const mapStatetoProps = ({Task}) => {
    return { taskFilter: Task.taskFilter, myTaskFilter: Task.myTaskFilter };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFilter:(e) => dispatch(filterTasks(e)),
        setMyTaskFilter: (e) => dispatch(filterMyTasks(e))
    }
}

// export default withRouter(withNamespaces()(SidebarContent));
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(withNamespaces()(SidebarContent)));