import React, { useState, useEffect, useRef } from 'react';
import Select from "react-select";
import DatepickerDiv from "../../Datepicker";
import task_card_calendar from "../../../assets/images/task-card-calendar.svg";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #ECECF5',
        color: state.isSelected ? '#fff' : '#8890AB',
        padding: 6,
        fontSize: 13,
    }),
    placeholder: (base, state) => ({
        ...base,
        color: '#AFB6CB',
        fontSize: 13,
    }),
    control: (base, state) => ({
        ...base,
        border: '0 !important',
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
    singleValue:(base, state) => ({
        ...base,
        fontSize: 13
    }),
}

export const DomainFormBody = React.memo(props => {
    let date = new Date();
    date = new Date(date.setMonth( date.getMonth() + 1 ));
    const [error, setError] = useState({
        domainError: '',
        propertyTypeError: '',
        typeError: ''
    });
    const [state, setState] = useState({
        property: '',
        domain_name: '',
        id: '',
        expiry_date: date,
        type: '',
        type_options: [],
        property_options: []
    });

    useEffect(() => {
        let property_options = [], type_options = [], selected_type = '', selected_property = '';

        props.property_options.map(property => {
            property_options.push({
                value: property.id,
                label: property.name
            })
        })

        props.type_options.map(type => {
            type_options.push({
                value: type.id,
                label: type.title
            })
        })

        // fill details if action is to edit domain
        if(props.domain_details){
            selected_type = {
                value: props.domain_details.type.id,
                label: props.domain_details.type.title
            }

            selected_property = {
                value: props.domain_details.property.id,
                label: props.domain_details.property.name
            }
        }

        console.log(selected_type)

        setState({
            ...state,
            id: props.domain_details.id,
            property: selected_property,
            domain_name: props.domain_details.domain_name || '',
            expiry_date:  props.domain_details.expiry_date || '',
            type: selected_type,
            property_options: property_options,
            type_options: type_options,
        })
    }, [props.domain_details]);

    useEffect(() => {
        setError({
            ...error,
            domainError: props.error && props.error.domain_name ? props.error.domain_name[0] : '',
            propertyTypeError: props.error && props.error.property_id ? props.error.property_id[0] : '',
            typeError: props.error && props.error.type ? props.error.type[0] : ''
        })
    }, [props.error])

    const handleSubmit = () => {
        let params = {
            id: state.id,
            property_id: state.property.value,
            domain_name: state.domain_name,
            expiry_date: state.expiry_date,
            type: state.type.value,
        }

        props.submitButton(params);
    }

    const updateExpiryDate = (e) => {
        setState({ ...state, expiry_date: e });
    }

    const onChangeInput = (e, keyName, errorKey) => {
        const value = keyName == 'domain_name' ? e.target.value : e ;

        setState(prevState => ({...prevState, [keyName]: value}))   // set state using dynamic key 
        error[errorKey] = '';
    }

    const domainDetails = () => {
        return (
        <React.Fragment>
             <div className="contact-group">
                <div className="row-1"> Domain </div>
                <div className="row-2">
                    <input className="form-control note-subject" placeholder="Enter Domain" value={state.domain_name} onChange={(e) => onChangeInput(e, 'domain_name', 'domainError')} />
                    {error.domainError && <label className="eror-fields">{error.domainError}</label>}
                </div>
            </div>
            <div className="contact-group note-container">
                <div className="row-1"> Expiry Date </div>
                <div className="row-2">
                    <DatepickerDiv updateDueDate={updateExpiryDate} date={state.expiry_date} showTimeInput={true}/>
                </div>
                <img className="task-card-calendar-ico mr-3" src={task_card_calendar}></img>
            </div>
            <div className="contact-group">
                <div className="row-1"> Property </div>
                <div className="row-2 mr-2">
                    <Select
                        className="form-select"
                        styles={customStyles}
                        placeholder='Select Property'
                        value={state.property}
                        onChange={(e) => onChangeInput(e, 'property', 'propertyTypeError')}
                        options={state.property_options}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                    />
                    {error.propertyTypeError && <label className="eror-fields">{error.propertyTypeError}</label>}
                </div>
            </div>
            <div className="contact-group">
                <div className="row-1"> Type </div>
                <div className="row-2 mr-2">
                    <Select
                        className="form-select"
                        styles={customStyles}
                        placeholder='Select Type'
                        value={state.type}
                        onChange={(e) => onChangeInput(e, 'type', 'typeError')}
                        options={state.type_options}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                    />
                    {error.typeError && <label className="eror-fields">{error.typeError}</label>}
                </div>
            </div>
        </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div>
                {domainDetails()}
                <div className="form-action">
                    <button className="btn btn-primary btn-large" onClick={handleSubmit} disabled={props.isSubmit}>
                        {props.isSubmit && <i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i>}
                        {props.domain_details ? 'Update' : 'Save'}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
});


export default DomainFormBody;
