
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import logPhone from "../assets/images/logPhone.png";
import {
    customStyles, UniversalValidator
} from '../Utils/helper';
import Api from "../Utils/API";
import Select from "react-select";

export const AddPhoneLog = (props) => { 
    const [state, setState] = React.useState({
        isSubmit: false,
        contactOptions: [],
        isMinimized: false
    });
    const [stateContact, setStateContact] = React.useState({
        contact: '',
        contactError: '',
        subject: '',
        subjectError: '',
        details: '',
        detailsError: '',
        direction: 0,
        directionError: '',
    });
    // const handleChange = value => {
    //     setState({ value });
    //     props.handleChange(value);
    // };

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setState({
            ...state, isMinimized: true
        })
    }

    function openForm(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');

        setState({
            ...state, isMinimized: false
        })
    }

    useEffect(() => {
        if(props.selectContact) {
            let contacts = props.contacts;
            let contactToUpdate = props.contactDetailsUpdate ? props.contactDetailsUpdate.contact.target : '';
            let contactOptions = [];

            for (let index = 0; index < contacts.length; index++) {
                contactOptions.push({
                    value: contacts[index].id,
                    label: `${contacts[index].firstname} <${contacts[index].email}> `,
                });

                // if props is to update phone log
                if(props.contactDetailsUpdate){
                    if(contacts[index].id == contactToUpdate.contact_id){
                        let defaultContact = {
                            value: contacts[index].id,
                            label: `${contacts[index].firstname} <${contacts[index].email}> `,
                        }
                        setStateContact({ 
                            ...stateContact, 
                            contact: defaultContact,
                            subject: contactToUpdate.subject,
                            details: contactToUpdate.details,
                            direction: contactToUpdate.is_incoming,
                        })
                    }
                }
            }

            setState({
                ...state,
                contactOptions: contactOptions,
            });
        }
    }, [props]);

    function handleSubmit(event) {
        //event.preventDefault();
        const subjectError = UniversalValidator({ value: stateContact.subject, isValidate: true, isUrl: false, errorMsg: 'Subject is required.' });
        const directionError = UniversalValidator({ value: stateContact.direction, isValidate: true, isUrl: false, errorMsg: 'Direction is required.' });
        const detailsError = UniversalValidator({ value: stateContact.details, isValidate: true, isUrl: false, errorMsg: 'Details is required.' });
        
        if (subjectError || directionError ) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill in all fields!',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setStateContact({ ...stateContact, subjectError: subjectError, directionError: directionError, detailsError: detailsError });
        }else{
            setState({ ...state, isSubmit: true });
            let params = {
                contact_id: props.id ?? stateContact.contact.value,
                subject : stateContact.subject,
                is_incoming : stateContact.direction,
                details : stateContact.details,
            }
            Api.savePhoneCalls(params).then(result => {
                props.action();
                setState({ ...state, isSubmit: false });
            }).catch(error => {
                setState({ ...state, isSubmit: false });
                console.log(error);
            });
        }
    }

    return (
        <React.Fragment>
            <div className="contact-form phone-logs-form" >
                <div className="form-header" onClick={(e) => openForm(e)} >
                   <div className="row-1"><img src={logPhone} className="mr-2"/>Log Phone Call</div>
                    <div className="row-2">
                        {/* <button className="btn btn-icon" onClick={props.minimize} > <img src={minimizeIcon} /> </button> */}
                        {state.isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                <div className="row ml-2">
                    {props.selectContact ?
                        <>
                            <div className="col-lg-12">
                                <div className="contact-group">
                                    <div className="row-1">
                                        Contact
                                    </div>
                                    <div className="row-2">
                                        <Select
                                            className="form-select ml-1"
                                            // classNamePrefix="select2-selection"
                                            styles={customStyles}
                                            value={stateContact.contact}
                                            onChange={(e) => { setStateContact({ ...stateContact, contact: e }) }}
                                            options={state.contactOptions}
                                            placeholder="Select Contact"
                                            noOptionsMessage={() => null}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                        {
                                            state.projectError ?
                                                <label className="eror-fields">{state.projectError}</label>
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </> : null
                    }
                       <div className="col-lg-12">
                            <div className="contact-group">
                                <div className="row-1">
                                    Subject
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Subject"  value={stateContact.subject}   onChange={(e) => { setStateContact({ ...stateContact, subject: e.target.value, subjectError: '' }) }} />
                                    {
                                        stateContact.subjectError ?
                                            <label className="eror-fields">{stateContact.subjectError}</label>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="contact-group">
                                <div className="row-1">
                                    Direction
                                </div>
                                <div className="row-2">
                                    <div className="inline-radio">
                                        <input name="is_incoming" checked={stateContact.direction} onChange={(e) => { setStateContact({ ...stateContact, direction: 1, directionError: '' }) }}  type="radio" className="form-control" style={{ with: 15, height: 15 }}  />  Incoming
                                        <input name="is_incoming" checked={!stateContact.direction} onChange={(e) => { setStateContact({ ...stateContact, direction: 0, directionError: '' }) }}  type="radio" className="form-control" style={{ with: 15, height: 15 }}  />  Outgoing 
                                    </div>
                                    {
                                        stateContact.directionError ?
                                            <label className="eror-fields">{stateContact.directionError}</label>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="contact-group">
                                <textarea className="form-input" placeholder="Enter details" style={{height: 250}} value={stateContact.details} onChange={(e) => { setStateContact({ ...stateContact, details: e.target.value, detailsError: '' }) }}></textarea>
                            </div>
                            {
                                stateContact.detailsError ?
                                    <label className="eror-fields">{stateContact.detailsError}</label>
                                    : ''
                            }
                        </div>
                    </div>

                    <div className="form-action">
                        <button onClick={handleSubmit} className="btn btn-primary btn-large" disabled={state.isSubmit}>
                            {
                                state.isSubmit ?
                                    (<i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i>)
                                    : ''
                            }
                            Assign
                       </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddPhoneLog;
