import React from 'react';
import Swal from "sweetalert2";
import confirmDelete from "../assets/images/confirmDelete.png";

export default function ConfirmDelete(props) {  
    return (
        Swal.fire({
            title: 'Delete Confirmation',
            text: `Are you sure you want to delete this ${props.page}? You can't undo this action.`,
            imageUrl: confirmDelete,
            showCancelButton: true,
            confirmButtonColor: '#EB5757',
            cancelButtonColor: '#ffffff',
            confirmButtonText: 'Yes, Delete',
            showCloseButton: true,
            customClass: {
                confirmButton: 'swal-custom-confirm',
                cancelButton: 'swal-custom-cancel',
                container: 'swal-custom-container',
                header: 'swal-custom-header',
                title: 'swal-custom-title',
                content: 'swal-custom-content',
                icon: 'swal-custom-icon',
                popup: 'swal-custom-popup'
            },
            cancelButtonText: "Don't Delete",
            reverseButtons: true
          }).then((result) => {
            return result;
        })
    );
}

