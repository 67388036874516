import React, { useEffect } from 'react';
import Swal from 'sweetalert2'
import moment from 'moment';
import { NavLink, NavItem, Nav } from "reactstrap";
import classnames from "classnames";
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import plusIcon from "../assets/images/plusIcon.png";
import calendar from "../assets/images/calendar.png";
import Select from "react-select";
import TextareaAutosize from 'react-textarea-autosize';
import DatepickerDiv from "./Datepicker";
import {
    customStyles,
} from '../Utils/helper';
import {
    UniversalValidator,
} from '../Utils/helper';
import Api from "../Utils/API";



export const AddTask = React.memo(props => {
    var date = new Date();
    date.setDate(date.getDate() + 1);

    const [state, setState] = React.useState({
        company_id: props.company_id,
        value: null,
        customActiveTab: "1",
        dueDate: date,
        openCalendar: false,
        isSubmit: false,
        subject: '',
        assignee: '',
        qa: '',
        details: '',
        coder_options: '',
        project_options: '',
        selectedProject: '',
        company_id: '',
        contact_id: '',
        selectedPeroject:'',
        isMinimized: false
    });

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setState({
            ...state, isMinimized: true
        })
    }

    function openForm(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');

        setState({
            ...state, isMinimized: false
        })
    }

    useEffect(() => {
        let project_options = props.projects;
        let contactDetails = props.contactDetails;
        let coders = props.coders;
        let coder_options = [];
        for (let index = 0; index < coders.length; index++) {
            coder_options.push({
                value: coders[index].id,
                label: `${coders[index].firstname} ${coders[index].surname} `,
            });

        }

        setState({
            ...state,
            project_options: project_options,
            coder_options: coder_options,
            projectError: '',
            subjectError: '',
            assigneeError: '',
            qaError: '',
            detailsError: '',
            company_id: props.company_id ? props.company_id : (contactDetails.company ? contactDetails.company.id : ''),
            contact_id: contactDetails ? (contactDetails.id ? contactDetails.id : '') : '',
        });
    }, [props]);

    function handleSubmit(event) {
        event.preventDefault();
        const projectError = UniversalValidator({ value: state.selectedPeroject, isValidate: true, isUrl: false, errorMsg: 'Project is required.' });
        const subjectError = UniversalValidator({ value: state.subject, isValidate: true, isUrl: false, errorMsg: 'Subject is required.' });
        const assigneeError = UniversalValidator({ value: state.assignee, isValidate: true, isUrl: false, errorMsg: 'Assignee is required.' });
        const qaError = UniversalValidator({ value: state.assignee, isValidate: true, isUrl: false, errorMsg: 'QA is required.' });
        const detailsError = UniversalValidator({ value: state.details, isValidate: true, isUrl: false, errorMsg: 'Details is required.' });

        if (projectError || subjectError || assigneeError ||  qaError || detailsError) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill in all fields!',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setState({ ...state, projectError: projectError, subjectError: subjectError, assigneeError: assigneeError, qaError: qaError, detailsError: detailsError, openCalendar: false });
        } else {
            setState({ ...state, isSubmit: true });
            let dueDate = moment(state.dueDate).format("YYYY-MM-DD");
            let params = {
                project_id: state.selectedPeroject.value,
                title: state.subject,
                description: state.details,
                assignee_id: state.assignee,
                qa_id: state.qa,
                due_date: dueDate,
                type: 'task',
                company_id: state.company_id,
                requestor: state.contact_id
            }

            Api.addTaskCompany(params).then(result => {
                var date = new Date();
                date.setDate(date.getDate() + 1);

                setState({
                    ...state,
                    customActiveTab: "1",
                    dueDate: date,
                    openCalendar: false,
                    subject: '',
                    assignee: '',
                    qa: '',
                    details: '',
                    selectedProject: ''
                });
                setState({ ...state, isSubmit: false });
                props.updateList(result.data.task);
            }).catch(error => {
                setState({ ...state, isSubmit: false });
                console.log(error);
            });
        }
    }

    const updateDueDate = (e) => {
        setState({ ...state, dueDate: e });
    }

    const toggleNav = (e) => {
        setState({ ...state, customActiveTab: e })
    }

    function newTemplete() {
        alert("Under Construction");
    }

    return (
        <React.Fragment>
            <div className="contact-form" >
                <div className="form-header" onClick={(e) => openForm(e)} >
                    <div className="row-1"> Add Task to {props.name}</div>
                    <div className="row-2">
                        {/* <button className="btn btn-icon" onClick={props.minimize} > <img src={minimizeIcon} /> </button> */}
                        {state.isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body form-body-contact">
                    <Nav tabs className="nav-tabs-custom  nav-contact">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: state.customActiveTab === "1"
                                })}
                                onClick={() => {
                                    toggleNav("1");
                                }}
                            >
                                <span className="d-sm-block">Compose Task</span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="navlink-second" >
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: state.customActiveTab === "2"
                                })}
                                onClick={() => {
                                    toggleNav("2");
                                }}
                            >
                                <span className="d-sm-block">Choose a Task</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <form onSubmit={handleSubmit} >
                        <div style={{ display: state.customActiveTab !== "1" ? 'none' : '', margin: 20 }} >
                            <div className="contact-group">
                                <div className="row-1">
                                    Project
                                </div>
                                <div className="row-2">
                                    <Select
                                        className="form-select ml-1"
                                        // classNamePrefix="select2-selection"
                                        styles={customStyles}
                                        value={state.selectedPeroject}
                                        onChange={(e) => { setState({ ...state, selectedPeroject: e, projectError: '' }) }}
                                        options={state.project_options}
                                        placeholder="Select Project"
                                        noOptionsMessage={() => null}
                                        // noResultsText="No company found!"
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                    {
                                        state.projectError ?
                                            <label className="eror-fields">{state.projectError}</label>
                                            : ''
                                    }
                                </div>
                            </div>
                            <div className="row contact-group-border">
                                <div className="col-7">
                                    <div className="flex-row item-baseline">
                                        <div className="flex-item">Task assignee</div>
                                        <div className="flex-item flex-1 ">
                                            <Select
                                                className="form-select ml-1"
                                                styles={customStyles}
                                                value={state.assigneeDetails}
                                                onChange={(e) => { setState({ ...state, assigneeDetails: e, assignee: e.value, assigneeError: '' }) }}
                                                options={state.coder_options}
                                                placeholder="Select Assignee"
                                                noOptionsMessage={() => null}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />
                                            {
                                                state.assigneeError ?
                                                    <label className="eror-fields">{state.assigneeError}</label>
                                                    : ''
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="col-5">
                                    <div className="flex-row item-baseline">
                                        <div className="flex-item">QA</div>
                                        <div className="flex-item flex-1 ">
                                            <Select
                                                className="form-select ml-1"
                                                styles={customStyles}
                                                value={state.qaDetails}
                                                onChange={(e) => { setState({ ...state, qaDetails: e, qa: e.value, qaError: '' }) }}
                                                options={state.coder_options}
                                                placeholder="Select QA"
                                                noOptionsMessage={() => null}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />
                                            {
                                                state.qaError ?
                                                    <label className="eror-fields">{state.qaError}</label>
                                                    : ''
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="contact-group">
                                <div className="row-1">
                                    Task Subject
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Subject Here" value={state.subject} onChange={(e) => { setState({ ...state, subject: e.target.value, subjectError: '' }) }} />
                                    {
                                        state.subjectError ?
                                            <label className="eror-fields">{state.subjectError}</label>
                                            : ''
                                    }

                                </div>
                            </div>
                            <div className="contact-group">
                                <div className="row-1">
                                    Due date
                                </div>
                                <div className="row-2">
                                    <DatepickerDiv updateDueDate={updateDueDate} date={state.dueDate} open={state.openCalendar} />
                                </div>
                                <div className="row-3">
                                    <img src={calendar} style={{ cursor: 'pointer' }} onClick={() => setState({ ...state, openCalendar: true })} />
                                </div>
                            </div>
                            <TextareaAutosize placeholder="Enter details" className="task-details" onBlur={(e) => { setState({ ...state, details: e.target.value, detailsError: '' }) }}  ></TextareaAutosize>
                            {
                                state.detailsError ?
                                    <label className="eror-fields">{state.detailsError}</label>
                                    : ''
                            }
                            <div className="footer-action">
                                <div className="row">
                                    <div className="col-8">
                                        Merge Fields
                                    </div>
                                    <div className="col-4 text-right">
                                        <button type="submit" className="btn btn-primary btn-large" disabled={state.isSubmit}  >
                                            {
                                                state.isSubmit ?
                                                    (<i className="bx bx-loader bx-spin font-size-14 align-middle"></i>)
                                                    : ''
                                            }
                                            Assign
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div style={{ display: state.customActiveTab !== "2" ? 'none' : '', margin: 20 }} >
                        <div className="contact-group task-template">
                            <div className="row-1">
                                Template
                                </div>
                            <div className="row-2">
                                <Select
                                    className="form-select ml-1"
                                    // classNamePrefix="select2-selection"
                                    styles={customStyles}
                                    value={state.selectedPeroject}
                                    onChange={(e) => { setState({ ...state, selectedPeroject: e, projectError: '' }) }}
                                    options={state.task_options}
                                    placeholder="Select Template"
                                    noOptionsMessage={() => null}
                                    // noResultsText="No company found!"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                                {
                                    state.projectError ?
                                        <label className="eror-fields">{state.projectError}</label>
                                        : ''
                                }
                            </div>
                            <div className="row-3">
                                <button className="btn btn-clear" onClick={newTemplete} > <img src={plusIcon} className="mr-2" />  New Template</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
});

export default AddTask;
