import React, { useEffect } from "react";

export default function NumberPickerData(props) {    console.log();
    const [state, setState] = React.useState({
        hr: props.hr,
        min: props.min,
        type: props.type,
        loading: props.loading
    });

    useEffect(() => { 
        setState({
            ...state,
            hr: props.hr,
            min: props.min,
            type: props.type,
            loading: props.loading
        });
    }, [props]);

    function saveTime() {
        setState({...state, loading : true});
        props.action(state);
    }

    function cancel() {
        props.cancel();
    }
  
    return (
        <React.Fragment>
            <div className="timer-picker"  >
                <div className="timer-title">
                    {
                        state.type === 1 ?
                            'Actual Time' : ( state.type === 2 ?
                                'Estimated Time' : 'Time Adjustments')
                            
                    }
                </div>
                <div className="row" >
                    <div className="col-6" >
                        Hours
                        <input className="form-control" type="number" min="0" value={state.hr} onChange={(e) => (setState({ ...state, hr: e.target.value }))} />
                    </div>
                    <div className="col-6" >
                        Minutes
                        <input className="form-control" type="number" min="0" max="60" value={state.min} onChange={(e) => (setState({ ...state, min: e.target.value }))} />
                    </div>
                    <div className="col-12 text-right footer-content" >
                        <button className="btn btn-default picker-cancel-btn" onClick={cancel} >
                            {
                                state.loading ?
                                    (<i className="bx bx-loader bx-spin font-size-14 align-middle"></i>)
                                    : 'Cancel'
                            }
                            
                        </button>
                        <button className="btn btn-default picker-save-btn" onClick={saveTime}  >
                            {
                                state.loading ?
                                    (<i className="bx bx-loader bx-spin font-size-14 align-middle"></i>)
                                    : 'Save'
                            }
                            
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}



