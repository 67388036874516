import React, { useState, useEffect, useRef } from 'react';
import imageIcon from "../../../assets/images/inviteImage.png";
import Avatar from 'react-avatar';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Swal from 'sweetalert2';
import Api from "../../../Utils/API";

export const TeamFormBody = React.memo(props => {
    const [state, setState] = useState({
        id: '',
        name: '',
        description: '',
        descriptionError: '',
        teamNameError: '',
        members: [],
        member_options: [],
        photo: ''
    });

    useEffect(() => {
        let members = []
        props.members_list.forEach(member => {
            state.member_options.push({
                id: member.id,
                name: `${member.firstname} ${member.surname}`,
                email: member.email
            });

            setState({...state, member_options: state.member_options})
        });

        // fill details if action is edit
        if(props.team_details){
            props.team_details.members.forEach(member => {
                members.push({
                    id: member.member_details.id,
                    name: `${member.member_details.firstname} ${member.member_details.surname}`,
                    email: member.member_details.email
                });
            });

            setState({
                ...state,
                id: props.team_details.id,
                team_name: props.team_details.team_name,
                description:  props.team_details.description,
                photo: props.team_details.photo,
                members: members
            })
        }
    }, []);

    const handleSubmit = () => {
        const data = new FormData();
        if(state.id) data.append('id', state.id);
        data.append('team_name', state.team_name);
        data.append('description', state.description);
        data.append('photo', state.photo);
        data.append('members', JSON.stringify(state.members));

        checkValidation(data);
    }

    const checkValidation = (data) => {
        let teamNameError = '';
        teamNameError = !state.team_name ? 'Team Name is required.' : null;

        if (teamNameError) 
            return setState({ ...state, teamNameError: teamNameError});

        props.submitButton(data);
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const handleChange = event => {
        let selected_photo = event.target.files[0];
        selected_photo.preview = URL.createObjectURL(event.target.files[0]);
        selected_photo.formattedSize = formatBytes(event.target.files[0].size);
        
        setState({...state, photo: selected_photo})
    }

    function closeToast() {
        var x = document.getElementById("snackbar-company-form"); 
        x.className = x.className.replace("show", "");
    }

    const TeamPhoto = () => {
        return(
        <div className="col-12">
            <p className="mb-2 member-photo-title">Team Photo</p>
            <div className="row">
                <div className="col-3" style={{textAlign: 'center'}}>
                    {state.photo ? (
                        <Avatar round size="80" src={state.photo.hasOwnProperty('preview') ? state.photo.preview : `${state.photo.path}`} className="btn-cirle-avatar" />
                    ):(
                        <Avatar round size="80" facebookId="100008343750912" className="btn-cirle-avatar" />
                    )}
                    
                </div>
                <div className="col-9 upload-container">
                    <label htmlFor="files" className="btn btn-primary btn-medium mb-3">Upload Photo</label>
                    <input id="files" type="file" className="btn btn-default" accept=".jpg, .jpeg, .png" style={{display: 'none'}} onChange={handleChange}/>
                    {state.photo &&
                        <button className="btn btn-default mb-3 ml-2" onClick={() => setState({...state, photo: ''})}>Remove</button>
                    }
                    <p className="upload-image-desc">Images should be atleast 300 x 300 px in png or jpeg file</p>
                </div>
            </div>
        </div>
        )
    }
    
    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    const handleOnSelect = (new_member) => {
        let exists = state.members.some(member => member.id === new_member.id);
        if(exists)
            return Swal.fire({ title: 'Ooops!',  text: "Member already exists.", icon: 'warning'});

        setState({...state, members: [...state.members, new_member]})
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const removeMember = (member, index) =>{
        let params = {
            member_id: member.id,
            team_id: state.id
        }
        Api.removeMember(params).then(result => {
            let members = state.members;
            members.splice(index, 1);
            setState({ ...state, members: members });
        }).catch(error => {
            console.log(error)
        })
    }

    const teamDetails = () => {
        return (
        <React.Fragment>
            <div className="col-12 mb-3">
                <h6>Team Name</h6>
                <div className="row">
                    <input className="form-control" placeholder="Enter Team Name" value={state.team_name} onChange={(e)=>{setState({...state, team_name: e.target.value, teamNameError: ''}) }}  />
                    { state.teamNameError && <label className="eror-fields">{state.teamNameError}</label> } 
                </div>
            </div>
            <div className="col-12 mb-3">
                <h6>Description</h6>
                <div className="row">
                    <input className="form-control" placeholder="Enter Description" value={state.description} onChange={(e)=>{setState({...state, description: e.target.value, descriptionError: ''}) }}  />
                    { state.descriptionError && <label className="eror-fields">{state.descriptionError}</label> } 
                </div>
            </div>
            <div className="border-spacer" ></div>
            <div className="col-12 mb-3">
                <h6>Add Members</h6>
                <ReactSearchAutocomplete
                    items={state.member_options}
                    className="autosearch"
                    styling={{ 
                        zIndex: 2,
                        border: 'unset',
                        borderRadius: '0px',
                        boxShadow: 'none',
                        fontSize: '16px',
                        fontFamily: 'Poppins',
                        lineColor: "rgb(232, 234, 237)",
                        margin: 0,
                    }} 
                    style={{padding:0}}
                    showIcon={false}
                    placeholder="Search Name"
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    onChange={(e)=>{setState({...state, email: e.target.value , searchError: ''})}}
                    autoFocus
                />
                { state.searchError && <label className="eror-fields">{state.searchError}</label> } 

            </div>
           
        </React.Fragment>
        )
    }

    const memberList = () => {
        return(
        <div className="col-12 mb-3">
            <p>Member List ({state.members.length})</p>
            {state.members.map((member, index) => {
                return(
                    <div className="row mt-3 align-items-center" key={index}>
                        <div className="col-4 team-member-name">
                            <p 
                                className="text-truncate"  
                                data-toggle="tooltip"
                                data-placement="top" 
                                title={member.name}>
                                {member.name}
                            </p>
                        </div>
                        <div className="col-6 team-member-email">
                            <p 
                                className="text-truncate" 
                                data-toggle="tooltip"
                                data-placement="top" 
                                title={member.email}>
                                {member.email}
                            </p>
                        </div>
                        <div className="col-2">
                            <button className="btn-sm action-btn delete remove-member" onClick={() => removeMember(member, index)}><i className="fa fa-trash" ></i></button>
                        </div>
                    </div>
                )
            })}
        </div>
        )
    }

    return (
        <React.Fragment>
            <div id="snackbar-company-form" onClick={closeToast.bind(this)} className="display-none"  >
                <div className="col-5 notification-image flex-center" >
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto" >
                    <h5>Success!</h5>
                    <p>Added New Member!.</p>
                </div>
            </div>
            <div className="teams form-container">
                <div className="row mb-4 mt-2">
                    {TeamPhoto()}
                </div>
                <div className="row">
                    {teamDetails()}
                </div>
                <div className="row mt-2">
                    {memberList()}
                </div>
                <div className="form-action">
                    <button className="btn btn-primary btn-large" onClick={handleSubmit} disabled={props.preload}>
                        {props.preload && <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>}
                        {props.team_details ? 'Update' : 'Add'}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
});


export default TeamFormBody;
