import React, {useEffect, useState} from 'react';
import ButtonUser from '../ButtonUser';
import ButtonUserOnly from '../ButtonUserOnly';

export  default function ProjectManagers(props) {
    const [options, setOptions] = useState(props.options);
    const [state, setState] = useState({
        loadOptions: false,
        showOptions: false,
        activePmIndex: -1,
    });

    useEffect(() => {
        const options = props.options.filter(usr => !props.projectManagers.includes(usr.id));
        setOptions(options);
    }, [props.options, JSON.stringify(props.projectManagers)]);

    const selectPS = (selected, prev) => {
        props.updateProjectManagers(selected.id, prev);
    };

    const setActivePmIndex = (index) => {
        setState((prevState) => ({
            ...prevState,
            activePmIndex: index === prevState.activePmIndex ? -1 : index
        }));
    }

    return (
        <React.Fragment>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                {props.projectManagers.length ?
                    props.projectManagers.map((id, i) =>
                        <div style={{marginRight: "10px"}}>
                            <ButtonUserOnly
                                key={`pm-${i}`}
                                action={props.viewOnly ? null : selectPS}
                                actionDelete={props.viewOnly ? null : props.removeProjectManager}
                                details={state.primaryAdetails}
                                load={state.loadPrimaryAssignee}
                                assignee={props.options.filter(opt => opt.id == id)[0]}
                                scrollUser={props.scroolUser}
                                options={options}
                                active={state.activePmIndex === (i + 1) ? true : false}
                                index={i + 1}
                                setActiveOptionIndex={setActivePmIndex}
                                viewOnly={props.viewOnly}
                            />
                        </div>
                    ) : null
                }
                {props.viewOnly ?
                    null:
                    <div style={{margin: 0}}>
                        <ButtonUser index={0} key={`pm-0`} action={selectPS} load={state.loadOptions} userIcon={props.userIcon} options={options} active={state.activePmIndex === 0 ? true : false} setActiveOptionIndex={setActivePmIndex} />
                    </div>
                }
            </div>
        </React.Fragment>
    );
}