import React, { useState, useEffect } from "react";
import Api from "../../Utils/API";
import imageIcon from "../../assets/images/inviteImage.png";
import errorImageIcon from "../../assets/images/error-img.png";
import LoadingIndicator from "../../components/LoadingIndicator";
import { connect } from "react-redux";
import { weeklyWagesCost, weeklyOtherOverhead } from "../../store/actions";

const Settings = ({Profitability, setWeeklyOtherOverhead, setWeeklyWagesCost, ...props}) => {
    const [state, setState] = useState({
        loading: false,
        weeklyWages: 0,
        weeklyOverhead: 0,
        weeklyWagesError: '',
        weeklyOverheadError: '',
        toastData: { title: '', description: '' },
        updateError: false,
    });
        
    // Render --------------------------------------------
    useEffect(() => {
        setState({
            ...state,
            weeklyWages: Profitability.weeklyWages,
            weeklyOverhead: Profitability.weeklyOtherOverhead
        });
    }, [Profitability.weeklyWages, Profitability.weeklyOtherOverhead]);

    // API -----------------------------------------------
    const updateSettings = (e) => {
        e.preventDefault();

        setState({
            ...state,
            loading: true
        });

        const params = {
            weekly_wages: state.weeklyWages,
            weekly_overhead: state.weeklyOverhead
        }

        Api.updateSettings(params).then(result => {
            setWeeklyWagesCost(state.weeklyWages);
            setWeeklyOtherOverhead(state.weeklyOverhead);
            setState({ 
                ...state,
                loading: false
            });

            showToast('Success!', 'Settings has been updated.');
        }).catch(error => {
            if (error.response.status === 422) {
                const responseData = error.response.data;

                setState({
                    ...state,
                    loading: false,
                    weeklyWages: responseData.errors['weekly_wages'][0],
                    weeklyOverhead: responseData.errors['weekly_overhead'][0]
                });
            } else {
                setState({
                    ...state,
                    loading: false
                });

                showToast('Error!', 'An error occured in updating Settings.', true);
                console.log('updateSettings > error', error);
            }
        });
    }

    // Toast ---------------------------------------------
    const showToast = (title, description, updateError = false) => {
        setState({
            ...state,
            toastData: {
                title: title,
                description: description
            },
            updateError: updateError
        });

        setTimeout(() => { closeToast() }, 2500);
    }

    const closeToast = () => {
        setState({
            ...state,
            toastData: {
                title: '',
                description: ''
            },
            updateError: false
        });
    }

    // Input Handler -------------------------------------
    const handleWeeklyWagesChange = (e) => {
        const value = e.target.value

        setState({
            ...state,
            weeklyWages: value ?? 0,
            weeklyWagesError: ''
        });
    }

    const handleWeeklyOverheadChange = (e) => {
        const value = e.target.value

        setState({
            ...state,
            weeklyOverhead: value ?? 0,
            weeklyOverheadError: ''
        });
    }

    return (
        <React.Fragment>
            {/* SNACKBAR NOTIFICATION */}
            {
                state.toastData.title !== ''
                    ? <div id="snackbar-settings" onClick={closeToast} className={`show row notification-div ${state.updateError ? 'error' : ''}`}>
                        <div className="col-5 notification-image flex-center" >
                            <img src={state.updateError ? errorImageIcon : imageIcon} />
                        </div>
                        <div className="col-7 notification-details my-auto" >
                            <h5>{state.toastData.title}</h5>
                            <p>{state.toastData.description}</p>
                        </div>
                    </div>
                    : null
            }

            {/* LOADER */}
            {state.loading && <LoadingIndicator loading={state.loading} />}

            {/* CONTENT */}
            <div className="page-content settings">
                <div className="header-title"></div>
                <div className="container-fluid container-body white-bg">
                    <form onSubmit={ state.loading ? () => {} : updateSettings } className="form-body">
                        <div className="col-lg-12">
                            <div className="contact-group">
                                <div className="row-1">Weekly Wages Cost</div>
                                <div className="row-2">
                                    <input
                                        className="form-control"
                                        placeholder="Enter Weekly Wages Cost"
                                        value={state.weeklyWages}
                                        onChange={ handleWeeklyWagesChange }
                                        type="number"
                                        min="0"
                                    />

                                    { state.weeklyWagesError && <label className="eror-fields">{state.weeklyWagesError}</label> }
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-12">
                            <div className="contact-group">
                                <div className="row-1">Weekly Other Overhead</div>
                                <div className="row-2">
                                    <input
                                        className="form-control"
                                        placeholder="Enter Weekly Other Overhead"
                                        value={state.weeklyOverhead}
                                        onChange={ handleWeeklyOverheadChange }
                                        type="number"
                                        min="0"
                                    />

                                    { state.weeklyOverheadError && <label className="eror-fields">{state.weeklyOverheadError}</label> }
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <button className="btn btn-primary btn-large" disabled={state.loading}> 
                                {state.loading && <i className="bx bx-loader bx-spin font-size-20 align-middle ml-2"></i> }
                                Update 
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = ({Profitability}) => {
    return { Profitability: Profitability };
};

export default connect(mapStatetoProps, (dispatch) => {
    return {
        setWeeklyWagesCost: (e) => dispatch(weeklyWagesCost(e)),
        setWeeklyOtherOverhead: (e) => dispatch(weeklyOtherOverhead(e))
    }
})(Settings);
