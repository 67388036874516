import React, { useState, useEffect } from "react";
import Api from "../../Utils/API";
import imageIcon from "../../assets/images/inviteImage.png";
import LoadingIndicator from "../../components/LoadingIndicator";
import DataTable from "../../components/DataTable";
import moment from 'moment';
import { connect } from "react-redux";
import DomainForm from "../../components/Forms/Domains/DomainForm";

function DomainsList(props) {
    const [stateTypeOptions, setStateTypeOptions] = useState([]);
    const [statePropertyOptions, setStatePropertyOptions] = useState([]);
    const [state, setState] = useState({
        loading: true,                 // For page progress loader purposes
        openDomainForm: false,         // Modal for adding log
        addDomain: false,              // Modal for adding Domain
        editDomain: false,             // Modal for editing Domain
        domains_list: [],              // Populate domains to datatable
        isSubmit: false,               // preloader on add/update domain
        domain_details: '',            // for updating a domain
        toastData: { title: '', description: '' },
        error: '',
    })
        
    // Render
    useEffect(() => {
        typeList();             // fill type_options
        propertyList();         // fill type_options
        domainList();              // fill list
    }, [])

    const domainList = () => {
        Api.getDomains().then(result => {
            setState({...state, domains_list: result.data, loading: false});
        }).catch(error => {
            console.log(error);
        });
    }

    // API -----------------------------------------------
    const propertyList = () => {
        Api.getAllProperties().then(result => {
            setStatePropertyOptions({...statePropertyOptions, property_options: result.data, loading: false})
        }).catch(error => {
            console.log(error);
        });
    }

    const typeList = () => {
        Api.getAllDomainTypes().then(result => {
            setStateTypeOptions({...stateTypeOptions, type_options: result.data})
        }).catch(error => {
            console.log(error);
        });
    }

    // Toast ---------------------------------------------
    const showToast = () => {
        var x = document.getElementById("snackbar-properties");
        x.className = "show row notification-div";
        setTimeout(() => { closeToast() }, 2500);
    }

    const closeToast = () => {
        var x = document.getElementById("snackbar-properties");
        x.className = x.className.replace("show", "");
    }

    // Add/Update Log ---------------------------------------
    const addDomain = (domain) => {
        setState({...state, isSubmit: true});
        
        Api.addDomain(domain).then(response => {
            let new_domain = response.data.domain, message = response.data.message;
            
            // Check for validation errors
            if (response.data.status == 'validation failed')
                return setState({...state, error: message, isSubmit: false})

            state.domains_list.unshift(new_domain);
            updateDomainList(message);
        }).catch(error => {
            console.log(error);
        });
    }

    const updateDomain = (domain) => {
        setState({...state, isSubmit: true});

        Api.updateDomain(domain).then(response => {
            let domains = state.domains_list,
                updated_domain = response.data.domain,
                message = response.data.message;

            // Check for validation errors
            if (response.data.status == 'validation failed')
                return setState({...state, error: message, isSubmit: false})

            // Update the selected domain
            const domainIndex = domains.findIndex(obj => obj.id === updated_domain.id);
            domains[domainIndex] = updated_domain;

            updateDomainList(message);
        }).catch(error => {
            console.log(error);
        });
    }

    // Update Domains List after adding/updating it on the DB --
    const updateDomainList = (message) => {
        setState({
            ...state,
            domains_list: state.domains_list,
            openDomainForm: false,
            addDomain: false,
            editDomain: false,
            isSubmit: false,
            domain_details: '',
            error: '',
            toastData: { title:'Success!', description: message} 
        });
        showToast();
    }

    const minimize = (event) => {
        event.stopPropagation();
        document.body.classList.add('minimize-form');
    }

    // Modals --------------------------------------------
    // ----- add modal -----
    const openDomain = (event, add=false, edit=false, details='') => {
        event.stopPropagation();
        event.preventDefault();
        document.body.classList.remove('minimize-form');
        setState({...state, openDomainForm: true, addDomain: add, editDomain: edit, domain_details: details, error: ''});
    }

    // ----- close modal -----
    const closeDomain = (event) => {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        setState({...state, openDomainForm: false, domain_details: '' });
    }
    
    // Datatable -------------------------------------------
    const { domains_list } = state;
    const domainsTable = {
        columns: [
            {
                label: "Domain",
                field: "domain_name"
            },
            {
                label: "Expiry Date",
                field: "expiry_date"
            },
            {
                label: "Property",
                field: "property"
            },
            {
                label: "Type",
                field: "type"
            },
            {
                label: "Date Added",
                field: "date_added"
            },
        ],
        rows: []
    };

    domains_list.forEach(item => {
        domainsTable.rows.push({
            domain_name: item.domain_name,
            expiry_date: moment(item.expiry_date).format('YY-MM-DD, hh:mm:ss'),
            property: item.property.company ? item.property.company.name : '',
            type: item.type.title,
            date_added: moment(item.created_at).format('YY-MM-DD hh:mm A'),
            clickEvent: (e) => openDomain(e, false, true, item)
        })
    });

    return (
        <React.Fragment>
            {/* SNACKBAR NOTIFICATION */}
            <div id="snackbar-properties" onClick={closeToast} className="display-none">
                <div className="col-5 notification-image flex-center" >
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto" >
                    <h5>{state.toastData.title}</h5>
                    <p>{state.toastData.description}</p>
                </div>
            </div>
            
            {/* LOADER */}
            {state.loading && <LoadingIndicator loading={state.loading} />}
            
            <div className="page-content">
                <div className="header-title-2">
                    <div className="row">

                        {/* HEADER TITLE */}
                        <div className="col-md-6"></div>

                        {/* Add Log Button */}
                        <div className="col-md-6 mb-2 text-right" style={{marginTop: -5}}>
                            <button className="btn btn-primary" onClick={(e) => openDomain(e, true)} disabled={state.isSubmit || state.loading}>
                                + Add Domain
                            </button>
                        </div>
                    </div>
                </div>

                {/* DATATABLE */}
                <div className="container-fluid container-body white-bg clickable-datatable">
                    <DataTable data={domainsTable} entriesOptions={[8, 30, 100, 500]} entries={8} scrollY={true}/>
                </div>

            </div>

            {/* FORMS FOR LOGS */}
            { state.openDomainForm &&
                <DomainForm
                    minimize={minimize}
                    open={openDomain}
                    close={closeDomain}
                    isSubmit={state.isSubmit}
                    submitButton={state.domain_details ? updateDomain : addDomain}
                    action_title={state.addDomain ? 'Add Domain' : 'Update Domain'}
                    domain_details={state.domain_details ? state.domain_details : ''}
                    type_options={stateTypeOptions.type_options}
                    property_options={statePropertyOptions.property_options}
                    error={state.error}
                />
            }

        </React.Fragment>
    );
}

const mapStatetoProps = ({Company}) => {
    return { companySearchString: Company.companySearchString };
};

export default connect(mapStatetoProps, (dispatch) => {
    return {
        // setsearch:(e)=> dispatch(searchCompanies(e))
    }
})((DomainsList));
