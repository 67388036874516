import React, { useState, useEffect } from "react";
import Api from "../../Utils/API";
import imageIcon from "../../assets/images/inviteImage.png";
import LoadingIndicator from "../../components/LoadingIndicator";
import DataTable from "../../components/DataTable";
import Avatar from "../../components/Avatar";
import { getFirstWord } from '../../Utils/helper';
import moment from 'moment';
import { connect } from "react-redux";
import { completedWork, costOfAfterSalesCare } from "../../store/actions";
import MemberForm from "../../components/Forms/Members/MemberForm";
import TeamForm from "../../components/Forms/Teams/TeamForm";
import Swal from 'sweetalert2';

function TeamsList({
    setAfterSalesCost,
    setCompletedWork,
    ...props
}) {
    const [userLevels, userLevelsState] = useState([])
    const [membersState, setMemberList] = useState([])
    const [teamsState, setTeamList] = useState([])
    const [state, setState] = useState({
        propertyType: 'members',    // Property Type
        loading: true,              // For page progress loader purposes
        preload: false,             // Preloader for adding/updating member or team (Button)
        openTeamForm: false,        // Modal for adding team
        openMemberForm: false,      // Modal for adding member
        updateMember: false,        // Modal for updating Member
        updateTeam: false,          // Modal for updating team
        team_options: [],           // Add Member > Select Team
        member_details: '',         // for updating a member
        team_details: '',           // for updating a team
        toastData: { title: '', description: '' },
    })

    useEffect(() => {
        userLevelList();
        if(state.propertyType == 'members')
            teamList();       // initial render, get teams first to add on teams_options[]
    }, [])
        
    // re-render everytime property type changes
    useEffect(() => {
        state.propertyType == 'members' ? memberList() : teamList();
    }, [state.propertyType])

    const userLevelList = () => {
        Api.getUserLevels().then(result => {
            userLevelsState(result.data)
        })
    }
    
    // Lists
    const teamList = () => {
        Api.getTeams().then(result => {
            let teams_options = [];
            const teams = result.data;
            setTeamList(teams)

            // Popuplate Team Options Array
            teams.map(team => {
                teams_options.push({
                    id: team.id,
                    value: team.id,
                    label: team.team_name
                });
            });
            setTimeout(() => { setState({...state, team_options: teams_options, loading: false}); }, 1000)
        }).catch(error => {
            console.log(error)
        })
    }

    const memberList = () => {
        Api.getMembers().then(result => {
            const members = result.data;
            setState({...state, loading: false})
            setMemberList(members)
        }).catch(error => {
            console.log(error)
        })

    }

    // Toast -------------------------------------------
    const showToast = () => {
        var x = document.getElementById("snackbar-properties");
        x.className = "show row notification-div";
        setTimeout(() => { closeToast() }, 2500);
    }

    const closeToast = () => {
        var x = document.getElementById("snackbar-properties");
        x.className = x.className.replace("show", "");
    }

    // Add/Update Member ---------------------------------------
    const addMember = (member) => {
        setState({...state, preload: true})

        Api.addMember(member).then(result => {
            const new_member = result.data;
            
            setMemberList([new_member, ...membersState])
            setState({
                ...state,
                openMemberForm: false,
                addMember: false,
                preload: false,
                toastData: { title:'Success!', description: 'You successfully added a new member!'}
            })
            showToast();
        }).catch(error => {
            console.log(error);
            setState({...state, preload: false})
            swalAlert('Error!', error.response.data.message, 'error');
        })
    }

    const editMember = (member) => {
        setState({...state, preload: true})
        Api.updateMember(member).then(result => {
            let updated_member = result.data;
            let index = membersState.findIndex(item => item.id === updated_member.id)

            if(updated_member.rate != membersState[index].rate) {
                getAfterSalesCareCost();
                getCompletedWork();
            }

            membersState[index] = updated_member;

            setMemberList(membersState);
            setState({
                ...state, 
                openMemberForm: false, 
                updateMember: false, 
                member_details: '', 
                preload: false,
                toastData: { title:'Success!', description: 'You successfully update the member!'} 
            })

            showToast();
        }).catch(error => {
            console.log(error)
            setState({...state, preload: false})
            swalAlert('Error!', 'Hubstaff user id not found!', 'error');
        })
    }

    const getAfterSalesCareCost = async() => {
        await Api.getAfterSalesCareCost().then(({data}) => {
            setAfterSalesCost({
                hours: data.hours,
                amount: data.amount
            });
        }).catch(error => {
            console.log('compnaies-profile > getCompletedWork > error', error);
        });
    }

    const getCompletedWork = async() => {
        await Api.getCompletedWork().then(({data}) => {
            setCompletedWork({
                hours: data.hours,
                amount: data.amount
            });
        }).catch(error => {
            console.log('compnaies-profile > getCompletedWork > error', error);
        });
    }

    const swalAlert = (title, message, icon) => {
        Swal.fire({
            title: title,
            text: message,
            icon: icon,
            confirmButtonText: 'Close'
        });
    }

    // Add/Update Team -----------------------------------------
    const addTeam = (team) => {
        setState({...state, preload: true})

        Api.addTeam(team).then(result => {
            const new_team = result.data;

            setTeamList([new_team, ...teamsState])
            setState({
                ...state,
                openTeamForm: false,
                addTeam: false,
                preload: false,
                toastData: { title:'Success!', description: 'You successfully added a new team!'}
            })
            showToast();
        }).catch(error => {
            console.log(error);
            setState({...state, preload: false})
            swalAlert('Error!', "There's an error when adding a team", 'error');
        })
    }

    const editTeam = (team) => {
        setState({...state, preload: true})

        Api.updateTeam(team).then(result => {
            let updated_team = result.data;
            let index = teamsState.findIndex(item => item.id === updated_team.id)
            teamsState[index] = updated_team;

            setTeamList(teamsState);
            setState({
                ...state, 
                openTeamForm: false, 
                updateTeam: false, 
                team_details: '', 
                preload: false,
                toastData: { title:'Success!', description: 'You successfully updated the team!'} })
            showToast();
        }).catch(error => {
            console.log(error)
            setState({...state, preload: false})
            swalAlert('Error!', "There's an error when updating the team", 'error');
        })
    }

    const minimize = (event) => {
        event.stopPropagation();
        document.body.classList.add('minimize-form');
    }

    // Modals --------------------------------------------
    // ----- Open modal -----
    const openMember = (event) => {
        event.stopPropagation();
        event.preventDefault();
        document.body.classList.remove('minimize-form');
        setTimeout(() => { 
            setState({
                ...state, 
                openMemberForm: true, 
                openTeamForm: false, 
                addMember: true, 
                updateMember: false, 
                updateTeam: false, 
                member_details: ''
            }) 
        }, 100);
    }

    const openTeam = (event) => {
        event.stopPropagation();
        event.preventDefault();
        document.body.classList.remove('minimize-form');
        setTimeout(() => { 
            setState({
                ...state, 
                openMemberForm: false, 
                openTeamForm: true, 
                addTeam: true, 
                updateMember: false, 
                updateTeam: false, 
                member_details: ''
            }) 
        }, 100);
    }

    // ----- update modal -----
    const updateMember = (event, details) => {
        event.stopPropagation();
        event.preventDefault();
        console.log(details);
        document.body.classList.remove('minimize-form');
        setTimeout(() => { 
            setState({
                ...state, 
                openMemberForm: true, 
                openTeamForm: false, 
                addMember: false, 
                updateMember: true, 
                updateTeam: false, 
                member_details: details
            }) 
        }, 100);
    }

    const updateTeam = (event, details) => {
        event.stopPropagation();
        event.preventDefault();
        console.log(details);
        document.body.classList.remove('minimize-form');
        setTimeout(() => { 
            setState({
                ...state,
                openMemberForm: false,
                openTeamForm: true,
                addTeam: false,
                updateMember: true,
                updateTeam: false,
                team_details: details
            }) 
        }, 100);
    }

    // ----- close modal -----
    const closeMember = (event) => {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        setState({...state, openMemberForm: false, member_details: '' });
    }

    const closeTeam = (event) => {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
        setState({...state, openTeamForm: false, team_details: ''});
    }
    
    // Datatable -------------------------------------------
    // const { members_list, teams_list } = state;
    const members_list = membersState;
    const teams_list = teamsState;
    const membersTable = {
        columns: [
            {
                label: "Name",
                field: "name",
            },
            {
                label: "Email",
                field: "email",
            },
            {
                label: "Mobile",
                field: "mobile",
            },
            {
                label: "Phone",
                field: "phone",
            },
            {
                label: "Team",
                field: "team",
            },
            {
                label: "User Level",
                field: "user_level",
            },
            {
                label: "Action",
                field: "action",
            },
        ],
        rows: []
    };
    
    const teamsTable = {
        columns: [
            {
                label: "Team Name",
                field: "name",
            },
            {
                label: "Description",
                field: "description",
            },
            {
                label: "Members",
                field: "members",
            },
            {
                label: "Date Created",
                field: "date_created",
            },
            {
                label: "Action",
                field: "action",
            },
        ],
        rows: []
    };
    
    members_list.forEach(item => {
        membersTable.rows.push({
            name: <div  className="container-profile">
                    <div className="row-1">
                        <Avatar
                            name={` ${getFirstWord(item.firstname)} `}
                            round={true}
                            size="32"
                            color="#f44336"
                            image={item.photo ? item.photo.path : ''}
                            className="btn-cirle-avatar"
                        />
                    </div>
                    <div className="row-2 item-center flex-row member_name">
                        {item.firstname} {item.surname}
                    </div>
                </div>,
            email: item.email,
            mobile: item.mobile,
            phone: item.phone,
            team: <div>
                    <span className="team_name">{item.team ? item.team.team_details.team_name : 'No Team'}</span><br/>
                    {item.team ? <p className="team_members_count">{item.team.team_details.members} Members</p> : null }
                </div>,
            user_level: <span className="user_level_td" style={{textTransform: 'capitalize'}}>{item.user_level.title}</span>,
            action: <button onClick={(e) => updateMember(e, item)} className="btn-sm action-btn edit"><i className="fa fa-pencil-alt" ></i></button>
        })
    });

    teams_list.forEach(item => {
        teamsTable.rows.push({
            name: 
                <div className="container-profile">
                    <div className="row-1">
                        <Avatar
                            name={` ${getFirstWord(item.team_name)} `}
                            round={true}
                            size="32"
                            color="#f44336"
                            image={item.photo ? item.photo.path : ''}
                            className="btn-cirle-avatar"
                        />
                    </div>
                    <div className="row-2 item-center flex-row member_name">
                        {item.team_name}
                    </div>
                </div>,
            description: item.description,
            members: `${item.members.length} Members`,
            date_created: moment(item.created_at).format('MM-DD-YY hh:mm A'),
            action: <button onClick={(e) => updateTeam(e, item)} className="btn-sm action-btn edit"><i className="fa fa-pencil-alt" ></i></button>
        })
    });

    return (
        <React.Fragment>
            {/* SNACKBAR NOTIFICATION */}
            <div id="snackbar-properties" onClick={closeToast} className="display-none">
                <div className="col-5 notification-image flex-center" >
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto" >
                    <h5>{state.toastData.title}</h5>
                    <p>{state.toastData.description}</p>
                </div>
            </div>
            
            {/* LOADER */}
            {state.loading && <LoadingIndicator loading={state.loading} />}
            
            <div className="page-content">
                <div className="header-title-2">
                    <div className="row">

                        {/* HEADER TITLE */}
                        <div className="col-md-6">
                            <button className={`btn btn-properties-tab ${state.propertyType == 'members' ? 'active' : ''}`} onClick={() => setState({...state, propertyType: 'members' })} >
                                Members
                            </button>
                            <button className={`btn btn-properties-tab ${state.propertyType == 'teams' ? 'active' : ''}`}  onClick={() => setState({...state, propertyType: 'teams' })}>
                                Teams
                            </button>
                        </div>

                        {/* DROPDOWN BUTTON "ADD PROPERTIES" */}
                        <div className="col-md-6 text-right" style={{marginTop: -5}}>
                            <button className="btn btn-primary" onClick={state.propertyType == 'members' ? openMember : openTeam} disabled={state.preload}>
                                {`Add ${state.propertyType == 'members' ? 'Member' : 'Team'}`}
                            </button>
                        </div>
                    </div>
                </div>

                {/* DATATABLE */}
                <div className="container-fluid container-body white-bg">
                    {state.propertyType == 'members' ? (
                        <DataTable data={membersTable} entriesOptions={[8, 30, 100, 500]} entries={8}/>
                    ) : (
                        <DataTable data={teamsTable} entriesOptions={[8, 30, 100, 500]} entries={8}/>
                    )}
                </div>

            </div>

            {/* FORMS FOR MEMBERS */}
            { state.openMemberForm &&
                <MemberForm
                    minimize={minimize}
                    open={openMember}
                    close={closeMember}
                    submitButton={state.member_details ? editMember : addMember}
                    action_title={state.addMember ? 'Add Member' : 'Update Member'}
                    team_options={state.team_options}
                    userLevels={userLevels}
                    member_details={state.member_details ? state.member_details : ''}
                    preload={state.preload}
                />
            }

            {/* FORMS FOR TEAM */}
            { state.openTeamForm &&
                <TeamForm
                    minimize={minimize}
                    open={openTeam}
                    close={closeTeam}
                    submitButton={state.team_details ? editTeam : addTeam}
                    action_title={state.addTeam ? 'Add Team' : 'Update Team'}
                    team_details={state.team_details ? state.team_details : ''}
                    members_list={membersState}
                    preload={state.preload}
                />
            }
        </React.Fragment>
    );
}

const mapStatetoProps = ({Company}) => {
    return { companySearchString: Company.companySearchString };
};

export default connect(mapStatetoProps, (dispatch) => {
    return {
        // setsearch:(e)=> dispatch(searchCompanies(e))
        setAfterSalesCost: (e) => dispatch(costOfAfterSalesCare(e)),
        setCompletedWork: (e) => dispatch(completedWork(e))
    }
})((TeamsList));
