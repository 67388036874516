import React, { useEffect, useRef, useState } from "react";
import Api from "../../Utils/API";
import moment from "moment";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import blueArchiveIcon from "../../assets/images/archive-blue.svg";
import InternalNote from "../../components/InternalNote";
import PublicReply from "../../components/PublicReply";
import imageIcon from "../../assets/images/inviteImage.png";
import { connect } from "react-redux";
import NewEmail from "../../components/NewEmail";
import ConfirmArchive from "../../components/ConfirmArchive";
import ConfirmDelete from "../../components/ConfirmDelete";
import Swal from "sweetalert2";
import styled from "styled-components";
import DataTable from "../../components/DataTable";
import EmailThread from "./email-thread";
import SendProposal from "../../components/SendProposal";
import InboxFollower from "../../components/InboxFollower";
import { Spinner } from "reactstrap";

const ArchiveButton = styled.img`
  color: #000;
  height: 100%;
  margin-right: 10px;
  :hover {
    cursor: pointer;
  }
`;

const EmailInbox = (props) => {
  const [state, setState] = useState({
    loading: true,
    addRequest: false,
    isInternal: false,
    isPublicReply: false,
    isProposal: false,
    addRequest: false,
    newEmail: false,
    NewticketRequest: false,
    selectedEmail: [],
    markedEmails: [],
    emails: [],
    allUser: [],
    allContacts: [],
    adminCoderOptions: [],
    emailType: 1,
    toastData: { title: "", description: "" },
    addRequestRequestor: null,
    pricingMethodOptions: [],
    allCheckEmail: false,
    disableAddRequest: false,
    disableAddRequestMessage: "",
    requestDetails: [],
    isAddRequest: false,
    currentPage: null,
    lastPage: null,
    fromIndex: null,
    toIndex: null,
    lastPageUrl: null,
    nextPageUrl: null,
    prevPageUrl: null,
    perPage: null,
    nEmails: null,
    path: null,
  });
  // const prevState = usePrevious(props.inboxSearchString);
  const myRefPublic = useRef(null);

  useEffect(() => {
    allContacts();
    getAdminCoderOptions();
    getPricingMethodOptions();
    allUser();
  }, []);

  useEffect(() => {
    search();
  }, [props.inboxSearchString]);

  const search = () => {
    Api.searchInbox(props.inboxSearchString)
      .then((result) => {
        const res = result.data;

        setState((prevState) => ({
          ...prevState,
          emails: res.data,
          currentPage: res.current_page,
          lastPage: res.last_page,
          fromIndex: res.from,
          toIndex: res.to,
          firstPageUrl: res.first_page_url,
          lastPageUrl: res.last_page_url,
          nextPageUrl: res.next_page_url,
          prevPageUrl: res.prev_page_url,
          perPage: res.per_page,
          nEmails: res.total,
          path: res.path,
          loading: false,
          allCheckEmail: false,
          markedEmails: [],
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const allUser = () => {
    Api.allUser()
      .then((result) => {
        setState((prevState) => ({ ...prevState, allUser: result.data }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const allContacts = () => {
    Api.getContacts()
      .then((result) => {
        // console.log('coders' , result.data);
        setState((prevState) => ({ ...prevState, allContacts: result.data }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAdminCoderOptions = () => {
    Api.getAdminAndCoders()
      .then((result) => {
        // console.log('coders' , result.data);
        setState((prevState) => ({
          ...prevState,
          adminCoderOptions: result.data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPricingMethodOptions = () => {
    Api.getPricingMethodOptions()
      .then((result) => {
        setState((prevState) => ({
          ...prevState,
          pricingMethodOptions: result.data,
        }));
      })
      .catch((error) => {
        console.log("ERROR: email-inbox > getPricingMethodOptions", error);
      });
  };

  const setEmailAssignee = (emailId, userId) => {
    Api.setEmailAssignee(emailId, userId)
      .then((result) => {
        console.log("set email assignee: success");
        const { emails } = state;
        const email = emails.filter((opt) => opt.id === emailId)[0];
        const emailIndex = emails.findIndex((obj) => obj.id == email.id);
        email.assignee_id = userId;
        emails.splice(emailIndex, 1, email);
        setState((prevState) => ({ ...prevState, emails }));
      })
      .catch((error) => {
        console.log("ERROR: email-inbox > setEmailAssignee", error);
      });
  };

  const removeEmailAssignee = (emailId) => {
    Api.removeEmailAssignee(emailId)
      .then((result) => {
        console.log("remove email assignee: success");
        const { emails } = state;
        const email = emails.filter((opt) => opt.id === emailId)[0];
        const emailIndex = emails.findIndex((obj) => obj.id == email.id);
        email.assignee_id = null;
        emails.splice(emailIndex, 1, email);
        setState((prevState) => ({ ...prevState, emails }));
      })
      .catch((error) => {
        console.log("ERROR: email-inbox > removeEmailAssignee", error);
      });
  };

  const addEmailFollower = (emailId, userId) => {
    Api.addEmailFollower(emailId, userId)
      .then((result) => {
        console.log("add email follower: success");
        const { emails } = state;
        const email = emails.filter((opt) => opt.id === emailId)[0];
        const emailIndex = emails.findIndex((obj) => obj.id == email.id);
        email.active_followers = result.data;
        emails.splice(emailIndex, 1, email);
        setState((prevState) => ({ ...prevState, emails }));
      })
      .catch((error) => {
        console.log("ERROR: email-inbox > addEmailFollower", error);
      });
  };

  const removeEmailFollower = (emailId, userId) => {
    Api.removeEmailFollower(emailId, userId)
      .then((result) => {
        console.log("remove email follower: success");
        const { emails } = state;
        const email = emails.filter((opt) => opt.id === emailId)[0];
        const emailIndex = emails.findIndex((obj) => obj.id == email.id);
        email.active_followers = result.data;
        emails.splice(emailIndex, 1, email);
        setState((prevState) => ({ ...prevState, emails }));
      })
      .catch((error) => {
        console.log("ERROR: email-inbox > removeEmailFollower", error);
      });
  };

  const replaceEmailFollower = (emailId, userIdCurr, userIdNew) => {
    Api.replaceEmailFollower(emailId, userIdCurr, userIdNew)
      .then((result) => {
        console.log("replace email follower: success");
        const { emails } = state;
        const email = emails.filter((opt) => opt.id === emailId)[0];
        const emailIndex = emails.findIndex((obj) => obj.id == email.id);
        email.active_followers = result.data;
        emails.splice(emailIndex, 1, email);
        setState((prevState) => ({ ...prevState, emails }));
      })
      .catch((error) => {
        console.log("ERROR: email-inbox > replaceEmailFollower", error);
      });
  };

  const setSelectedEmail = (e) => {
    console.log(e);
    if (state.selectedEmail) {
      let disableAddRequest = state.disableAddRequest;
      let disableAddRequestMessage = state.disableAddRequestMessage;

      Api.checkContactCompany([e.from, e.to])
        .then((result) => {
          setState((prevState) => ({
            ...prevState,
            addRequestRequestor: result.data,
            selectedEmail: e,
            NewticketRequest: false,
            disableAddRequest: false,
            disableAddRequestMessage: "",
          }));
          setTaskDetails(e);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            console.log(error.response);
            disableAddRequest = true;
            disableAddRequestMessage = error.response.data;
          } else {
            console.log(error);
            disableAddRequest = true;
            disableAddRequestMessage = error;
          }

          setState((prevState) => ({
            ...prevState,
            selectedEmail: e,
            NewticketRequest: false,
            disableAddRequest,
            disableAddRequestMessage,
          }));
        });
    }
  };

  const setTaskDetails = (selectedEmail) => {
    if (selectedEmail.task_id) {
      Api.taskDetails(selectedEmail.task_id)
        .then(async (result) => {
          return setState((prevState) => ({
            ...prevState,
            id: state.task_id,
            requestDetails: result.data.content ?? [],
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return setState((prevState) => ({
        ...prevState,
        id: state.task_id,
        requestDetails: [],
      }));
    }
  };

  const addRequestFunction = () => {
    setState({ ...state, isAddRequest: true });
    const requestor = state.addRequestRequestor;
    console.log("addRequestFunction", requestor);
    const { selectedEmail } = state;

    let params = {
      title: selectedEmail.subject,
      type: "request",
      email_id: selectedEmail?.id ?? 0,
      description: selectedEmail?.body,
      requestor_email: requestor,
    };

    Api.addTaskCompany(params)
      .then((result) => {
        console.log("api return", result.data);
        newRequest(result.data);
        setTaskDetails(selectedEmail);
      })
      .catch((error) => {
        setState({ ...state, isAddRequest: false });
        console.log(error);
      });
  };

  const minimizeNewEmail = (event) => {
    event.stopPropagation();
    document.body.classList.add("minimize-form");
  };

  const newRequest = (e) => {
    console.log(e);
    let temp = state.selectedEmail;
    temp.task_id = e.task.task_id;
    temp.replies.unshift(e.task);
    console.log(temp);
    setState((prevState) => ({
      ...prevState,
      NewticketRequest: true,
      addRequest: false,
      isAddRequest: false,
      toastData: {
        title: "Success!",
        description: `You successfully created a new request!.`,
      },
    }));
    showToast();
  };

  const addEmail = (email) => {
    let emails = email.concat(state.emails);

    setState((prevState) => ({
      ...prevState,
      emails: emails,
      newEmail: false,
      isSubmit: false,
      toastData: {
        title: "Success!",
        description: "You successfully created a new request!",
      },
    }));
    console.log(state.emails);
    showToast();
  };

  const showToast = () => {
    var x = document.getElementById("snackbar-email");

    if (x) {
      x.className = "show row notification-div";
      setTimeout(() => {
        closeToast();
      }, 5000);
    }
  };

  const closeToast = () => {
    var x = document.getElementById("snackbar-email");

    if (x) {
      x.className = x.className.replace("show", "");
    }
  };

  const scroll = (ref, type) => {
    if (type === 1)
      setState((prevState) => ({
        ...prevState,
        isPublicReply: true,
        isInternal: false,
        isProposal: false,
      }));

    if (type === 2)
      setState((prevState) => ({
        ...prevState,
        isPublicReply: false,
        isInternal: true,
        isProposal: false,
      }));

    if (type === 3)
      setState({
        ...state,
        id: state.task_id,
        requestDetails: state.requestDetails,
        isPublicReply: false,
        isInternal: false,
        isProposal: true,
      });

    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const newPublicReply = (e) => {
    let selectedEmail = state.selectedEmail;
    selectedEmail.replies.unshift(e);
    setState((prevState) => ({
      ...prevState,
      isPublicReply: false,
      toastData: {
        title: "Success!",
        description: `Public Reply successfully submitted!.`,
      },
      selectedEmail: selectedEmail,
    }));
    showToast();
  };

  const newInternal = (e) => {
    let selectedEmail = state.selectedEmail;
    selectedEmail.replies.unshift(e);
    setState((prevState) => ({
      ...prevState,
      isInternal: false,
      toastData: {
        title: "Success!",
        description: `Internal Note successfully submitted!.`,
      },
      selectedEmail: selectedEmail,
    }));
    showToast();
  };

  const newProposal = (e) => {
    setState((prevState) => ({
      ...prevState,
      isProposal: false,
      toastData: {
        title: "Success!",
        description: `Proposal successfully sent!.`,
      },
    }));
    showToast();
  };

  const confirmDeleteEmail = () => {
    let confirm_details = { page: "email request" };

    ConfirmDelete(confirm_details).then((res) => {
      if (res.isConfirmed) deleteEmail();
    });
  };

  const deleteEmail = () => {
    let array = [...state.emails]; // make a separate copy of the array
    const index = array.indexOf(state.selectedEmail);
    if (index !== -1) {
      array.splice(index, 1);
      Api.deleteEmail(state.selectedEmail.id)
        .then((result) => {
          const se = [];
          Swal.fire("Deleted!", "Email has been deleted.", "success");
          setState((prevState) => ({
            ...prevState,
            selectedEmail: se,
            emails: array,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const newEmail = (event) => {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    setState((prevState) => ({ ...prevState, newEmail: true }));
  };

  const closeNewEmail = (event) => {
    event.stopPropagation();
    setState((prevState) => ({ ...prevState, newEmail: false }));
  };

  const confirmArchiveEmails = (isMultiple = true) => {
    let confirm_details = {};

    if (isMultiple) {
      confirm_details = { nemails: state.markedEmails.length };
    } else {
      confirm_details = { nemails: 1 };
    }

    ConfirmArchive(confirm_details).then((res) => {
      if (res.isConfirmed) {
        if (isMultiple) {
          archiveEmails();
        } else {
          archiveEmail();
        }
      }
    });
  };

  const toggleEmail = (e, email) => {
    e.stopPropagation();
    const markedEmails = state.markedEmails;
    const { emails } = state;

    // check if id is already in the markedEmails array
    const index = markedEmails.indexOf(email.id);

    if (index === -1) {
      // add to markedEmails
      markedEmails.push(email.id);
    } else {
      // remove from markedEmails
      markedEmails.splice(index, 1);
    }

    state.allCheckEmail = markedEmails.length == emails.length ? true : false;
    email.isChecked = !email.isChecked;
    setState((prevState) => ({ ...prevState, markedEmails: markedEmails }));
  };

  const toggleAllEmail = (e, emails) => {
    e.stopPropagation();
    const emailCheck = e.target.checked;
    const markedEmails = state.markedEmails;

    emails.forEach((email) => {
      const index = markedEmails.indexOf(email.id);
      emailCheck ? markedEmails.push(email.id) : markedEmails.splice(index, 1); // add or remove to markedEmails depending on emailCheck's value
      email.isChecked = emailCheck; // update isChecked value on each email
      state.allCheckEmail = emailCheck; // update check all value
    });

    setState((prevState) => ({ ...prevState, markedEmails: markedEmails }));
  };

  // archive single email
  const archiveEmail = () => {
    let emails = [];
    emails.push(state.selectedEmail.id);

    let array = [...state.emails]; // make a separate copy of the array
    const index = array.indexOf(state.selectedEmail);

    if (index !== -1) {
      array.splice(index, 1);
      Api.archiveEmails(emails)
        .then((result) => {
          const se = [];
          Swal.fire(
            "Archived!",
            "Email has been archived successfully.",
            "success"
          );
          array.splice(index, 1);
          setState((prevState) => ({
            ...prevState,
            selectedEmail: se,
            emails: array,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // archive 1 or more emails
  const archiveEmails = () => {
    const me = [];
    let emails = [];
    emails = state.markedEmails;
    let array = [...state.emails]; // make a separate copy of the array
    let indices = [];

    Api.archiveEmails(emails)
      .then((result) => {
        Swal.fire(
          "Archived!",
          "Marked Emails have been archived successfully.",
          "success"
        );

        // remove from emails
        array.forEach((element, index) => {
          if (emails.indexOf(element.id) > -1) {
            indices.push(index);
          }
        });

        indices.sort(function (a, b) {
          return b - a;
        }); // sort indices array in descending order
        indices.forEach((element) => {
          array.splice(element, 1); // splice
        });

        const temp = array;
        let cbs = document.querySelectorAll(".email-checkbox");

        // var checks = document.querySelectorAll('#' + divid + ' input[type="checkbox"]');
        for (let i = 0; i < cbs.length; i++) {
          var cb = cbs[i];
          if (!cb.disabled) cb.checked = false;
        }

        setState((prevState) => ({
          ...prevState,
          markedEmails: me,
          emails: temp,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectEmail = (email, i) => {
    setSelectedEmail(email);
    let params = {
      id: email.id,
    };

    state.emails[i].seen = true;
    Api.readEmail(params)
      .then((result) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const { emails, emailType } = state;

  const dataTable = {
    columns: [
      {
        label: (
          <input
            className="email-checkbox"
            id="select-all"
            checked={state.allCheckEmail}
            type="checkbox"
            onChange={(e) => toggleAllEmail(e, emails)}
          />
        ),
        field: "checkbox",
      },
      {
        label: "Subject",
        field: "subject",
      },
      {
        label: "Requestor",
        field: "requestor",
      },
      {
        label: "Requested",
        field: "requested",
        width: 150,
      },
      {
        label: "Assignee",
        field: "assignee",
        width: 150,
      },
      {
        label: "Follower",
        field: "follower",
        width: 150,
      },
      {
        label: "Last Updated",
        field: "last_updated",
        width: 150,
      },
    ],
    rows: [],
  };

  for (let index = 0; index < emails?.length; index++) {
    if (emailType == 1) {
      dataTable.rows.push({
        checkbox: (
          <input
            className="email-checkbox"
            type="checkbox"
            value={emails[index].id}
            checked={emails[index].isChecked}
            onClick={(e) => toggleEmail(e, emails[index])}
          />
        ),
        subject: (
          <div
            className={`subject-container ${
              emails[index].seen ? "" : "email-seen"
            }`}>
            {emails[index].seen ? null : (
              <i className="fas fa-circle inbox-seen"></i>
            )}
            <span
              className={`mb-1 email-subject ${
                emails[index].seen ? "" : "email-seen"
              } `}>
              <Link to="#" className="text-dark">
                {emails[index].subject}
              </Link>
            </span>
          </div>
        ),
        requestor: (
          <span className={`${emails[index].seen ? "" : "email-seen"}`}>
            {emails[index].from}
          </span>
        ),
        requested: (
          <span className={`${emails[index].seen ? "" : "email-seen"}`}>
            {emails[index].created_at === null
              ? ""
              : moment(emails[index].created_at).format("DD/MM/YYYY hh:mm A")}
          </span>
        ),
        // assignee: <div className="col-6" onClick={e => {e.stopPropagation();}} key={'assignee-' + index}><InboxFollower type="assignee" followers={emails[index].active_followers} set={setEmailAssignee} remove={removeEmailAssignee} load={false} emailId={emails[index].id} assigneeId={emails[index].assignee_id} coders={state.allUser.filter(opt => opt.role === 'coder')} spinner={Spinner} /></div>,
        assignee: (
          <AssigneeContainer
            index={index}
            followers={emails[index].active_followers}
            set={setEmailAssignee}
            remove={removeEmailAssignee}
            emailId={emails[index].id}
            assigneeId={emails[index].assignee_id}
          />
        ),
        follower: (
          <FollowerContainer
            followers={emails[index].active_followers}
            exclude={emails[index].assignee_id}
            emailId={emails[index].id}
            coders={state.allUser.filter((opt) => opt.role === "coder")}
          />
        ),
        last_updated: (
          <span className={`${emails[index].seen ? "" : "email-seen"}`}>
            {emails[index].updated_at === null
              ? ""
              : moment(emails[index].updated_at).format("DD/MM/YYYY hh:mm A")}
          </span>
        ),
        clickEvent: () => selectEmail(emails[index], index),
      });
    } else if (emailType == 2) {
      if (
        emails[index].to.includes("@jiffiwebhelp") ||
        emails[index].from.includes("@jiffiwebhelp")
      ) {
        dataTable.rows.push({
          checkbox: (
            <input
              className="email-checkbox"
              type="checkbox"
              value={emails[index].id}
              checked={emails[index].isChecked}
              onClick={(e) => toggleEmail(e, emails[index])}
            />
          ),
          subject: (
            <div
              className={`subject-container ${
                emails[index].seen ? "" : "email-seen"
              }`}>
              {emails[index].seen ? null : (
                <i className="fas fa-circle inbox-seen"></i>
              )}
              <span
                className={`mb-1 email-subject ${
                  emails[index].seen ? "" : "email-seen"
                } `}>
                <Link to="#" className="text-dark">
                  {emails[index].subject}
                </Link>
              </span>
            </div>
          ),
          requestor: (
            <span className={`${emails[index].seen ? "" : "email-seen"}`}>
              {emails[index].from}
            </span>
          ),
          requested: (
            <span className={`${emails[index].seen ? "" : "email-seen"}`}>
              {emails[index].created_at === null
                ? ""
                : moment(emails[index].created_at).format("DD/MM/YYYY hh:mm A")}
            </span>
          ),
          last_updated: (
            <span className={`${emails[index].seen ? "" : "email-seen"}`}>
              {emails[index].updated_at === null
                ? ""
                : moment(emails[index].updated_at).format("DD/MM/YYYY hh:mm A")}
            </span>
          ),
          clickEvent: () => selectEmail(emails[index], index),
        });
      }
    } else if (emailType == 3) {
      if (
        emails[index].to.includes("@nucleus") ||
        emails[index].from.includes("@nucleus")
      ) {
        dataTable.rows.push({
          checkbox: (
            <input
              className="email-checkbox"
              type="checkbox"
              value={emails[index].id}
              checked={emails[index].isChecked}
              onClick={(e) => toggleEmail(e, emails[index])}
            />
          ),
          subject: (
            <div
              className={`subject-container ${
                emails[index].seen ? "" : "email-seen"
              }`}>
              {emails[index].seen ? null : (
                <i className="fas fa-circle inbox-seen"></i>
              )}
              <span
                className={`mb-1 email-subject ${
                  emails[index].seen ? "" : "email-seen"
                } `}>
                <Link to="#" className="text-dark">
                  {emails[index].subject}
                </Link>
              </span>
            </div>
          ),
          requestor: (
            <span className={`${emails[index].seen ? "" : "email-seen"}`}>
              {emails[index].from}
            </span>
          ),
          requested: (
            <span className={`${emails[index].seen ? "" : "email-seen"}`}>
              {emails[index].created_at === null
                ? ""
                : moment(emails[index].created_at).format("DD/MM/YYYY hh:mm A")}
            </span>
          ),
          last_updated: (
            <span className={`${emails[index].seen ? "" : "email-seen"}`}>
              {emails[index].updated_at === null
                ? ""
                : moment(emails[index].updated_at).format("DD/MM/YYYY hh:mm A")}
            </span>
          ),
          clickEvent: () => selectEmail(emails[index], index),
        });
      }
    } else {
      // for emails that do not belong to jiffi nor nucleus
      if (
        !emails[index].to.includes("@jiffiwebhelp") &&
        !emails[index].from.includes("@jiffiwebhelp") &&
        !emails[index].to.includes("@nucleus") &&
        !emails[index].from.includes("@nucleus")
      ) {
        dataTable.rows.push({
          checkbox: (
            <input
              className="email-checkbox"
              type="checkbox"
              value={emails[index].id}
              checked={emails[index].isChecked}
              onClick={(e) => toggleEmail(e, emails[index])}
            />
          ),
          subject: (
            <div
              className={`subject-container ${
                emails[index].seen ? "" : "email-seen"
              }`}>
              {emails[index].seen ? null : (
                <i className="fas fa-circle inbox-seen"></i>
              )}
              <span
                className={`mb-1 email-subject ${
                  emails[index].seen ? "" : "email-seen"
                } `}>
                <Link to="#" className="text-dark">
                  {emails[index].subject}
                </Link>
              </span>
            </div>
          ),
          requestor: (
            <span className={`${emails[index].seen ? "" : "email-seen"}`}>
              {emails[index].from}
            </span>
          ),
          requested: (
            <span className={`${emails[index].seen ? "" : "email-seen"}`}>
              {emails[index].created_at === null
                ? ""
                : moment(emails[index].created_at).format("DD/MM/YYYY hh:mm A")}
            </span>
          ),
          last_updated: (
            <span className={`${emails[index].seen ? "" : "email-seen"}`}>
              {emails[index].updated_at === null
                ? ""
                : moment(emails[index].updated_at).format("DD/MM/YYYY hh:mm A")}
            </span>
          ),
          clickEvent: () => selectEmail(emails[index], index),
        });
      }
    }
  }

  const handlePageChange = (page, url) => {
    if (page != state.currentPage) {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      Api.pageChange(url)
        .then((result) => {
          const res = result.data;
          setState((prevState) => ({
            ...prevState,
            emails: res.data,
            currentPage: res.current_page,
            fromIndex: res.from,
            toIndex: res.to,
            nextPageUrl: res.next_page_url,
            prevPageUrl: res.prev_page_url,
            loading: false,
            allCheckEmail: false,
            markedEmails: [],
          }));
        })
        .catch((error) => {
          console.log("email-inbox > handlePageChange error", error);
        });
    }
  };

  function AssigneeContainer(props) {
    return (
      <div
        className="col-6"
        onClick={(e) => {
          e.stopPropagation();
        }}
        key={"assignee-" + props.index}>
        <InboxFollower
          type="assignee"
          followers={props.followers}
          set={setEmailAssignee}
          remove={removeEmailAssignee}
          emailId={props.emailId}
          assigneeId={props.assigneeId}
          coders={state.allUser.filter((opt) => opt.role === "coder")}
          spinner={Spinner}
        />
      </div>
    );
  }

  function FollowerContainer(props) {
    return (
      <>
        <div className="row">
          {props.followers &&
            props.followers.map((follower, index) => {
              return (
                <div
                  key={`${props.emailId}-follower-${follower.id}`}
                  className={`col-6 email-follower-avatar ${
                    props.followers.length > 1 ? "mb-2" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <InboxFollower
                    type="follower"
                    followers={props.followers}
                    exclude={props.exclude}
                    set={addEmailFollower}
                    remove={removeEmailFollower}
                    replace={replaceEmailFollower}
                    key={"follower-" + index}
                    load={false}
                    emailId={props.emailId}
                    assigneeId={follower.user_id}
                    coders={state.allUser.filter((opt) => opt.role === "coder")}
                    spinner={Spinner}
                  />
                </div>
              );
            })}
          <div
            key={`${props.emailId}-follower-0`}
            className={`col-6 email-follower-avatar ${
              props.followers?.length > 1 ? "mb-2" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <InboxFollower
              type="follower"
              followers={props.followers}
              exclude={props.exclude}
              set={addEmailFollower}
              remove={removeEmailFollower}
              replace={replaceEmailFollower}
              emailId={props.emailId}
              coders={state.allUser.filter((opt) => opt.role === "coder")}
              spinner={Spinner}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      <div id="snackbar-email" onClick={closeToast} className="display-none">
        <div className="col-5 notification-image flex-center">
          <img src={imageIcon} />
        </div>
        <div className="col-7 notification-details my-auto">
          <h5>{state.toastData.title}</h5>
          <p>{state.toastData.description}</p>
        </div>
      </div>
      <div className="page-content">
        <div className="header-title">
          <div className="row">
            <div className="col-md-6">
              <button
                className={`btn btn-inbox ${
                  state.emailType == 1 ? "active" : ""
                }`}
                onClick={() =>
                  setState((prevState) => ({ ...prevState, emailType: 1 }))
                }>
                All Requests
              </button>
              <button
                className={`btn btn-inbox ${
                  state.emailType == 2 ? "active" : ""
                }`}
                onClick={() =>
                  setState((prevState) => ({ ...prevState, emailType: 2 }))
                }>
                Jiffi Requests
              </button>
              <button
                className={`btn btn-inbox ${
                  state.emailType == 3 ? "active" : ""
                }`}
                onClick={() =>
                  setState((prevState) => ({ ...prevState, emailType: 3 }))
                }>
                Nucleus Requests
              </button>
            </div>
            <div className="col-md-6 text-right">
              {state.markedEmails.length > 0 && (
                <ArchiveButton
                  onClick={() => confirmArchiveEmails(true)}
                  src={blueArchiveIcon}
                  alt="inbox archive button"
                />
              )}

              <button className="btn btn-primary" onClick={newEmail}>
                <i className="fa fa-plus"></i>
                New Email
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid container-body nopadding">
          <div className="row row-email">
            <div className="col-md-7 table-col email-datatable">
              <DataTable
                data={dataTable}
                entries={state.perPage}
                responsive={true}
                paging={false}
                currentPage={state.currentPage}
                lastPage={state.lastPage}
                fromIndex={state.fromIndex}
                toIndex={state.toIndex}
                firstPageUrl={state.firstPageUrl}
                lastPageUrl={state.lastPageUrl}
                nextPageUrl={state.nextPageUrl}
                prevPageUrl={state.prevPageUrl}
                perPage={state.perPage}
                nEmails={state.nEmails}
                path={state.path}
                handlePageChange={handlePageChange.bind(this)}
              />
            </div>
            <div className="col-md-5 p-0 pr-2 row-email-details">
              {/* EMAIL THREAD */}
              <EmailThread
                selectedEmail={state.selectedEmail}
                requestDetails={state.requestDetails}
                NewticketRequest={state.NewticketRequest}
                confirmArchiveEmails={confirmArchiveEmails}
                confirmDeleteEmail={confirmDeleteEmail}
                scroll={scroll}
                // openRequest={openRequest}
                isInternal={state.isInternal}
                isProposal={state.isProposal}
                isPublicReply={state.isPublicReply}
                myRefPublic={myRefPublic}
                disableAddRequest={state.disableAddRequest}
                // disableAddRequestMessage={state.disableAddRequestMessage}
                addRequest={addRequestFunction}
                isAddRequest={state.isAddRequest}
              />

              {state.isInternal && (
                <InternalNote
                  close={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isInternal: false,
                    }))
                  }
                  selectedEmail={state.selectedEmail}
                  save={newInternal}
                />
              )}

              {state.isPublicReply && (
                <PublicReply
                  close={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isPublicReply: false,
                    }))
                  }
                  allUser={state.allUser}
                  selectedEmail={state.selectedEmail}
                  save={newPublicReply}
                  requestor={state.addRequestRequestor}
                />
              )}

              {state.isProposal && (
                <SendProposal
                  close={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isProposal: false,
                    }))
                  }
                  selectedEmail={state.selectedEmail}
                  newProposal={newProposal}
                  coders={state.adminCoderOptions}
                  disabled={!state.requestDetails.proposed_at}
                  requestor={state.addRequestRequestor}
                  requestDetails={state.requestDetails}
                  pricingMethodOptions={state.pricingMethodOptions}
                  updateRequestDetails={setTaskDetails}
                  updateList={search}
                />
              )}
              <div ref={myRefPublic}></div>
            </div>
          </div>
        </div>
      </div>

      {/* { state.addRequest &&
                <AddRequest
                    isContact={false}
                    isEmail={true}
                    minimize={minimize}
                    // open={openRequest}
                    close={closeRequest}
                    newRequest={newRequest}
                    task={[]}
                    coders={state.adminCoderOptions}
                    allUser={state.allUser}
                    contacts={[]}
                    selectedEmail={state.selectedEmail}
                    requestor={state.addRequestRequestor}
                    pricingMethodOptions={state.pricingMethodOptions}
                />
            } */}

      {state.newEmail && (
        <NewEmail
          minimize={minimizeNewEmail}
          newEmail={newEmail}
          contacts={state.allContacts}
          close={closeNewEmail}
          addEmail={addEmail}
        />
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = ({ Inbox }) => {
  return { inboxSearchString: Inbox.inboxSearchString };
};

export default connect(mapStatetoProps)(EmailInbox);
