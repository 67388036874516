import React, {useEffect} from 'react';
import publicReply from "../assets/images/publicReply.png";
import RichText from './RichText';
import Avatar from "./Avatar";
import Select from "react-select";
import Api from "../Utils/API";
import { customStyles, emailValidator } from '../Utils/helper';

export const PublicReply = React.memo(props => {   
    let userOptions = [];

    const [state, setState] = React.useState({
        isSubmit: false,
        userOptions: '',
        details: '',
        detailsError: '',
        email_id: '',
        comment: '',
        files_uploaded: [],
        comment_attachments: [],
        emailError: '',
        preload_new_user: false,
    });

    const [selectedEmail, setselectedEmail] = React.useState({
        to: [],
        cc: [],
    });

    useEffect(() => {
        let users = props.allUser;
        for (let index = 0; index < users.length; index++) {
            userOptions.push({
                value: users[index].id,
                label: `${users[index].firstname} <${users[index].email}> `,
            });

            // Auto-fill "To: " in public reply
            // if(props.selectedEmail){
            //     if(users[index].email == props.requestor) {
            if(props.selectedEmail && props.selectedEmail.sender){
                if(users[index].email == props.selectedEmail.sender.email){
                    let default_email_to = {
                        value: users[index].id,
                        label: `${users[index].firstname} <${users[index].email}> `,
                    }
                    
                    setselectedEmail({ ...selectedEmail, to: [default_email_to]})
                }
            }
        }
        
        console.log('user options: ', userOptions)
        setState({
            ...state,
            userOptions: userOptions,
            email_id: props.selectedEmail ? props.selectedEmail.id :''
        });
    }, [props]);

    function handleSubmit(event) {
        let to_input = [];
        let to = selectedEmail.to;

        if (to.length > 0) {
            for (let index = 0; index < to.length; index++) {
                to_input.push(to[index].value);
            }
        }

        let cc_input = [];
        let cc = selectedEmail.cc ? selectedEmail.cc : [];
        if (cc.length > 0) {
            for (let index = 0; index < cc.length; index++) {
                cc_input.push(cc[index].value);
            }
        }

        const {email_id, comment, details, comment_attachments} = state;
        const data = new FormData();

        if (comment_attachments.length > 0) {
            comment_attachments.forEach(attachment => {
                data.append('files[]', attachment); 
            });
        }

        if (cc_input.length > 0) {
            cc_input.forEach(cc => {
                data.append('cc[]', cc); 
            });
        }

        if (to_input.length > 0) {
            to_input.forEach(to => {
                data.append('to[]', to); 
            });
        }

        setState({ ...state, isComment: true, isSubmit: true });
        data.append('email_id', email_id)
        data.append('body', comment);
        data.append('is_public', 1);

        Api.publicReply(data).then(result => {
            props.save(result.data);
            setState({ ...state, comment: '', isSubmit: false });
        }).catch(error => {
            setState({ ...state, isSubmit: false });
            console.log(error);
        });
    }

    const handleChangeRich = (e) => {
        console.log(e);
        // setState({ ...state, details: e, clearRich: false, detailsError: '' });
        setState({ ...state, comment: e, clearRich: false, detailsError: '' });
    }

    function uploadFiles(e) {
        const files = e.target.files;
        console.log(files);
        let files_uploaded = state.files_uploaded;
        for (let index = 0; index < files.length; index++) {
            // let dataFiles = files[index];
            // dataFiles = URL.createObjectURL(files[index]);
            files_uploaded.push(files[index]);
        }

        console.log(files_uploaded)

        setState({ ...state, files_uploaded: files_uploaded });
       
        // e.preventDefault();
        // e.stopPropagation();

        // const files = e.target.files;
        // const files_uploaded = state.files_uploaded
        // for (let i = 0; i < files.length; i++) {
        //     files_uploaded.push(files[i]);
        // }
        // setState({...state, files_uploaded: files_uploaded})
    }

    function removeAttachment(event, file, index){
        let attachments = state.files_uploaded;
        attachments.splice(index, 1);
        setState({ ...state, files_uploaded: attachments });
    }

    // function onChangecc(e){
    //     setselectedEmail({ ...selectedEmail, cc: e ? e : [] })
    // }

    function onChangeto(e){
        setselectedEmail({ ...selectedEmail, to: e ? e : [] })
    }

    const handleImageRich = (e) => {
        let files = e;
        let commentFiles = state.comment_attachments;

        for (let index = 0; index < files.length; index++) {
            let dataFiles = files[index];
            dataFiles.preview = URL.createObjectURL(files[index]);
            dataFiles.formattedSize = formatBytes(files[index].size);
            commentFiles.push(dataFiles);
        }

        setState({ ...state, comment_attachments: commentFiles });
    }

    const createNewUser = (user) => {
        setState({...state, preload_new_user: true})
        const emailError = emailValidator(user);
        const firstname = user.split('@')[0];             // get the name before the '@' on email, and store it as firstname
        const params = {
            email: user,
            company_id: 1,
            firstname: firstname,
            role: 'user',
        }

        if(emailError) 
            return setState(prevState => ({...prevState, emailError: emailError, preload_new_user: false}));
        
        Api.saveUser(params).then(result => { 
            const user = result.data;
            const new_user = {
                value: user.id,
                label: `${user.firstname} <${user.email}> `,
            };

            userOptions.push(new_user);                   // add to user options
            setState({...state, userOptions: userOptions, preload_new_user: false})
        }).catch(error => {
            console.log(error)
            setState({...state, preload_new_user: false})
        });
    }

    const noOptionsMessage = (e) => {
        const user = e.inputValue;
        return (
            <React.Fragment>
                <div className="create-company" onClick={() => createNewUser(user)}>
                    { state.preload_new_user ? 
                        <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-1"></i>
                            Creating new user
                        </>
                        : 
                        <>
                            <p>Create <span style={{fontWeight: '500'}}>"{user}"</span></p>
                            {state.emailError && <label className="eror-fields">{state.emailError}</label>}
                        </>
                    }
                </div>
            </React.Fragment>
        )
    }

    function formatBytes(bytes, decimals = 2) {
        bytes = bytes * 1000;
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    return (
        <React.Fragment>
            <div className="internal-notes">
                <div className="header">
                    <img src={publicReply} />
                    Public Reply
                    <div style={{ cursor: 'pointer' }} className="btn-icon-only ripple" onClick={props.close} ><i className="fas fa-angle-down"></i></div>
                </div>
                <div className='flex-row public-reply'>
                    <div className="flex-item">
                        <Avatar
                            //name={iconName ? ` ${getFirstWord(iconName)} ` : ''}
                            name="Niel Daculan"
                            round={true}
                            size="47"
                            color="#f44336"
                            // image={ApiImage + props.details.avatar}
                            className="btn-cirle-avatar"
                        />
                    </div>
                    <div className="flex-item flex-1">
                    <div className='flex-row item-baseline'>
                            <div className="recipient">To:</div>
                            <div className="flex-1">
                                <Select
                                    value={selectedEmail.to}
                                    isMulti
                                    className="form-select"
                                    styles={customStyles}
                                    onChange={onChangeto}
                                    onInputChange={(e) => setState({...state, emailError: ''})}
                                    options={state.userOptions}
                                    placeholder="Select user"
                                    noOptionsMessage={(e) => noOptionsMessage(e)}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            </div>
                        </div>
                        {/* <div className='flex-row item-baseline'>
                            <div className="recipient">CC:</div>
                            <div className="flex-1">
                                <Select
                                    isMulti
                                    className="form-select"
                                    styles={customStyles}
                                    onChange={onChangecc}
                                    options={state.userOptions}
                                    placeholder="Select user"
                                    noOptionsMessage={(e) => noOptionsMessage(e)}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>

                <RichText
                    placeholder="Write your message here..."
                    handleChange={handleChangeRich}
                    handleImage={handleImageRich}
                    form="public-reply"
                    value={state.comment}
                    fileUploadAction={uploadFiles}
                    comment_attachments={state.comment_attachments}
                />

                {state.files_uploaded.length > 0 &&
                    <div className="attachment-container">
                        <div className="attachment-line"></div>
                        <span className="attachment-title">Attachments</span>
                        <div className="attachment-list">
                            {state.files_uploaded.map((file, index) => (
                                <div className="attachment" key={index}>
                                    <span>{file.name}</span>
                                    <i className="fa fa-times" onClick={((e) => removeAttachment(e, file, index))}></i>
                                </div>
                            ))}
                        </div>
                    </div>
                }

                <div className="footer-note">
                    <div className="row">
                        <div className="col-12 text-right">
                            <button type="buttom" onClick={handleSubmit} className="btn btn-primary btn-large" disabled={state.isSubmit || !state.comment} >
                                    {state.isSubmit && <i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i> }
                                    Submit
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});

export default PublicReply;