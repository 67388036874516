import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Avatar from "../../Avatar";
//i18n
import { withNamespaces } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';


// users
import user1 from '../../../assets/images/users/avatar-1.jpg';
import {
    deleteCookie,
    getCookie,
    getFirstWord
} from '../../../Utils/helper';

let profileDetails = [];
if(getCookie('userDetails')){
     profileDetails = JSON.parse(getCookie('userDetails'));
}
const ProfileMenu = (props) => {

   // Declare a new state variable, which we'll call "menu"
   const [menu, setMenu] = useState(false);
  
   const [fname, setFname] = useState(profileDetails ? profileDetails.firstname :'');
   const [lname, setLname] = useState(profileDetails ? profileDetails.surname :'');

    useEffect(() => {
           if(localStorage.getItem("authUser") == null)
           {
               console.log('authUser', localStorage.getItem("authUser"))
            // const obj = JSON.parse(localStorage.getItem("authUser"));
            // setusername(obj.firstname);
            // //  if(process.env.REACT_APP_DEFAULTAUTH === 'firebase')
            // //  {
            // //     const obj = JSON.parse(localStorage.getItem("authUser"));
            // //     setusername(obj.displayName);
            // //  }
            // //   else if((process.env.REACT_APP_DEFAULTAUTH === 'fake') || (process.env.REACT_APP_DEFAULTAUTH === 'jwt'))
            // //  {
            // //     const obj = JSON.parse(localStorage.getItem("authUser"));
            // //     setusername(obj.username);
            // //  }
          }
    },[props.success]);

    function logout() {
        deleteCookie("access_token");
        deleteCookie("userDetails");
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                <DropdownToggle className="btn header-item" id="page-header-user-dropdown" tag="button">
                    <Avatar
                    name={` ${fname ?  getFirstWord(fname) : ''} ${lname ?  getFirstWord(lname) : ''} `}
                    round={true}
                    size="32"
                    color="#fbaf42"
                    className="btn-cirle-avatar"
                />
                    <span className="d-none d-xl-inline-block ml-2 mr-1">{fname}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block" style={{color: '#555B6D'}}></i>
                </DropdownToggle>
                <DropdownMenu right>
                    {/* <DropdownItem tag="a"  href="/profile"> <i className="bx bx-user font-size-16 align-middle mr-1"></i>{props.t('Profile')}  </DropdownItem> */}
                    {/* <DropdownItem tag="a" href="#"><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>{props.t('Settings')}</DropdownItem> */}
                    {/* <DropdownItem tag="a" href="auth-lock-screen"><i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{props.t('Lock screen')}</DropdownItem> */}
                     {/* <div className="dropdown-divider"></div> */}
                    <Link to="/change-password" className="dropdown-item">
                        <i className="bx bx-key font-size-16 align-middle mr-1"></i>
                        <span>{props.t('Change Password')}</span>
                    </Link>
                    <Link to="/logout" className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{props.t('Logout')}</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { error,success } = state.Profile;
    return { error,success };
}

export default withRouter(connect(mapStatetoProps, {  })(withNamespaces()(ProfileMenu)));

