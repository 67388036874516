import React, {useEffect, useRef} from 'react';
import Swal from 'sweetalert2'
import Api from "../Utils/API";
import { Api as ApiImage } from '../Utils/consts';
import { getFirstWord } from '../Utils/helper';
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import Select from "react-select";
import Avatar from "./Avatar";
import RichText from './RichText';
import ScroolUser from "./ScroolUser";
import {customStyles, UniversalValidator} from '../Utils/helper';
import userIcon from "../assets/images/userIcon.png";
import clockIcon from "../assets/images/clock.png";
import NumberPickerData from "./NumberPickerData";
import ConfirmSend from './ConfirmSend';
import SuccessAlert from './SuccessAlert';
import ErrorAlert from './ErrorAlert';

function ButtonUser(props) {
    return (
        <button className="btn btn-cirle-dash ml-1" onClick={props.load ? null : props.action} disabled={props.load} >
            {props.load ? <i className="bx bx-loader bx-spin font-size-14 align-middle mr-0"></i> : <img src={userIcon} />}
        </button>
    )
}

function ButtonUserOnly(props) {
    return (
        <div className="primary-assignee-div">
            <div className="close-avatar">
                <button className="btn-cirle" disabled={props.load} onClick={props.load ? null : props.actionDelete}> 
                    {props.load ? 
                        <i className="bx bx-loader bx-spin font-size-14 align-middle mr-0"></i> 
                    :   <i className="fa fa-times" style={{ fontSize: 10 }} ></i>  }
                </button>
            </div>
            <div onClick={props.load ? null : props.action} >
                <Avatar
                    name={` ${getFirstWord(props.details.firstname)} ${getFirstWord(props.details.surname)} `}
                    round={true}
                    size="32"
                    color="#f44336"
                    image={ApiImage + props.details.avatar}
                    className="btn-cirle-avatar"
                />
            </div>
        </div>
    )
}

export const SendProposal = React.memo(props => {
    const estimatedRef = useRef(null);
    useOutsideEstimateAlerter(estimatedRef);
    const [state, setState] = React.useState({
        userOptions: [],
        clearRich: false,
        isSubmit: false,
        psOpen: false,
        qaOpen: false,
        sendingProposal: false,
        description: '',
        coders: props.coders,
        value: null,
        qa: '',
        details: '',
        primaryError: '',
        clarifiedError: '',
        primaryAdetails: '',
        clarified_description: '',
        QAdetails: '',
        email_id: props.selectedEmail,
        requestor: props.requestor,
        loadPrimaryAssignee: false,
        loadQAAssignee: false,
        timeOpen: false,
        estimateTimeHr: '00',
        estimateTimeMin: '00',
        isMinimized: false,
        estimateError: '',
        qaError: '',
        pricingMethodErrorMsg: '',
        pricing_method: '',
        pricing_methods_options: props.pricingMethodOptions,
    });

    useEffect(() => {
        console.log(props);
        let users = props.coders;
        let pricing_methods_options = []; 
        let detailsPricingObject = '';
        let userOptions = state.userOptions;
        let estimated_time = props.requestDetails && props.requestDetails.estimated_time ? props.requestDetails.estimated_time : ''
        const qa_details = props.requestDetails && props.requestDetails.qa_assignee ? props.requestDetails.qa_assignee : ''
        const primary_details = props.requestDetails && props.requestDetails.primary_assignee ? props.requestDetails.primary_assignee : ''
        const clarified_description = props.requestDetails && props.requestDetails.clarified_description_to_client ? props.requestDetails.clarified_description_to_client : ''
        const pricing_method = props.requestDetails && props.requestDetails.pricing_method ? props.requestDetails.pricing_method : pricing_methods_options[0]
        for (let index = 0; index < users.length; index++) {
            userOptions.push({
                value: users[index].id,
                label: `${users[index].firstname} <${users[index].email}> `,
            });
        }

        //PRICING METHOD
        if (pricing_method) {
            detailsPricingObject = {
                value: pricing_method.id,
                label: pricing_method.name,
            };
        }

        // convert 0:0 to 00:00 estimated time format
        let estimate_hours = estimated_time.split(':')[0], 
            estimate_minutes = estimated_time.split(':')[1];
        estimate_hours = ("0" + estimate_hours).slice(-2);
        estimate_minutes = ("0" + estimate_minutes).slice(-2);

        setState({
            ...state,
            QAdetails: qa_details,
            primaryAdetails: primary_details,
            clarified_description: clarified_description,
            pricing_method: detailsPricingObject,
            estimateTimeHr: estimate_hours,
            estimateTimeMin: estimate_minutes,
            userOptions: userOptions,
        });
    }, [props]);

    const editEstimate = (e) => {
        e.stopPropagation();
        e.preventDefault();
        document.body.classList.add('actual-time-show');
        document.querySelector('.timer-picker-div').classList.remove('toogle-hide');
        setState({ ...state, hr: state.estimateTimeHr, min: state.estimateTimeMin, timeOpen: true, actualTimeAction: 2, });
    }

    function updateTime(e) {
        const params = {
            task_id: state.email_id.task_id,
            estimateTime: e.hr + ':' + e.min,
        }

        e.hr = ("0" + e.hr).slice(-2);
        e.min = ("0" + e.min).slice(-2);

        return updateEstimateAPI(e, params); 
    }

    const updateEstimateAPI = (e, params) => {
        Api.updateEstimateUsed(params).then(result => {
            setState({ ...state, timeOpen: false, estimateTimeHr: e.hr, estimateTimeMin: e.min, estimateError: '' });
            props.updateRequestDetails(props.selectedEmail)
        }).catch(error => {
            setState({ ...state, isSaveTime: false, timeOpen: false });
            console.log(error);
        });
    }

    //If clicked outside the div of estimated time's update form container
    function useOutsideEstimateAlerter(ref) {
        useEffect(() => {
            // clicked outside of the element
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)){
                    setState(prevState => ({ ...prevState, timeOpen: false }));
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    function handleSubmit(event) {
        event.preventDefault();

        let strippedString = state.clarified_description.replace(/(<([^>]+)>)/gi, "");
        const confirm_details = { page: 'proposal email for this task' }
        const clarifiedError = UniversalValidator({ value: strippedString, isValidate: true, errorMsg: 'Scope is required.' });
        const primaryErroMsg = state.primaryAdetails ? '' : 'Primary Assignee is required';
        const qaErrorMsg = state.QAdetails ? '' :'QA Assignee is required';
        const pricingMethodErrorMsg = state.pricing_method ? '' :'Pricing Method is required';
        const estimateErrorMsg = state.estimateTimeHr == '00' && state.estimateTimeMin == '00' ? 'Estimate is required' : '';
        
        console.log(pricingMethodErrorMsg)
        console.log(state.pricing_method)
        if (clarifiedError || primaryErroMsg || qaErrorMsg || estimateErrorMsg || pricingMethodErrorMsg) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill in all fields!',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            
            return setState({ ...state, 
                clarifiedError: clarifiedError, 
                primaryError: primaryErroMsg, 
                qaError: qaErrorMsg,
                estimateError: estimateErrorMsg,
                pricingMethodErrorMsg: pricingMethodErrorMsg
            });
        }

        let params = {
            id: state.email_id.task_id,
            clarified_description: state.clarified_description
        }

        ConfirmSend(confirm_details).then(res => {
            if (res.isConfirmed) {
                setState({...state, sendingProposal: true})
                if(state.estimateTimeHr == 0 && state.estimateTimeMin == 0){
                    setState({...state, sendingProposal: false})
                    return ErrorAlert({message: 'Estimated Time should not be 0:0.'});
                }

                sendProposalToAPI(params);
            }
        })
    }

    const sendProposalToAPI = (params) => {
console.log('sendProposalToAPI')
        Api.sendProposal(params).then(result => {
            props.close();
            setState({...state, sendingProposal: false});
            SuccessAlert({message: 'Successfully sent proposal email!'});
            props.updateList();
        }).catch(error => {
            setState({...state, sendingProposal: false});
            console.log(error);

            if(error.response && error.response.data) 
                return ErrorAlert({message: error.response.data});
            
            return ErrorAlert({message: error});
        });
    }


    const primaryAssignee = (e) => {
        setState(prevState => ({ ...prevState, loadPrimaryAssignee: true, psOpen: false }));
        let coders = state.coders,
            selectedData = e,
            indexData = coders.indexOf(selectedData),
            newObject = { index: indexData, ...selectedData },
            selectedCoders = state.primaryAdetails,
            params = {
                task_id: state.email_id.task_id,
                primary_assignee: selectedData.id,
            };

        if(selectedCoders && selectedCoders.id == selectedData.id){
            setState({ ...state, loadPrimaryAssignee: false })
            return deletePrimaryAssignee();
        }

        Api.primaryUpdate(params).then(result => {
            // setState({ ...state, loadPrimaryAssignee: false })
            if (state.primaryAdetails) {
                // REMOVE SELECTED ASSIGNEE FROM LIST
                let lastIndex = coders.length - 1;   // get the last index of coders
                let indexToAdd = selectedCoders.index; // get index of record to add
               
                // ADD PREVIOUSLY SELECTED ASSIGNEE BACK TO THE LIST
                if (indexToAdd >= lastIndex && selectedCoders.id > coders[lastIndex].id)
                    coders.push(selectedCoders);
            }

            setState({ ...state, primaryAdetails: newObject, psOpen: false, loadPrimaryAssignee: false });
            props.updateRequestDetails(props.selectedEmail)
        }).catch(error => {
            console.log(error);
            const err = error.response ? error.response.data : 'Something went wrong.';
            setState({ ...state, loadPrimaryAssignee: false })
            Swal.fire({
                title: 'Error!',
                text: err,
                icon: 'error',
                confirmButtonText: 'Close'
            });
        });
    }

    const qaAssignee = (e) => {
        setState(prevState => ({...prevState, loadQAAssignee: true, qaOpen: false }) );
        let coders = state.coders,
            selectedData = e,
            indexData = coders.indexOf(selectedData),
            newObject = { index: indexData, ...selectedData },
            selectedCoders = state.QAdetails,
            params = {
                task_id: state.email_id.task_id,
                qa_assignee: e.id,
            };
        

        if(state.QAdetails && state.QAdetails.id == selectedData.id){
            setState({ ...state, loadQAAssignee: false })
            return deleteQAAssignee();
        }

        Api.qaUpdate(params).then(result => {
            setState({ ...state, loadQAAssignee: false })

            if (state.QAdetails) {
                let lastIndex = coders.length - 1;      // get the last index of coders
                let indexToAdd = selectedCoders.index;  // get index of record to add
    
                if (indexToAdd >= lastIndex && selectedCoders.id > coders[lastIndex].id)
                    coders.push(selectedCoders);
            } 

            setState({ ...state, qaOpen: false, QAdetails: newObject });
            props.updateRequestDetails(props.selectedEmail)
        }).catch(error => {
            console.log(error);
            const err = error.response ? error.response.data : 'Something went wrong.';
            setState({ ...state, loadQAAssignee: false })
            Swal.fire({
                title: 'Error!',
                text: err,
                icon: 'error',
                confirmButtonText: 'Close'
            });
        });
    }

    const closeDropdown=()=>{setState({...state,qaOpen:false,psOpen:false});}

    const pricing_methodUpdate = (e) => {
        setState({ ...state, pricing_method: e });
        let params = {
            task_id: state.email_id.task_id,
            pricing_id: e.value,
        }
        Api.pricingUpdate(params).then(result => {
            console.log(result);
            props.updateRequestDetails(props.selectedEmail)
        }).catch(error => {
            setState({ ...state, isSave: false });
            console.log(error);
        });
    }

    const selectPS = (e) => {
        document.body.classList.add('primary-assignee-show');
        document.querySelector('.primary-assignee-content').classList.remove('toogle-hide');
        setState({ ...state, psOpen: true, qaOpen: false });
    }

    const selectQA = (e) => {
        document.body.classList.add('primary-qa-show');
        document.querySelector('.primary-qa-content').classList.remove('toogle-hide');
        setState({ ...state, qaOpen: true, psOpen: false });
    }

    const deletePrimaryAssignee = (e) => {
        setState(prevState => ({...prevState, loadPrimaryAssignee: true }));
        let params = {task_id: state.email_id.task_id}

        Api.removePrimary(params).then(result => {
            setState({ ...state, primaryAdetails: [], loadPrimaryAssignee: false });
            props.updateRequestDetails(props.selectedEmail)
        }).catch(error => {
            console.log(error);
        });
    }

    const deleteQAAssignee = (e) => {
        setState(prevState => ({...prevState, loadQAAssignee: true }));
        let params = { task_id: state.email_id.task_id }

        Api.removeQA(params).then(result => {
            setState({ ...state, QAdetails: [], loadQAAssignee: false });
            props.updateRequestDetails(props.selectedEmail)
        }).catch(error => {
            console.log(error);
        });
    }

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setState({
            ...state, isMinimized: true
        })
    }

    function openForm(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');

        setState({
            ...state, isMinimized: false
        })
    }

    return (
        <React.Fragment>
            <div className="contact-form" >
                <div className="form-header" onClick={(e) => openForm(e)} >
                    <div className="row-1">Send Proposal</div>
                    <div className="row-2">
                        {state.isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body add-request-form">
                    <div className="mt-4"></div>
                    <div className="flex-row" >
                        {/* PRIMARY ASSIGNEE */}
                        <div className="col-6">
                            <h6 className="title-1">Primary Assignee</h6>
                            { state.primaryAdetails ?
                                <ButtonUserOnly 
                                    action={selectPS} 
                                    actionDelete={deletePrimaryAssignee} 
                                    details={state.primaryAdetails} 
                                    load={state.loadPrimaryAssignee || state.loadQAAssignee}
                                />
                                : 
                                <ButtonUser action={selectPS} load={state.loadPrimaryAssignee || state.loadQAAssignee}/>
                            }
                            <div className="primary-assignee-content" >
                                { state.psOpen && 
                                    <div>
                                        <ScroolUser list={state.coders} action={primaryAssignee} closeDropdown={closeDropdown} page='inbox'/>
                                    </div>
                                }
                            </div>
                            { state.primaryError && <label className="eror-fields">{state.primaryError}</label> }
                        </div>

                        {/* QA ASSIGNEE */}
                        <div className="col-6">
                            <h6 className="title-1">QA Assignee</h6>
                            { state.QAdetails ?
                                <ButtonUserOnly 
                                    action={selectQA} 
                                    actionDelete={deleteQAAssignee} 
                                    details={state.QAdetails} 
                                    load={state.loadPrimaryAssignee || state.loadQAAssignee}
                                />
                                : 
                                <ButtonUser action={selectQA} load={state.loadPrimaryAssignee || state.loadQAAssignee}/>
                            }
                            <div className="primary-qa-content" >
                                { state.qaOpen && 
                                    <div>
                                        <ScroolUser list={state.coders} action={qaAssignee} closeDropdown={closeDropdown} page='inbox'/>
                                    </div>
                                }
                            </div>
                            { state.qaError && <label className="eror-fields">{state.qaError}</label> }
                        </div>
                    </div>
                    <div className="flex-row">
                        {/* ESTIMATED TIME */}
                        <div className="col-6 actual-time actual-time-and-estimate">
                            <h6 className="title-1">Estimated Time</h6>
                            <img src={clockIcon} />
                            <span className="time-2 ml-2" onClick={editEstimate}>{state.estimateTimeHr}:{state.estimateTimeMin}</span>
                            <div className="timer-picker-div">
                                { state.timeOpen &&
                                    <div ref={estimatedRef}>
                                        <NumberPickerData
                                            hr={state.estimateTimeHr}
                                            min={state.estimateTimeMin}
                                            type={2}
                                            action={updateTime}
                                            loading={state.isSaveTime}
                                        />
                                    </div>
                                }
                            </div>
                            { state.estimateError && <label className="eror-fields">{state.estimateError}</label> }
                        </div>
                        {/* PRICING METHOD */}
                        <div className="col-6 mt-3">
                            <h6 className="title-1">Pricing Method</h6>
                            <Select
                                className="form-select ml-1"
                                // classNamePrefix="select2-selection"
                                styles={customStyles}
                                value={state.pricing_method}
                                onChange={pricing_methodUpdate}
                                options={state.pricing_methods_options}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                            { state.pricingMethodErrorMsg && <label className="eror-fields">{state.pricingMethodErrorMsg}</label> }
                        </div>
                    </div>
                    <div className="mt-4"></div>
                    <div className="contact-right-text">
                        <h6 className="title-1 ml-1 pt-3">Scope</h6>
                        <div className="row mt-2 mb-2 p-2" style={{background: 'white'}}>
                            <RichText
                                placeholder="Scope"
                                handleChange={(e) => setState({ ...state, clarified_description: e, clearRich: false, clarifiedError: '' })}
                                value={state.clarified_description}
                                clear={state.clearRich}
                                index={3}
                            />
                        </div>
                        { state.clarifiedError && <label className="eror-fields">{state.clarifiedError}</label> }
                    </div>
                    <div className="footer-action">
                        <div className="row">
                            <div className="col-12 text-right">
                                <button type="buttom" onClick={handleSubmit} className="btn btn-primary btn-large" disabled={state.isSubmit} >
                                    { state.sendingProposal ?
                                        <i className="bx bx-loader bx-spin font-size-14 align-middle mr-0"></i>
                                        :   
                                        <i className="fas fa-paper-plane"></i> 
                                    }
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </React.Fragment >
    );
});

export default SendProposal;
