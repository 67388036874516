import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import Avatar from 'react-avatar';
import { getFirstWord } from '../../Utils/helper';
import { Api as ApiImage, TicketNo } from '../../Utils/consts';
import moment from 'moment';

const RequestList = (props) => {
    const [state, setState] = useState({
        rows: props.rows
    })

    useEffect(() => {
        props.rows.forEach(row => {
            row.isOpen = true
        });
        setState({...state, rows: props.rows})
    }, [])

    const convertToHourAndMinute = (estimated_time) => {
        var hms = estimated_time;   // your input string
        var a = hms.split(':'); // split it at the colons

        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60; 

        var h = Math.floor(seconds / 3600);
        var m = Math.floor(seconds % 3600 / 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
        return hDisplay + mDisplay
    }

    const toggle = (event, row, index) => {
        let item = state.rows[index];
        item.isOpen = !item.isOpen;
        state.rows[index] = item;
        setState({rows: state.rows})
    };

    function openForm(e, data) {
        props.requestDetails(data.content) 
    }

    return (
        <React.Fragment>
            <div className="row request-list-header">
                <div className="col-3 list-header">
                    Task
                </div>
                <div className="col list-header">
                    Company
                </div>
                <div className="col list-header">
                    Assignee
                </div>
                <div className="col list-header">
                    Estimate/User Time
                </div>
                <div className="col list-header">
                    Due
                </div>
            </div>

            <div className="collapse-container">
            {state.rows.map((row, index) => 
                <div key={index}>
                    <div className="collapse-title font-weight-bold" onClick={(e) => toggle(e, row, index)}>
                        {row.isOpen ? <i className="fas fa-caret-down mr-3"></i> : <i className="fas fa-caret-right mr-3"></i>}
                        {row.title} {row.type ? ` (${row.type})` : ''}
                    </div>
                    <Collapse isOpen={row.isOpen}>
                        {row.cards.map((item, index) => 
                            <div onClick={(e) => openForm(e, item)} className="row collapse-body" key={index}>
                                <div className="col-3 title text-truncate">
                                    <input type="checkbox" className="mr-2"/>
                                    {item.content.title ? item.content.title : 'Request Title'}
                                </div>
                                <div className="col company">
                                    {item.content.company}
                                </div>
                                <div className="col assignee">
                                    {item.content.primary_assignee ? (
                                        <Avatar
                                            name={` ${getFirstWord(item.content.primary_assignee.firstname)} ${getFirstWord(item.content.primary_assignee.surname)} `}
                                            round={true}
                                            size="25"
                                            color="#f44336"
                                            image={ApiImage + item.content.primary_assignee.avatar}
                                            className="btn-cirle-avatar"
                                            style={{marginRight: '2px'}}
                                        />
                                    ) : null}

                                    {item.content.qa_assignee ? (
                                        <Avatar
                                            name={` ${getFirstWord(item.content.qa_assignee.firstname)} ${getFirstWord(item.content.qa_assignee.surname)} `}
                                            round={true}
                                            size="25"
                                            color="#f44336"
                                            image={ApiImage + item.content.qa_assignee.avatar}
                                            className="btn-cirle-avatar"
                                            style={{marginRight: '2px'}}
                                        />
                                    ) : null}
                                </div>
                                <div className="col estimated-time">
                                    {convertToHourAndMinute(item.content.estimated_time)}
                                </div>
                                <div className="col due">
                                    { item.content.due_date ? moment(item.content.due_date).format('Do MMMM') : ''}
                                </div>
                            </div>
                        )}
                    </Collapse>
                </div>
            )}
            </div>
            
            
        </React.Fragment>
    );
}

export default RequestList;