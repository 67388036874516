import React, {useEffect} from 'react';
import internalNote from "../assets/images/internalNote.png";
import RichText from './RichText';
import Api from "../Utils/API";

export const InternalNote = React.memo(props => {
    const [state, setState] = React.useState({
        isSubmit: false,
        userOptions: '',
        details: '',
        detailsError: '',
        email_id: '',
        comment_attachments: []
    });

    useEffect(() => {
        
        setState({
            ...state,
            email_id: props.selectedEmail ? props.selectedEmail.id :'',
        });
    }, [props]);

    function handleSubmit(event) {
        event.preventDefault();
        const {email_id, details, comment_attachments} = state;
        const data = new FormData();

        if (comment_attachments.length > 0) {
            comment_attachments.forEach(attachment => {
                data.append('files[]', attachment); 
            });
        }

        setState({ ...state, isComment: true, isSubmit: true  });
        data.append('email_id', email_id)
        data.append('body', details);
        data.append('is_public', 0);

        Api.publicReply(data).then(result => {
            props.save(result.data);
            setState({ ...state, isSubmit: false });
        }).catch(error => {
            setState({ ...state, isSubmit: false });
            console.log(error);
        });
    }

    const handleChangeRich = (e) => {
        setState({ ...state, details: e, clearRich: false, detailsError: '' });
    }

    const handleImageRich = (e) => {
        let files = e;
        let commentFiles = state.comment_attachments;

        for (let index = 0; index < files.length; index++) {
            let dataFiles = files[index];
            dataFiles.preview = URL.createObjectURL(files[index]);
            dataFiles.formattedSize = formatBytes(files[index].size);
            commentFiles.push(dataFiles);
        }

        setState({ ...state, comment_attachments: commentFiles });
    }

    function formatBytes(bytes, decimals = 2) {
        bytes = bytes * 1000;
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    return (
        <React.Fragment>
            <div className="internal-notes">
                <div className="header">
                    <img src={internalNote} /> 
                    Internal Note
                    <div style={{ cursor: 'pointer'}}  className="btn-icon-only ripple" onClick={props.close} ><i className="fas fa-angle-down"></i></div>
                </div>
                <RichText
                    placeholder="Write your message here..."
                    handleChange={handleChangeRich}
                    handleImage={handleImageRich}
                    value={state.details}
                    comment_attachments={state.comment_attachments}
                    // clear={state.clearRich}
                />
                <div className="footer-note">
                    <div className="row">
                        <div className="col-12 text-right">
                            <button type="buttom" onClick={handleSubmit} className="btn btn-primary btn-large" disabled={state.isSubmit || !state.details} >
                                {
                                    state.isSubmit ?
                                        (<i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i>)
                                        : ''
                                }
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});

export default InternalNote;