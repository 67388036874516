import React from "react";

const Tags = (props) => {
    const [tags, setTags] = React.useState([]);
    const addTags = event => {
        if (event.key === "Enter" && event.target.value !== "") {
            setTags([...tags, event.target.value]);
            props.selectedTags([...tags, event.target.value]);
            event.target.value = "";
        }
    };

    // const removeTags = (tag, index) => {
    //     console.log(tag, index)
    //     setTags([...props.tags.filter(tag => props.tags.indexOf(tag) !== index)]);
    // }; 

    return (
        <div className="tag-list row">
            {props.tags.map((tag, index) => (
                <div className="tag col-3" key={index}>
                    <span>{tag.title}</span>
                    <i className="fa fa-times remove-tag" onClick={() => props.removeTags(tag, index)}> </i>
                </div>
            ))}
        </div>
    );
};

export default Tags;