// @flow
import {
    WEEKLY_WAGES_COST,
    WEEKLY_OTHER_OVERHEAD,
    COST_OF_AFTER_SALES_CARE,
    COMPLETED_WORK
} from "./actionTypes";

const INIT_STATE = {
    weeklyWages: 0,
    weeklyOtherOverhead: 0,
    costOfAfterSalesCare: {
        hours: 0,
        amount: 0
    },
    completedWork: {
        hours: 0,
        amount: 0
    },
    profit: 0
};

const Profitability = (state = INIT_STATE, action) => {
	switch (action.type) {
        case WEEKLY_WAGES_COST:
            {
                console.log('::reducer > WEEKLY_WAGES_COST', action.payload);
                return {
                    ...state, 
                    weeklyWages: action.payload,
                    profit: state.completedWork.amount - action.payload - state.weeklyOtherOverhead
                }
            }

        case WEEKLY_OTHER_OVERHEAD:
            {
                console.log('::reducer > WEEKLY_OTHER_OVERHEAD', action.payload);
                return {
                    ...state, 
                    weeklyOtherOverhead: action.payload,
                    profit: state.completedWork.amount - state.weeklyWages - action.payload
                }
            }

        case COST_OF_AFTER_SALES_CARE:
            {
                console.log('::reducer > COST_OF_AFTER_SALES_CARE', action.payload);
                return {
                    ...state, 
                    costOfAfterSalesCare: action.payload
                }
            }

		case COMPLETED_WORK:
            {
                console.log('::reducer > COMPLETED_WORK', action.payload);
                return {
                    ...state,
                    completedWork: action.payload,
                    profit: action.payload.amount - state.weeklyWages - state.weeklyOtherOverhead
                };
            }

		default:
			return state;
	}
};

export default Profitability;
