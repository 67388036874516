// @flow
import {
    SEARCH_INBOX
} from "./actionTypes";

const INIT_STATE = {
	inboxSearchString: ""
};

const Inbox = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SEARCH_INBOX:
			return {
				...state,
				inboxSearchString: action.payload
			};

		default:
			return state;
	}
};

export default Inbox;
