import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutUser } from '../../store/actions';
import {
    deleteCookie
} from '../../Utils/helper';
import Api from "../../Utils/API";

const Logout = (props) => {

    useEffect(() => {
        deleteCookie("access_token");
        deleteCookie("userDetails");
        // props.logoutUser(props.history);
        Api.logout().then(result =>{
            deleteCookie("access_token");
            deleteCookie("userDetails");
            props.logoutUser(props.history);
        }).catch(error =>{
            console.log(error);
        });
    }, []);

    return (
        <></>
    );
}

export default withRouter(
    connect(
        null,
        { logoutUser }
    )(Logout)
);
