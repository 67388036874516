import { Height } from '@material-ui/icons';
import React, { useRef, useEffect, useState } from 'react';

export default function ScroolUser(props) {
    const [list, setList] = useState(props.list);

    useEffect(() => {
        // if(props.referrer) {
        //     setList(list.splice(list.indexOf(props.referrer), 1));
        // }
    }, [props]);

const wrapperRef = useRef(null);
useOutsideAlerter(wrapperRef);

function getFirstWord(name) {
    var firstWord = name.replace(/ .*/, '');
    return firstWord;
}

//If clicked outside the div of primary assignee's dropdown container
function useOutsideAlerter(ref) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(e) {
            // inside click
            if (wrapperRef.current.contains(e.target)) { console.log('wrapper contains e');return };

            // outside click 
            props.toggleOptions();
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

    return (
        <div className={`assignee-scroll`} style={{minHeight: 0}} ref={wrapperRef}>
            {list.length ?
                list.map((item, i) =>
                    <div className={`assignee-scroll-list`} onClick={(e) => props.action(item, e)} key={i}  >
                        <span className={`ml-2 mr-2`} >{item.label}</span>
                    </div>
                )
                :
                <div className={`assignee-scroll-list`}>
                    <span className={`ml-2 mr-2`}>No Other Affiliate Companies</span>
                </div>
            }
        </div>
    )
}