import axios from 'axios';

const API = axios.create({
    baseURL: "http://localhost:8000/api"
});
// axios.defaults.baseURL = 'http://localhost:8000/api'

// Gets the logged in user data from local session 
const getLoggedInUser = () => {
    const user = localStorage.getItem('user');
    if (user)
        return JSON.parse(user);
    return null;
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

// Register Method
const postFakeRegister = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status ) {
            switch (err.response.status) {
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }
        }
        throw message;
    });

}

// Login Method
const postFakeLogin = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

// postForgetPwd 
const postFakeForgetPwd = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}


// Login Method
const postJwtProfile = (url, data) => {
    return axios.post(url, data,{ headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6ImFkbWluIiwiYWRtaW4iOnRydWUsImp0aSI6ImQ2MTEwYzAxLWMwYjUtNDUzNy1iNDZhLTI0NTk5Mjc2YjY1NiIsImlhdCI6MTU5MjU2MDk2MCwiZXhwIjoxNTkyNTY0NjE5fQ.QgFSQtFaK_Ktauadttq1Is7f9w0SUtKcL8xCmkAvGLw'} }).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

const postFakeProfile = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

// Register Method
const postJwtRegister = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status ) {
            switch (err.response.status) {
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }
        }
        throw message;
    });
}

// // Login Method
// const postJwtLogin = (url, data) => {
// const API = axios.create({
//     baseURL: 'http://localhost:8000/api'
// });
// console.log('Api settings', axios.defaults.baseURL);
// return API.post(``, {email: 'nathaniel@jiffiwebhelp.com', password: 'password'})
// .then(response => {
//     console.log('test')
//     console.log(response.data)

//     localStorage.setItem("authUser", JSON.stringify(response));
//     console.log('test', localStorage.getItem('authUser'))
// }).catch(err => {
//     console.log(err.request, err.response)
//     alert(err)
// });
// // // return API.post(`/login`, {email: 'nathaniel@jiffiwebhelp.com', password: 'password'})
// // // .then(response => {
// // //     console.log('test')
// // //     console.log(response.data)
// // // console.log('postJwtLogin - data', data)
// // alert(url)
// //     API.post(url, data).then(response => {
// // //         if (response.status === 400 || response.status === 500) {
// // // console.log('postJwtLogin', 'err 400 or err 500')
// // //             throw response.data;
// // //         }
// // console.log('postJwtLogin', response.data)
// //         return response.data;
// //     }).catch(err => {
// // console.log(err)
// //         // throw err[1];
// //     });
// }
// Login Method
const postJwtLogin = (url, data) => {
    alert(url)
    return axios.post(url, data, {headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }}).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
alert(err)
        throw err[1];
    });
}

// postForgetPwd 
const postJwtForgetPwd = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}


export { getLoggedInUser, isUserAuthenticated, postFakeRegister, postFakeLogin,postFakeProfile, postFakeForgetPwd,postJwtRegister, postJwtLogin, postJwtForgetPwd, postJwtProfile }