import {
    ADVANCE_SEARCH_TASKS,
    SEARCH_TASKS,
    FILTER_TASKS,
	FILTER_MY_TASKS
} from "./actionTypes";

export const advanceSearchTasks = advance_search => ({
	type: ADVANCE_SEARCH_TASKS,
	payload: advance_search
});

export const searchTasks = search => ({
	type: SEARCH_TASKS,
	payload: search
});

export const filterTasks = filter => ({
	type: FILTER_TASKS,
	payload: filter
});

export const filterMyTasks = filter => ({
	type: FILTER_MY_TASKS,
	payload: filter
});