import React, { useState, useEffect } from 'react';
import { Modal } from "reactstrap";
import Api from "../Utils/API";
import TextareaAutosize from 'react-textarea-autosize';
import modalCloseIcon from "../assets/images/modalCloseIcon.svg";

const TimeAdjustmentForm = (props) => {
    const [modal_center, setmodal_center] = useState(false);
    const [is_add, setis_add] = useState(props.isAdd);
    const [hr, sethr] = useState(props.hr ? props.hr : 0);
    const [min, setmin] = useState(props.min ? props.min : 0);
    const [state, setstate] = useState({
        hrError: '',
        minError: '',
        note: ''
    });

    useEffect(() => {
        tog_center();
    }, [props]);

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function close() {
        setmodal_center(false)
        props.close();
    }

    function update() {
        let errors = [];

        if(hr == null || hr == '') {
            errors['hr'] = 'Hours should not be empty or null!';
        }

        if(min == null || min == '') {
            errors['min'] = 'Minutes should not be empty or null!';
        }

        if(errors.length > 0) {
            setstate({ ...errors, hrError: errors['hr'], minError: errors['min'] });
        } else {
            let params = {
                task_id: props.tid,
                timeAdjustments: hr + ':' + min,
                note: state.note,
                is_add: is_add ? 1 : 0
            }

            Api.updateTimeAdjustments(params).then(result => {
                props.update({'hr': hr, 'min': min, is_add: is_add});
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function updateHr(input) {
        if (input === '') {
            input = 0;
        }

        sethr(input)
    }

    function updateMin(input) {
        if(input === '') {
            input = 0;
        }

        setmin(input)
    }

    function updateNote(note) {
        setstate({ ...state, note: note });
    }

    return(
        <div className="time-adjustments-modal">
            <Modal
                size="sm"
                isOpen={modal_center}
                toggle={() => { tog_center() }}
                centered={true}
            >
                <div className="modal-body">
                    <div className="mb-4 flex-row">
                        <div className="time-adjustments-header">Adjust Time</div>
                        <button
                            type="button"
                            onClick={() => { close() } }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <img src={modalCloseIcon} />
                        </button>
                    </div>

                    <div className="flex-row time-adjustments-add-deduct">
                        <button className={is_add ? "btn btn-outline-primary add-time" : "btn btn-outline-gray add-time"} onClick={() => setis_add(true)}>+ Add</button>
                        <button className={! is_add ? "btn btn-outline-primary deduct-time" : "btn btn-outline-gray deduct-time"} onClick={() => setis_add(false)}>- Deduct</button>
                    </div>

                    <label className="time-adjustment-section">Time</label>
                    <div className="time-adjustment-fields">
                        <div className="time-adjustment-input">
                            <input className="time-input" onChange={(e) => updateHr(e.target.value)} value={hr} type="number" min="0" step="1"/><div className="time-adjustment-label">hrs</div>
                            {
                                state.hrError ?
                                    <label className="eror-fields">{state.hrError}</label>
                                    : ''
                            }
                        </div>
                        
                        <div className="time-adjustment-input">
                            <input className="time-input" onChange={(e) => updateMin(e.target.value)} value={min} type="number" min="0" step="1"/><div className="time-adjustment-label">{min > 1 ? 'mins' : 'min'}</div>
                            {
                                state.minError ?
                                    <label className="eror-fields">{state.minError}</label>
                                    : ''
                            }
                        </div>
                    </div>

                    <label className="time-adjustment-section">Note</label>
                    <TextareaAutosize placeholder="Enter Note" className="task-details time-adjustment-note" onBlur={(e) => updateNote(e.target.value)}></TextareaAutosize>

                    <div className="time-adjustment-buttons">
                        <button className="btn btn-gray" onClick={() => close() }>Cancel</button>
                        <button className="btn btn-primary" onClick={() => update() }>Save</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default TimeAdjustmentForm;