import React from 'react';
import Swal from 'sweetalert2'
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import whiteNoteIcon from "../assets/images/whiteNoteIcon.svg";
import { UniversalValidator } from '../Utils/helper';
import Api from "../Utils/API";
import TextareaAutosize from 'react-textarea-autosize';

export const AddKanbanColumnForm = (props) => {
    const [state, setState] = React.useState({
        id: '',
        title: '',
        status_title: '',
        position: '',
        titleError: '',
        isMinimized: false
    });

    React.useEffect(() => {
        const statusDetails = props.statusDetails;
        setState({...state, id: statusDetails?.targetId, title: statusDetails?.title, status_title: statusDetails?.status_title, position: statusDetails?.position})
    }, [props.noteDetails]);

    function handleSubmit(event) {
        // event.preventDefault();
        const titleError = UniversalValidator({ value: state.title, isValidate: true, isUrl: false, errorMsg: 'Title is required.' });

        if (titleError) {
            Swal.fire({
                title: 'Error!',
                text: 'Subject and Note are required.',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setState({ ...state, titleError, isSubmit: false });
        } else {
            setState({ ...state, isSubmit: true });

            if(props.isNew) {
                let params = {
                    title: state.title,
                    status_title: state.status_title,
                    record_type: props.recordType
                }

                Api.addRequestStatusColumn(params).then(result => {
                    const data = result.data

                    if(data.status && data.status === 'validation failed') {
                        setState({ ...state, isSubmit: false, titleError: data.message });
                    } else {
                        props.updateBoard();

                        setState({ ...state, isSubmit: false, subject: '', note: '', title: '', status_title: ''});
                    }
                }).catch(error => {
                    setState({ ...state, isSubmit: false });
                    // setState({ ...state, isSubmit: false, tagTitleError: error.data.message.title[0] });
                    console.log(error);
                });
            } else {
                let params = {
                    id: state.id,
                    title: state.title,
                    status_title: state.status_title,
                    position: state.position,
                }

                Api.updateStatusColumn(params).then(result => {
                    props.updateNote(result.data);
                    setState({ ...state, isSubmit: false});
                }).catch(error => {
                    setState({ ...state, isSubmit: false });
                    // setState({ ...state, isSubmit: false, tagTitleError: error.data.message.title[0] });
                    console.log(error);
                });
            }
        }
    }

    function updateTitle(title) {
        setState({ ...state, title, titleError: '' });
    }

    function updateStatusTitle(status_title) {
        setState({ ...state, status_title });
    }

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setState({
            ...state, isMinimized: true
        })
    }

    function openForm(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');

        setState({
            ...state, isMinimized: false
        })
    }

    return (
        <React.Fragment>
            <div className="contact-form phone-logs-form" >
                <div className="form-header" onClick={(e) => openForm(e)} >
                    <div className="row-1"><img src={whiteNoteIcon} className="mr-2"/>{props.isNew ? 'Add Status' : 'Update Status'}</div>
                    <div className="row-2">
                        {state.isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                    <div className="contact-group">
                        <div className="row-1">
                            Title
                        </div>
                        <div className="row-2">
                            <input className="form-control note-subject" placeholder="Enter Subject" value={state.title} onChange={(e) => updateTitle(e.target.value)} />
                            {
                                state.titleError ?
                                    <label className="eror-fields">{state.titleError}</label>
                                    : ''
                            }
                        </div>
                    </div>

                    <div className="contact-group">
                        <div className="row-1">
                            Status Title
                        </div>
                        <div className="row-2">
                            <input className="form-control note-subject" placeholder="Enter Subject" value={state.status_title} onChange={(e) => updateStatusTitle(e.target.value)} />
                        </div>
                    </div>

                    <div className="footer-action">
                        <div className="row">
                            <div className="col-12 text-right">
                                <button type="buttom" onClick={handleSubmit} className="btn btn-primary btn-large" disabled={state.isSubmit} >
                                    {
                                        state.isSubmit ?
                                            (<i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i>)
                                            : ''
                                    }
                                    {props.isNew ? 'Submit' : 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddKanbanColumnForm;
