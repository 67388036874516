let randomColor = ['#f44336', '#e91e63', '#9c27b0' , '#673ab7',  '#2196f3', '#009688', '#4caf50', '#8bc34a', '#ff9800', '#fbe9e7', '#556ee6', '#564ab1', '#6f42c1', '#f46a6a', '#34c38f', '#50a5f1'  ];


export const setCookie = data => {
    
    var d = new Date();
    d.setDate(d.getDate() + data[2]);
    var expires = "expires=" + d.toUTCString();
    document.cookie = data[0] + "=" + data[1] + ";" + expires + ";path=/";
};


export const getCookie = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const deleteCookie = data => {
    document.cookie = data + "= ; expires=Thu, 01 Jan 1970 00:00:00 UTC";
};

export const UniversalValidator = data => {
    if (!data.isValidate || data?.value?.length <= 0) return data.errorMsg;
    return '';
};

export const emailValidator = email => {
    const re = /\S+@\S+\.\S+/;

    if (!email || email.length <= 0) return 'Email cannot be empty.';
    if (!re.test(email)) return 'Ooops! We need a valid email address.';

    return '';
};

export const linkValidator = data => {
    const re = /^(ftp|http|https):\/\/[^ "]+$/;

    if (!data || data.length <= 0) return 'URL cannot be empty.';
    if (!re.test(data)) return 'Ooops! We need a valid url.(http://|https://|ftp://)';
    return '';
};

export const urlValidator = (data, isRequired = false) => {
    const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    if (isRequired && (!data || data.length <= 0)) return 'URL cannot be empty.';
    if (data.length > 0 && !re.test(data)) return 'Please enter a valid URL.';
    return '';
};

export const ipAddressValidator = data => {
    const re = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

    // if (!data || data.length <= 0) return 'IP Address cannot be empty.';
    if (!re.test(data)) return 'Please enter a valid IPv4 or IPv6 address.';
    return '';
};

export const numberValidator = (data, errorMessage) => {
    const re = /^[0-9]+$/;

    if (!data || data.length <= 0) return errorMessage;
    if (!re.test(data)) return 'Rate must be a whole number.';
    return '';
};

export const floatValidator = (data, errorMessage = 'Please enter a number.') => {
    const re = /^\d*\.?\d*$/;

    if (!re.test(data)) return errorMessage;
    return '';
};

export function numberOnly(value) {
    return value.replace(/[^\d]/g, '');
}

export function abnFormat(value) {
    return value.replace(/[^\d ]/g, '');
}

export const getFirstWord = data => {
    if(data == null || data === '')
        return '';
    else
        return data.replace(/ .*/,'');
};

export const getColor = data => {
    if(randomColor[data]){
        return randomColor[data];
    }
    //Math.floor(Math.random()*16777215).toString(16)
   return "#fbaf42";
};

export const download = (url, filename) => {
    fetch(url).then(function(t) {
        return t.blob().then((b)=>{
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        }
        );
    });
}

export const multipleDownload = (files) => {
    files.forEach(file => {
        fetch(file.url).then(function(t) {
            return t.blob().then((b)=>{
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", file.name);
                a.click();
            }
            );
        });
    });
}



export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #ECECF5',
        color: state.isSelected ? '#fff' : '#8890AB',
        padding: 6,
        fontSize: 12,
    }),
    placeholder: (base, state) => ({
        ...base,
        color: '#AFB6CB',
        fontSize: 12,
    }),
    control: (base, state) => ({
        ...base,
        border: '0 !important',
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
    singleValue:(base, state) => ({
        ...base,
        // backgroundColor: '#F8F8FB',
        // border: '1px solid #ECECF5',
        // boxSizing: 'border-box',
        // borderRadius: 50,
        // paddingLeft: 10,
        // paddingRight: 10,
        fontSize: 12
    }),
    multiValue:(base, state) => ({
        ...base,
        backgroundColor: '#F8F8FB',
        border: '1px solid #ECECF5',
        boxSizing: 'border-box',
        borderRadius: 50,
        paddingLeft: 10,
        paddingRight: 10,
    }),
    multiValueLabel:(base, state) => ({
        ...base,
       fontSize: 12
    }),
}

export const stringLimit = data => {
    var text = data.title;
    var count = data.count;
    var result = text.slice(0, count) + (text.length > count ? "..." : "");
    return result;
};






