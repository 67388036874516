// @flow
import {
    SEARCH_COMPANIES
} from "./actionTypes";

const INIT_STATE = {
	companySearchString: ""
};

const Company = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SEARCH_COMPANIES:
			return {
				...state,
				companySearchString: action.payload
			};

		default:
			return state;
	}
};

export default Company;
