import React, { useEffect, useState, useRef } from 'react';
import { CardBody, Card, Alert,Container } from "reactstrap";
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Swal from "sweetalert2";
import queryString from 'query-string';

// import images
import logo from "../../assets/images/jiffi_logo_white.svg";
import mail from "../../assets/images/mail.svg";
import call from "../../assets/images/call.svg";
import Api from "../../Utils/API";

const ForgotPassword = (props) => {
    const params = queryString.parse(props.location.search);
    const [state, setState] = useState({
        isSubmit: false,
        newPassword: '',
        confirmPassword: '',
        email: false,
        emailError: false,
        token: params.token
    });
    const emailFormRef = useRef(null);
    const resetPasswordFormRef = useRef(null);

    useEffect(() => {
        console.log('FORGOTPASSWORD', state);
    }, [])

    function handleEmailSubmit(event, values) {
        setState({ ...state, emailError: false, isSubmit: true });
        Api.forgotPasswordEmail(values.email).then(result =>{
            setState({ ...state, isSubmit: false, emailError: false });
            Swal.fire({
                title: '',
                text: 'A reset password email has been sent to your inbox.',
                icon: 'success',
                confirmButtonText: 'Close'
            });
        }).catch(error =>{
            setState({ ...state, emailError: true, isSubmit: false });
            console.log(error);
        });
    }

    function handlePasswordSubmit(event, values) {
        setState({ ...state, isSubmit: false });
        if (values.newPassword !== values.confirmPassword) {
            Swal.fire({
                title: 'Error!',
                text: 'New Password and Confirm New Password do not match.',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            document.querySelector("[for='newPassword']").parentElement.classList.add('text-danger');
            document.querySelector("[for='confirmPassword']").parentElement.classList.add('text-danger');
            setState({ ...state, isSubmit: false });
        } else {
            setState({ ...state, isSubmit: true });
            document.querySelector("[for='newPassword']").parentElement.classList.remove('text-danger');
            document.querySelector("[for='confirmPassword']").parentElement.classList.remove('text-danger');
            const params = {token: state.token, password: values.newPassword};
            Api.resetPassword(params).then(result =>{
                setState({
                    ...state,
                    isSubmit: null,
                    email: null,
                    emailError: null,
                    token: null
                });

                const res = Swal.fire({
                    title: '',
                    text: 'Password has been changed!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });

                window.location = '/';
            }).catch(error =>{
                let message = error?.response?.data ?? 'An unexpected error has been encountered!'
                setState({ ...state, passwordError: message, isSubmit: false });
                console.log(error);
            });
        }
    }

    return (
        <React.Fragment>
        <div style={{display: 'flex', background: 'linear-gradient(90deg, #FF782F -15.49%, #FF5757 116.35%)', minHeight: '100vh', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{flexDirection: 'row'}} className="justify-content-center">
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{marginBottom: 40}}>
                            <Link to="/">
                                <img src={logo} alt="" width="100%" height="auto" />
                            </Link>
                        </div>

                        <div className="justify-content-center" style={{display: 'flex', flex: 1, marginBottom: 20}}>
                            <Card className="overflow-hidden" style={{background: '#FFF', boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.06)', borderRadius: 10}}>
                                <CardBody>
                                    <div className="p-2" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                        {params.token ?
                                          <div>
                                            <h2 style={{fontSize: 36, fontFamily: 'sofia-pro !important'}}>Reset Password</h2>
                                            <AvForm className="form-horizontal" onValidSubmit={(e,v) => { handlePasswordSubmit(e,v) }}>
                                                <div className="form-group">
                                                    <AvField name="newPassword" label="New Password" value={state.newPassword} className="form-control" placeholder="Enter New Password" type="password" required />
                                                </div>

                                                <div className="form-group">
                                                    <AvField name="confirmPassword" label="Confirm New Password" value={state.confirmPassword} type="password" required placeholder="Re-enter New Password" validate={{match:{value:'newPassword', errorMessage: 'New Password and Confirm Password do not match!'}}} required />
                                                </div>

                                                <div className="mt-3">
                                                    <button disabled={state.isSubmit} className="btn btn-primary btn-block waves-effect waves-light" type="submit" style={{backgroundColor: '#F15A23', borderRadius: 50}}>
                                                        {state.isSubmit ? <i className="bx bx-loader bx-spin font-size-14 align-middle"></i> : 'Reset Password' }
                                                    </button>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <Link to="/" className="text-muted"><i className="mdi mdi-lock mr-1"></i>Back to Home</Link>
                                                </div>
                                            </AvForm>
                                          </div>
                                          :
                                          <div>
                                            <h2 style={{fontSize: 36, fontFamily: 'sofia-pro !important'}}>Forgot Password</h2>
                                            <AvForm className="form-horizontal" onValidSubmit={(e,v) => { handleEmailSubmit(e,v) }}>
                                                <div className="form-group">
                                                    <AvField name="email" label="Email" value="" className="form-control" placeholder="Enter your registered email address..." type="email" required />
                                                </div>

                                                <div className="mt-3">
                                                    <button disabled={state.isSubmit} className="btn btn-primary btn-block waves-effect waves-light" type="submit" style={{backgroundColor: '#F15A23', borderRadius: 50}}>
                                                        {state.isSubmit ? <i className="bx bx-loader bx-spin font-size-14 align-middle"></i> : 'Reset Password' }
                                                    </button>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <Link to="/" className="text-muted"><i className="mdi mdi-lock mr-1"></i>Back to Home</Link>
                                                </div>
                                            </AvForm>
                                          </div>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{flexDirection: 'row', marginBottom: 15}}>
                                {/* <Link to="/"> */}
                                    <img src={mail} alt="" width="auto" height="20" style={{marginRight: 10}} />
                                    <span style={{color: 'white'}}>support@jiffiwebhelp.com</span>
                                {/* </Link> */}
                            </div>
                            <div style={{flexDirection: 'row'}}>
                                {/* <Link to="/"> */}
                                    <img src={call} alt="" width="auto" height="20" style={{marginRight: 10}} />
                                    <span style={{color: 'white'}}>1300 247 516</span>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </React.Fragment>
    );
}

export default ForgotPassword;

