import React, { useEffect, useState } from 'react';

import { Row, Col, CardBody, Card, Alert,Container } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser, apiError, userForgetPassword } from '../../store/actions';

// import images

import logo from "../../assets/images/jiffi_logo_white.svg";
import mail from "../../assets/images/mail.svg";
import call from "../../assets/images/call.svg";
import Api from "../../Utils/API";
import {
    UniversalValidator,
    setCookie,
    getCookie
} from '../../Utils/helper';

let access_token = getCookie("access_token");

const Login = (props) => {
    const [state, setState] = useState({
        isSubmit: false,
        errorLogin: false,
        rememberIsChecked: false
    })
    useEffect(() => {
        if(access_token)
            window.location.href = '/email-inbox'
    }, [])

    function handleValidSubmit(event, values) {
        values.rememberIsChecked = state.rememberIsChecked;
        setState({ ...state, errorLogin: false, isSubmit: true });

        Api.login(values).then(result =>{
            let days = result.data.remember == 1 ? 2 : 1;
            setCookie(["access_token", result.data.access_token, days]);
            setCookie(["userDetails", JSON.stringify(result.data.user), days]);
            setCookie(["rate", JSON.stringify([result.data.rate, result.data.is_hourly]), days]);
            setState({ ...state, isSubmit: false });

            // check props.prev
            if(props.prev.includes('/login')) {
                window.location.href = '/email-inbox';
            } else {
                window.location.href = props.prev;
            }
        }).catch(error =>{
            setState({ ...state, errorLogin: true, isSubmit: false });
            console.log(error);
        });
    }

    return (
        <React.Fragment>
        <div style={{display: 'flex', background: 'linear-gradient(90deg, #FF782F -15.49%, #FF5757 116.35%)', minHeight: '100vh', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{flexDirection: 'row'}} className="justify-content-center">
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{marginBottom: 40}}>
                            <Link to="/">
                                <img src={logo} alt="" width="100%" height="auto" />
                            </Link>
                        </div>

                        <div className="justify-content-center" style={{display: 'flex', flex: 1, marginBottom: 20}}>
                            <Card className="overflow-hidden" style={{background: '#FFF', boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.06)', borderRadius: 10}}>
                                <CardBody>
                                    <div className="p-2" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                        <h2 style={{fontSize: 36, fontFamily: 'sofia-pro !important'}}>Log In</h2>
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { handleValidSubmit(e,v) }}>

                                            { state.errorLogin &&
                                                <Alert color="danger" role="alert">
                                                    Incorrect username or password.
                                                </Alert>
                                            }
                                            
                                            <div className="form-group">

                                                <AvField name="username" label="Username" value="" className="form-control" placeholder="Enter Username" type="email" required />

                                            </div>

                                            <div className="form-group">
                                                <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" />
                                            </div>

                                            <Row className="p-2">
                                                <div className="custom-control custom-checkbox col-md-6">
                                                    <input type="checkbox" className="custom-control-input" id="customControlInline" onChange={() => setState({rememberIsChecked: !state.rememberIsChecked})} checked={state.rememberIsChecked}/>
                                                    <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                                </div>

                                                <div className="col-md-6">
                                                    <Link to="/forgot-password" style={{color: '#F15A23'}}>Forgot Password?</Link>
                                                </div>
                                            </Row>

                                            <div className="mt-3">
                                                <button disabled={state.isSubmit} className="btn btn-primary btn-block waves-effect waves-light" type="submit" style={{backgroundColor: '#F15A23', borderRadius: 50}}>
                                                    {state.isSubmit ? <i className="bx bx-loader bx-spin font-size-14 align-middle"></i> : 'Sign In' }
                                                </button>
                                            </div>

                                            <div className="mt-4 text-center">
                                                <Link to="/" className="text-muted"><i className="mdi mdi-lock mr-1"></i>Back to Home</Link>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{flexDirection: 'row', marginBottom: 15}}>
                                {/* <Link to="/"> */}
                                    <img src={mail} alt="" width="auto" height="20" style={{marginRight: 10}} />
                                    <span style={{color: 'white'}}>support@jiffiwebhelp.com</span>
                                {/* </Link> */}
                            </div>
                            <div style={{flexDirection: 'row'}}>
                                {/* <Link to="/"> */}
                                    <img src={call} alt="" width="auto" height="20" style={{marginRight: 10}} />
                                    <span style={{color: 'white'}}>1300 247 516</span>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser,apiError })(Login));

