import React, { useEffect,useState } from "react";
import Avatar from "./Avatar";
import { getFirstWord } from '../Utils/helper';
import { Api as ApiImage } from '../Utils/consts';
import ScroolUser from './ScroolUser';
import assigneeIcon from "../assets/images/inbox-assignee-icon.svg";

const InboxFollower = (props) => {
    const Spinner = props.spinner;
    const [showSpinner, setShowSpinner] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [state, setState] = useState({assignee: null, coders: [], showOptions: false});
    const toggleOptions = () => {
        setShowOptions(!showOptions)
    }

    const handleSelect = (e) => {
        setShowSpinner(true);
        if(props.type === 'assignee') {
            // set assignee
            props.set(props.emailId, e.id);
        } else if(props.assigneeId) {
            // replace follower
            props.replace(props.emailId, props.assigneeId, e.id);
        } else {
            // add follower
            props.set(props.emailId, e.id);
        }
    }

    const handleRemove = () => {
        if(props.type === 'assignee') {
            props.remove(props.emailId);
        } else {
            props.remove(props.emailId, props.assigneeId);
        }
    }

    useEffect(() => {
console.log('TEST > props.emailId', props.emailId);
if(props.type === 'assignee') console.log('AssigneeContainer > coders', props.coders);
        const coders = props.coders;
        let assignee = null;

        if(props.assigneeId) {
            let user = coders.filter(opt => opt.id === props.assigneeId);
console.log('TEST > props.assigneeId > USER', user);
            if(user) {
                user = user[0];
                const userIndex = coders.findIndex((obj => obj.id == user.id));
                if(userIndex > -1) coders.splice(userIndex, 1);

                assignee = {
                    firstname: user?.firstname ?? '',
                    surname: user?.surname ?? ''
                };
console.log('TEST > props.assigneeId > ASSIGNEE', assignee);
            }
        }

        if(props.exclude) {
            let user = coders.filter(opt => opt.id === props.exclude);
console.log('TEST > props.exclude > USER', user);
            if(user) {
                user = user[0];
                const userIndex = coders.findIndex((obj => obj.id == user.id));
                if(userIndex > -1) coders.splice(userIndex, 1);
            }
        }

        if(props.followers) {
            const followers = props.followers;
            let fIndex = null;
            {followers.map((follower, index) => {
console.log(`TEST > props.followers > ${index}`, follower);
                fIndex = coders.findIndex((obj => obj.id == follower.user_id));
                if(fIndex > -1) coders.splice(fIndex, 1);
            })}
        }

        setState({ assignee, coders });
        if(showSpinner) setShowSpinner(false);
        if(showOptions) setShowOptions(false);
    }, [props.assigneeId, props.followers, props.coders]);

    return (
        <>
        {showSpinner ?
            <Spinner className="mr-2" color="info" />
            :
            (
                state.assignee ?
                <>
                    <span className="close-avatar" onClick={handleRemove} >
                        <button className="btn-cirle" > 
                            {props.load ? 
                                <i className="bx bx-loader bx-spin font-size-14 align-middle"></i> :
                                <i className=" fa fa-times" style={{ fontSize: 10 }} ></i>
                            }
                        </button>
                    </span>
                    <div onClick={() => setShowOptions(!showOptions)}>
                        <Avatar
                            name={`${getFirstWord(state.assignee.firstname)} ${getFirstWord(state.assignee.surname)}`}
                            round={true}
                            size="32"
                            color="#A6B0CF"
                            image={props?.details?.avatar ? ApiImage + props.details.avatar : null}
                            className="btn-cirle-avatar"
                        />
                    </div>
                </>
                :
                <div onClick={toggleOptions}>
                <Avatar
                    name=""
                    round={true}
                    size="32"
                    color="#A6B0CF"
                    image={assigneeIcon}
                    className="btn-cirle-avatar"
                />
                </div>
            )
        }

        { showOptions && state.coders.length > 0 && <ScroolUser list={state.coders} closeDropdown={toggleOptions} action={handleSelect} page='board'/> }
        </>
    )
}

export default InboxFollower;