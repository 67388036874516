import React, { useState, useEffect } from "react";
import backIcon from "../../assets/images/backIcon.png";
import imageIcon from "../../assets/images/inviteImage.png";
import { Link } from "react-router-dom";
import API from "../../Utils/API";
import LoadingIndicator from "../../components/LoadingIndicator";
import DataTable from "../../components/DataTable";
import queryString from "query-string";
import Select from "react-select";
import moment from 'moment';
import AddWebsiteLog from "./add-website-log";
import useDebounce from "../../Utils/useDebounce";
import WebsiteForm from "./website-form";


const dataTable = {
    columns: [
        {
            label: "Title",
            field: "title",
        },
        {
            label: "Type",
            field: "type",
        },
        {
            label: "Added By",
            field: "addedBy",
        },
        {
            label: "Date & Time",
            field: "dateTime",
        },
    ],
    rows: [],
};

const WebsiteDetails = (props) => {
    const urlParams = queryString.parse(props.location.search);
    const [websiteDetails, setWebsiteDetails] = useState({});
    const [deploymentPolicy, setDeploymentPolicy] = useState("");
    const [companyOptions, setCompanyOptions] = useState([]);
    const [assigneeOptions, setAssigneeOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [logs, setLogs] = useState(dataTable);
    const [isAddWebsiteLogOpen, setIsAddWebsiteLogOpen] = useState(false);
    const [toastData, setToastData] = useState({ title: "", description: "" });
    const [search, setSearch] = useState();
    const debouncedSearch = useDebounce(search);

    /** FUNCTIONS */
    function showToast(title = "", description = "") {
        setToastData({
            title,
            description
        });

        var x = document.getElementById("snackbar-website"); 
        x.className = "show row notification-div";
        setTimeout( ()=> { closeToast(); }, 5000);
    }

    function closeToast() {
        var x = document.getElementById("snackbar-website"); 
        if(x)
            x.className = x.className.replace("show", "");
    }

    const fetchWebsiteDetails = () => {
        API.getWebsiteDetails(urlParams.id)
        .then(({data}) => {
            setWebsiteDetails(data);
        })
        .catch((error) => {
            console.log("::fetchWebsiteDetails > error", error);
        });
    }

    const fetchWebsiteLogs = async() => {
        setLogs((prevState) => ({
            ...prevState, rows: []
        }));

        await API.getWebsiteLogs(urlParams.id, debouncedSearch)
        .then(({data}) => {
            const rows = [];

            data.map(log => {
                const temp = JSON.parse(log.description);

                rows.push(
                    {
                        title: (
                            <span>
                                {temp.log}
                            </span>
                        ),
                        type: (
                            <span>
                                {temp.type}
                            </span>
                        ),
                        addedBy: (
                            <span>
                                {log.actor.firstname}&nbsp;{log.actor.lastname}
                            </span>
                        ),
                        dateTime: (
                            <span>
                                {moment(log.created_at).format("YYYY-MM-DD hh:mm a")}
                            </span>
                        )
                    }
                );
            });

            setLogs((prevState) => ({
                ...prevState, rows: rows
            }));
        })
        .catch((error) => {
            console.log("::fetchWebsiteLogs > error", error);
        });
    }

    const fetchCompaniesOptions = () => {
        API.getCompanies()
        .then(({data}) => {
            let companies = data;
            let options = [];
            
            for (let company of companies) {
                options.push({
                    value: company.id,
                    label: company.name,
                });
            }

            setCompanyOptions(options);
            console.log("::companyOptions", data);
        })
        .catch((error) => {
            console.log("::fetchCompaniesOptions > error", error);
        });
    }

    const fetchAssigneeOptions = () => {
        API.getAdminAndCoders()
        .then(({data}) => {
            let options = [];
            
            for (let user of data) {
                options.push({
                    value: user.id,
                    label: `${user.firstname} ${user.surname}`,
                });
            }

            setAssigneeOptions(options);
            console.log("::assigneeOptions", data);
        })
        .catch((error) => {
            console.log("::fetchAssigneeOptions > error", error);
        });
    }

    const updateUrl = (e) => {
        const value = e.target.value;

        if(value) {
            setWebsiteDetails((prevState) => ({
                ...prevState, name: value
            }));
        }
    }

    // const updateDesigners = (id, prev = null) => {
    //     const {designers} = state;
    //     let prevIndex = prev ? designers.indexOf(prev) : -1;

    //     if(designers.includes(id)) {
    //         // remove id from the array
    //         designers.splice(designers.indexOf(id), 1);
    //     } else {
    //         // check if prev is not null and if it is in the designers array
    //         if(prev && designers.includes(prev)) {
    //             // retrieve prev's index
    //             prevIndex = designers.indexOf(prev);
    //             // remove prev from the array
    //             designers.splice(prevIndex, 1, id);
    //         } else {
    //             designers.push(id);
    //         }
    //     }

    //     setState((prevState) => ({
    //         ...prevState,
    //         designers
    //     }));
    // }

    // const updateDevelopers = (id, prev = null) => {
    //     const {developers} = state;
    //     let prevIndex = prev ? developers.indexOf(prev) : -1;

    //     if(developers.includes(id)) {
    //         // remove id from the array
    //         developers.splice(developers.indexOf(id), 1);
    //     } else {
    //         // check if prev is not null and if it is in the developers array
    //         if(prev && developers.includes(prev)) {
    //             // retrieve prev's index
    //             prevIndex = developers.indexOf(prev);
    //             // remove prev from the array
    //             developers.splice(prevIndex, 1, id);
    //         } else {
    //             developers.push(id);
    //         }
    //     }

    //     setState((prevState) => ({
    //         ...prevState,
    //         developers
    //     }));
    // }

    // const updateProjectManagers = (id, prev = null) => {
    //     const {projectManagers} = state;
    //     let prevIndex = prev ? projectManagers.indexOf(prev) : -1;

    //     if(projectManagers.includes(id)) {
    //         // remove id from the array
    //         projectManagers.splice(projectManagers.indexOf(id), 1);
    //     } else {
    //         // check if prev is not null and if it is in the projectManagers array
    //         if(prev && projectManagers.includes(prev)) {
    //             // retrieve prev's index
    //             prevIndex = projectManagers.indexOf(prev);
    //             // remove prev from the array
    //             projectManagers.splice(prevIndex, 1, id);
    //         } else {
    //             projectManagers.push(id);
    //         }
    //     }

    //     setState((prevState) => ({
    //         ...prevState,
    //         projectManagers
    //     }));
    // }

    // const updateQas = (id, prev = null) => {
    //     const {qas} = state;
    //     let prevIndex = prev ? qas.indexOf(prev) : -1;

    //     if(qas.includes(id)) {
    //         // remove id from the array
    //         qas.splice(qas.indexOf(id), 1);
    //     } else {
    //         // check if prev is not null and if it is in the qas array
    //         if(prev && qas.includes(prev)) {
    //             // retrieve prev's index
    //             prevIndex = qas.indexOf(prev);
    //             // remove prev from the array
    //             qas.splice(prevIndex, 1, id);
    //         } else {
    //             qas.push(id);
    //         }
    //     }

    //     setState((prevState) => ({
    //         ...prevState,
    //         qas
    //     }));
    // }

    // const removeDesigner = (assignee) => {
    //     const {designers} = state;
    //     if(designers.includes(assignee.id)) {
    //         // remove id from the array
    //         designers.splice(designers.indexOf(assignee.id), 1);
    //     }

    //     setState((prevState) => ({
    //         ...prevState,
    //         designers
    //     }));
    // }

    // const removeDeveloper = (assignee) => {
    //     const {developers} = state;
    //     if(developers.includes(assignee.id)) {
    //         // remove id from the array
    //         developers.splice(developers.indexOf(assignee.id), 1);
    //     }

    //     setState((prevState) => ({
    //         ...prevState,
    //         developers
    //     }));
    // }

    // const removeProjectManager = (assignee) => {
    //     const {projectManagers} = state;
    //     if(projectManagers.includes(assignee.id)) {
    //         // remove id from the array
    //         projectManagers.splice(projectManagers.indexOf(assignee.id), 1);
    //     }

    //     setState((prevState) => ({
    //         ...prevState,
    //         projectManagers
    //     }));
    // }

    // const removeQa = (assignee) => {
    //     const {qas} = state;
    //     if(qas.includes(assignee.id)) {
    //         // remove id from the array
    //         qas.splice(qas.indexOf(assignee.id), 1);
    //     }

    //     setState((prevState) => ({
    //         ...prevState,
    //         qas
    //     }));
    // }

    const updatePricingMethod = (e) => {
        const value = e.value;

        if(value) {
            setWebsiteDetails((prevState) => ({
                ...prevState, pricing_method_id: value
            }));
        }
    }

    /** USEEFFECTS / CALLBACKS */
    useEffect(() => {
        if(!urlParams.id) {
            return;
        }

        fetchWebsiteDetails();
        // fetchPricingMethodOptions();
        fetchCompaniesOptions();
        // fetchAssigneeOptions();
    }, []);

    useEffect(() => {
        if(assigneeOptions.length) {
            // check
        }
    }, [assigneeOptions]);

    useEffect(() => {
        fetchWebsiteLogs();
    }, [debouncedSearch]);

    useEffect(() => {
        console.log("::deployment", deploymentPolicy)
    }, [deploymentPolicy]);

    return (
        <React.Fragment>
            <div
                id="snackbar-website"
                onClick={closeToast}
                className="display-none"
            >
                <div className="col-5 notification-image flex-center">
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto">
                    {/* <h5>{this.state.toastData.title}</h5>
                    <p>{this.state.toastData.description}</p> */}
                    <h5>{toastData.title}</h5>
                    <p>{toastData.description}</p>
                </div>
            </div>
            {/* ALERTS - START */}

            {isLoading ? (
                <LoadingIndicator loading={isLoading} />
            ) : (
                ""
            )}

            <div className="col-md-12 row pt-16 pl-0 ml-0" style={{ background: "#F8F8FB" }}>
                <div className="col-md-6 bg-white min-h-dvh w-full pt-8 px-0">
                    {/* WEBSITE DETAILS SECTION */}
                    {/* Header */}
                    <div className="d-flex flex-row justify-between px-8-i">
                        <div>
                            <Link
                                to="websites-list"
                                className="btn btn-icon template back-to-list px-0">
                                <img src={backIcon} /> &nbsp; Back to Websites
                            </Link>
                        </div>
                        <p className="m-0 website-capsule">Website</p>
                    </div>

                    {/* Website and Notes */}
                    <WebsiteForm
                        id={urlParams.id}
                        websiteDetails={websiteDetails}
                        setWebsiteDetails={setWebsiteDetails}
                        companyOptions={companyOptions}
                        showToast={showToast}
                        setToastData={setToastData}
                        view="website-details"
                    />
                </div>

                <div className="website-logs-container col-md-6 p-8-i">
                    {/* LOGS SECTION */}
                    <div className="bg-white p-8">
                        {/* Header */}
                        <div className="d-flex flex-row align-center">
                            {/* Title */}
                            <div className="d-flex" style={{ flex: 1 }}>
                                <h5 className="text-left">Log</h5>
                            </div>

                            {/* Search and Add Log button */}
                            <div className="d-flex flex-row justify-content-end" style={{ flex: 1 }}>
                                {/* Search bar */}
                                {/* <div className="position-relative"> */}
                                <div className="d-flex flex-row border-grey rounded mr-3">
                                    <input
                                        type="text"
                                        className="form-control max-h-9"
                                        placeholder="Search..."
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    {/* <span className="bx bx-search-alt"></span> */}
                                </div>

                                {/* Add Log button */}
                                <button onClick={() => setIsAddWebsiteLogOpen(true)} className="btn btn-primary h-9" disabled={false}>
                                    Add Log
                                </button>
                            </div>
                        </div>

                        {/* Table */}
                        <DataTable
                            data={logs}
                            entriesOptions={[8, 30, 100, 500]}
                            entries={8}
                        />
                    </div>
                </div>
            </div>

            {
                isAddWebsiteLogOpen ?
                    <AddWebsiteLog
                        id={urlParams.id}
                        close={() => setIsAddWebsiteLogOpen(false) }
                        updateList={fetchWebsiteLogs}
                    />
                    : ''
            }
        </React.Fragment>
    );
}

export default React.memo(WebsiteDetails);
// const mapStatetoProps = ({Property}) => {
//     return {
//         propertySearchString: Property.propertySearchString,
//         clearPropertySearchString: Property.clearPropertySearchString
//     };
// };
// export default connect(mapStatetoProps)(WebsiteDetails);