
import React, {useEffect, useState} from 'react';
import Avatar from "./Avatar";
import {getFirstWord} from "../Utils/helper";
import {Api as ApiImage} from "../Utils/consts";
import ScroolUser from "./ScroolUser";

export default function ButtonUser(props) {
    const [state, setState] = useState({
        selected: props.assignee
    });

    const handleOptionSelect = (id, identifier) => {
        setState((prevState) => ({...prevState, selected : id, psOpen: false}));
        props.action(id, props.assignee?.id);
        toggleOptions();
    }

    const handleRemoveAssignee = () => {
        setState((prevState) => ({...prevState, selected : null, psOpen: false}));
        props.actionDelete(props.assignee)
    }

    const toggleOptions = () => {
        props.setActiveOptionIndex(props.index)
    }

    return (
        <React.Fragment>
            <div className="primary-assignee-div" style={{margin: "2px"}}>
                {/* remove assignee button */}
                {props.viewOnly ?
                    null:
                    <div className="close-avatar" onClick={handleRemoveAssignee}>
                        <button className="btn-cirle">
                        {props.load ? (
                            <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
                        ) : (
                            <i className=" fa fa-times" style={{fontSize: 10}}></i>
                        )}
                        </button>
                    </div>
                }

                {/* Avatar */}
                <div onClick={props.viewOnly ? null : toggleOptions}>
                    <Avatar
                    name={` ${getFirstWord(props.assignee?.firstname ?? "")} ${getFirstWord(
                        props.assignee?.surname ?? ""
                    )} `}
                    round={true}
                    size="32"
                    color="#f44336"
                    image={props.avatar ? ApiImage + props.avatar : ""}
                    className="btn-cirle-avatar"
                    />
                </div>

                {/* Assignee Options */}
                {props.viewOnly ?
                    null :
                    <div className="primary-assignee-content">
                        {props.active && (
                            <ScroolUser
                                list={props.options}
                                action={(e) => handleOptionSelect(e, props.identifier)}
                                page="add-website-designer"
                                closeDropdown={toggleOptions}
                            />
                        )}
                    </div>
                }
            </div>
        </React.Fragment>
    );
}
