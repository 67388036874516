import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";

export default function Datepicker(props) {
  const calendarRef = useRef();
  const [date, setDate] = useState("");
  const [time, setTime] = useState({ value: "17:00", label: "05.00 pm" });
  const [propsUpdated, setPropsUpdated] = useState(false);

  const options = [
    { value: "00:00", label: "12:00 am" },
    { value: "00:30", label: "12:30 am" },
    { value: "01:00", label: "01:00 am" },
    { value: "01:30", label: "01:30 am" },
    { value: "02:00", label: "02:00 am" },
    { value: "02:30", label: "02:30 am" },
    { value: "03:00", label: "03:00 am" },
    { value: "03:30", label: "03:30 am" },
    { value: "04:00", label: "04:00 am" },
    { value: "04:30", label: "04:30 am" },
    { value: "05:00", label: "05:00 am" },
    { value: "05:30", label: "05:30 am" },
    { value: "06:00", label: "06:00 am" },
    { value: "06:30", label: "06:30 am" },
    { value: "07:00", label: "07:00 am" },
    { value: "07:30", label: "07:30 am" },
    { value: "08:00", label: "08:00 am" },
    { value: "08:30", label: "08:30 am" },
    { value: "09:00", label: "09:00 am" },
    { value: "09:30", label: "09:30 am" },
    { value: "10:00", label: "10:00 am" },
    { value: "10:30", label: "10:30 am" },
    { value: "11:00", label: "11:00 am" },
    { value: "11:30", label: "11:30 am" },
    { value: "12:00", label: "12:00 pm" },
    { value: "12:30", label: "12:30 pm" },
    { value: "13:00", label: "01:00 pm" },
    { value: "13:30", label: "01:30 pm" },
    { value: "14:00", label: "02:00 pm" },
    { value: "14:30", label: "02:30 pm" },
    { value: "15:00", label: "03:00 pm" },
    { value: "15:30", label: "03:30 pm" },
    { value: "16:00", label: "04:00 pm" },
    { value: "16:30", label: "04:30 pm" },
    { value: "17:00", label: "05:00 pm" },
    { value: "17:30", label: "05:30 pm" },
    { value: "18:00", label: "06:00 pm" },
    { value: "18:30", label: "06:30 pm" },
    { value: "19:00", label: "07:00 pm" },
    { value: "19:30", label: "07:30 pm" },
    { value: "20:00", label: "08:00 pm" },
    { value: "20:30", label: "08:30 pm" },
    { value: "21:00", label: "09:00 pm" },
    { value: "21:30", label: "09:30 pm" },
    { value: "22:00", label: "10:00 pm" },
    { value: "22:30", label: "10:30 pm" },
    { value: "23:00", label: "11:00 pm" },
    { value: "23:30", label: "11:30 pm" },
  ];

  const convertDate = (str) => {
    let date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join("-");
  };

  useEffect(() => {
    if (props.date) {
      let newDate = new Date(props.date);
      let selectedTime = moment(newDate).format("hh:mm a");
      let newSelectedTime = options.find((el) => el.label == selectedTime);
      setPropsUpdated(true);
      if (newSelectedTime && selectedTime != "05:00 pm") {
        setTime(newSelectedTime);
      }
      setDate(Date.parse(props.date));
    }
    if (props.open) {
      calendarRef.current.setOpen(true);
    }
  }, [props.date]);

  useEffect(() => {
    if (props.dueDateUpdate == "openCalender") {
      if (date != "") {
        let newDate = new Date(date);
        newDate.setHours(17, 0);
        if (time) {
          if (time.value === "17:00") {
            newDate.setHours(17, 0);
          } else {
            let currentTime = time.value.split(":");
            let [hrs, minutes] = currentTime;
            if (minutes == "00") {
              newDate.setHours(parseInt(hrs), 0);
            } else {
              newDate.setHours(parseInt(hrs), 30);
            }
          }
        } else {
          newDate.setHours(17, 0);
        }
        if (!props.date) {
          props.updateDueDate(newDate);
        } else {
          if (props.dueDateUpdate === "openCalender") {
            props.updateDueDate(newDate);
            props.updateDueDateVariable("selectNewValue");
          }
        }
      }
    }
  }, [date]);

  useEffect(() => {
    if (props.dueDateUpdate == "openCalender") {
      if (propsUpdated == false) {
        let newDate;
        if (date == "") {
          newDate = new Date();
          newDate.setDate(newDate.getDate() + 1); // add a day
        } else {
          newDate = new Date(date);
        }
        if (time) {
          if (time.value === "17:00") {
            newDate.setHours(17, 0);
          } else {
            let currentTime = time.value.split(":");
            let [hrs, minutes] = currentTime;
            if (minutes == "00") {
              newDate.setHours(parseInt(hrs), 0);
            } else {
              newDate.setHours(parseInt(hrs), 30);
            }
          }
          if (!props.date) {
            props.updateDueDate(newDate);
          } else {
            if (props.dueDateUpdate === "openCalender") {
              props.updateDueDate(newDate);
              props.updateDueDateVariable("selectNewValue");
            }
          }
        }
      }
    }
  }, [time]);

  const handleCalender = () => {
    if (date == "") {
      let date = new Date();
      date.setDate(date.getDate() + 1); // add a day
      setDate(date);
      if (props.updateDueDateVariable) {
        props.updateDueDateVariable("openCalender");
      }
    }
  };

  const handleChangeDate = (e) => {
    if (props.updateDueDateVariable) {
      props.updateDueDateVariable("openCalender");
    }
    setPropsUpdated(false);
    setDate(e);
  };

  const handleTimeChange = (e) => {
    if (props.updateDueDateVariable) {
      props.updateDueDateVariable("openCalender");
    }
    setPropsUpdated(false);
    setTime(e);
  };

  return (
    <React.Fragment>
      <DatePicker
        className={`${
          props.allowPreviousDates ? "" : "form-control form-control-date"
        }`}
        selected={date}
        placeholderText="Click to select a date"
        onChange={(e) => handleChangeDate(e)}
        onFocus={handleCalender}
        dateFormat="MMMM d, yyyy"
      />
      <Select
        className="form-select"
        value={time}
        onChange={(e) => handleTimeChange(e)}
        options={options}
      />
    </React.Fragment>
  );
}
