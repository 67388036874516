import React from "react";
import { Quill } from "react-quill";
import Swal from "sweetalert2";

let _this = "";

function uploadFile(e) {
  const files = e;
  const files_uploaded = [];

  for (let i = 0; i < files.length; i++) {
    files_uploaded.push(files[i]);
  }
  console.log(files_uploaded);
}

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

function imageHandler() {
  var _this = this;

  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();
  input.onchange = async function () {
    const maxSize = 2097152; // 2MB
    const file = input.files[0];
    const fileSize = file.size;

    if (fileSize <= maxSize) {
      var range = _this.quill.getSelection(true);
      var reader = new FileReader();

      reader.onload = function () {
        var dataUrl = reader.result;
        _this.quill.insertEmbed(range.index, "image", dataUrl); // Insert temporary image
        _this.quill.setSelection(range.index + 1); // Move cursor to right side of image (easier to continue typing)
      };
      reader.readAsDataURL(file);
    } else {
      Swal.fire({
        title: "Maximum limit: 2MB",
        text: "The file size exceeds the maximum limit",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar-1",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      image: imageHandler,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const textarea_module_2 = {
  toolbar: {
    container: "#toolbar-textarea-2",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      image: imageHandler,
    },
  },
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const textarea_module_3 = {
  toolbar: {
    container: "#toolbar-textarea-3",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      image: imageHandler,
    },
  },
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

// Quill Toolbar component
export const EditorToolbar = React.memo((props) => {
  _this = props;
  return (
    <div>
      {props.index == 1 ? (
        <div id="toolbar-1">
          {props.form == "email" ? (
            <span className="ql-formats">
              <select className="ql-font" defaultValue="arial">
                <option value="arial">Arial</option>
                <option value="comic-sans">Comic Sans</option>
                <option value="courier-new">Courier New</option>
                <option value="georgia">Georgia</option>
                <option value="helvetica">Helvetica</option>
                <option value="lucida">Lucida</option>
              </select>
              <select className="ql-size" defaultValue="medium">
                <option value="extra-small">1</option>
                <option value="small">2</option>
                <option value="medium">3</option>
                <option value="large">4</option>
              </select>
            </span>
          ) : null}
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="bullet" />
          </span>
          <span className="ql-formats">
            <button className="ql-blockquote" />
          </span>
          <span className="ql-formats">
            <button className="ql-image" />
          </span>
        </div>
      ) : null}

      {/* TEXTAREA TOOLBAR */}
      {props.index == 2 ? (
        <div id="toolbar-textarea-2">
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="bullet" />
            <button className="ql-image" />
          </span>
          <span className="ql-formats">
            <button className="ql-blockquote" />
          </span>
        </div>
      ) : null}

      {props.index == 3 ? (
        <div
          id="toolbar-textarea-3"
          className={props.className ? props.className : ""}>
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="bullet" />
            <button className="ql-image" />
          </span>
          <span className="ql-formats">
            <button className="ql-blockquote" />
          </span>
        </div>
      ) : null}
    </div>
  );
});

export default EditorToolbar;
