import React from "react";
import { Route,Redirect,withRouter } from "react-router-dom";
import {
    getCookie
} from '../../Utils/helper';

const Authmiddleware = ({
	component: Component,
	layout: Layout
}) => (
		<Route
			render={props => {
                // if (!getCookie("access_token")) {
				// 	return (
				// 		<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
				// 	);
				// }
				
				return (
		     	    <Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

export default withRouter(Authmiddleware);

