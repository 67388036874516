import React, { useEffect } from 'react';
import Api from "../Utils/API";
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import Select from "react-select";
import {
    customStyles,
} from '../Utils/helper';
import { urlValidator } from '../Utils/helper';
import {
    handleErrors,
} from '../Utils/handleError';
import TeamAssignmentRow from './PropertyManagement/TeamAssignmentRow';

const defaultCredentials = [];

// function ButtonUser(props) {
//     return (
//         <button className="btn btn-cirle-dash ml-1" onClick={props.action}  >
//             <img src={userIcon} />
//         </button>
//     )
// }

// function ButtonUserOnly(props) {
//     return (
//         <div className="primary-assignee-div"  >
//             <div className="close-avatar" onClick={props.actionDelete} >
//                 {/* <img src={deleteIcon} /> */}
//                 <button className="btn-cirle" > <i className=" fa fa-times" style={{ fontSize: 10 }} ></i> </button>
//             </div>
//             <div onClick={props.action} >
//                 <Avatar
//                     name={` ${getFirstWord(props.details.firstname)} ${getFirstWord(props.details.surname)} `}
//                     //name="Niel Daculan"
//                     round={true}
//                     size="32"
//                     color="#f44336"
//                     image={ApiImage + props.details.avatar}
//                     className="btn-cirle-avatar"
//                 />
//             </div>
//         </div>
//     )
// }

// function WebHostDiv(props, i) {
//     return (
//         <React.Fragment>
//             <h4 className="website-font mt-4">Web Host</h4>
//             <div className="row ml-2">
//                 <div className="col-lg-6">
//                     <div className="contact-group">
//                         <div className="row-1">
//                             Username
//                         </div>
//                         <div className="row-2">
//                             <input counter={props.i} name="website" className="form-control" placeholder="Enter Username" value={props.items.website || ''} onChange={props.handleChange} />
//                         </div>
//                     </div>
//                     <div className="contact-group">
//                         <div className="row-1">
//                             Company
//                         </div>
//                         <div className="row-2">
//                             <Select
//                                 className="form-select ml-1"
//                                 styles={customStyles}
//                                 options={props.companiesOptions}
//                                 placeholder="Select Company"
//                                 noOptionsMessage={() => null}
//                                 components={{
//                                     IndicatorSeparator: () => null
//                                 }}
//                             />
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-lg-6">
//                     {/* <div className="contact-group">
//                         <div className="row-1">
//                             Primary Contact
//                         </div>
//                         <div className="row-2">
//                             <Select
//                                 className="form-select ml-1"
//                                 styles={customStyles}
//                                 options={props.companiesOptions}
//                                 placeholder="Select Contact"
//                                 noOptionsMessage={() => null}
//                                 components={{
//                                     IndicatorSeparator: () => null
//                                 }}
//                             />
//                         </div>
//                     </div> */}
//                     <div className="contact-group">
//                         <div className="row-1">
//                             Credentials
//                         </div>
//                         <div className="row-2">
//                             <input className="form-control" placeholder="Enter Credentials" counter={props.i} name="credentail" value={props.items.credential || ''} onChange={props.handleChange} />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </React.Fragment>
//     )
// }

// function WebSiteAdmin(props, i) {
//     return (
//         <React.Fragment>
//             Website Admin form here
//             {/* <h4 className="website-font mt-4">Website Admin</h4>
//             <div className="row ml-2">
//                 <div className="col-lg-6">
//                     <div className="contact-group">
//                         <div className="row-1">
//                             Username
//                         </div>
//                         <div className="row-2">
//                             <input className="form-control" placeholder="Enter Username" value={state.state} onChange={(e) => { setState({ ...state, state: e.target.value, stateError: '' }) }} />
//                             {
//                                 state.stateError ?
//                                     <label className="eror-fields">{state.stateError}</label>
//                                     : ''
//                             }
//                         </div>
//                     </div>
//                     <div className="contact-group">
//                         <div className="row-1">
//                             URL
//                         </div>
//                         <div className="row-2">
//                             <input className="form-control" placeholder="Enter URL" value={state.state} onChange={(e) => { setState({ ...state, state: e.target.value, stateError: '' }) }} />
//                             {
//                                 state.stateError ?
//                                     <label className="eror-fields">{state.stateError}</label>
//                                     : ''
//                             }
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-lg-6">
//                     <div className="contact-group">
//                         <div className="row-1">
//                             Password
//                         </div>
//                         <div className="row-2">
//                             <input className="form-control" placeholder="Enter Password" value={state.state} onChange={(e) => { setState({ ...state, state: e.target.value, stateError: '' }) }} />
//                             {
//                                 state.stateError ?
//                                     <label className="eror-fields">{state.stateError}</label>
//                                     : ''
//                             }
//                         </div>
//                     </div>
//                 </div>
//             </div> */}
//         </React.Fragment>
//     )
// }

// function Sftp(props, i) {
//     return (
//         <React.Fragment>
//             Sftp form here
//         </React.Fragment>
//     )
// }

// function Other(props, i) {
//     return (
//         <React.Fragment>
//             Other form here
//         </React.Fragment>
//     )
// }

// function CreateUI(props) {
//     return props.items.map((el, i) =>
//         <div key={i} className="mb-4">
//             {
//                 el.type === '1' ?
//                     <WebHostDiv
//                         handleChange={props.handleChange}
//                         items={el}
//                         i={i}
//                     />
//                     :
//                     (el.type === '2' ?
//                         <WebSiteAdmin
//                             handleChange={props.handleChange}
//                             items={el}
//                             i={i}
//                         />
//                         :
//                         (el.type === '3' ?
//                             <Sftp
//                                 handleChange={props.handleChange}
//                                 items={el}
//                                 i={i}
//                             />
//                             :
//                             (el.type === '4' ?
//                                 <Other
//                                     handleChange={props.handleChange}
//                                     items={el}
//                                     i={i}
//                                 /> : ''
//                             )
//                         )
//                     )
//             }
//         </div>

//     )
// }

export const UpdateEnvironment = React.memo(props => {
    const [state, setState] = React.useState({
        company_id: props.companyId,
        ismoreCredential: false,
        moreDetails: [],
        name: '',
        id: 0,
        primary_contact: [],
        username: '',
        root_login: '',
        password: '',
        credentials: defaultCredentials,
        contacts: props.contacts,
        isSubmit: false,
        companies: props.companies || [],
        companyOptions: [],
        selectedCompany: '',
        isMinimized: false,
        pricingMethods: [],
        projectManagers: [],
        developers: [],
        qas: [],
        designers: [],
        pricing: null,
    });

    useEffect(() => {
        const companies = [];
        let selectedCompany = ''
        let property = props.propertyDetails;
        let propery_details = typeof property.details == 'string' ? JSON.parse(property.details) : property.details;
        const assignees = props.propertyDetails.assignees;
        const projectManagers = [];
        const designers = [];
        const developers = [];
        const qas = [];

        // let primary_contact = {
        //     label: `${property.primary_contact.firstname} ${property.primary_contact.surname}`,
        //     value: property.primary_contact.id
        // }

        if(state.companies.length > 0){
            state.companies.map(company => {
                let company_details = {value: company.id, label: company.name}
                companies.push(company_details)
            });

            selectedCompany = {
                value: property.company.id,
                label: property.company.name
            }
        }

        const pricing = {
            value: property.pricing?.id,
            label: property.pricing?.name
        }

        if(assignees.length) {
            assignees.map((assignee) => {
                switch (assignee.type) {
                    case "project manager":
                        return projectManagers.push(assignee.user.id);
                    case "designer":
                        return designers.push(assignee.user.id);
                    case "qa":
                        return qas.push(assignee.user.id);
                    default:
                        return developers.push(assignee.user.id);
                }
            });

            setState({
                ...state,
                id: property.id,
                name: property.name,
                environment: propery_details.environment,
                hosted_by: propery_details.environment,
                // primary_contact: primary_contact,
                credentials: props.propertyDetails.credentials,
                companyOptions: companies,
                selectedCompany: selectedCompany,
                projectManagers,
                designers,
                developers,
                qas,
                pricing
            });
        } else {
            setState({
                ...state,
                id: property.id,
                name: property.name,
                username: propery_details.username,
                root_login: propery_details.root_login,
                password: propery_details.password,
                companyOptions: companies,
                selectedCompany: selectedCompany,
                projectManagers,
                designers,
                developers,
                qas,
                pricing
            });
        }

        retrieveContacts(props.propertyDetails.company_id);
    }, [props, JSON.stringify(props.propertyDetails.assignees)]);

    useEffect(() => {
        const property = props.propertyDetails;
        Api.getPricingMethodOptions().then(result => {
            setState((prevState) => ({
                ...prevState,
                pricingMethods: result.data,
                // pricing: .filte,
                pricing: result.data.filter(opt => opt.value == props.propertyDetails.pricing_method_id) ?? null
            }));
        }).catch(error => {
            console.log(error);
            handleErrors();
        });
    }, [JSON.stringify(props.propertyDetails)]);

    function updateCredentials(index, data, subdata, _value) {
        let temp = state.credentials;

        if (subdata != null)
            temp[index][data][subdata] = _value;
        else
            temp[index][data] = _value;

        setState({ ...state, credentials: temp })
    }

    function submitEnvironment(event) {
        event.preventDefault();
        setState({ ...state, isSubmit: true });

        // validate url using urlValidator(string: url, boolean(optional, defaults to false): isRequired)
        let nameError = urlValidator(state.name, true);

        const params = {
            id: state.id,
            name: state.name,
            company_id: state.selectedCompany.value || state.company_id,
            primary_contact_id: state.primary_contact.value,
            type: 'environment',
            details: {
                root_login: state.root_login,
                username: state.username,
                password: state.password
            },
            credentials: state.credentials,
            pricing_id: state.pricing?.value,
            project_managers: state.projectManagers,
            developers: state.developers,
            qas: state.qas,
            designers: state.designers
        }

        if (nameError) 
            return setState({ ...state, nameError: nameError });

        Api.updateCompanyProperties(params).then(result => {
            // params.id = result.data;
            props.updateProperty(params)
            setState({
                ...state,
                credentials: defaultCredentials,
                ismoreCredential: false,
                description: '',
                moreDetails: [],
                url: '',
                primary_contact: [],
                root_login: '',
                username: '',
                password: '',
                isSubmit: false
            });
            // showToast();
        }).catch(error => {
            console.log(error);
            handleErrors();
            setState({ ...state, isSubmit: false });
        });
    }

    const moreDetails = (e) => {
        let values = [...state.moreDetails];
        values.push({
            type: e.target.getAttribute("type"),
            url: '', contact_id: '', company_id: '', credentail: '',
            username: '', password: '',
            subject: ''
        });
        setState({ ...state, moreDetails: values, ismoreCredential: false });
    }

    function handleChange(event) {
        let values = [...state.moreDetails];
        values[event.target.getAttribute('counter')][event.target.getAttribute('name')] = event.target.value;
        setState({ ...state, moreDetails: values });
    }

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setState({
            ...state, isMinimized: true
        })
    }

    function openForm(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');

        setState({
            ...state, isMinimized: false
        })
    }

    const retrieveContacts = (compId) => {
        Api.getCompanyContacts(compId).then(result => {
            const contactOptions = result.data;
            const selectedContact = contactOptions.filter(cont => cont.value === JSON.parse(props.propertyDetails.details).contact_id);
            setState((prevState) => ({
                ...prevState,
                contactOptions,
                selectedContact
            }));
        }).catch(error => {
            console.log(error);
            handleErrors();
        });
    }

    const updateDesigners = (id, prev = null) => {
        const {designers} = state;
        let prevIndex = prev ? designers.indexOf(prev) : -1;

        if(designers.includes(id)) {
            // remove id from the array
            designers.splice(designers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the designers array
            if(prev && designers.includes(prev)) {
                // retrieve prev's index
                prevIndex = designers.indexOf(prev);
                // remove prev from the array
                designers.splice(prevIndex, 1, id);
            } else {
                designers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            designers
        }));
    }

    const updateDevelopers = (id, prev = null) => {
        const {developers} = state;
        let prevIndex = prev ? developers.indexOf(prev) : -1;

        if(developers.includes(id)) {
            // remove id from the array
            developers.splice(developers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the developers array
            if(prev && developers.includes(prev)) {
                // retrieve prev's index
                prevIndex = developers.indexOf(prev);
                // remove prev from the array
                developers.splice(prevIndex, 1, id);
            } else {
                developers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            developers
        }));
    }

    const updateProjectManagers = (id, prev = null) => {
        const {projectManagers} = state;
        let prevIndex = prev ? projectManagers.indexOf(prev) : -1;

        if(projectManagers.includes(id)) {
            // remove id from the array
            projectManagers.splice(projectManagers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the projectManagers array
            if(prev && projectManagers.includes(prev)) {
                // retrieve prev's index
                prevIndex = projectManagers.indexOf(prev);
                // remove prev from the array
                projectManagers.splice(prevIndex, 1, id);
            } else {
                projectManagers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            projectManagers
        }));
    }

    const updateQas = (id, prev = null) => {
        const {qas} = state;
        let prevIndex = prev ? qas.indexOf(prev) : -1;

        if(qas.includes(id)) {
            // remove id from the array
            qas.splice(qas.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the qas array
            if(prev && qas.includes(prev)) {
                // retrieve prev's index
                prevIndex = qas.indexOf(prev);
                // remove prev from the array
                qas.splice(prevIndex, 1, id);
            } else {
                qas.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            qas
        }));
    }

    const removeDesigner = (assignee) => {
        const {designers} = state;
        if(designers.includes(assignee.id)) {
            // remove id from the array
            designers.splice(designers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            designers
        }));
    }

    const removeDeveloper = (assignee) => {
        const {developers} = state;
        if(developers.includes(assignee.id)) {
            // remove id from the array
            developers.splice(developers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            developers
        }));
    }

    const removeProjectManager = (assignee) => {
        const {projectManagers} = state;
        if(projectManagers.includes(assignee.id)) {
            // remove id from the array
            projectManagers.splice(projectManagers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            projectManagers
        }));
    }

    const removeQa = (assignee) => {
        const {qas} = state;
        if(qas.includes(assignee.id)) {
            // remove id from the array
            qas.splice(qas.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            qas
        }));
    }

    const selectPricing = (e) => {
        setState({ ...state, pricing: e });
    }

    return (
        <React.Fragment>
            <div className="contact-form website-form"  >
                <div className="form-header" onClick={(e) => openForm(e)} >
                    <div className="row-1">Update Environment</div>
                    <div className="row-2">
                        {state.isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                <div style={{borderBottom: "1px solid #ECECF5", padding: "0px", paddingBottom: "22px"}}>
                        <h4 className="website-font mt-4">Team</h4>
                        <div className="row ml-2">
                            <div className="col-lg-6" style={{paddingLeft: 0}}>
                                <div className="contact-group" style={{borderBottom: 'none'}}>
                                    <TeamAssignmentRow
                                        projectManagers={state.projectManagers}
                                        developers={state.developers}
                                        qas={state.qas}
                                        designers={state.designers}
                                        pricing={state.pricing}
                                        updateProjectManagers={updateProjectManagers}
                                        updateDevelopers={updateDevelopers}
                                        updateQas={updateQas}
                                        updateDesigners={updateDesigners}
                                        removeProjectManager={removeProjectManager}
                                        removeDeveloper={removeDeveloper}
                                        removeQa={removeQa}
                                        removeDesigner={removeDesigner}
                                    />
                                </div>
                            </div>
                            {state.companies.length > 0 &&
                                <div className="col-lg-6">
                                    <div className="contact-group">
                                        <div className="row-1">
                                            Pricing Method
                                        </div>
                                        <div className="row-2">
                                            <Select
                                                className="form-select ml-1"
                                                styles={customStyles}
                                                value={state.pricing}
                                                onChange={(e) => selectPricing(e)}
                                                options={state.pricingMethods}
                                                placeholder="Select Method"
                                                noOptionsMessage={() => null}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />
                                            {state.companyError ? <label className="eror-fields">{state.companyError}</label> : ''}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <h4 className="website-font mt-4">Environment</h4>
                    <div className="row ml-2">
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    Name
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter URL" value={state.name || ''} onChange={(e) => { setState({ ...state, name: e.target.value, urlError: '' }) }} />
                                    {state.nameError ? <label className="eror-fields">{state.nameError}</label> : ''}
                                </div>
                            </div> 
                            {/* <div className="contact-group">
                                <div className="row-1">
                                    Username
                                    </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Email/Username" value={state.username || ''} onChange={(e) => { setState({ ...state, username: e.target.value, usernameError: '' }) }} />
                                    {state.usernameError ? <label className="eror-fields">{state.usernameError}</label> : ''}
                                </div>
                            </div> */}
                        </div>
                        <div className="col-lg-6">
                            {/* <div className="contact-group">
                                <div className="row-1">
                                    Root Login
                                    </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Email/Username" value={state.root_login || ''} onChange={(e) => { setState({ ...state, root_login: e.target.value, rootLoginError: '' }) }} />
                                    {state.rootLoginError ? <label className="eror-fields">{state.rootLoginError}</label> : ''}
                                </div>
                            </div>
                            <div className="contact-group">
                                <div className="row-1">
                                    Password
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Password" value={state.password || ''} onChange={(e) => { setState({ ...state, password: e.target.value, passwordError: '' }) }} />
                                    {state.passwordError ? <label className="eror-fields">{state.passwordError}</label> : ''}
                                </div>
                            </div> */}
                            {state.companies.length > 0 ? (
                                <div className="contact-group">
                                    <div className="row-1">
                                        Company
                                    </div>
                                    <div className="row-2">
                                        <Select
                                            className="form-select ml-1"
                                            styles={customStyles}
                                            value={state.selectedCompany}
                                            onChange={(e) => { setState({ ...state, selectedCompany: e }) }}
                                            options={state.companyOptions}
                                            placeholder="Select Company"
                                            noOptionsMessage={() => null}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                        {state.companyError ? <label className="eror-fields">{state.companyError}</label> : ''}
                                    </div>
                                </div>
                            ) : null }
                        </div>
                    </div>
                    {/* <h4 className="website-font mt-4">Web Host</h4>
                    <div className="row ml-2">
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    Username
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Username" value={state.credentials[0].username} onChange={(e) => updateCredentials(0, 'details', 'username', e.target.value)} />
                                    {
                                        state.stateError ?
                                            <label className="eror-fields">{state.stateError}</label>
                                            : ''
                                    }
                                </div>
                            </div>
                            <div className="contact-group">
                                <div className="row-1">
                                    URL
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter URL" value={state.credentials[0].url} onChange={(e) => updateCredentials(0, 'details', 'url', e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    Password
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Password" value={state.credentials[0].password} onChange={(e) => updateCredentials(0, 'details', 'password', e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <h4 className="website-font mt-4">Website Admin</h4>
                    <div className="row ml-2">
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    Username
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Username" value={state.credentials[1].username} onChange={(e) => updateCredentials(1, 'details', 'username', e.target.value)} />
                                    {
                                        state.stateError ?
                                            <label className="eror-fields">{state.stateError}</label>
                                            : ''
                                    }
                                </div>
                            </div>
                            <div className="contact-group">
                                <div className="row-1">
                                    URL
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter URL" value={state.credentials[1].url} onChange={(e) => updateCredentials(1, 'details', 'url', e.target.value)} />
                                    {
                                        state.stateError ?
                                            <label className="eror-fields">{state.stateError}</label>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    Password
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Password" value={state.credentials[1].password} onChange={(e) => updateCredentials(1, 'details', 'password', e.target.value)} />
                                    {
                                        state.stateError ?
                                            <label className="eror-fields">{state.stateError}</label>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <h4 className="website-font mt-4">SFTP</h4>
                    <div className="row ml-2">
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    Username
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Username" value={state.credentials[2].username} onChange={(e) => updateCredentials(2, 'details', 'username', e.target.value)} />
                                </div>
                            </div>
                            <div className="contact-group">
                                <div className="row-1">
                                    URL
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter URL" value={state.credentials[2].url} onChange={(e) => updateCredentials(2, 'details', 'url', e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    Password
                                    </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Password" value={state.credentials[2].password} onChange={(e) => updateCredentials(2, 'details', 'password', e.target.value)} />
                                </div>
                            </div>
                            <div className="contact-group">
                                <div className="row-1">
                                    Port
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Port" value={state.credentials[2].port} onChange={(e) => updateCredentials(2, 'details', 'port', e.target.value)} />
                                    {
                                        state.stateError ?
                                            <label className="eror-fields">{state.stateError}</label>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="w-400 mt-4"> 
                        <CreateUI
                            items={state.credentials}
                            companiesOptions={state.companiesOptions}
                            handleChange={handleChange}
                        />
                        {
                            state.ismoreCredential ?
                                (
                                    <div className="select-type-content">
                                        <div className="select-type">
                                            <div className="select-type-list">
                                                <div className="select-type-title">
                                                    Select Type
                                            </div>
                                                <ul>
                                                    <li type={1} onClick={moreDetails}>Web Host</li>
                                                    <li type={2} onClick={moreDetails}>Website Admin</li>
                                                    <li type={3} onClick={moreDetails}>SFTP</li>
                                                    <li type={4} onClick={moreDetails}>Other</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : ''
                        }
                        <div className="new-card-div ripple" onClick={() => (setState({ ...state, ismoreCredential: !state.ismoreCredential }))} >
                            <img src={addCardIcon} />
                            <span>Add More Credentials</span>
                        </div>
                    </div> */}

                    <div className="form-action">
                        <button onClick={submitEnvironment} className="btn btn-primary btn-large" disabled={state.isSubmit}>
                            {
                                state.isSubmit ?
                                    (<i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i>)
                                    : ''
                            }
                            Save
                       </button>
                    </div>

                </div>
            </div>
        </React.Fragment >
    );
});

export default UpdateEnvironment;
