
import React, { useState, useEffect } from 'react';
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import imageIcon from "../assets/images/inviteImage.png";
import WebsiteForm from '../pages/Properties/website-form';
import API from '../Utils/API';

export const PropertyInformation = (props) => {

    const [state, setState] = React.useState({
        property_details: {},
        credentials: [],
        projectManagers: [],
        developers: [],
        qas: [],
        designers: [],
        pricing: null,
    });

    const [toastData, setToastData] = useState({ title: "", description: "" });
    const [companyOptions, setCompanyOptions] = useState([]);

    const fetchCompaniesOptions = () => {
        API.getCompanies()
        .then(({data}) => {
            let companies = data;
            let options = [];
            
            for (let company of companies) {
                options.push({
                    value: company.id,
                    label: company.name,
                });
            }

            setCompanyOptions(options);
            console.log("::companyOptions", data);
        })
        .catch((error) => {
            console.log("::fetchCompaniesOptions > error", error);
        });
    }

    function showToast(title = "", description = "") {
        setToastData({
            title,
            description
        });

        var x = document.getElementById("snackbar-website-details"); 
        x.className = "show row notification-div";
        setTimeout( ()=> { closeToast(); }, 5000);
    }

    function closeToast() {
        var x = document.getElementById("snackbar-website-details"); 
        if(x)
            x.className = x.className.replace("show", "");
    }

    useEffect(() => {
        setState({
            ...state,
            property_details: props.propertyDetails,
            credentials: props.propertyDetails.credentials,
        });
        fetchCompaniesOptions();
    }, []);

    useEffect(() => {
        let property = props.propertyDetails;
        let propery_details = typeof property.details == 'string' ? JSON.parse(property.details) : property.details;

        // if credential details is fetched as string
        props.propertyDetails.credentials.forEach(credential => {
            if(typeof credential.details == 'string') {
                let credential_details = JSON.parse(credential.details);
                credential.details = credential_details;
            }
        });

        const assignees = props.propertyDetails.assignees;
        const projectManagers = [];
        const designers = [];
        const developers = [];
        const qas = [];

        if(assignees.length) {
            assignees.map((assignee) => {
                switch (assignee.type) {
                    case "project manager":
                        return projectManagers.push(assignee.user.id);
                    case "designer":
                        return designers.push(assignee.user.id);
                    case "qa":
                        return qas.push(assignee.user.id);
                    default:
                        return developers.push(assignee.user.id);
                }
            });
        }

        setState({
            projectManagers,
            designers,
            developers,
            qas,
            credentials: props.propertyDetails.credentials,
            propery_details: props.propertyDetails,
        });
    }, [JSON.stringify(props.propertyDetails)]);

    return (
        <React.Fragment>
            <div
                id="snackbar-website-details"
                onClick={closeToast}
                className="display-none"
            >
                <div className="col-5 notification-image flex-center">
                    <img src={imageIcon} />
                </div>
                <div className="col-7 notification-details my-auto">
                    {/* <h5>{this.state.toastData.title}</h5>
                    <p>{this.state.toastData.description}</p> */}
                    <h5>{toastData.title}</h5>
                    <p>{toastData.description}</p>
                </div>
            </div>

            <div className="contact-form property-information-form">
                <div className="form-header" onClick={props.openPropertInfo} >
                    <div className="row-1">{props.propertyDetails.name}</div>
                    <div className="row-2">
                        {props.isMinimized ?
                            <button className="btn btn-icon box-shadow-none-i" onClick={props.openPropertInfo} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon box-shadow-none-i" onClick={props.minimize}> <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon box-shadow-none-i" onClick={props.close}> <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                    {
                        props.propertyDetails && <WebsiteForm
                            id={props.propertyDetails.id}
                            websiteDetails={props.propertyDetails}
                            // setWebsiteDetails={(details) => {
                            //     setState({
                            //         ...state, property_details: details
                            //     });
                            // }}
                            companyOptions={companyOptions}
                            showToast={showToast}
                            setToastData={setToastData}
                        />
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default PropertyInformation;
