

import React, { useState } from 'react';
import NumberPickerData from "../NumberPickerData";
import clockIcon from "../../assets/images/clockIcon.svg";

export const TimePicker = (props) => {
    const [showTP, setShowTP] = useState(false);

    function updateTime(e) {
        props.update(e);
        setShowTP(false);
    }

    function hideTimePicker() {
        setShowTP(false);
    }

    function showTimePicker() {
        setShowTP(true);
    }

    return (
        <React.Fragment>
            <div className="actual-time actual-time-and-estimate" style={{flex: 1, flexDirection: "column"}}>
                <h6 className="title-1">{props.title}</h6>
                <img src={clockIcon} />
                <span className={props.viewOnly ? "time-1 ml-2 default-cursor" : "time-1 ml-2"} onClick={props.viewOnly ? null : showTimePicker}>
                {props.hr}:{props.min}
                </span>
                <div className="timer-picker-div">
                {showTP && (
                    <NumberPickerData
                    hr={props.hr}
                    min={props.min}
                    type={props.actualTimeAction}
                    action={updateTime}
                    // loading={state.isSaveTime}
                    loading={null}
                    cancel={hideTimePicker}
                    />
                )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default TimePicker;
