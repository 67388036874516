import React, { useEffect, useState } from "react";
import Switch from "react-switch";

function SwitchData(props) {

    const [state, setState] = useState(props.state ?? false);

    useEffect(() => {
        setState(props.state);
    }, [props.state]);

    function handleChange(checked) {
        props.toggleStatus();
    }

    return (
        <React.Fragment>
            <label htmlFor="icon-switch">
                <Switch
                    checked={state}
                    onChange={handleChange}
                    onColor="#2F80ED"
                    uncheckedIcon={
                        <div className="switch-div" >
                            {props.offText ?? "Off"}
                        </div>
                    }
                    checkedIcon={
                        <div className="switch-div">
                            {props.onText ?? "On"}
                        </div>
                    }
                    className="react-switch"
                    id="icon-switch"
                    height={props.height ?? 28}
                    width={props.width ?? 60}
                />
            </label>
        </React.Fragment>
    );

}

export default SwitchData;