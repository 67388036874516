// @flow
import {
    SEARCH_CONTACTS
} from "./actionTypes";

const INIT_STATE = {
	contactSearchString: ""
};

const Contact = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SEARCH_CONTACTS:
			return {
				...state,
				contactSearchString: action.payload
			};

		default:
			return state;
	}
};

export default Contact;
